import { upperFirst, camelCase } from 'lodash';


// Auto import components
export default {
  install(vue, options) {
    options.requireComponent.keys().forEach((fileName) => {
      // Get component config
      const componentConfig = options.requireComponent(fileName);

      // Get PascalCase name of component
      const componentName = upperFirst(
        camelCase(
          // Strip the leading `./` and extension from the filename
          fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
        )
      );

      // Register component globally
      vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      );
    });
  }
};
