import store from '@/store';

/**
 * Check if the user is in a role.
 * @param {string} roleToCheck The role to check that the user is a member of.
 * @returns {boolean} Returns true if the user is in the role.
 */
export function isInRole(roleToCheck) {
  return isInSomeRole([roleToCheck]);
}

/**
 * Check if the user is in one of the roles.
 * @param {string[]} rolesToCheckFor The list to check that the user is a member of.
 * @returns {boolean} Returns true if the user is in one of the roles.
 */
export function isInSomeRole(rolesToCheckFor) {
  const userRoles = store.state.auth.roles;
  if (userRoles) {
    return rolesToCheckFor.some(roleToCheck =>
      userRoles.some(role => role === roleToCheck)
    );
  }
  return false;
}
