<template>
    <v-dialog max-width="560" v-model="showDialog" persistent>
        <v-card>
            <v-card-title class="headline">{{header}}</v-card-title>
                <v-card-text class="container-background">
                    <v-row align="center"> 
                        <v-col align="center">
                            <div class="container-width">
                                <pdf 
                                :src="url"
                                @num-pages="pageCount = $event"
                                @page-loaded="currentPage = $event"
                                :page="currentPage"
                                ></pdf>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                    <v-card-actions>
                        <div style="padding-right:18px">{{currentPage}} / {{pageCount}}</div>
                        <v-btn @click="leftClick" >
                            <v-icon>chevron_left</v-icon>
                        </v-btn>
                        <v-btn @click="rightClick">
                            <v-icon>chevron_right</v-icon>
                        </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn  color="secondary lighten-3" @click="() => this.$emit('close')" id="closeButton">Close</v-btn>
                    <v-btn color="orange lighten-1" @click="onPrint" id="printButton">
                        <v-icon>print</v-icon>
                    </v-btn>
                    <v-btn :disabled="loading"
                    :loading="loading" color="orange lighten-1" @click="onSave" id="saveButton">Save File</v-btn>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { UI } from '@/store/ui';
import pdf from 'vue-pdf'

export default {
  components: {
    pdf
  },
  props: {
    header: {
        type: String
    },  
    showDialog: {
        type: Boolean,
    },
    url:{
        type: String,
    },
    data:{
        type: Blob
    },
    fileName: {
       type: String, 
    }
  },
  data(){
    return {
        loading:false,
        pageCount: 1,
        currentPage: 1
    }
  },
  watch: {
    showDialog: {
      immeditate: true,
      handler(showDialog) {
        if(!showDialog){  
            this.pageCount = 1;
            this.currentPage = 1;
        }
      }
    }
  },
  methods: {
    leftClick(){
        if(this.currentPage > 1) this.currentPage--;
    },
    rightClick(){
        if(this.currentPage < this.pageCount) this.currentPage++;
    },
    async onSave(){
        this.loading = true;
        try {
            const fileHandler = await window.showSaveFilePicker({
            suggestedName: this.fileName,
            types: [{
                description: 'pdf',
                accept: {
                'application/pdf': ['.pdf'],
                },
            }],
            });
            const file = await fileHandler.createWritable();
            await file.write(this.data);
            await file.close();
            this.loading = false;
            await this.$store.dispatch(
            UI.NS(UI.ACTIONS.ADD_TOAST),
            { 
                text: "Successfully saved pdf"
            },
            { root: true }
            );
        }catch(e){
            if(e instanceof  DOMException){
                this.loading = false;
                return;
            }
            await this.$store.dispatch(
            UI.NS(UI.ACTIONS.ADD_TOAST),
            { 
                text: "Failed saving pdf"
            },
            { root: true }
            );
        }
    },
    async onPrint(){
        window.open(this.url, '_blank').focus();
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/styles/_responsive.scss';
@import '../assets/styles/_variables.scss';
.container-background{
    background: #F0F0F0
}
.container-width{
    width:460px;
}
</style>