export function mapProductToColor(product, border = false) {
  const transparency = border ? 1.0 : 0.8;

  switch (product.toLowerCase()) {
    case 'ago':
      return `hsla(120, 100%, 25%, ${transparency})`; // Green
    case 'lfo':
      return `hsla(0, 100%, 25%, ${transparency})`; // Maroon
    case 'pms':
      return `hsla(0, 100%, 50%, ${transparency})`; // Red
    case 'rms':
      return border ? `hsla(0, 0%, 75%, ${transparency})` : `hsla(0, 0%, 100%, ${transparency})`; // Silver for white background
    case 'jeta1':
      return `hsla(0, 0%, 0%, ${transparency})`; // Black
    case 'b100':
      return `hsla(300, 100%, 25%, ${transparency})`; // Purple
    case 'slops':
      return `hsla(60, 100%, 50%, ${transparency})`; // Yellow
  }
}

export function mapProductToTextColor(product) {
  switch (product.toLowerCase()) {
    case 'rms':
    case 'slops':
      return `hsla(0, 0%, 0%, 0.9)`;  // Black Text
    default:
      return `hsla(0, 0%, 100%, 0.9)`;  // White Text
  }
}