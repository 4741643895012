import { http } from '@/api';

export default {
  getShipPortPerformance: ({ planId }) => http.get(
      `v1/shipPortPerformance/planId/${planId}`
    ),
  updateShipPortPerformance: ({ planId, submissionResults, sectionComments, shipPerformanceStatus }) => http.post(
    `v1/shipPortPerformance/planId/${planId}`, {
      checklistSubmissionResults: submissionResults,
      shipPortPerformanceSectionComments: sectionComments,
      shipPortPerformanceStatusId: shipPerformanceStatus
    }
  ),
  getShipPortPerformanceReportPdf: ({ planId }) => http.get(
    `v1/shipPortPerformance/planId/${planId}/shipPortPerformanceReport/pdf`, { responseType: 'arraybuffer' }
  ),
  emailShipPortPerformanceReportPdf: ({ planId, emailModel }) => http.put(
    `v1/shipPortPerformance/planId/${planId}/shipPortPerformanceReport/email`, { 
      emailModel: emailModel
    }
  )
}