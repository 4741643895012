export const AUTH_GETTERS = {
  GET_USERNAME: 'getUsername',
  GET_ASSUMED_ROLE: 'getAssumedRole'
};

export const getters = {
  [AUTH_GETTERS.GET_USERNAME](state) {
    return state.user && state.user.profile && state.user.profile.name;
  },
  [AUTH_GETTERS.GET_ASSUMED_ROLE](state) {
    if (state.roles) {
      if (state.isAssumingRole) {
        // It is only possible to assume a single role so this is safe to do.
        return state.roles[0];
      }
    }
    return undefined;
  }
};
