<template>
  <v-flex mt-1 pa-1>
    <levels-of-concern-edit-modal v-if="selectedTank && dialogs.loc.show" :showDialog="dialogs.loc.show"
      :selectedTank="selectedTank" v-on:close-modal="() => changeShowDialog(dialogs.loc)"
      v-on:close-modal-saved="() => changeShowDialogSaved(dialogs.loc)" :tankNames="tankNames" />
    <levels-of-concern-tank-calibration-modal v-if="selectedTank && dialogs.tankCalibrations.show"
      :showDialog="dialogs.tankCalibrations.show" :selectedTank="selectedTank"
      v-on:close-modal="() => changeShowDialog(dialogs.tankCalibrations)"
      v-on:close-modal-saved="() => changeShowDialogSaved(dialogs.tankCalibrations)" />

    <v-container class="slider-container" fluid fill-height>
      <v-slide-group v-if="activeTerminal" show-arrows center-active selected-class="bg-primary" id="slide-group">
        <div v-for="tank in sortedTerminalTechnicalDetails" v-bind:key="tank.id">
          <v-card class="tank-column">
              <tr>
                <th id="tank-name-and-product" :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <v-flex xs12 class="upper-case" :style="{
                    border: `1px solid ${mapProductToColor(
                      tank.product,
                      true,
                      true
                    )}`,
                    borderRadius: '1px'
                  }">
                    <h3 :style="{
                      color: mapProductToColor(tank.product),
                      backgroundColor: mapProductToColor(tank.product, true)
                    }" :id="`product_${tank.name}_Header`">{{ tank.product }}</h3>
                    <h3 :style="{
                      color: mapProductToColor(tank.product),
                      backgroundColor: mapProductToColor(tank.product, true)
                    }" :id="`tankName_${tank.name}_Header`">{{ tank.name }}</h3>
                  </v-flex>
                </th>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.OVERFILL"
                    :millimetres="tank.overfillMillimetres" :litres="tank.overfillLitres"
                    :idPrefix="`overfill_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.HIGH_HIGH_LEVEL"
                    :millimetres="tank.highHighLevelAlarmMillimetres" :litres="tank.highHighLevelAlarmLitres"
                    :idPrefix="`highHighLevelAlarm_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.HIGH_LEVEL"
                    :millimetres="tank.highLevelAlarmMillimetres" :litres="tank.highLevelAlarmLitres"
                    :idPrefix="`highLevelAlarm_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell v-bind:key="tank.id" :label="LABEL_NAMES.OPERATIONAL_LEVEL"
                    :millimetres="tank.operationalLevelMillimetres" :litres="tank.operationalLevelLitres"
                    :idPrefix="`operationalLevel_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.OPERATOR_LEVEL_ALARM"
                    :millimetres="tank.operatorLevelAlarmMillimetres" :litres="tank.operatorLevelAlarmLitres"
                    :idPrefix="`operatorLevelAlarm_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.SLOW_PUMP_LEVEL"
                    :millimetres="tank.slowPumpLevelMillimetres" :litres="tank.slowPumpLevelLitres"
                    :idPrefix="`slowPumpLevel_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.LOW_LEVEL"
                    :millimetres="tank.lowLevelAlarmMillimetres" :litres="tank.lowLevelAlarmLitres"
                    :idPrefix="`lowLevelAlarm_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.LOW_LOW_LEVEL"
                    :millimetres="tank.lowLowLevelAlarmMillimetres" :litres="tank.lowLowLevelAlarmLitres"
                    :idPrefix="`lowLowLevelAlarm_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td class="table-data no-print">
                  <v-layout justify-space-between pa-1>
                    <v-flex xs4>
                      <v-btn :id="`print_${tank.name}_Button`" small @click="print(tank)">Print</v-btn>
                    </v-flex>
                    <v-flex xs4 v-if="hasEditRole" xs6 class="button-align-right">
                      <v-btn :id="`editLoc_${tank.name}_Button`" small
                        @click="() => openEditModal(dialogs.loc, tank)">Edit</v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-space-between pa-1>
                    <v-flex xs12 v-if="hasTankCalibrations(tank)">
                      <v-btn small :id="`editTankCalibrations_${tank.name}_Button`"
                        @click="() => openEditModal(dialogs.tankCalibrations, tank)">Tank Calibrations</v-btn>
                    </v-flex>
                  </v-layout>
                </td>
              </tr>
          </v-card>

          <div v-if="showExtraData" class="divider"></div>

          <v-expand-transition v-if="showExtraData" class="no-print">
            <v-card class="extra-data-column">
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.TANK_BOTTOM_LEVEL"
                    :millimetres="tank.tankBottomLevelLitresMillimetres" :litres="tank.tankBottomLevelLitres"
                    :idPrefix="`tankBottomLevelLitres_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.MAX_FLOW_RATE_PER_MINUTE"
                    :litres="tank.maxFlowRateLitresPerMinute" :idPrefix="`maxFlowRatePerMinute_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.MAX_SLOW_PUMPING_FLOW_RATE_PER_MINUTE"
                    :litres="tank.maxSlowPumpingFlowRateLitresPerMinute"
                    :idPrefix="`maxSlowPumpingFlowRatePerMinute_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.LITRES_PER_MILLIMETRE"
                    :litres="tank.litresPerMillimetre" :idPrefix="`litresPerMillimetre_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.IFRW" :singleValue="tank.ifrw"
                    :idPrefix="`ifrw_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.TANK_STATUS"
                    :singleValue="tank.tankStatus == 'UnderMaintenance' ? 'Under Maintenance' : tank.tankStatus"
                    :idPrefix="`tankStatus_${tank.name}`" />
                </td>
              </tr>
              <tr>
                <td :class="[selectedTank.name !== tank.name ? 'no-print' : '']">
                  <levels-of-concern-receipt-plan-cell :label="LABEL_NAMES.CHART_ID" :singleValue="tank.chartId"
                    :idPrefix="`chartId_${tank.name}`" />
                </td>
              </tr>
            </v-card>
          </v-expand-transition>
        </div>
      </v-slide-group>
    </v-container>
    <v-flex xs2 mt-3>
      <flat-button v-if="activeTerminal && !isLoading" class="no-print extra-data-button-margins" :loading="loading"
        @click="toggleExtraData()">{{ extraDataButtonText }}</flat-button>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex';
import { UI } from '@/store/ui';
import { TERMINALS } from '@/store/terminals';
import { mapProductToColor, mapProductToTextColor } from '../../helpers/ColorMapper';
import { isInSomeRole } from '@/helpers/auth'
import { ADMIN, ENGINEER } from '@/constants/roles'
import LABEL_NAMES from '@/constants/levelsOfConcernLabels';
import { PRODUCT_SORT_ORDER } from '@/constants/products';

export default {
  async mounted() {
    await this.fetchData();
  },

  data() {
    return {
      loading: false,
      showExtraData: false,
      selectedTank: {},
      LABEL_NAMES,
      dialogs: {
        loc: {
          show: false
        },
        tankCalibrations: {
          show: false
        }
      }
    };
  },
  computed: {
    hasEditRole() {
      return isInSomeRole([ADMIN, ENGINEER]);
    },
    isLoading() {
      return this.$store.getters[UI.NS(UI.GETTERS.IS_LOADING)] || this.loading;
    },
    sortedTerminalTechnicalDetails() {
      if (this.activeTerminal && this.activeTerminal.tankTechnicalDetails) {
        return [...this.activeTerminal.tankTechnicalDetails].sort((a, b) => {
          return PRODUCT_SORT_ORDER.indexOf(a.product) - PRODUCT_SORT_ORDER.indexOf(b.product);
        });
      }
      return null;
    },
    extraDataButtonText() {
      if (this.showExtraData) {
        return 'Hide Extra Data ▲';
      }

      return 'Show Extra Data ▼';
    },
    tankNames() {
      return this.sortedTerminalTechnicalDetails && this.sortedTerminalTechnicalDetails.map(t => t.name.toLowerCase());
    },
    ...mapState({
      activeTerminal: state => state.terminals.activeTerminal,
      terminalsByKey: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.reduce((terminals, terminal) => {
          terminals[terminal.id] = { ...terminal };
          return terminals;
        }, {}),
      terminalsList: state => state.terminals.terminalsList,
      isLoading: state => state.ui.isLoading
    })
  },
  methods: {
    mapProductToColor(product, background = false, border = false) {
      if (!product) return;
      if (background) return mapProductToColor(product, border);
      return mapProductToTextColor(product);
    },
    async fetchData() {
      this.loading = true;
      if (!this.terminalsList) {
        await this.$store.dispatch(TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS));
      }
      const routeId = this.$route.params && this.$route.params.terminalId;

      const terminal = this.terminalsByKey[routeId];
      this.$store.dispatch(
        TERMINALS.NS(TERMINALS.ACTIONS.GET_TANK_TECHNICAL_DETAILS),
        terminal
      );
      this.$store.dispatch(
        TERMINALS.NS(TERMINALS.ACTIONS.SET_ACTIVE_TERMINAL),
        terminal
      );
      this.loading = false;
    },
    changeShowDialog(dialogType) {
      dialogType.show = false;
    },
    async changeShowDialogSaved(dialogType) {
      await this.fetchData();
      const routeId = this.$route.params && this.$route.params.terminalId;

      if (routeId) {
        const terminal = this.terminalsByKey[routeId];
        this.$store.dispatch(
          TERMINALS.NS(TERMINALS.ACTIONS.SET_ACTIVE_TERMINAL),
          terminal
        );
      }

      dialogType.show = false;
    },
    toggleExtraData() {
      this.showExtraData = !this.showExtraData;
    },
    hasTankCalibrations(tank) {
      return tank && tank.tankCalibrations && tank.tankCalibrations.length > 0;
    },
    print(tank) {
      this.selectedTank = tank;
      setTimeout(() => {
        //Print wasnt working without setTimout, event loop issues.
        window.print();
      }, 1);
    },
    openEditModal(dialogType, tank) {
      this.selectedTank = tank;
      dialogType.show = true;
    },
    openTankCalibration(tank) {
      this.selectedTank = tank;
      this.showTankCalibrationModal = true;
    },
    closeTankCalibrationModel() {
      this.showTankCalibrationModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';

.upper-case {
  text-transform: uppercase;
}

h2,
h3 {
  text-align: center;
}

.input-width {
  max-width: 250px;
}

:deep(.v-text-field--box) .v-text-field__suffix {
  margin-top: 22px;
}

:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}

:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details,
:deep(.v-text-field.v-text-field--enclosed)>.v-input__control>.v-input__slot {
  padding: 0 3px !important;
}

.tabs {
  width: 100%;
}

.tabs .v-tabs__bar {
  background-color: #eee;
  padding: 18px 25px 0;
}

.tabs .v-tabs__item {
  font-size: 2rem;
  text-transform: none;
  font-weight: bold;
  padding: 25px 30px;
}

.tabs .v-tabs__item--active {
  background-color: white;
  border: 1px solid $light-charcoal;
  border-bottom: none;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.button {
  margin-right: 10px !important;
}

.loading-content {
  min-height: calc(100vh - (64px + 48px + 50px));
  align-items: center;
}

.reduced-padding {
  padding: 10px !important;
}

.save-title {
  font-size: 120%;
}

.save-message {
  margin: 10px 0;
}

.save-error {
  color: $error-color;
  font-style: italic;
}

.table-sticky-header {
  position: sticky;
  top: 112px;
  background: white;
  z-index: 8;
}

.divider {
  border: 1px solid rgb(192, 192, 192);
  margin-top: 30px;
  margin-bottom: 30px;
  min-width: 100%;
  margin-left: -30px;
}

.button-align-right {
  text-align: right !important;
}

.extra-data-button-margins {
  margin-left: 20px;
  margin-bottom: 20px;
}

.tank-column {
  width: 200px;
  margin: 3px;
  padding: 5px;
  padding-bottom: 10px;
}

.extra-data-column {
  width: 200px;
  margin: 3px;
  padding: 5px;
}

.slider-container {
  background-color: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1030px) {
  .table-container {
    min-width: 1030px;
  }
}

@media print {
  :deep(.container) {
    padding: 0 !important;
  }

  .show-overflow {
    overflow-x: hidden !important;
  }

  .table {
    width: 100% !important;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    min-width: 0 !important;
  }

  .table-sticky-header {
    position: initial;
    top: 0;
  }
}
</style>
