import { actions, CARGO_DOCK_HOSE_INFORMATION_ACTIONS } from './actions';
import { getters, CARGO_DOCK_HOSE_INFORMATION_GETTERS } from './getters';
import { mutations, CARGO_DOCK_HOSE_INFORMATION_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'cargoDockHoseInformation';

export const state = {
    cargoDockHoses: []
};

export function GET_DEFAULT_CARGO_DOCK_HOSE_DETAILS() {
    return {
        equipmentName: 0,
        serialNumber: null,
        testCertDate: null,
        testCertExpiry: null,
        diameterMm: 0,
        lengthMetres: null,
        notes: null,
        documents: null
    }
};

export const CARGO_DOCK_HOSE_INFORMATION = {
    ACTIONS: CARGO_DOCK_HOSE_INFORMATION_ACTIONS,
    GETTERS: CARGO_DOCK_HOSE_INFORMATION_GETTERS,
    MUTATIONS: CARGO_DOCK_HOSE_INFORMATION_MUTATIONS,
    NAME_SPACE,
    NS: x => `${NAME_SPACE}/${x}`
};

export const cargoDockHoseInformation = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};