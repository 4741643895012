import { req } from "vuelidate/lib/validators/common";
import { isValidMaskedDateTime } from "@/helpers/date"

export default (value) => {
  // Check if a value has been passed in
  if (!req(value)) {
    return true;
  }
  return isValidMaskedDateTime(value);
};
