import { http } from '@/api';

export default {
  getDischargeLogList: (planId) => http.get(
    `/v1/plans/${planId}/dischargelogs`, {
      params: {
        sortDirection: 'Descending'
      }
    }
  ),
  getDischargeLog: (planId, dischargeLogId) => http.get(
    `/v1/plans/${planId}/dischargelogs/${dischargeLogId}`
  ),
  createDischargeLog: (planId, dischargeLog) => http.post(
    `/v1/plans/${planId}/dischargelogs`, {
      model: dischargeLog
    }
  ),
  editDischargeLog: (planId, dischargeLog) => http.put(
    `/v1/plans/${planId}/dischargelogs`, {
      model: dischargeLog
    }
  )
}