const density = {
    max: 9.9999
}

const flashPoint = {
    max: 999.9
}

const temperature = {
    max: 999.9
}

const hoursSettled = {
    max: 9999.99
}

const conductivity = {
    max: 999
}

const staticDissipatorAdditive = {
    max: 9999.99
}

export default {
    density,
    flashPoint,
    temperature,
    hoursSettled,
    conductivity,
    staticDissipatorAdditive
};