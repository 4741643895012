var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasLoaded)?_c('v-layout',{attrs:{"row":"","wrap":"","pa-5":""}},[_c('v-col',{attrs:{"xs3":"","pr-3":""}},[_c('v-text-field',{staticClass:"input-width",attrs:{"color":"orange lighten-1","label":"Vessel","rules":[
        function () { return _vm.$v.vessel.required || 'Vessel is required'; }
      ],"disabled":_vm.isDisabled,"readonly":_vm.readOnly,"id":"vesselTextFeild"},on:{"input":function($event){return _vm.$v.vessel.$touch()}},model:{value:(_vm.vessel),callback:function ($$v) {_vm.vessel=$$v},expression:"vessel"}})],1),_c('v-col',{attrs:{"xs3":"","pr-3":""}},[_c('v-text-field',{staticClass:"input-width",attrs:{"color":"orange lighten-1","label":"Voyage","rules":[
        function () { return _vm.$v.voyage.required || 'Voyage is required'; }
      ],"disabled":_vm.isDisabled,"readonly":_vm.readOnly,"id":"voyageTextFeild"},on:{"input":function($event){return _vm.$v.voyage.$touch()}},model:{value:(_vm.voyage),callback:function ($$v) {_vm.voyage=$$v},expression:"voyage"}})],1),_c('v-col',{attrs:{"xs3":""}},[(!_vm.readOnly)?_c('v-datetime-picker',{attrs:{"color":"orange lighten-1","label":"Pilot on board","datePickerProps":{
          showCurrent:false
        },"textFieldProps":{
          errorMessages: _vm.pilotOnBoardErrors
        },"disabled":_vm.isDisabled || _vm.readOnly},on:{"input":function($event){return _vm.$v.pilotOnBoard.$touch()}},model:{value:(_vm.pilotOnBoard),callback:function ($$v) {_vm.pilotOnBoard=$$v},expression:"pilotOnBoard"}}):_vm._e(),(_vm.readOnly)?_c('v-text-field',{staticClass:"input-width",attrs:{"color":"orange lighten-1","value":_vm._f("formatDateTime")(_vm.pilotOnBoard,'yyyy-MM-dd HH:mm'),"label":"Pilot on board","readonly":true,"id":"pilotOnBoardTextFeild"}}):_vm._e()],1),(_vm.showSave && _vm.$v.$anyDirty)?_c('v-col',{attrs:{"xs3":""}},[_c('v-btn',{attrs:{"color":"orange lighten-1","loading":_vm.isLoading,"disabled":_vm.isLoading || _vm.isDisabled || _vm.$v.$invalid,"id":"saveVesselInfoButton"},on:{"click":_vm.onSave}},[_vm._v("Save")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }