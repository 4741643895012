import api from './api';
import { QUALITY_REPORT_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
import { GET_DEFAULT_QUALITY_REPORT } from '@/store/tankerDischarge/postDischarge/qualityReport';

export const ERROR_GET_QUALITY_REPORT =
  'There was a problem trying to load the quality report';

export const ERROR_UPDATE_QUALITY_REPORT =
  'There was a problem trying to update the quality report';

export const ERROR_RELEASE_TANK =
  'There was a problem trying to release the tank';

export const SUCCESSFULLY_RELEASED_TANK = 
  'Successfully released the tank';

export const ERROR_UPDATE_INTERNAL_NOTES = 
  'There was a problem trying to update the internal notes';
  
export const SUCCESSFULLY_UPDATE_INTERNAL_NOTES = 
  'Successfully updated internal notes';

export const ERROR_CREATE_POST_RECEIPT_STORAGE_TANK_SAMPLES = 
  'There was a problem trying to create the post receipt storage tank sample';

export const ERROR_UPDATE_POST_RECEIPT_STORAGE_TANK_SAMPLES = 
  'There was a problem trying to update the post receipt storage tank sample';

export const QUALITY_REPORT_ACTIONS = {
  RESET_QUALITY_REPORT: 'resetQualityReport',
  GET_QUALITY_REPORT: 'getQualityReport',
  GET_QUALITY_REPORT_PDF: 'getQualityReportPdf',
  UPDATE_QUALITY_REPORT: 'updateQualityReport',
  RELEASE_TANK: 'releaseTank',
  UPDATE_INTERNAL_NOTES: 'updateInternalNotes',
  CREATE_POST_RECEIPT_STORAGE_TANK_SAMPLE: 'createPostReceiptStorageTankSample',
  UPDATE_POST_RECEIPT_STORAGE_TANK_SAMPLE: 'updatePostReceiptStorageTankSample'
}

export const actions = {
  [QUALITY_REPORT_ACTIONS.RESET_QUALITY_REPORT]({ commit }, { receiptPlanId, tankPlanId }) {
      commit(QUALITY_REPORT_MUTATIONS.SET_QUALITY_REPORT, {
        receiptPlanId,
        tankPlanId,
        qualityReport: GET_DEFAULT_QUALITY_REPORT()
      });
  },
  async [QUALITY_REPORT_ACTIONS.GET_QUALITY_REPORT](
    { commit, dispatch },
    { planId, receiptPlanId, tankPlanId }
  ) {
    let success = false;
    try {
      const response = await api.getQualityReport({
        planId,
        receiptPlanId,
        tankPlanId
      });
      commit(QUALITY_REPORT_MUTATIONS.SET_QUALITY_REPORT, {
        receiptPlanId,
        tankPlanId,
        qualityReport: response.data
      });
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-quality-report',
        text: ERROR_GET_QUALITY_REPORT,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [QUALITY_REPORT_ACTIONS.GET_QUALITY_REPORT_PDF](
    { dispatch },
    { planId, receiptPlanId, tankPlanId }
  ) {
    try {
      return await api.getQualityReportPdf({
        planId,
        receiptPlanId,
        tankPlanId
      });
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-quality-report',
        text: ERROR_GET_QUALITY_REPORT,
        error
      },
        { root: true }
      );
    }
  },
  async [QUALITY_REPORT_ACTIONS.UPDATE_QUALITY_REPORT](
    { dispatch },
    { planId, receiptPlanId, tankPlanId, qualityReport }
  ) {
    let success = false;
    try {
      await api.updateQualityReport({
        planId, receiptPlanId, tankPlanId, qualityReport
      });
      success = true;
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-update-quality-report',
        text: 'Successfully updated quality report'
      },
        { root: true }
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-update-quality-report',
        text: ERROR_UPDATE_QUALITY_REPORT,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [QUALITY_REPORT_ACTIONS.CREATE_POST_RECEIPT_STORAGE_TANK_SAMPLE](
    { dispatch },
    { planId, receiptPlanId, tankPlanId, postReceiptStorageTankSamples }
  ){
    let success = false;
    try {
      await api.createPostReceiptStorageTankSamples(
        planId, receiptPlanId, tankPlanId, postReceiptStorageTankSamples
      );
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-create-quality-report',
        text: 'Successfully created post receipt storage tank samples'
      },
        { root: true }
      );
      success = true;
    } catch (error) {
      if(error.response.status == 409){ 
        // Data may have been entered on another window 
        // Overide existing samples with put request
        return dispatch(QUALITY_REPORT_ACTIONS.UPDATE_POST_RECEIPT_STORAGE_TANK_SAMPLE,
        {
          planId,
          receiptPlanId,
          tankPlanId,
          postReceiptStorageTankSamples
        });
      }
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST),
        {
          id: 'failed-create-quality-report',
          text: ERROR_CREATE_POST_RECEIPT_STORAGE_TANK_SAMPLES,
          error
        },
        { root: true }
      );
      success = false;
    }
    return success;
  },
  async [QUALITY_REPORT_ACTIONS.UPDATE_POST_RECEIPT_STORAGE_TANK_SAMPLE](
    { dispatch },
    { planId, receiptPlanId, tankPlanId, postReceiptStorageTankSamples }
  ){
    let success;
    try {
      await api.updatePostReceiptStorageTankSamples(
        planId, receiptPlanId, tankPlanId, postReceiptStorageTankSamples
      );
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-update-post-receipt-storage-tank-samples',
        text: 'Successfully updated post receipt storage tank samples'
      },
        { root: true }
      );
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST),
        {
          id: 'failed-create-quality-report',
          text: ERROR_UPDATE_POST_RECEIPT_STORAGE_TANK_SAMPLES,
          error
        },
        { root: true }
      );
      success = false;
    }
    return success;
  },
  async [QUALITY_REPORT_ACTIONS.RELEASE_TANK]({dispatch}, {
    planId,
    receiptPlanId,
    tankPlanId,
    releaseDetails
  }){
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      await api.releaseTank(
        planId,
        receiptPlanId,
        tankPlanId,
        releaseDetails
      );
      success = true;
      dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
        root: true
      });
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'successfully-release-tank',
        text: SUCCESSFULLY_RELEASED_TANK
      },
        { root: true }
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-release-tank',
        text: ERROR_RELEASE_TANK,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [QUALITY_REPORT_ACTIONS.UPDATE_INTERNAL_NOTES]({dispatch}, {
    planId,
    receiptPlanId,
    tankPlanId,
    internalNotes
  }){
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      await api.updateQualityReportInternalNotes(
        planId,
        receiptPlanId,
        tankPlanId,
        internalNotes
      );
      success = true;
      dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
        root: true
      });
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'successfully-updated-internal-notes',
        text: SUCCESSFULLY_UPDATE_INTERNAL_NOTES
      },
        { root: true }
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-updating-internal-notes',
        text: ERROR_UPDATE_INTERNAL_NOTES ,
        error
      },
        { root: true }
      );
    }
    return success;
  } 
}