export const TERMINALS_GETTERS = {
  GET_TERMINALS: 'getTerminals',
  GET_ACTIVE_TERMINAL: 'getActiveTerminal'
};

export const getters = {
  [TERMINALS_GETTERS.GET_TERMINALS](state) {
    return state.terminals && state.terminals.terminalsList;
  },
  [TERMINALS_GETTERS.GET_ACTIVE_TERMINAL](state) {
    return state.terminals && state.terminals.activeTerminal;
  }
};
