<template>
  <v-container fluid fill-height v-if="this.tankToTankTransferPlan">
    <v-dialog class="no-print" max-width="500" v-model="showAbandonConfirmation">
      <v-card>
        <v-card-text>
          <v-layout>
            <v-flex xs12>Are you sure you want to abandon this receipt plan?</v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          id="confirm-abandon-dialog"
          color="red lighten-3"
          @click="onAbandon"
          :disabled="isLoading"
          :loading="isLoading">
            Abandon
          </v-btn>
          <v-btn
          id="cancel-abandon-dialog"
          color="secondary lighten-3"
          @click="showAbandonConfirmation = false"
          :disabled="isLoading"
          :loading="isLoading">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap pa-6>

      <v-layout row wrap>
        <v-flex xs3 pr-3>
          <v-text-field
          id="date"
          class="input-width"
          label="Date"
          :value="tankToTankTransferPlan.date | formatDateTime('yyyy-MM-dd HH:mm')"
          :readonly="true"
          color="orange lighten-1"/>
        </v-flex>
        <v-flex xs3 pr-3>
          <v-text-field
          id="comment"
          :value="tankToTankTransferPlan.comment"
          class="input-width"
          label="Comment"
          :readonly="true"
          color="orange lighten-1"/>
        </v-flex>
        <v-flex xs3 pr-3>
          <v-text-field
          id="last-source-tank-release-form-date"
          class="input-width"
          label="Last Source Tank Release Form Date"
          v-model="lastSourceReleaseFormDate"
          @input="$v.lastSourceReleaseFormDate.$touch()"
          :error-messages="lastSourceReleaseFormDateErrors"
          color="orange lighten-1"
          v-mask="'####-##-## ##:##'"
          placeholder="yyyy-MM-dd HH:mm"/>
        </v-flex>
        <v-flex xs3 pr-3>
          <v-text-field
          id="last-target-tank-release-form-date"
          class="input-width"
          label="Last Target Tank Release Form Date"
          v-model="lastTargetReleaseFormDate"
          @input="$v.lastTargetReleaseFormDate.$touch()"
          :error-messages="lastTargetReleaseFormDateErrors"
          color="orange lighten-1"
          v-mask="'####-##-## ##:##'"
          placeholder="yyyy-MM-dd HH:mm"/>
        </v-flex>
      </v-layout>

      <v-flex xs12>
        <v-layout>
          <v-flex>
            <div style="display: flex; justify-content: left">
              <table class="table">
                <tr>
                  <td>
                    <v-flex>
                      <h2>Source Tank</h2>
                    </v-flex>
                    <v-flex>
                      <h2>
                        {{ sourceTankTechnicalDetails.name }}
                      </h2>
                    </v-flex>
                    <h2 :style="{
                      color: mapProductToColor(sourceTankTechnicalDetails && sourceTankTechnicalDetails.product),
                      backgroundColor: mapProductToColor(sourceTankTechnicalDetails && sourceTankTechnicalDetails.product, true),
                      border: `1px solid ${mapProductToColor(sourceTankTechnicalDetails && sourceTankTechnicalDetails.product, true, true)}`,
                      borderRadius: '1px'}">
                      {{ sourceTankTechnicalDetails.product }}
                    </h2>
                  </td>
                  <td>
                    <v-flex>
                      <h2>Target Tank</h2>
                    </v-flex>
                    <v-flex>
                      <h2>
                        {{ targetTankTechnicalDetails.name }}
                      </h2>
                    </v-flex>
                    <h2 :style="{
                      color: mapProductToColor(targetTankTechnicalDetails && targetTankTechnicalDetails.product),
                      backgroundColor: mapProductToColor(targetTankTechnicalDetails && targetTankTechnicalDetails.product, true),
                      border: `1px solid ${mapProductToColor(targetTankTechnicalDetails && targetTankTechnicalDetails.product, true, true)}`,
                      borderRadius: '1px'}">
                      {{ targetTankTechnicalDetails.product }}
                    </h2>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="source-opening-dip"
                      label="Opening Dip"
                      color="orange lighten-1"
                      v-model="sourceOpeningDip"
                      :error-messages="sourceOpeningDipErrors"
                      @input="$v.sourceOpeningDip.$touch()"
                      suffix="mm"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="target-opening-dip"
                      label="Opening Dip"
                      color="orange lighten-1"
                      v-model="targetOpeningDip"
                      :error-messages="targetOpeningDipErrors"
                      @input="$v.targetOpeningDip.$touch()"
                      suffix="mm"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="source-planned-volume-litres"
                      label="Planned Volume"
                      color="orange lighten-1"
                      v-model="sourcePlannedVolumeLitres"
                      prefix="-"
                      suffix="L"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="target-planned-volume-litres"
                      label="Planned Volume"
                      color="orange lighten-1"
                      v-model="targetPlannedVolumeLitres"
                      :error-messages="targetPlannedVolumeLitresErrors"
                      @input="$v.targetPlannedVolumeLitres.$touch()"
                      suffix="L"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <tanker-discharge-receipt-plan-cell
                      id="source-estimated-pumpable-volume"
                      label="Estimated Pumpable Volume"
                      :millimetres="sourceEstimatedPumpableVolume.mm"
                      :litres="sourceEstimatedPumpableVolume.litres"
                      :error="sourceEstimatedPumpableVolume.error"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <tanker-discharge-receipt-plan-cell
                      id="target-estimated-pumpable-volume"
                      label="Estimated Pumpable Volume"
                      :millimetres="targetEstimatedPumpableVolume.mm"
                      :litres="targetEstimatedPumpableVolume.litres"
                      :error="targetEstimatedPumpableVolume.error"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <tanker-discharge-receipt-plan-cell
                      id="source-estimated-finish-dip"
                      label="Estimated Finish Dip"
                      :millimetres="sourceEstimatedFinishDip.mm"
                      :litres="sourceEstimatedFinishDip.litres"
                      :error="sourceEstimatedFinishDip.error"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <tanker-discharge-receipt-plan-cell
                      id="target-estimated-finish-dip"
                      label="Estimated Finish Dip"
                      :millimetres="targetEstimatedFinishDip.mm"
                      :litres="targetEstimatedFinishDip.litres"
                      :error="targetEstimatedFinishDip.error"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="planned-pipeline-adjustments-litres"
                      label="Planned Pipeline Adjustments"
                      color="orange lighten-1"
                      v-model="sourcePlannedPipelineAdjustmentsLitres"
                      suffix="L"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="planned-tank-bottom-adjustment-litres"
                      label="Planned Tank Bottom Adjustment"
                      color="orange lighten-1"
                      v-model="targetPlannedTankBottomAdjustmentLitres"
                      suffix="L"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <tanker-discharge-receipt-plan-cell
                      id="design-low-level-and-low-level-alarm"
                      label="Design Low Level & Low Low Level (Alarm)"
                      :millimetres="sourceTankTechnicalDetails.lowLowLevelAlarmMillimetres"
                      :litres="sourceTankTechnicalDetails.lowLowLevelAlarmLitres"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <tanker-discharge-receipt-plan-cell
                      id="normal-safe-fill-level-alarm"
                      label="Normal Safe Fill Level (Alarm)"
                      :millimetres="targetTankTechnicalDetails.operationalLevelMillimetres"
                      :litres="targetTankTechnicalDetails.operationalLevelLitres"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-select
                        label="Pre Transfer Tank Appearance"
                        :clearable=true
                        v-model="sourceAppearance"
                        @input="$v.sourceAppearance.$touch()"
                        :error-messages="sourceAppearanceErrors"
                        :items="getAppearanceListByProductForSource"
                        color="orange lighten-1"
                        id="source-appearance"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-select
                        label="Pre Transfer Tank Appearance"
                        :clearable=true
                        v-model="targetAppearance"
                        @input="$v.targetAppearance.$touch()"
                        :error-messages="targetAppearanceErrors"
                        :items="getAppearanceListByProductForTarget"
                        color="orange lighten-1"
                        id="target-appearance"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-select
                        label="Pre Transfer Tank Colour"
                        :clearable=true
                        v-model="sourceColour"
                        @input="$v.sourceColour.$touch()"
                        :error-messages="sourceColourErrors"
                        :items="colourList"
                        color="orange lighten-1"
                        id="source-colour"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-select
                        label="Pre Transfer Tank Colour"
                        :clearable=true
                        v-model="targetColour"
                        @input="$v.targetColour.$touch()"
                        :error-messages="targetColourErrors"
                        :items="colourList"
                        color="orange lighten-1"
                        id="target-colour"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="source-density"
                      type="number"
                      label="Pre Transfer Tank Density"
                      color="orange lighten-1"
                      v-model="sourceDensity"
                      :error-messages="sourceDensityErrors"
                      @input="$v.sourceDensity.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="4"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="target-density"
                      type="number"
                      label="Pre Transfer Tank Density"
                      color="orange lighten-1"
                      v-model="targetDensity"
                      :error-messages="targetDensityErrors"
                      @input="$v.targetDensity.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="4"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="source-flashpoint"
                      label="Pre Transfer Tank Flashpoint"
                      color="orange lighten-1"
                      v-model="sourceFlashpoint"
                      :error-messages="sourceFlashpointErrors"
                      @input="$v.sourceFlashpoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="1"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="target-flashpoint"
                      label="Pre Transfer Tank Flashpoint"
                      color="orange lighten-1"
                      v-model="targetFlashpoint"
                      :error-messages="targetFlashpointErrors"
                      @input="$v.targetFlashpoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="1"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="source-conductivity"
                      label="Pre Transfer Tank Conductivity"
                      color="orange lighten-1"
                      v-model="sourceConductivity"
                      :error-messages="sourceConductivityErrors"
                      @input="$v.sourceConductivity.$touch()"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex xs12>
                      <v-currency-field
                      id="target-conductivity"
                      label="Pre Transfer Tank Conductivity"
                      color="orange lighten-1"
                      v-model="targetConductivity"
                      :error-messages="targetConductivityErrors"
                      @input="$v.targetConductivity.$touch()"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(false) == products.AGO"
                      id="source-viscosity"
                      label="Viscosity @40°C, cSt"
                      color="orange lighten-1"
                      v-model="sourceViscosity"
                      :error-messages="sourceViscosityErrors"
                      @input="$v.sourceViscosity.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="3"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(true) == products.AGO"
                      id="target-viscosity"
                      label="Viscosity @40°C, cSt"
                      color="orange lighten-1"
                      v-model="targetViscosity"
                      :error-messages="targetViscosityErrors"
                      @input="$v.targetViscosity.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="3"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(false) == products.AGO"
                      id="source-sulphur"
                      label="Pre Transfer Sulphur mg/kg"
                      color="orange lighten-1"
                      v-model="sourceSulphur"
                      :error-messages="sourceSulphurErrors"
                      @input="$v.sourceSulphur.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(true) == products.AGO"
                      id="target-sulphur"
                      label="Pre Transfer Sulphur mg/kg"
                      color="orange lighten-1"
                      v-model="targetSulphur"
                      :error-messages="targetSulphurErrors"
                      @input="$v.targetSulphur.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(false) == products.AGO"
                      id="source-cloud-point"
                      label="Pre Transfer Cloud Point °C"
                      color="orange lighten-1"
                      v-model="sourceCloudPoint"
                      :error-messages="sourceCloudPointErrors"
                      @input="$v.sourceCloudPoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"
                      :allow-negative="true"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(true) == products.AGO"
                      id="target-cloud-point"
                      label="Pre Transfer Cloud Point °C"
                      color="orange lighten-1"
                      v-model="targetCloudPoint"
                      :error-messages="targetCloudPointErrors"
                      @input="$v.targetCloudPoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"
                      :allow-negative="true"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(false) == products.AGO"
                      id="source-cold-filter-plugging-point"
                      label="Cold Filter Plugging Point °C"
                      color="orange lighten-1"
                      v-model="sourceColdFilterPluggingPoint"
                      :error-messages="sourceColdFilterPluggingPointErrors"
                      @input="$v.sourceColdFilterPluggingPoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"
                      :allow-negative="true"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(true) == products.AGO"
                      id="target-cold-filter-plugging-point"
                      label="Cold Filter Plugging Point °C"
                      color="orange lighten-1"
                      v-model="targetColdFilterPluggingPoint"
                      :error-messages="targetColdFilterPluggingPointErrors"
                      @input="$v.targetColdFilterPluggingPoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"
                      :allow-negative="true"/>
                    </v-flex>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(false) == products.AGO"
                      id="source-pour-point"
                      label="Pour Point °C"
                      color="orange lighten-1"
                      v-model="sourcePourPoint"
                      :error-messages="sourcePourPointErrors"
                      @input="$v.sourcePourPoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"
                      :allow-negative="true"/>
                    </v-flex>
                  </td>
                  <td>
                    <v-flex>
                      <v-currency-field
                      v-if="this.getProduct(true) == products.AGO"
                      id="target-pour-point"
                      label="Pour Point °C"
                      color="orange lighten-1"
                      v-model="targetPourPoint"
                      :error-messages="targetPourPointErrors"
                      @input="$v.targetPourPoint.$touch()"
                      :autoDecimalMode="false"
                      :decimalLength="2"
                      :allow-negative="true"/>
                    </v-flex>
                  </td>
                </tr>
              </table>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>

        <v-layout class="no-print" row justify-end>
          <v-flex xs3 pa-1>
            <flat-button
            @click="onSave"
            id="save-transfer-plan-btn"
            color="orange lighten-1"
            class="button"
            :loading="isLoading"
            :disabled="isLoading">
              Save transfer plan
            </flat-button>
          </v-flex>
          <v-flex xs3 pa-1>
            <flat-button
            id="abandon-transfer-plan-btn"
            color="secondary lighten-3"
            class="button"
            @click="onAbandonConfirmation"
            :loading="isLoading"
            :disabled="isLoading">
              Abandon transfer plan
            </flat-button>
          </v-flex>
        </v-layout>

    </v-layout>
  </v-container>
</template>

<script>
import { mapProductToColor, mapProductToTextColor } from '@/helpers/ColorMapper';
import { mapState } from 'vuex';
import { TANK_TO_TANK_TRANSFER, GET_DEFAULT_TANK_PLAN, GET_DEFAULT_LAST_RELEASE_DATES } from '@/store/tankToTankTransfer';
import { ROUTE_NAMES } from '@/routes';
import { maxLength } from 'vuelidate/lib/validators';
import { 
  validateDensityTankToTank, 
  validateFlashPointTankToTank, 
  validateConductivityTankToTank,
  validateViscosity,
  validateSulphur,
  validateCloudPointTankToTank,
  validateColdFilterPluggingPointTankToTank,
  validatePourPointTankToTank,
  validateAppearance,
  validateColour
} from '@/helpers/validation/sharedThresholds';
import { tankType, tankTypeString } from '@/constants/tankToTank';
import { getAppearanceList } from '@/helpers/appearance';
import { PRODUCT_QUALITY_COLOUR_OPTIONS } from '@/constants/colourItems';
import { PRODUCTS } from "@/constants/products";
import { maskedDatetime } from '@/validators';
import { formatDateTimeToMasked, isValidMaskedDateTime } from '@/helpers/date';
import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import { cloneDeep } from '@/helpers/object';
import { convertMMToLitres, convertLitresToMM } from '@/helpers/discharge';

export default {
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      showAbandonConfirmation: false,
      isLoading: false,
      sourceInput: GET_DEFAULT_TANK_PLAN(),
      targetInput: GET_DEFAULT_TANK_PLAN(),
      transferPlanInput: GET_DEFAULT_LAST_RELEASE_DATES(),
      sourceDateString: '',
      targetDateString: '',
      isTargetEdited: false
    };
  },
  computed: {
    tankToTankPlanId() {
      return this.$route.params && this.$route.params.tankToTankPlanId;
    },
    ...mapState({
      activeTerminal: (state) => state.terminals && state.terminals.activeTerminal,
      tankToTankTransferPlan: (state) => state.tankToTankTransfer &&
        state.tankToTankTransfer.tankToTankTransferPlan
    }),
    sourceTank() {
      return this.tankToTankTransferPlan.tankPlans.filter(item => item.tankType === tankTypeString.source)[0];
    },
    targetTank() {
      return this.tankToTankTransferPlan.tankPlans.filter(item => item.tankType === tankTypeString.target)[0];
    },
    sourceTankTechnicalDetails() {
      return this.tankToTankTransferPlan && this.tankToTankTransferPlan.sourceTankTechnicalDetails;
    },
    targetTankTechnicalDetails() {
      return this.tankToTankTransferPlan && this.tankToTankTransferPlan.targetTankTechnicalDetails;
    },
    sourceEstimatedPumpableVolume() {
      if (this.sourceInput.openingDipMm == 0) return { mm: 0, litres: 0 };
      const litres = convertMMToLitres(this.sourceInput.openingDipMm, this.sourceTankTechnicalDetails) - Number(this.sourceTankTechnicalDetails.lowLowLevelAlarmLitres);
      const mm = convertLitresToMM(litres, this.sourceTankTechnicalDetails);
      const error = litres < 0 || mm < 0 ? 'Caution: estimated pumpable volume is negative' : null;
      this.sourceInput.estimatedPumpableVolumeMm = mm;
      this.sourceInput.estimatedPumpableVolumeLitres = litres;
      return { mm, litres, error };
    },
    targetEstimatedPumpableVolume() {
      if (this.targetInput.openingDipMm == 0) return { mm: 0, litres: 0 };
      const litres = convertMMToLitres(this.targetInput.openingDipMm, this.targetTankTechnicalDetails) - Number(this.targetTankTechnicalDetails.lowLowLevelAlarmLitres);
      const mm = convertLitresToMM(litres, this.targetTankTechnicalDetails);
      const error = litres < 0 || mm < 0 ? 'Caution: estimated pumpable volume is negative' : null;
      this.targetInput.estimatedPumpableVolumeMm = mm;
      this.targetInput.estimatedPumpableVolumeLitres = litres;
      return { mm, litres, error };
    },
    sourceEstimatedFinishDip() {
      const litres = convertMMToLitres(this.sourceInput.openingDipMm, this.sourceTankTechnicalDetails) + Number(this.sourceInput.plannedVolumeLitres);
      const mm = convertLitresToMM(litres, this.sourceTankTechnicalDetails);
      const error = litres < 0 || mm < 0 ? 'Caution: finish dip is negative' : null;
      this.sourceInput.estimatedFinishDipMm = mm;
      this.sourceInput.estimatedFinishDipLitres = litres;
      return { mm, litres, error };
    },
    targetEstimatedFinishDip() {
      const totalLitres = Number(this.targetInput.plannedVolumeLitres)
                          + Number(this.targetInput.plannedTankBottomAdjustmentLitres)
                          + Number(this.sourceInput.plannedPipelineAdjustmentsLitres);

      const litres = convertMMToLitres(this.targetInput.openingDipMm, this.targetTankTechnicalDetails) + Number(totalLitres);
      const mm = convertLitresToMM(litres, this.targetTankTechnicalDetails);
      const error = litres < 0 || mm < 0 ? 'Caution: finish dip is negative' : null;
      this.targetInput.estimatedFinishDipMm = mm;
      this.targetInput.estimatedFinishDipLitres = litres;
      return { mm, litres, error };
    },
    getAppearanceListByProductForSource() {
      const product = this.getProduct(false);
      if (product) {
        return getAppearanceList(product);
      }
      return null;
    },
    getAppearanceListByProductForTarget() {
      const product = this.getProduct(true);
      if (product) {
        return getAppearanceList(product);
      }
      return null;
    },
    colourList() {
      return PRODUCT_QUALITY_COLOUR_OPTIONS;
    },
    products() {
      return PRODUCTS;
    },
    lastSourceReleaseFormDate: {
      get() {
        return this.sourceDateString;
      },
      set(value) {
        this.sourceDateString = value;
        if (isValidMaskedDateTime(value, DATE_TIME_FORMAT)) {
          this.transferPlanInput.lastSourceReleaseFormDate = value;
        }
        this.transferPlanInput.lastSourceReleaseFormDate = value;
      }
    },
    lastTargetReleaseFormDate: {
      get() {
        return this.targetDateString;
      },
      set(value) {
        this.targetDateString = value;
        if (isValidMaskedDateTime(value, DATE_TIME_FORMAT)) {
          this.transferPlanInput.lastTargetReleaseFormDate = value;
        }
        this.transferPlanInput.lastTargetReleaseFormDate = value;
      }
    },
    sourceOpeningDip: {
      get() {
        return this.sourceInput.openingDipMm;
      },
      set(value) {
        this.sourceInput.openingDipMm = value;
        if (value == '') this.sourceInput.openingDipMm = 0;
      }
    },
    sourcePlannedVolumeLitres: {
      get() {
        return this.sourceInput.plannedVolumeLitres;
      },
      set(value) {
        if (value >= 0) this.sourceInput.plannedVolumeLitres = value * -1;
        else if (value == '') this.sourceInput.plannedVolumeLitres = 0;
      }
    },
    sourceEstimatedPumpableVolumeMm: {
      get() {
        return this.sourceInput.estimatedPumpableVolumeMm;
      },
      set(value) {
        this.sourceInput.estimatedPumpableVolumeMm = this.sourceEstimatedPumpableVolume.mm;
      }
    },
    sourcePlannedPipelineAdjustmentsLitres: {
      get() {
        return this.sourceInput.plannedPipelineAdjustmentsLitres;
      },
      set(value) {
        this.sourceInput.plannedPipelineAdjustmentsLitres = value;
        if (value == '') this.sourceInput.plannedPipelineAdjustmentsLitres = 0;
      }
    },
    sourceAppearance: {
      get() {
        return this.sourceInput.appearance;
      },
      set(value) {
        this.sourceInput.appearance = value;
      }
    },
    sourceColour: {
      get() {
        return this.sourceInput.colour;
      },
      set(value) {
        this.sourceInput.colour = value;
      }
    },
    sourceDensity: {
      get() {
        return this.sourceInput.density;
      },
      set(value) {
        this.sourceInput.density = value;
      }
    },
    sourceFlashpoint: {
      get() {
        return this.sourceInput.flashpoint;
      },
      set(value) {
        this.sourceInput.flashpoint = value;
      }
    },
    sourceConductivity: {
      get() {
        return this.sourceInput.conductivity;
      },
      set(value) {
        this.sourceInput.conductivity = value;
      }
    },
    sourceViscosity: {
      get() {
        return this.sourceInput.viscosity;
      },
      set(value) {
        this.sourceInput.viscosity = value;
      }
    },
    sourceSulphur: {
      get() {
        return this.sourceInput.sulphur;
      },
      set(value) {
        this.sourceInput.sulphur = value;
      }
    },
    sourceCloudPoint: {
      get() {
        return this.sourceInput.cloudPoint;
      },
      set(value) {
        this.sourceInput.cloudPoint = value;
      }
    },
    sourceColdFilterPluggingPoint: {
      get() {
        return this.sourceInput.coldFilterPluggingPoint;
      },
      set(value) {
        this.sourceInput.coldFilterPluggingPoint = value;
      }
    },
    sourcePourPoint: {
      get() {
        return this.sourceInput.pourPoint;
      },
      set(value) {
        this.sourceInput.pourPoint = value;
      }
    },
    targetOpeningDip: {
      get() {
        return this.targetInput.openingDipMm;
      },
      set(value) {
        this.targetInput.openingDipMm = value;
        if (value == '') this.targetInput.openingDipMm = 0;
      }
    },
    targetPlannedVolumeLitres: {
      get() {
        
        if (this.validateTargetPlannedVolume(this.sourceInput.plannedVolumeLitres) == true 
          && this.targetInput.plannedVolumeLitres >= 0
          && this.isTargetEdited === false) {
          this.targetInput.plannedVolumeLitres = this.sourceInput.plannedVolumeLitres * -1;
        }
        return this.targetInput.plannedVolumeLitres;
      },
      set(value) {
        this.isTargetEdited = true;
        this.targetInput.plannedVolumeLitres = value;
      }
    },
    targetPlannedTankBottomAdjustmentLitres: {
      get() {
        return this.targetInput.plannedTankBottomAdjustmentLitres;
      },
      set(value) {
        this.targetInput.plannedTankBottomAdjustmentLitres = value;
        if (value == '') this.targetInput.plannedTankBottomAdjustmentLitres = 0;
      }
    },
    targetAppearance: {
      get() {
        return this.targetInput.appearance;
      },
      set(value) {
        this.targetInput.appearance = value;
      }
    },
    targetColour: {
      get() {
        return this.targetInput.colour;
      },
      set(value) {
        this.targetInput.colour = value;
      }
    },
    targetDensity: {
      get() {
        return this.targetInput.density;
      },
      set(value) {
        this.targetInput.density = value;
      }
    },
    targetFlashpoint: {
      get() {
        return this.targetInput.flashpoint;
      },
      set(value) {
        this.targetInput.flashpoint = value;
      }
    },
    targetConductivity: {
      get() {
        return this.targetInput.conductivity;
      },
      set(value) {
        this.targetInput.conductivity = value;
      }
    },
    targetViscosity: {
      get() {
        return this.targetInput.viscosity;
      },
      set(value) {
        this.targetInput.viscosity = value;
      }
    },
    targetSulphur: {
      get() {
        return this.targetInput.sulphur;
      },
      set(value) {
        this.targetInput.sulphur = value;
      }
    },
    targetCloudPoint: {
      get() {
        return this.targetInput.cloudPoint;
      },
      set(value) {
        this.targetInput.cloudPoint = value;
      }
    },
    targetColdFilterPluggingPoint: {
      get() {
        return this.targetInput.coldFilterPluggingPoint;
      },
      set(value) {
        this.targetInput.coldFilterPluggingPoint = value;
      }
    },
    targetPourPoint: {
      get() {
        return this.targetInput.pourPoint;
      },
      set(value) {
        this.targetInput.pourPoint = value;
      }
    },
    lastSourceReleaseFormDateErrors() {
      if (!this.$v.lastSourceReleaseFormDate.$dirty || this.$v.lastSourceReleaseFormDate.$pending) {
        return [];
      }
      if (!this.$v.lastSourceReleaseFormDate.maskedDatetime) {
        return ['A valid date time is required (YYYY-MM-DD HH:MM)'];
      }
    },
    lastTargetReleaseFormDateErrors() {
      if (!this.$v.lastTargetReleaseFormDate.$dirty || this.$v.lastTargetReleaseFormDate.$pending) {
        return [];
      }
      if (!this.$v.lastTargetReleaseFormDate.maskedDatetime) {
        return ['A valid date time is required (YYYY-MM-DD HH:MM)'];
      }
    },
    sourceOpeningDipErrors() {
      if (!this.$v.sourceOpeningDip.$dirty || this.$v.sourceOpeningDip.$pending) {
        return [];
      }
      if (!this.$v.sourceOpeningDip.maxLength) {
        return ['Opening Dip exceeds 16 characters'];
      }
    },
    targetOpeningDipErrors() {
      if (!this.$v.targetOpeningDip.$dirty || this.$v.targetOpeningDip.$pending) {
        return [];
      }
      if (!this.$v.targetOpeningDip.maxLength) {
        return ['Opening Dip exceeds 16 characters'];
      }
    },
    targetPlannedVolumeLitresErrors() {
      if (!this.$v.targetPlannedVolumeLitres.$dirty || this.$v.targetPlannedVolumeLitres.$pending) {
        return [];
      }
      if (!this.$v.targetPlannedVolumeLitres.hasAltered) {
        return ["Warning: Target Planned Volume has been altered"];
      }
    },
    sourceAppearanceErrors() {
      if (!this.$v.sourceAppearance.$dirty || this.$v.sourceAppearance.$pending) {
        return [];
        }

      if (!this.$v.sourceAppearance.isValid) {
        return 'Appearance value is invalid.';
      }
    },
    targetAppearanceErrors() {
      if (!this.$v.targetAppearance.$dirty || this.$v.targetAppearance.$pending) {
        return [];
        }

      if (!this.$v.targetAppearance.isValid ) {
        return 'Appearance value is invalid.';
      }
    },
    sourceColourErrors() {
      if (!this.$v.sourceColour.$dirty || this.$v.sourceColour.$pending) {
        return [];
        }

      if (!this.$v.sourceColour.isValid ) {
        return 'Colour value is invalid.';
      }
    },
    targetColourErrors() {
      if (!this.$v.targetColour.$dirty || this.$v.targetColour.$pending) {
        return [];
        }

      if (!this.$v.targetColour.isValid ) {
        return 'Colour value is invalid.';
      }
    },
    sourceDensityErrors() {
      if (!this.$v.sourceDensity.$dirty || this.$v.sourceDensity.$pending) {
        return [];
      }

      if (!this.$v.sourceDensity.isValid) {
        return ['Invalid density'];
      }
    },
    targetDensityErrors() {
      if (!this.$v.targetDensity.$dirty || this.$v.targetDensity.$pending) {
        return [];
      }

      if (!this.$v.targetDensity.isValid) {
        return ['Invalid density'];
      }
    },
    sourceFlashpointErrors() {
      if (!this.$v.sourceFlashpoint.$dirty || this.$v.sourceFlashpoint.$pending) {
        return [];
      }

      if (!this.$v.sourceFlashpoint.isValid) {
        return ['Invalid flashpoint'];
      }
    },
    targetFlashpointErrors() {
      if (!this.$v.targetFlashpoint.$dirty || this.$v.targetFlashpoint.$pending) {
        return [];
      }

      if (!this.$v.targetFlashpoint.isValid) {
        return ['Invalid flashpoint'];
      }
    },
    sourceConductivityErrors() {
      if (!this.$v.sourceConductivity.$dirty || this.$v.sourceConductivity.$pending) {
        return [];
      }

      if (!this.$v.sourceConductivity.isValid) {
        return ['Invalid conductivity'];
      }
    },
    targetConductivityErrors() {
      if (!this.$v.targetConductivity.$dirty || this.$v.targetConductivity.$pending) {
        return [];
      }

      if (!this.$v.targetConductivity.isValid) {
        return ['Invalid conductivity'];
      }
    },
    sourceViscosityErrors() {
      if (!this.$v.sourceViscosity.$dirty || this.$v.sourceViscosity.$pending) {
        return [];
      }

      if (!this.$v.sourceViscosity.isValid) {
        return ['Invalid viscosity'];
      }
    },
    targetViscosityErrors() {
      if (!this.$v.targetViscosity.$dirty || this.$v.targetViscosity.$pending) {
        return [];
      }

      if (!this.$v.targetViscosity.isValid) {
        return ['Invalid viscosity'];
      }
    },
    sourceSulphurErrors() {
      if (!this.$v.sourceSulphur.$dirty || this.$v.sourceSulphur.$pending) {
        return [];
      }

      if (!this.$v.sourceSulphur.isValid) {
        return ['Invalid sulphur'];
      }
    },
    targetSulphurErrors() {
      if (!this.$v.targetSulphur.$dirty || this.$v.targetSulphur.$pending) {
        return [];
      }

      if (!this.$v.targetSulphur.isValid) {
        return ['Invalid sulphur'];
      }
    },
    sourceCloudPointErrors() {
      if (!this.$v.sourceCloudPoint.$dirty || this.$v.sourceCloudPoint.$pending) {
        return [];
      }

      if (!this.$v.sourceCloudPoint.isValid) {
        return ['Invalid cloud point'];
      }
    },
    targetCloudPointErrors() {
      if (!this.$v.targetCloudPoint.$dirty || this.$v.targetCloudPoint.$pending) {
        return [];
      }

      if (!this.$v.targetCloudPoint.isValid) {
        return ['Invalid cloud point'];
      }
    },
    sourceColdFilterPluggingPointErrors() {
      if (!this.$v.sourceColdFilterPluggingPoint.$dirty || this.$v.sourceColdFilterPluggingPoint.$pending) {
        return [];
      }

      if (!this.$v.sourceColdFilterPluggingPoint.isValid) {
        return ['Invalid source cold filter plugging point'];
      }
    },
    targetColdFilterPluggingPointErrors() {
      if (!this.$v.targetColdFilterPluggingPoint.$dirty || this.$v.targetColdFilterPluggingPoint.$pending) {
        return [];
      }

      if (!this.$v.targetColdFilterPluggingPoint.isValid) {
        return ['Invalid source cold filter plugging point'];
      }
    },
    sourcePourPointErrors() {
      if (!this.$v.sourcePourPoint.$dirty || this.$v.sourcePourPoint.$pending) {
        return [];
      }

      if (!this.$v.sourcePourPoint.isValid) {
        return ['Invalid source pour point'];
      }
    },
    targetPourPointErrors() {
      if (!this.$v.targetPourPoint.$dirty || this.$v.targetPourPoint.$pending) {
        return [];
      }

      if (!this.$v.targetPourPoint.isValid) {
        return ['Invalid source pour point'];
      }
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch(
      TANK_TO_TANK_TRANSFER.NS(
        TANK_TO_TANK_TRANSFER.ACTIONS.GET_TANK_TO_TANK_TRANSFER_PLAN
      ),
        { id: this.tankToTankPlanId }
      );
      this.sourceInput = cloneDeep(this.sourceTank);
      this.targetInput = cloneDeep(this.targetTank);
      this.sourceDateString = formatDateTimeToMasked(this.tankToTankTransferPlan.lastSourceReleaseFormDate, DATE_TIME_FORMAT);
      this.targetDateString = formatDateTimeToMasked(this.tankToTankTransferPlan.lastTargetReleaseFormDate, DATE_TIME_FORMAT);
      this.transferPlanInput.lastSourceReleaseFormDate = this.sourceDateString;
      this.transferPlanInput.lastTargetReleaseFormDate = this.targetDateString;
      this.isLoading = false;
    },
    mapProductToColor(product, background = false, border = false) {
      if (!product) return;
      if (background) return mapProductToColor(product, border);
      return mapProductToTextColor(product);
    },
    onAbandonConfirmation() {
      this.showAbandonConfirmation = true;
    },
    async onAbandon() {
      this.isLoading = true;
      const success = await this.$store.dispatch(
        TANK_TO_TANK_TRANSFER.NS(
          TANK_TO_TANK_TRANSFER.ACTIONS.ABANDON_TRANSFER_PLAN
        ),
        { planId: this.tankToTankPlanId }
      );

      if (success) {
        this.$router.push({
          name: ROUTE_NAMES.TANK_TO_TANK_RECEIPT_PLAN_LIST
        });
      }
      this.isLoading = false;
      this.showAbandonConfirmation = false;
    },
    validateTargetPlannedVolume(value) {
      return this.sourceInput.plannedVolumeLitres * 1 === value;
    },
    getProduct(isTarget) {
      if (isTarget == true) return this.tankToTankTransferPlan.targetTankTechnicalDetails.product;
      else return this.tankToTankTransferPlan.sourceTankTechnicalDetails.product;
    },
    async onSave() {
      this.isLoading = true;
      const terminalId = this.$route.params && this.$route.params.terminalId;
      this.sourceInput.tankType = tankType.source;
      this.targetInput.tankType = tankType.target;
      this.sourceInput.estimatedPumpableVolumeMm = this.sourceEstimatedPumpableVolume.mm;
      this.sourceInput.estimatedPumpableVolumeLitres = this.sourceEstimatedPumpableVolume.litres;
      this.targetInput.estimatedPumpableVolumeMm = this.targetEstimatedPumpableVolume.mm;
      this.targetInput.estimatedPumpableVolumeLitres = this.targetEstimatedPumpableVolume.litres;
      this.sourceInput.estimatedFinishDipMm = this.sourceEstimatedFinishDip.mm;
      this.sourceInput.estimatedFinishDipLitres = this.sourceEstimatedFinishDip.litres;
      this.targetInput.estimatedFinishDipMm = this.targetEstimatedFinishDip.mm;
      this.targetInput.estimatedFinishDipLitres = this.targetEstimatedFinishDip.litres;

      let success;
      success = await this.$store.dispatch(
        TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.UPDATE_TANK_PLANS), {
          id: this.tankToTankPlanId,
          sourceTankPlanId: this.sourceTank.id, 
          targetTankPlanId: this.targetTank.id,
          lastSourceReleaseFormDate: this.transferPlanInput.lastSourceReleaseFormDate,
          lastTargetReleaseFormDate: this.transferPlanInput.lastTargetReleaseFormDate,
          sourceTankPlan: this.sourceInput,
          targetTankPlan: this.targetInput
        }
      );
      if (success) {
        this.$store.dispatch(
          TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.GET_TRANSFER_PLANS_BY_TERMINAL),
          terminalId
        );
      }
      this.isLoading = false;
    },
  },

  validations: {
    lastSourceReleaseFormDate: {
      maskedDatetime
    },
    lastTargetReleaseFormDate: {
      maskedDatetime
    },
    sourceOpeningDip: {
      maxLength: maxLength(16)
    },
    targetOpeningDip: {
      maxLength: maxLength(16)
    },
    targetPlannedVolumeLitres: {
      hasAltered: function (value) {
        return this.validateTargetPlannedVolume(this.targetInput.plannedVolumeLitres);
      }
    },
    sourceAppearance: {
      isValid: function (value) {
        return validateAppearance(this.getProduct(false), value);
      }
    },
    targetAppearance: {
      isValid: function (value) {
        return validateAppearance(this.getProduct(true), value);
      }
    },
    sourceColour: {
      isValid: function (value) {
        return validateColour(this.getProduct(false), value);
      }
    },
    targetColour: {
      isValid: function (value) {
        return validateColour(this.getProduct(true), value);
      }
    },
    sourceDensity: {
      isValid: function (value) {
        return validateDensityTankToTank(this.getProduct(false), value);
      }
    },
    targetDensity: {
      isValid: function (value) {
        return validateDensityTankToTank(this.getProduct(true), value);
      }
    },
    sourceFlashpoint: {
      isValid: function (value) {
        return validateFlashPointTankToTank(this.getProduct(false), value);
      }
    },
    targetFlashpoint: {
      isValid: function (value) {
        return validateFlashPointTankToTank(this.getProduct(true), value);
      }
    },
    sourceConductivity: {
      isValid: function (value) {
        return validateConductivityTankToTank(this.getProduct(false), value);
      }
    },
    targetConductivity: {
      isValid: function (value) {
        return validateConductivityTankToTank(this.getProduct(true), value);
      }
    },
    sourceViscosity: {
      isValid: function (value) {
        return validateViscosity(this.getProduct(false), value);
      }
    },
    targetViscosity: {
      isValid: function (value) {
        return validateViscosity(this.getProduct(true), value);
      }
    },
    sourceSulphur: {
      isValid: function (value) {
        return validateSulphur(this.getProduct(false), value);
      }
    },
    targetSulphur: {
      isValid: function (value) {
        return validateSulphur(this.getProduct(true), value);
      }
    },
    sourceCloudPoint: {
      isValid: function (value) {
        return validateCloudPointTankToTank(this.getProduct(false), value);
      }
    },
    targetCloudPoint: {
      isValid: function (value) {
        return validateCloudPointTankToTank(this.getProduct(true), value);
      }
    },
    sourceColdFilterPluggingPoint: {
      isValid: function (value) {
        return validateColdFilterPluggingPointTankToTank(this.getProduct(false), value);
      }
    },
    targetColdFilterPluggingPoint: {
      isValid: function (value) {
        return validateColdFilterPluggingPointTankToTank(this.getProduct(true), value);
      }
    },
    sourcePourPoint: {
      isValid: function (value) {
        return validatePourPointTankToTank(this.getProduct(false), value);
      }
    },
    targetPourPoint: {
      isValid: function (value) {
        return validatePourPointTankToTank(this.getProduct(true), value);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

h2 {
  text-align: center;
  text-transform: uppercase;
}

.table {
  width: 60% !important;
  align-self: center;
  border-collapse: separate;
  border-spacing: 10px 0;
  table-layout: fixed;
}

table tr td:nth-child(1) {
  border-left: 0;
}
</style>