import { DISCHARGE_LOG_MUTATIONS } from './mutations';
import api from './api';
import { UI } from '@/store/ui';

export const DISCHARGE_LOG_ACTIONS = {
  GET_DISCHARGE_LOG_LIST: 'getDischargeLogList',
  CREATE_DISCHARGE_LOG: 'createDischargeLog',
  EDIT_DISCHARGE_LOG: 'editDischargeLog'
};

export const ERROR_GET_DISCHARGE_LOG_LIST =
  'There was a problem trying to load the log of events list';
export const ERROR_CREATE_DISCHARGE_LOG =
  'There was a problem trying to create the event log item';
export const ERROR_EDIT_DISCHARGE_LOG =
    'There was a problem trying to edit the event log item';

export const actions = {
  async [DISCHARGE_LOG_ACTIONS.GET_DISCHARGE_LOG_LIST]({ commit, dispatch }, {
    planId
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      const response = await api.getDischargeLogList(planId);
      commit(DISCHARGE_LOG_MUTATIONS.SET_DISCHARGE_LOG_LIST, response.data.data);
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          id: 'failed-get-discharge-log-list',
          text: ERROR_GET_DISCHARGE_LOG_LIST,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  async [DISCHARGE_LOG_ACTIONS.CREATE_DISCHARGE_LOG]({ commit, dispatch }, {
    planId,
    dischargeLog
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      const response = await api.createDischargeLog(planId, dischargeLog);
      commit(DISCHARGE_LOG_MUTATIONS.ADD_DISCHARGE_LOG_LIST, response.data);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          id: 'failed-create-discharge-log',
          text: ERROR_CREATE_DISCHARGE_LOG,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  },
  async [DISCHARGE_LOG_ACTIONS.EDIT_DISCHARGE_LOG]({ commit, dispatch }, {
    planId,
    dischargeLog
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      await api.editDischargeLog(planId, dischargeLog);
      success = true;

      // we need to get the update details which are not available in the spa
      if (success) {
        const result = await api.getDischargeLog(planId, dischargeLog.id);
        commit(DISCHARGE_LOG_MUTATIONS.EDIT_DISCHARGE_LOG_LIST, result.data);
      }
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-edit-discharge-log',
        text: ERROR_EDIT_DISCHARGE_LOG,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  }
};

