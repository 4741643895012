import { PIPELINE_INFORMATION_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
import api from './api';

export const PIPELINE_INFORMATION_ACTIONS = {
    GET_PIPELINE_INFORMATION_BY_TERMINAL: 'getPipelineInformationByTerminal',
    UPDATE_PIPELINE_DETAILS: 'updatePipelineDetails',
    CREATE_NEW_PIPELINE_DETAILS: 'createNewPipelineDetails'
}

export const ERROR_GET_PIPELINE_INFORMATION_BY_TERMINAL =
    'There was a problem with loading the pipeline information';

export const ERROR_UPDATE_PIPELINE_DETAILS =
    'There was a problem with updating the pipeline details';

export const ERROR_CREATE_NEW_PIPELINE_DETAILS =
    'There was a problem with creating a new pipeline detail';

export const actions = {
    async [PIPELINE_INFORMATION_ACTIONS.GET_PIPELINE_INFORMATION_BY_TERMINAL]({ commit, dispatch }, terminalId) {
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
            root: true
        });
        try {
            let response = await api.getPipelineInformationByTerminal(terminalId);
            commit(PIPELINE_INFORMATION_MUTATIONS.SET_PIPELINE_INFORMATION_BY_TERMINAL, response.data);
        }
        catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'failed-load-pipeline-information',
                    text: ERROR_GET_PIPELINE_INFORMATION_BY_TERMINAL,
                    error
                },
                { root: true }
            );
        }
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
            root: true
        });
    },
    async [PIPELINE_INFORMATION_ACTIONS.UPDATE_PIPELINE_DETAILS]({ dispatch }, pipelineDetails) {
        let success = false;
        try {
            await api.updatePipelineDetails(pipelineDetails.id, pipelineDetails.pipelineDetails);
            dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'success-update-pipeline-details',
                    text: 'Successfully updated pipeline details'
                },
                { root: true }
            );
            success = true;
        } catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'error-update-pipeline-details',
                    text: 'Updating pipeline details failed',
                    error: true
                },
                { root: true }
            );
        }
        return success;
    },
    async [PIPELINE_INFORMATION_ACTIONS.CREATE_NEW_PIPELINE_DETAILS]({ commit, dispatch }, { terminalId, pipelineDetails }
    ) {
        let success = false;
        try {
            const response = await api.createNewPipelineDetails(terminalId, pipelineDetails);
            pipelineDetails.id = response.data.id;
            dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'successfully-created-new-pipeline-details',
                    text: 'Successfully created new pipeline'
                },
                { root: true }
            );
            success = true;
        } catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST), {
                id: 'failed-to-create-new-pipeline-details',
                text: ERROR_CREATE_NEW_PIPELINE_DETAILS,
                error
            },
                { root: true }
            );
        }
        return success;
    },
};