import {
    PRODUCTS_WITH_CONDUCTIVITY,
    PRODUCTS_WITH_FLASHPOINT,
    PRODUCTS_WITH_VISCOSITY,
    PRODUCTS_WITH_CLOUD_POINT,
    PRODUCTS_WITH_SULPHUR,
    PRODUCTS_WITH_COLD_FILTER_PLUGGING_POINT,
    PRODUCTS_WITH_POUR_POINT
  } from '@/constants/products';
  
  export default{
    methods: {
        showConductivity(product) {
            return product && PRODUCTS_WITH_CONDUCTIVITY.map((a) => {
              return a.toLowerCase();
            }).includes(product.toLowerCase());
          },
          showFlashPoint(product = null) {
            return PRODUCTS_WITH_FLASHPOINT.map((a) => {
              return a.toLowerCase();
            }).includes(product ? product.toLowerCase() : (this.product ? this.product.toLowerCase() : '' ));
          },
          showViscosity(product){
            return product && PRODUCTS_WITH_VISCOSITY.map((a) => {
              return a.toLowerCase();
            }).includes(product.toLowerCase());
          },
          showCloudPoint(product){
            return product && PRODUCTS_WITH_CLOUD_POINT .map((a) => {
              return a.toLowerCase();
            }).includes(product.toLowerCase());
          },
          showSulphur(product){
            return product && PRODUCTS_WITH_SULPHUR.map((a) => {
              return a.toLowerCase();
            }).includes(product.toLowerCase());
          },
          showColdFilterPluggingPoint(product){
            return product && PRODUCTS_WITH_COLD_FILTER_PLUGGING_POINT .map((a) => {
              return a.toLowerCase();
            }).includes(product.toLowerCase());
          },
          showPourPoint(product){
            return product && PRODUCTS_WITH_POUR_POINT .map((a) => {
              return a.toLowerCase();
            }).includes(product.toLowerCase());
          }
    }
  }
