import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';
import { removeTimezone } from '@/helpers/date';
import { parseISO } from 'date-fns';

export const DISCHARGE_PLAN_MUTATIONS = {
  SET_DISCHARGE_PLAN: 'setDischargePlan',
  SET_ORIGINAL_DISCHARGE_PLAN: 'setOriginalDischargePlan',
  SET_VESSEL: 'setVessel',
  SET_VOYAGE: 'setVoyage',
  SET_PILOT_ON_BOARD: 'setPilotOnBoard',
  SET_IMO_NUMBER: 'setImoNumber',
  SET_DISCHARGE_PLAN_LIST: 'setDischargePlanList',
  SET_DISCHARGE_PLAN_LIST_TOTAL: 'setDischargePlanListTotal',
  CONVERT_DATE_FIELDS_TO_DATES: 'convertDateFieldsToDates',
  SET_RECEIPT_PLAN_TANK_PRODUCT_ADJUSTMENTS: 'setReceiptPlanTankProductAdjustments',
  SET_RECEIPT_PLAN_TANK_ADJUSTMENT_COMMENTS: 'setReceiptPlanTankAdjustmentComments'
};

export const mutations = {
  [DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN](state, dischargePlan) {
    Vue.set(state, 'dischargePlan', cloneDeep(dischargePlan));
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_ORIGINAL_DISCHARGE_PLAN](state, dischargePlan) {
    Vue.set(state, 'originalDischargePlan', cloneDeep(dischargePlan));
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_VESSEL](state, vessel) {
    state.dischargePlan.vessel = vessel;
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_VOYAGE](state, voyage) {
    state.dischargePlan.voyage = voyage;
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_IMO_NUMBER](state, imoNumber) {
    state.dischargePlan.imoNumber = imoNumber;
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_PILOT_ON_BOARD](state, pilotOnBoard) {
    //Removes timezone information ie changes Wed Mar 25 2015 13:00:00 GMT+1300 (New Zealand Daylight Time) to Wed Mar 25 2015 13:00
    //So that it gets parsed correctly by axios
    state.dischargePlan.pilotOnBoard = removeTimezone(new Date(pilotOnBoard));
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN_LIST](state, dischargePlanList) {
    Vue.set(state, 'dischargePlanList', cloneDeep(dischargePlanList));
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN_LIST_TOTAL](state, total) {
    Vue.set(state, 'terminalDischargeListTotal', total)
  },
  [DISCHARGE_PLAN_MUTATIONS.CONVERT_DATE_FIELDS_TO_DATES](state) {
    state.dischargePlan.pilotOnBoard = parseISO(
      state.dischargePlan.pilotOnBoard
    );
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_RECEIPT_PLAN_TANK_ADJUSTMENT_COMMENTS](
    state,
    { value, receiptPlanId, tankPlanId }
  ) {
    state.dischargePlan.receiptPlans[receiptPlanId].tankPlans[
      tankPlanId
    ].adjustmentComments = value;
  },
  [DISCHARGE_PLAN_MUTATIONS.SET_RECEIPT_PLAN_TANK_PRODUCT_ADJUSTMENTS](
    state,
    { value, receiptPlanId, tankPlanId }
  ) {
    state.dischargePlan.receiptPlans[receiptPlanId].tankPlans[
      tankPlanId
    ].productAdjustments = isNaN(value) ? value : Number(value);
  }
};
