import { FEATURES_MUTATIONS } from './mutations';
import { AppConfigurationClient } from '@azure/app-configuration';
import {
  AD_APP_CONFIG_CONN_STRING
} from '@/helpers/config';

const client = new AppConfigurationClient(AD_APP_CONFIG_CONN_STRING);

export const FEATURES_ACTIONS = {
  SET_FEATURE_STATE: 'setFeatureState',
  SET_CONFIG_SETTING: 'setConfigSetting'
};

export const actions = {
  async [FEATURES_ACTIONS.SET_FEATURE_STATE]({ commit }, flagKey='') {
    if (!flagKey || !flagKey.toString().trim().length)
      console.error('A feature flag key must be supplied.');

    try {
      const result = await client.getConfigurationSetting({
          key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
        });
        if (result && typeof result === 'object') {
          console.debug('Feature: ' + JSON.parse(result.value).id, JSON.parse(result.value).enabled);
          commit(FEATURES_MUTATIONS.SET_FEATURE_STATE, {
            key: flagKey, 
            value: JSON.parse(result.value).enabled
          });
        }
    } catch (error) {
      console.error(error);
    }
  },
  async [FEATURES_ACTIONS.SET_CONFIG_SETTING]({ commit }, configKey='') {
    if (!configKey || !configKey.toString().trim().length) 
      console.error('A config key must be supplied.');

    try {
      const result = await client.getConfigurationSetting({ key: configKey.toString().trim() });
        if (result) {
          console.debug('Config: ' + result.key, result.value);
          commit(FEATURES_MUTATIONS.SET_CONFIG_SETTING, result.value);
        }
    } catch (error) {
      console.error(error);
    }
  }
}