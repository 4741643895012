import api from './api';
import { SHIP_PORT_PERFORMANCE_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';

export const ERROR_GET_SHIP_PORT_PERFORMANCE =
  'There was a problem trying to load the ship port performance';
export const ERROR_UPDATE_SHIP_PORT_PERFORMANCE =
  'There was a problem trying to update the ship port performance';

export const SHIP_PORT_PERFORMANCE_ACTIONS = {
  GET_SHIP_PORT_PERFORMANCE: 'getShipPortPerformance',
  UPDATE_SHIP_PORT_PERFORMANCE_ANSWERS: 'updateShipPortPerformanceAnswers',
  GET_SHIP_PORT_PERFORMANCE_REPORT_PDF: 'getShipPortPerformanceReportPdf',
  EMAIL_SHIP_PORT_PERFORMANCE_REPORT_PDF: 'emailShipPortPerformanceReportPdf'
}

export const actions = {
  async [SHIP_PORT_PERFORMANCE_ACTIONS.GET_SHIP_PORT_PERFORMANCE](
    { commit, dispatch },
    { planId }
  ) {
    let success = false;
    try { 
      const response = await api.getShipPortPerformance({ planId });
      commit(SHIP_PORT_PERFORMANCE_MUTATIONS.SET_SHIP_PORT_PERFORMANCE, response.data);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-ship-port-performance',
        text: ERROR_GET_SHIP_PORT_PERFORMANCE,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [SHIP_PORT_PERFORMANCE_ACTIONS.UPDATE_SHIP_PORT_PERFORMANCE_ANSWERS](
    { commit, dispatch }, 
    { submissionResults, sectionComments, shipPerformanceStatus, planId }
  ) {
    let success = false;
    try {
      await api.updateShipPortPerformance({
        planId, submissionResults, sectionComments, shipPerformanceStatus
      });
      success = true;
    } catch (error) {
      success = false;
    }
    return success;
  },
  async [SHIP_PORT_PERFORMANCE_ACTIONS.GET_SHIP_PORT_PERFORMANCE_REPORT_PDF](
    { dispatch },
    { planId }
  ) {
    try {
      return await api.getShipPortPerformanceReportPdf({
        planId
      });
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-ship-port-performance-report-pdf',
        text: ERROR_GET_SHIP_PORT_PERFORMANCE,
        error
      },
        { root: true }
      );
    }
  },
  async [SHIP_PORT_PERFORMANCE_ACTIONS.EMAIL_SHIP_PORT_PERFORMANCE_REPORT_PDF](
    { commit, dispatch }, 
    { planId, emailModel }
  ) {
    let success = false;
    try {
      await api.emailShipPortPerformanceReportPdf({
        planId, emailModel
      });
      success = true;
    } catch (error) {
      success = false;
    }
    return success;
  }
}