import { actions, SHIP_SAMPLE_ACTIONS } from './actions';
import { getters, SHIP_SAMPLE_GETTERS } from './getters';
import { mutations, SHIP_SAMPLE_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'shipSample';

export function GET_DEFAULT_SHIP_SAMPLE() {
  return {
    product: 'Ago',
    vesselTank: '',
    initials: '',
    testAppearance: '',
    testConductivity: 0,
    testDensity: 0,
    testFlashPoint: 0
  };
}

export const state = {
  shipSampleList: []
};

export const SHIP_SAMPLE = {
  ACTIONS: SHIP_SAMPLE_ACTIONS,
  GETTERS: SHIP_SAMPLE_GETTERS,
  MUTATIONS: SHIP_SAMPLE_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const shipSample = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};