import { http } from '@/api';

export default {
  getMonitorDetails: (planId, receiptPlanId, tankPlanId) => http.get(
    `/v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/monitordetails`
  ),
  createMonitorDetails: (planId, receiptPlanId, tankPlanId, monitorDetails) => http.post(
    `/v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/monitordetails`,
    {
      planId,
      receiptPlanId,
      model: monitorDetails
    }
  ),
  updateMonitorDetails: (
    planId,
    receiptPlanId,
    tankPlanId,
    monitorDetailsId,
    monitorDetails
  ) => http.put(
      `/v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/monitordetails/${monitorDetailsId}`, {
      planId,
      receiptPlanId,
      model: monitorDetails
    }
  ),
  deleteMonitorDetails: (
    planId,
    receiptPlanId,
    tankPlanId,
    monitorDetailsId
  ) => http.delete(
      `/v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/monitordetails/${monitorDetailsId}`
  )
}