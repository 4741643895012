import axios from 'axios';
import { API_BASE_URL, ASSUME_ROLE_ENABLED } from './helpers/config';
import { AUTH } from '@/store/auth';
import { AUTH_GETTERS } from './store/auth/getters';
import store from '@/store';
import { UI } from '@/store/ui';

// create axios client
export const http = axios.create({
  baseURL: API_BASE_URL
});

const packageJsonFile = require('../package.json');

let pendingHttpRequests = 0;
function showOrHideLoadingIndicator(show){
  show ? pendingHttpRequests++ : pendingHttpRequests--;
  if(!show && pendingHttpRequests > 0) return;
  store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), show, {
    root: true
  });
}

// get an access token before each request
http.interceptors.request.use(config => {
  config.headers['x-api-frontend-version'] = packageJsonFile.gitversion;

  if (ASSUME_ROLE_ENABLED) {
    const assumedRole = store.getters[AUTH.NS(AUTH_GETTERS.GET_ASSUMED_ROLE)];

    if (assumedRole) {
      config.headers['Assume-Role'] = assumedRole;
    }
  }

  return store.dispatch(AUTH.NS(AUTH.ACTIONS.ACQUIRE_TOKEN)).then(
    token => {
      config.headers.Authorization = `Bearer ${token}`;
      showOrHideLoadingIndicator(true);
      return config;
    },
    err => {
      console.log(err);
      return err;
    }
  );
});

http.interceptors.response.use(function (response) {
  showOrHideLoadingIndicator(false);
  return response;
}, function (error) {
  showOrHideLoadingIndicator(false);
  return Promise.reject(error);
});