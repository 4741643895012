<template>
  <v-card>
    <v-flex xs12>
      <v-card-title v-if="showSearch">
        <v-spacer />
        <v-spacer />
        <v-text-field
          color="orange lighten-1"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          class="search-input"
          @input="debounceOnSearch"
        ></v-text-field>
      </v-card-title>
    </v-flex>
    <v-data-table 
      :header-props="headerProps"
      color="orange lighten-1"
      :search="search"
      :headers="headers"
      :items="items"
      :loading="loading"
      loading-text="Loading... Please wait"
      :footer-props="footerProps"
      :items-per-page="10"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :must-sort="mustSort"
      :no-data-text="loading ? 'loading...' : ''"
      :hide-default-footer="hideActions"
      :server-items-length="totalItems"
      :options.sync="options"
      @update:pagination="debounceOnPagination"
      :height="height"
      @click:row="onRowClick">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="index" @click="onRowClick(item), selected = item.id">
            <slot :item="item" :index="(pagination.page - 1)* pagination.rowsPerPage + index"></slot>
          </tr>
        </tbody>
      </template>
      <template slot="footer">
        <slot name="footer" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { cloneDeep } from '@/helpers/object';

const defaultPagination = {
  page: 1,
  rowsPerPage: 10,
  descending: true
};

export default Vue.extend({
  data() {
    return {
      search: null,
      tempPrecision: 1,
      options:null,
      footerProps: {
        itemsPerPageOptions: [10, 25],
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'chevron_left',
        nextIcon: 'chevron_right'
      }
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: [Boolean, String]
    },
    mustSort: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Object
    },
    totalItems: {
      type: Number
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: undefined
    },
    showArrows : {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.debounceOnPagination = debounce(this.onPagination, 500);
    this.debounceOnSearch = debounce(this.onSearch, 500);
  },
  computed: {
    headerProps(){
      return this.showArrows ? undefined : { sortIcon: null }
    },
    localPagination() {
      return Object.assign({}, defaultPagination, this.$props.pagination || {});
    },
    sortBy(){
      return this.pagination && this.pagination.sortBy;
    },
    sortDesc(){
      return this.pagination && this.pagination.descending;
    }
  },
  watch: {
    options: {
      handler () {
        this.onPagination(this.options);
      },
      deep: true
    }
  },
  methods: {
    onRowClick(row) {
      this.$emit('selectRow', row.id);
    },
    onSearch() {
      const pagination = cloneDeep(this.localPagination);
      pagination.page = 1;

      const payload = this.enrichPagination(pagination);

      this.$emit('update:pagination', payload);
      this.$emit('pagination', payload);
    },
    onPagination(value) {
      const payload = this.enrichPagination(value);

      this.$emit('update:pagination', payload);
      this.$emit('pagination', payload);
    },
    // Reshape vuetify pagination object into our own.
    enrichPagination(pagination) {
      return {
        rowsPerPage: pagination.itemsPerPage,
        ...pagination,
        sortBy:  typeof pagination.sortBy === 'object' ? pagination.sortBy[0] : pagination.sortBy,
        descending:  typeof pagination.sortDesc[0] === 'boolean' ? pagination.sortDesc[0] : true,
        search: this.search,
        offset: pagination.page
          ? (Number(pagination.page) - 1) * pagination.itemsPerPage
          : undefined,
        sortDirection:
          typeof pagination.sortDesc[0] === 'boolean'
            ? pagination.sortDesc[0]
              ? 'descending'
              : 'ascending'
            : undefined
      };
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep table th{
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>