export const checklist = {
    tankerReceipt: { id: 1, type: 10 },
    shipPortPerformance: { id: 2, type: 20 },
    tankToTank: { id: 3, type: 30 }
}

export const checklistSectionType = {
    dischargePreArrivalId: 1,
    dischargeArrivalId: 2,
    dischargeDischargeId: 3,
    dischargeAfterDischargeId: 4,
    shipPortPerformanceTerminalAcceptance: 12,
    tankToTankPreArrivalId: 16,
    tankToTankDischargeId: 14,
    tankToTankAfterDischargeId: 15
}

export const shipPortPerformanceChecklistSectionIds = [5, 6, 7, 8, 9, 10, 11, 12];

export default [
    checklist,
    checklistSectionType
];