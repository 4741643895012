import { cloneDeep } from '@/helpers/object';
import { parseISO } from 'date-fns';

export const MONITORING_DETAILS_MUTATIONS = {
  ADD_MONITOR_DETAILS: 'addMonitorDetails',
  SET_MONITOR_DETAILS_BY_ID: 'setMonitorDetailsByIndex',
  DELETE_MONITOR_DETAILS: 'deleteMonitorDetails',
  MONITOR_DETAILS_CONVERT_DATE_FIELDS_TO_DATES: 'monitorDetailsConvertDateFieldsToDates'
}

export const mutations = {
  [MONITORING_DETAILS_MUTATIONS.ADD_MONITOR_DETAILS](
    state,
    { monitorDetails }
  ) {
    state.monitoringDetails.push(cloneDeep(monitorDetails));
  },
  [MONITORING_DETAILS_MUTATIONS.SET_MONITOR_DETAILS_BY_ID](
    state,
    { monitorDetailsId, monitorDetails }
  ) {
    const index = state.monitoringDetails.findIndex(v => v.id === monitorDetailsId);  
    state.monitoringDetails.splice(index, 1, monitorDetails);
  },
  [MONITORING_DETAILS_MUTATIONS.DELETE_MONITOR_DETAILS](
    state,
    { monitorDetailsId }
  ) {
    const index = state.monitoringDetails.findIndex(v => v.id === monitorDetailsId);  
    state.monitoringDetails.splice(index, 1);
  },
  [MONITORING_DETAILS_MUTATIONS.MONITOR_DETAILS_CONVERT_DATE_FIELDS_TO_DATES](state) {
    state.monitoringDetails.forEach(monitorDetail => {
      monitorDetail.createdOn = parseISO(monitorDetail.createdOn);
      monitorDetail.monitoredAt = parseISO(
        monitorDetail.monitoredAt
      );
      monitorDetail.updatedOn = parseISO(monitorDetail.updatedOn);
    });
  }
}