import { TERMINALS_MUTATIONS } from './mutations';
import api from './api';
import { UI } from '@/store/ui';
import { DISCHARGE_PLAN } from '@/store/tankerDischarge/preArrival/dischargePlan';

export const TERMINALS_ACTIONS = {
  GET_TERMINALS: 'getTerminals',
  SET_ACTIVE_TERMINAL: 'setActiveTerminal',
  UPDATE_ACTIVE_TERMINAL_TANK: 'updateActiveTerminalTank',
  UPDATE_ACTIVE_TERMINAL_TANK_TECHINCIAL_DETAILS: 'updateActiveTerminalTankTechnicalDetails',
  UPDATE_TANK_CALIBRATIONS: 'updateTankCalibrations',
  GET_TANK_TECHNICAL_DETAILS: 'getTankTechnicalDetails'
};

export const ERROR_GET_TERMINALS =
  'There was a problem trying to load the terminals';

export const ERROR_GET_TANK_TECHNICAL_DETAILS =
  'There was a problem trying to load the tank technical details';

export const actions = {
  async [TERMINALS_ACTIONS.GET_TERMINALS]({ commit, dispatch }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      const response = await api.getTerminals();
      commit(TERMINALS_MUTATIONS.SET_TERMINALS, response.data);
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST),
        {
          id: 'failed-load-terminals',
          text: ERROR_GET_TERMINALS,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  async [TERMINALS_ACTIONS.GET_TANK_TECHNICAL_DETAILS]({ commit, dispatch }, activeTerminal) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      const response = await api.getTankTechnicalDetails(activeTerminal.id);
      commit(TERMINALS_MUTATIONS.SET_TANK_TECHNICAL_DETAILS, response.data);
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST),
        {
          id: 'failed-load-terminals',
          text: ERROR_GET_TANK_TECHNICAL_DETAILS,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  [TERMINALS_ACTIONS.SET_ACTIVE_TERMINAL]({ commit, dispatch }, activeTerminal) {
    dispatch(DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.RESET_DISCHARGE_PLAN), {}, { root: true });
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_PIPELINE_PRODUCT), null, { root: true });
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_RECEIPT_PLAN_ID), null, { root: true });
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_TANK_ID), null, { root: true });
    commit(TERMINALS_MUTATIONS.SET_ACTIVE_TERMINAL, activeTerminal);
  },
  [TERMINALS_ACTIONS.UPDATE_ACTIVE_TERMINAL_TANK]({ commit }, tank) {
    commit(TERMINALS_MUTATIONS.UPDATE_ACTIVE_TERMINAL_TANK, tank);
  },
  async [TERMINALS_ACTIONS.UPDATE_ACTIVE_TERMINAL_TANK_TECHINCIAL_DETAILS]({ commit, dispatch }, tankTechnicalDetails) {
    try {
      await api.updateTechnicalDetails({ tankId: tankTechnicalDetails.id, tankTechnicalDetails });
      return true;
    } catch (error) {
      return false;
    }
  },
  async [TERMINALS_ACTIONS.UPDATE_TANK_CALIBRATIONS]({ commit, dispatch }, {id, tankCalibrations}) {
    try {
      await api.updateTankCalibrations(id, tankCalibrations);
      return true;
    } catch (error) {
      return false;
    }
  }
};