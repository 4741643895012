
export const BASE_URL = process.env.BASE_URL;
export const API_BASE_URL = process.env.VUE_APP_API_ROOT || window.env.API_BASE_URL;
export const AD_APP_INSTANCE = process.env.VUE_APP_AD_INSTANCE || window.env.AD_APP_INSTANCE;
export const AD_APP_TENANT = process.env.VUE_APP_AD_TENANT || window.env.AD_APP_TENANT;
export const AD_APP_CLIENTID = process.env.VUE_APP_AD_CLIENTID || window.env.AD_APP_CLIENTID;
export const AD_API_APP_ID = process.env.VUE_APP_AD_API_APP_ID || window.env.AD_API_APP_ID;
export const AD_APP_CONFIG_CONN_STRING = process.env.VUE_APP_CONFIG_CONN_STRING || window.env.AD_APP_CONFIG_CONN_STRING;
export const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT || window.env.ENVIRONMENT;
const runtimeConfig = window.env;

export const ASSUME_ROLE_ENABLED = runtimeConfig.ASSUME_ROLE_ENABLED;