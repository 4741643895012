<template>
  <v-container fluid fill-height pa-4>
    <loading-shim class="no-print" :is-loading="uiIsLoading" />
    <v-dialog class="no-print" max-width="300" v-model="showSaveConfirmation">
      <v-card>
        <v-card-text>
          <v-layout>
            <v-col xs12>
              <strong class="save-title"
                >Do you want to save before leaving?</strong
              >
              <div class="save-message">
                There are unsaved changes to this page.
              </div>

              <div v-if="$v.$invalid || ullageError" class="save-error">
                Warning: there are validation errors, are you sure you want to save?
              </div>
            </v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange lighten-1"
            @click="onSaveConfirmation"
            :disabled="isLoading"
            :loading="isLoading"
             id="popupSaveButton"
            >Save</v-btn
          >

          <v-btn color="red lighten-3"
            @click="onSaveConfirmationLeave"
            :disabled="isLoading"
            :loading="isLoading"
             id="popupLeaveButton"
            >Leave</v-btn
          >

          <v-btn color="secondary lighten-3"
            @click="onSaveConfirmationCancel"
            :disabled="isLoading"
            :loading="isLoading"
             id="popupCancelButton"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      class="no-print"
      max-width="500"
      v-model="showUpdateStatusConfirmation"
    >
      <v-card>
        <v-card-text>
          <v-layout>
            <v-col xs12>Are you sure you want to {{ statusText }} this receipt plan?</v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="onChangeStatus" :disabled="isLoading" :loading="isLoading">{{ statusText }}</v-btn>

          <v-btn color="secondary lighten-3"
            @click="showUpdateStatusConfirmation = false"
            :disabled="isLoading"
            :loading="isLoading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container v-if="!hasLoaded">
        <v-row style="height:80vh" align="center"> 
          <v-col  fill-height align="center">
             <loading-circle />
          </v-col>
        </v-row>
    </v-container>
  
    <v-layout row wrap v-if="hasLoaded">
      <tanker-discharge-receipt-plan-info />

      <v-container fluid>
        <v-row no-gutters>
          <template v-for="plan in sortedReceiptPlans">
            <v-col v-if="displayProduct(plan.product)" :key="plan.id">
              <v-container class="reduced-padding" fluid>
                <v-col xs12>
                  <h3
                    class="upper-case"
                    :style="{
                      color: mapProductToColor(plan.product),
                      backgroundColor: mapProductToColor(plan.product, true),
                      border: `1px solid ${mapProductToColor(
                        plan.product,
                        true,
                        true
                      )}`,
                      borderRadius: '1px'
                    }">
                    {{ plan.product }}
                  </h3>
                </v-col>
                <v-col xs12 class="inputHightlight">
                  <v-currency-field
                    :value="plan.amountInKiloLitres"
                    label="Value L" color="orange lighten-1"
                    @input="updateAmountInKiloLitres($event, plan.id)"
                    :disabled="isDisabled"
                    :id="`litreValue${plan.product}Input`"
                  />
                </v-col>
                <v-col xs12>
                  <v-currency-field
                    readonly
                    :value="plan.balance"
                    label="Balance"
                    filled color="orange lighten-1"
                    :error-messages="
                      $v.receiptPlans.$each[plan.id].balance.isValid
                        ? []
                        : 'Balance is invalid'"
                    :id="`balance${plan.product}Input`"
                  />
                </v-col>
              </v-container>
            </v-col>
          </template>
        </v-row>
      </v-container>

      <v-container fluid class="inputHightlight">
        <v-row no-gutters>
          <v-slide-group class="slide-group" show-arrows="always" center-active id="slide-group">
            <template v-for="plan in sortedReceiptPlans">
              <v-col
                v-for="tank in plan.tankPlans"
                :key="tank.id"
                class="reduced-padding"
              >
              
                <v-col xs12>
                  <div class="stay-put-sticky-position">
                    <v-col xs12>
                      <h3>{{ tank.name }}</h3>
                    </v-col>
                    <v-col xs12 class="upper-case">
                      <h4
                        :style="{
                          color: mapProductToColor(tank.product),
                          backgroundColor: mapProductToColor(tank.product, true),
                          border: `1px solid ${mapProductToColor(
                            tank.product,
                            true,
                            true
                          )}`,
                          borderRadius: '1px'
                        }">
                        {{ tank.product }}
                      </h4>
                    </v-col>
                  </div>
                </v-col>

                <v-col xs12>
                  <v-currency-field
                    @input="changeOpeningBalance($event, plan.id, tank.id)"
                    :value="tank.openingBalance"
                    suffix="mm" color="orange lighten-1"
                    label="Opening dip"
                    :disabled="isDisabled"
                    :error-messages="
                      getOpeningBalanceErrorMessages(plan.id, tank.id)
                    "
                    :id="`openingDip${tank.name}Input`"
                  />
                </v-col>

                <v-col xs12>
                  <v-currency-field
                    @input="changePlannedReceipt($event, plan.id, tank.id)"
                    :value="tank.plannedReceipt"
                    suffix="L" color="orange lighten-1"
                    label="Planned volume"
                    :disabled="isDisabled"
                    :id="`plannedVolume${tank.name}Input`"
                  />
                </v-col>

                <v-col xs12>
                  <tanker-discharge-receipt-plan-cell
                    label="Ullage after"
                    :millimetres="calculateUllage(plan.id, tank.id).mm"
                    :litres="calculateUllage(plan.id, tank.id).litres"
                    :error="calculateUllage(plan.id, tank.id).error"
                    :id="`ullageAfter${tank.name}TextField`"
                    :ref="`ullageAfter${tank.name}`"          
                  />
                </v-col>

                <v-col xs12>
                  <tanker-discharge-receipt-plan-cell
                    label="Estimated Finish Dip"
                    :millimetres="estimates &&
                            estimates[`${plan.id}_${tank.id}`] &&
                            estimates[`${plan.id}_${tank.id}`].estimateMM"
                    :litres="estimates &&
                            estimates[`${plan.id}_${tank.id}`] &&
                            estimates[`${plan.id}_${tank.id}`].estimateLitres"
                    :id="`estimatedFinishDip${tank.name}TextField`"                  
                  />
                </v-col>

                <v-col xs12>
                  <v-currency-field
                    @input="changePipelineAdjustments($event, plan.id, tank.id)"
                    :allowNegative ="true"
                    :value="tank.pipelineAdjustments"
                    suffix="L" color="orange lighten-1"
                    label="Planned Pipeline Adjustments"
                    :disabled="isDisabled"
                    :error-messages="getPipelineAdjustmentsErrorMessages(plan.id, tank.id)"
                    :id="`plannedPipelineAdjustments${tank.name}Input`"                     
                  />
                </v-col>

                <v-col xs12>
                  <v-currency-field
                    @input="changeTankAdjustments($event, plan.id, tank.id)"
                    :value="tank.tankAdjustments"
                    suffix="L" color="orange lighten-1"
                    label="Planned Tank Bottom Adjustments"
                    :disabled="isDisabled"
                    :error-messages="getTankAdjustmentsErrorMessages(plan.id, tank.id)"
                    :id="`plannedTankBottomAdjustments${tank.name}Input`"                     
                  />
                </v-col>

                <v-col xs12>
                  <tanker-discharge-receipt-plan-cell
                    label="Opening balance"
                    :value="estimates &&
                            estimates[`${plan.id}_${tank.id}`] &&
                            estimates[`${plan.id}_${tank.id}`].openingBalanceLitres"
                    :millimetres="0"
                    :litres="estimates &&
                            estimates[`${plan.id}_${tank.id}`] &&
                            estimates[`${plan.id}_${tank.id}`].openingBalanceLitres"
                    :id="`openingBalance${tank.name}TextField`"   
                  />
                </v-col>

                <v-col xs12>
                  <tanker-discharge-receipt-plan-cell
                    label="Normal/Safe Fill Level"
                    :millimetres="tank.operationalLevelMillimetres"
                    :litres="tank.operationalLevelLitres"
                    :id="`normalSafeFillLevel${tank.name}TextField`"                   
                  />
                </v-col>
                <v-divider/>


                <v-col xs12>
                  <v-currency-field
                    v-model="tank.density"
                    :label="'Pre Tank Density kg/l @15\u2103'"
                    :disabled="isDisabled"
                    :error-messages="getDensityErrors(plan.id, tank.id)"
                    :decimalLength="4" color="orange lighten-1"
                    :autoDecimalMode="false"
                    :id="`preTankDensity${tank.name}Input`"                   
                  />
                </v-col>

                <v-col xs12 v-if="showFlashPoint(tank.product)">
                  <v-currency-field
                    v-model="tank.flashPoint"
                    :value="tank.flashPoint"
                    :label="'Pre Tank Flash Point \u2103'"
                    :disabled="isDisabled"
                    :error-messages="getFlashPointErrors(plan.id, tank.id)"
                    :decimalLength="1" color="orange lighten-1"
                    :autoDecimalMode="false"
                    :id="`preTankFlashpoint${tank.name}Input`"                  
                  />
                </v-col>

                <v-col xs12 v-if="showConductivity(tank.product)">
                  <v-currency-field
                    v-model="tank.conductivity"
                    :value="tank.conductivity"
                    label="Pre Tank Conductivity pS/m"
                    :disabled="isDisabled"
                    :error-messages="getConductivityErrors(plan.id, tank.id)"
                    v-mask="'###'" color="orange lighten-1"
                    :id="`preTankConductivity${tank.name}Input`"                     
                  />
                </v-col>
                <v-col xs12 v-if="showViscosity(tank.product)">
                  <v-currency-field
                    v-model="tank.viscosity"
                    :label="viscosityLabel(tank.product)"
                    :disabled="isDisabled"
                    :error-messages="getViscosityErrors(plan.id, tank.id)"
                    :id="`preTankViscosity${tank.name}Input`"    
                    :decimalLength="3" color="orange lighten-1"
                    :defaultValue="null"
                    :autoDecimalMode="false"                 
                  />
                </v-col>
                <v-col xs12 v-if="showCloudPoint(tank.product)">
                  <v-currency-field
                    v-model="tank.cloudPoint"
                    :label="'Cloud Point \u2103'"
                    :disabled="isDisabled"
                    :allowNegative ="true"
                    :defaultValue="null"
                    :error-messages="getCloudPointErrors(plan.id, tank.id)"
                    :decimalLength="2" color="orange lighten-1"
                    :autoDecimalMode="false"
                    :id="`preTankCloudPoint${tank.name}Input`"                     
                  />
                </v-col>
                <v-col xs12 v-if="showSulphur(tank.product)">
                  <v-currency-field                
                    v-model="tank.sulphur"
                    label="Sulphur mg/kg"
                    :disabled="isDisabled"
                    :error-messages="getSulphurErrors(plan.id, tank.id)"
                    :decimalLength="2" color="orange lighten-1"
                    :autoDecimalMode="false"
                    :defaultValue="null"
                    :id="`preTankSulphur${tank.name}Input`"                     
                  />
                </v-col>
                <v-col xs12 v-if="showColdFilterPluggingPoint(tank.product)">
                  <v-currency-field
                    v-model="tank.coldFilterPluggingPoint"
                    :label="'Cold Filter Plugging Point \u2103'"
                    :allowNegative ="true"
                    :disabled="isDisabled"
                    :error-messages="getColdFilterPluggingPointErrors(plan.id, tank.id)"
                    :decimalLength="2" color="orange lighten-1"
                    :autoDecimalMode="false"
                    :defaultValue="null"                 
                    :id="`preTankColdFilterPluggingPoint${tank.name}Input`"                     
                  />
                </v-col>
                <v-col xs12 v-if="showPourPoint(tank.product)">
                  <v-currency-field
                    v-model="tank.pourPoint"
                    :label="'Pour Point \u2103'"
                    :disabled="isDisabled"
                    :allowNegative ="true"
                    :error-messages="getPourPointErrors(plan.id, tank.id)"
                    :decimalLength="2" color="orange lighten-1"
                    :autoDecimalMode="false"
                    :defaultValue="null"
                    :id="`preTankPourPoint${tank.name}Input`"                     
                  />
                </v-col>
              </v-col>
            </template>
          </v-slide-group>
        </v-row>
        <v-layout class="no-print" row pa-2 justify-end>
          <v-flex xs3 pa-1>
            <flat-button color="secondary lighten-3"
              class="button"
              @click="onUpdateStatusConfirmation"
              :loading="isLoading"
              :disabled="isLoading"
              id="updateStatusReceiptPlanButton"
            >{{ statusText }} Receipt plan</flat-button>
          </v-flex>
          <v-flex xs3 pa-1>
            <flat-button color="orange lighten-1"
              class="button" @click="onUpdate"
              :loading="isLoading"
              :disabled="isLoading || isDisabled"
               id="saveReceiptPlanButton"
               >Save Receipt plan</flat-button>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { mapProductToColor, mapProductToTextColor } from '@/helpers/ColorMapper';
import { 
  DISCHARGE_PLAN 
} from '@/store/tankerDischarge/preArrival/dischargePlan';
import isEqual from 'lodash/isEqual';
import {
  PRODUCT_SORT_ORDER,
  DISPLAYABLE_PRODUCTS
} from '@/constants/products';
import { DISABLED_STATUSES, RECEIPT_PLAN_STATUS } from '@/constants/receiptPlan';
import {
  integer,
  decimal,
  minValue,
  maxValue,
  required,
  maxLength
} from 'vuelidate/lib/validators';
import {
  validateFlashPointValue,
  validateDensityValue,
  validateConductivity,
  validateViscosity,
  validateSulphur
} from '@/helpers/validation/sharedThresholds';
import { date } from '@/validators';
import { ROUTE_NAMES } from '@/routes';
import { convertMMToLitres, convertLitresToMM } from '@/helpers/discharge';
import { UI } from '@/store/ui';
import { cloneDeep } from '@/helpers/object';

export default {
  async created() {
    this.loadData();
  },
  async beforeRouteLeave(to, from, next) {
    const unsavedChanges = !isEqual(
      this.dischargePlan,
      this.originalDischargePlan
    );

    // TODO: Move ullage onto the model - Laura 17/08
      // --> inlcuding calculations so we don't need to dig into the component
    for (var key in this.$refs) {
      if (this.$refs[key][0].error) {
        this.ullageError = true;
      }
    }

    if (unsavedChanges || this.$v.$invalid || this.ullageError) {
      this.showSaveConfirmation = true;
      this.saveConfirmationNext = next;
    } else {
      next();
    }
  },
  data() {
    return {
      showExtraFields: false,
      dischargePlan: null,
      ullageError: false,
      products: [
        {
          name: 'PMS',
          tanks: [1]
        },
        {
          name: 'AGO',
          tanks: [1, 2, 3]
        },
        {
          name: 'RMS',
          tanks: [1, 2, 3, 4]
        },
        {
          name: 'SLOPS',
          tanks: [1, 2]
        }
      ],
      loading: false,
      estimates: {},
      showUpdateStatusConfirmation: false,
      showSaveConfirmation: false,
      listRouteName: ROUTE_NAMES.RECEIPT_PLAN,
    };
  },
  mounted() {
    this.calculateAllEstimates();
    window.addEventListener('beforeunload', this.onUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onUnload);
  },
  computed: {
    receiptPlans(){
      return this.dischargePlan && this.dischargePlan.receiptPlans
    },
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    isLoading() {
      return this.uiIsLoading || this.loading;
    },
    isAbandonedPlan() {
      return this.planStatus === RECEIPT_PLAN_STATUS.ABANDONED;
    },
    isDisabled() {
      return DISABLED_STATUSES.includes(this.planStatus);
    },
    isCompleteButtonDisabled() {
      // TODO need some way of distinguishing if receipt plan has been saved
      const completeDisabled = true;
      return (
        completeDisabled ||
        this.$v.$invalid ||
        this.isLoading ||
        this.isDisabled
      );
    },
    hasLoaded() {
      return Boolean(this.dischargePlan && this.dischargePlan.id);
    },
    statusText() {
      return this.isAbandonedPlan ? 'Reactivate' : 'Abandon'
    },
    planStatus() {
      return this.dischargePlan && this.dischargePlan.planStatus;
    },
    // read only, used for validation
    vessel() {
      return this.dischargePlan && this.dischargePlan.vessel;
    },
    // read only, used for validation
    voyage() {
      return this.dischargePlan && this.dischargePlan.voyage;
    },
    // read only, used for validation
    pilotOnBoard() {
      return this.dischargePlan && this.dischargePlan.pilotOnBoard;
    },
    sortedReceiptPlans() {
      if (this.receiptPlans) {
        return [...Object.values(this.receiptPlans)].sort((a, b) => {
          return (
            PRODUCT_SORT_ORDER.indexOf(a.product) -
            PRODUCT_SORT_ORDER.indexOf(b.product)
          );
        });
      }
      return null;
    },
    ...mapState({
      originalDischargePlan: (state) =>
        state.dischargePlan && state.dischargePlan.originalDischargePlan,
      terminal: (state) => state.dischargePlan.terminal,
      uiIsLoading: (state) => state.ui.isLoading
    })
  },
  methods: {
    async loadData(){
      await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
        { id: this.planId }
      );
      this.dischargePlan = cloneDeep(this.$store.state.dischargePlan.dischargePlan);
      this.calculateAllEstimates();
    },
    viscosityLabel(product) {
      if (product.toLowerCase() === 'ago')
        return 'Viscosity @40\u2103, cSt';
      
      return 'Viscosity @50\u2103';
    },
    onUnload(event) {
      const unsavedChanges = !isEqual(
        this.dischargePlan,
        this.originalDischargePlan
      );

      if (unsavedChanges || this.$v.$invalid) {
        event.preventDefault();
        event.returnValue = '';
        // this message is only display in IE, other browsers have a generic message
        return 'Are you sure you want to leave without saving?';
      }
    },
    calculateUllage(planId, tankId) {
      var tankPlan = this.receiptPlans[planId].tankPlans[tankId];

      if (!tankPlan.plannedReceipt && tankPlan.pipelineAdjustments === 0) {
        return {
          mm: null,
          litres: null,
          error: null
        };
      }

      const totalLitres = tankPlan.plannedReceipt + tankPlan.pipelineAdjustments + tankPlan.tankAdjustments;
      const litres = tankPlan.operationalLevelLitres - (convertMMToLitres(tankPlan.openingBalance, tankPlan) + totalLitres);
      const mm = convertLitresToMM(litres, tankPlan);

      const error = litres < 0 || mm < 0 ? 'Caution: Ullage is insufficient' : null;

      return {
        mm,
        litres,
        error
      };
    },
    hasPositiveAdjustmentsAndPlannedReceipt(model, prop) {
      const hasPositiveAdjustments = model.$model.pipelineAdjustments > 0; // only check if there is positive adjustment
      if (prop.$model) {
        if (!model.$model.plannedReceipt && !hasPositiveAdjustments) {
          return false;
        }
      }
      return true;
    },
    propertyExists(model, prop) {
      if (!prop.$model) {
        if (model.plannedReceipt || model.pipelineAdjustments)
          return false;
      }
      return true;
    },
    propertyInvalid(model, prop) {
      if (prop.$invalid) {
        if (model.plannedReceipt || model.pipelineAdjustments)
          return true;
      }
      return false;
    },
    getPipelineAdjustmentsErrorMessages(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      const pipelineAdjustments = model.pipelineAdjustments;
      const hasNegativeAdjustments = pipelineAdjustments.$model < 0;
      const tankAdjustments = model.tankAdjustments;

      if (hasNegativeAdjustments && !model.$model.plannedReceipt) {
        return 'Planned receipt empty';
      }
      if (
        hasNegativeAdjustments &&
        model.plannedReceipt.$model + pipelineAdjustments.$model < 0
      ) {
        return 'Invalid value, negative discharge';
      }
      if (
        hasNegativeAdjustments &&
        model.plannedReceipt.$model +
          pipelineAdjustments.$model +
          tankAdjustments.$model <
          0
      ) {
        return 'Invalid value, negative discharge';
      }
      if (pipelineAdjustments.$invalid) {
        return 'Value is invalid';
      }
    },
    getTankAdjustmentsErrorMessages(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      const tankAdjustments = model.tankAdjustments;
      const hasNegativeAdjustments = tankAdjustments.$model < 0;
      const openingBalanceLiters = convertMMToLitres(
        model.openingBalance.$model,
        model.$model
      );

      if (tankAdjustments.$model > 0) {
        return 'Only negative adjustments allowed';
      }
      if (hasNegativeAdjustments && !model.openingBalance.$model) {
        return 'Opening balance empty';
      }
      if (openingBalanceLiters + tankAdjustments.$model < 0) {
        return 'Value is invald';
      }
      if (tankAdjustments.$invalid) {
        return 'Value is invalid';
      }
    },
    getOpeningBalanceErrorMessages(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      const openingBalance = model.openingBalance;
      const hasPositiveAdjustments = model.$model.pipelineAdjustments > 0; // only check if there is positive adjustment

      if (hasPositiveAdjustments && !openingBalance.$model) {
        return 'Value is required';
      }
      if (
        !model.$model.plannedReceipt &&
        openingBalance.$model &&
        !hasPositiveAdjustments
      ) {
        return 'Planned receipt empty';
      }
      if (model.$model.plannedReceipt && !openingBalance.$model) {
        return 'Value is required';
      }
      if (openingBalance.$invalid) {
        return 'Value is invalid';
      }
    },
    getDensityErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.density))
        return 'Planned receipt or pipeline adjustments required';

      if (!this.propertyExists(model.$model, model.density))
        return 'Value is required';

      if (this.propertyInvalid(model.$model, model.density))
        return 'Density value is invalid.';
    },
    getFlashPointErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.flashPoint))
        return 'Planned receipt or pipeline adjustments required';

      if (!this.propertyExists(model.$model, model.flashPoint))
        return 'Value is required';

      if (this.propertyInvalid(model.$model, model.flashPoint))
        return 'Flashpoint value is invalid.';
    },
    getConductivityErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.conductivity))
        return 'Planned receipt or pipeline adjustments required';

      if (!this.propertyExists(model.$model, model.conductivity))
        return 'Value is required';

      if (this.propertyInvalid(model.$model, model.conductivity))
        return 'Conductivity value is invalid.';
    },
    getViscosityErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.viscosity))
        return 'Planned receipt or pipeline adjustments required';

      if (this.propertyInvalid(model.$model, model.viscosity))
        return 'Viscosity value is invalid.';
    },
    getSulphurErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.sulphur))
        return 'Planned receipt or pipeline adjustments required';

      if (this.propertyInvalid(model.$model, model.sulphur))
        return 'Sulphur value is invalid.';
    },
    getCloudPointErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.cloudPoint))
        return 'Planned receipt or pipeline adjustments required';

      if (this.propertyInvalid(model.$model, model.cloudPoint))
        return 'Cloud Point value is invalid.';
    },
    getPourPointErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.pourPoint))
        return 'Planned receipt or pipeline adjustments required';

      if (this.propertyInvalid(model.$model, model.pourPoint))
        return 'Pour Point value is invalid.';
    },
    getColdFilterPluggingPointErrors(planId, tankId) {
      const model = this.$v.receiptPlans.$each[planId].tankPlans.$each[tankId];
      
      if (!this.hasPositiveAdjustmentsAndPlannedReceipt(model, model.coldFilterPluggingPoint))
        return 'Planned receipt or pipeline adjustments required';

      if (this.propertyInvalid(model.$model, model.coldFilterPluggingPoint))
        return 'Cold Filter Plugging Point value is invalid.';
    },
    onUpdateStatusConfirmation() {
      this.showUpdateStatusConfirmation = true;
    },
    async onChangeStatus() {
      this.loading = true;
      var success = false;

      if (this.isAbandonedPlan) {
        success = await this.$store.dispatch(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.REACTIVATE_DISCHARGE_PLAN),
          { planId: this.dischargePlan.id }
        );
      } else {
        success = await this.$store.dispatch(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.ABANDON_DISCHARGE_PLAN),
          { planId: this.dischargePlan.id }
        );
      }

      if (success) {
        this.$store.dispatch(UI.NS(UI.ACTIONS.ADD_TOAST), {
            id: `success-${this.statusText}-discharge-plan`,
            text: 'Successfully ' + (this.isAbandonedPlan ? 'reactivated' : 'abandoned') + ' plan.'
          },
          { root: true }
        );

        if (!this.isAbandonedPlan) {
          this.$router.push({
            name: ROUTE_NAMES.RECEIPT_PLAN_LIST
          });
        } else {
          this.loadData();
        }
      }
      this.loading = false;
      this.showUpdateStatusConfirmation = false;
    },
    async onComplete() {
      this.loading = true;
      const success = await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.COMPLETE_DISCHARGE_PLAN),
        { planId: this.dischargePlan.id }
      );

      if (success) {
        this.$router.push({
          name: ROUTE_NAMES.RECEIPT_PLAN_LIST
        });
        // reset dischargePlan data
        this.$store.dispatch(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.RESET_DISCHARGE_PLAN)
        );
      }
      this.loading = false;
    },
    displayProduct(product) {
      return DISPLAYABLE_PRODUCTS.includes(product);
    },
    async onSaveConfirmation() {
      await this.onSave();
      this.saveConfirmationNext();
      this.saveConfirmationNext = null;
      this.showSaveConfirmation = false;
    },
    onSaveConfirmationLeave() {
      this.showSaveConfirmation = false;
      this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.RESET_DISCHARGE_PLAN)
      );
      this.saveConfirmationNext();
      this.saveConfirmationNext = null;
    },
    onSaveConfirmationCancel() {
      this.showSaveConfirmation = false;
      this.saveConfirmationNext(false);
      this.saveConfirmationNext = null;
    },
    async onSave() {
      this.loading = true;
      const dischargePlan = this.dischargePlan;

      const success = await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.UPDATE_DISCHARGE_PLAN),
        { id: dischargePlan.id, dischargePlan }
      );
      this.loadData();
      this.loading = false;
      return success;
    },
    async onUpdate() {
      const success = await this.onSave();
      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-discharge-plan',
            text: 'Successfully updated receipt plan'
          },
          { root: true }
        );
      }
    },
    updateAmountInKiloLitres(value, receiptPlanId) {
      this.dischargePlan.receiptPlans[receiptPlanId].amountInKiloLitres = value;
      this.calculateReceiptPlanBalance(receiptPlanId);
    },
    changeOpeningBalance(value, receiptPlanId, tankPlanId) {
      this.dischargePlan.receiptPlans[receiptPlanId].tankPlans[tankPlanId].openingBalance = value;
      this.calculateTankEstimates(receiptPlanId, tankPlanId);
    },
    changePlannedReceipt(value, receiptPlanId, tankPlanId) {
      this.dischargePlan.receiptPlans[receiptPlanId].tankPlans[tankPlanId].plannedReceipt = value;
      this.calculateReceiptPlanBalance(receiptPlanId);
      this.calculateTankEstimates(receiptPlanId, tankPlanId);
    },
    changePipelineAdjustments(value, receiptPlanId, tankPlanId) {
      this.dischargePlan.receiptPlans[receiptPlanId].tankPlans[tankPlanId].pipelineAdjustments = value;
      if (value !== '-' && typeof Number(value) === 'number') {
        this.calculateReceiptPlanBalance(receiptPlanId);
        this.calculateTankEstimates(receiptPlanId, tankPlanId);
      }
    },
    changeTankAdjustments(value, receiptPlanId, tankPlanId) {
      this.dischargePlan.receiptPlans[receiptPlanId].tankPlans[tankPlanId].tankAdjustments = value;
      if (value !== '-' && typeof Number(value) === 'number') {
        this.calculateReceiptPlanBalance(receiptPlanId);
        this.calculateTankEstimates(receiptPlanId, tankPlanId);
      }
    },
    calculateAllEstimates() {
      Object.values(this.receiptPlans || {}).forEach((product) => {
        Object.values(product.tankPlans).forEach((tank) => {
          this.calculateTankEstimates(product.id, tank.id);
        });
      });
    },
    calculateTankEstimates(receiptPlanId, tankPlanId) {
      const tank = this.receiptPlans[receiptPlanId].tankPlans[tankPlanId];
      // include the adjustments on the estimate
      const estimateLiters = tank.openingBalance
        ? convertMMToLitres(tank.openingBalance, tank) +
          tank.plannedReceipt +
          tank.pipelineAdjustments +
          tank.tankAdjustments
        : 0;
      const estimate = {
        estimateMM: estimateLiters
          ? convertLitresToMM(estimateLiters, tank)
          : 0,
        estimateLitres: estimateLiters,
        openingBalanceLitres: tank.openingBalance
          ? convertMMToLitres(tank.openingBalance, tank) + tank.tankAdjustments // adjusted the openingBalance with tankAdjustments
          : 0
      };

      Vue.set(this.estimates, `${receiptPlanId}_${tankPlanId}`, estimate);
    },
    calculateReceiptPlanBalance(receiptPlanId) {
      const receiptPlan = this.receiptPlans[receiptPlanId];
      
      // sum up each tanks planned amount
      const balance = Object.values(receiptPlan.tankPlans).reduce(
        (total, tank) => (total += tank.plannedReceipt || 0),
        0
      );
      this.dischargePlan.receiptPlans[receiptPlanId].balance = receiptPlan.amountInKiloLitres - balance;
    },
    mapProductToColor(product, background = false, border = false) {
      if (!product) return;
      if (background)
        return mapProductToColor(product, border);
      return mapProductToTextColor(product);
    },
  },
  validations() {
    return {
      vessel: {
        required
      },
      voyage: {
        required
      },
      pilotOnBoard: {
        required,
        date
      },
      receiptPlans: {
        $each: {
          balance: {
            isValid: function (value, nestedModel) {
              return value === 0;
            }
          },
          tankPlans: {
            $each: {
              plannedReceipt: {
                minValue: minValue(0)
              },
              openingBalance: {
                isValid: function (value, nestedModel) {
                  const hasPositiveAdjustments =
                    nestedModel.pipelineAdjustments &&
                    nestedModel.pipelineAdjustments > 0;
                  const hasPlannedReceipt =
                    nestedModel.plannedReceipt &&
                    nestedModel.plannedReceipt > 0;

                  return hasPositiveAdjustments || hasPlannedReceipt
                    ? value > 0
                    : value <= 0;
                }
              },
              density: {
                decimal,
                isValid: function (value, nestedModel) {
                  var plannedReceipt = nestedModel.plannedReceipt;
                  var hasAdjustments = nestedModel.pipelineAdjustments > 0;

                  if (!(hasAdjustments || (plannedReceipt && plannedReceipt > 0)))
                    return value == 0;

                  return validateDensityValue(nestedModel.product, value, null, false);
                }
              },
              pipelineAdjustments: {
                integer,
                isValid: function (value) {
                  return !(value === '-');
                }
              },
              tankAdjustments: {
                integer,
                isValid: function (value) {
                  return !(value === '-');
                }
              },
              cloudPoint: {
                decimal,
                maxLength: maxLength(7),
              },
              pourPoint: {
                decimal,
                maxLength: maxLength(7),
              },
              coldFilterPluggingPoint: {
                decimal,
                maxLength: maxLength(7),
              },
              flashPoint: {
                decimal,
                maxLength: maxLength(5),
                minValue: minValue(0),
                maxValue: maxValue(999.9),
                isValid: function (value, nestedModel) {   
                  /*
                    Vuelidate executes when the page loads, unfortunatly this
                    doesnt take into account that some validations/properties arn't required for
                    some products, shown by the property being hidden in the template.
                    Same applys for conductivity.
                  */
                 if(!this.showFlashPoint(nestedModel.product) || !value){
                    return true;
                  }
                  return validateFlashPointValue(nestedModel.product, value, null, false, false);
                },
              },
              conductivity: {
                isValid: function (value, nestedModel) {
                  if(!this.showConductivity(nestedModel.product) || !value){
                    return true;
                  }
                  return validateConductivity(nestedModel.product, value);
                },
                maxLength: maxLength(3),
                integer
              },
              sulphur: {
                isValid: function (value, nestedModel) {
                  if(!this.showSulphur(nestedModel.product) || !value){
                    return true;
                  }
                  return validateSulphur(nestedModel.product, value);
                },
                maxLength: maxLength(8),
                minValue: minValue(0.00),
                maxValue: maxValue(20000.00),
                decimal
              },
              viscosity: {
                isValid: function (value, nestedModel) {
                  if(!this.showViscosity(nestedModel.product) || !value){
                    return true;
                  }
                  return validateViscosity(nestedModel.product, value);
                },
                maxLength: maxLength(6),
                minValue: minValue(0.000),
                maxValue: maxValue(100.000),
                decimal
              }
            }
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

.v-input {
  font-size: 14px;
}

.upper-case {
  text-transform: uppercase;
}
h2,
h3,
h4 {
  text-align: center;
}
.input-width {
  max-width: 250px;
}
:deep(.v-text-field--filled) .v-text-field__suffix {
  margin-top: 22px;
}
// always show input labels in "active" position and display full label
:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}
// remove padding from filled class inputs
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details,
:deep(.v-text-field.v-text-field--enclosed)
  > .v-input__control
  > .v-input__slot {
  padding: 0 3px !important;
}

.button {
  margin-right: 10px !important;
}

.stay-put-sticky-position {
  position: sticky;
  top: 112px;
  z-index: 8;
  background: white;
}

.reduced-padding {
  padding: 10px 15px 10px 0 !important;
}
.save-title {
  font-size: 120%;
}
.save-message {
  margin: 10px 0;
}
.save-error {
  color: $error-color;
  font-style: italic;
}
@media print {
  .container {
    padding: 0 !important;
  }
}

.inputHightlight {
  .v-input {
      margin: 2px;
      border-radius: 8px;
      border-style: solid;
      border: white;
      border-top-width: 10px;
      border-left-width: 5px;
      border-style:solid;
  }
  .v-input:focus-within{
      background: rgb(240, 240, 240) ;
      border-color:rgb(240, 240, 240);
  }
}

.slide-group {
  width: 2000px
}
</style>
