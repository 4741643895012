<template>
  <v-snackbar
    :value="value"
    :timeout="4000"
    :class="['toast', `toast--${type}`]"
    bottom
    left
    @input="onInput"
  >
    <typography type="small-p" no-margin>
      <slot/>
    </typography>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: undefined
    },
    type: {
      type: String,
      default: "regular",
      validator: value => {
        return ["regular", "error"].includes(value);
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_responsive.scss";
@import "../assets/styles/_variables.scss";

.toast {
  &--regular {
    :deep(.v-snack__content) {
      background-color: $charcoal;
    }
  }

  &--error {
    :deep(.v-snack__content) {
      background-color: $error-color;
    }
  }
}
</style>
