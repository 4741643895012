import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';

export const SHIP_SAMPLE_MUTATIONS = {
  SET_SHIP_SAMPLE_LIST: 'setShipSampleList',
  ADD_SHIP_SAMPLE_LIST: 'addShipSampleList',
  RESET_SHIP_SAMPLE_LIST: 'resetShipSampleList',
  EDIT_SHIP_SAMPLE_LIST: 'editShipSampleList',
  DELETE_SHIP_SAMPLE_LIST: 'deleteShipSampleList',
  UPDATE_SHIP_SAMPLE_LIST_DISABLE_FLAG: 'updateShipSampleListDisableFlag'
}

export const mutations = {
  [SHIP_SAMPLE_MUTATIONS.SET_SHIP_SAMPLE_LIST](state, shipSampleList) {
    Vue.set(state, 'shipSampleList', cloneDeep(shipSampleList));
  },
  [SHIP_SAMPLE_MUTATIONS.RESET_SHIP_SAMPLE_LIST](state) {
    state.shipSampleList = [];
  },
  [SHIP_SAMPLE_MUTATIONS.ADD_SHIP_SAMPLE_LIST](state, shipSample) {
    state.shipSampleList.push(shipSample);
  },
  [SHIP_SAMPLE_MUTATIONS.EDIT_SHIP_SAMPLE_LIST](state, shipSample) {
    const index = state.shipSampleList.findIndex(d => d.id === shipSample.id);
    const currentShipSample = shipSample;
    state.shipSampleList.splice(index, 1, currentShipSample);
  },
  [SHIP_SAMPLE_MUTATIONS.DELETE_SHIP_SAMPLE_LIST](state, shipSampleId) {
    const index = state.shipSampleList.findIndex(d => d.id === shipSampleId);
    state.shipSampleList.splice(index, 1);
  },
  [SHIP_SAMPLE_MUTATIONS.UPDATE_SHIP_SAMPLE_LIST_DISABLE_FLAG](state, item) {
    const index = state.shipSampleList.findIndex(d => d.id === item.id);
    const shipSample = state.shipSampleList[index];
    shipSample.enableSave = true;
    shipSample.tempPassOrFail = item.tempPassOrFail;
    state.shipSampleList.splice(index, 1, shipSample);
  }
}