import api from './api';
import { http } from '@/api';
import { cloneDeep } from '@/helpers/object';
import { removeTimezone } from '@/helpers/date';
import { CARGO_PLAN_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
import { DEFAULT_CARGO_PLAN } from '.';

export const ERROR_GET_CARGO_PLAN =
  'There was a problem trying to load the cargo plan';
export const ERROR_CREATE_CARGO_PLAN =
  'There was a problem trying to create the cargo plan';
export const ERROR_UPDATE_CARGO_PLAN =
  'There was a problem trying to update the cargo plan';
export const ERROR_GET_CARGO_PLAN_PDF =
  'There was a problem trying to get the cargo plan pdf (Eg: ensure discharge does not exceed 99 hours).';

export const CARGO_PLAN_ACTIONS = {
  GET_CARGO_PLAN: 'getCargoPlan',
  CREATE_CARGO_PLAN: 'createCargoPlan',
  UPDATE_CARGO_PLAN: 'updateCargoPlan',
  GET_CARGO_PLAN_PDF: 'getCargoPlanPdf'
};

export const actions = {
  async [CARGO_PLAN_ACTIONS.GET_CARGO_PLAN](
    { state, commit, dispatch },
    { dischargePlanId, hateoasGetUrl }
  ) {
    let success = false;
    try {
      let response;
      
      if (dischargePlanId) {
        response = await api.getCargoPlanByDischargeId(dischargePlanId);
      } else {
        // Temporarily replace http: with https:
        response = await http.get(hateoasGetUrl.replace('http:', 'https:'));
      }
      if(response.status == 200) {
        commit(CARGO_PLAN_MUTATIONS.SET_CARGO_PLAN, response.data);
        commit(CARGO_PLAN_MUTATIONS.CONVERT_DATE_FIELDS_TO_DATES);
        commit(CARGO_PLAN_MUTATIONS.SET_ORIGINAL_CARGO_PLAN, state.cargoPlan);
      }
      else if (response.status == 204) {     
        commit(CARGO_PLAN_MUTATIONS.SET_CARGO_PLAN, DEFAULT_CARGO_PLAN(dischargePlanId));
        commit(CARGO_PLAN_MUTATIONS.CONVERT_DATE_FIELDS_TO_DATES);
        commit(CARGO_PLAN_MUTATIONS.SET_ORIGINAL_CARGO_PLAN, state.cargoPlan);
      }
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          dischargePlanId: 'failed-load-cargo-id',
        text: ERROR_GET_CARGO_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [CARGO_PLAN_ACTIONS.CREATE_CARGO_PLAN](
    { dispatch },
    { date, amendment, dischargePlanId }
  ) {
    try {
      const response = await api.createCargoPlan(date, amendment, dischargePlanId);

      // the response has the url of the newly created plan
      const hateoasGetUrl =
        response && response.headers && response.headers.location;

      if (hateoasGetUrl) {
        return await dispatch(CARGO_PLAN_ACTIONS.GET_CARGO_PLAN, {
          id: null,
          hateoasGetUrl
        });
      }
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-create-cargo-plan',
        text: ERROR_CREATE_CARGO_PLAN,
        error
      },
        { root: true }
      );
    }
  },
  async [CARGO_PLAN_ACTIONS.UPDATE_CARGO_PLAN](
    { state, dispatch, commit },
    { dischargePlanId, cargoPlan }
  ) {
    let success = false;
    try {
      // update the date so axios can parse it properly
      const plan = cloneDeep(cargoPlan);
      plan.date = removeTimezone(plan.date);
      await api.updateCargoPlan(dischargePlanId, plan);
      dispatch(UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-update-discharge-plan', 
        text: 'Successfully updated cargo plan'
      }, { root: true });
      success = true;
      // update 'original' state
      commit(
        CARGO_PLAN_MUTATIONS.SET_ORIGINAL_CARGO_PLAN, cloneDeep(cargoPlan)
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-update-cargo-plan',
        text: ERROR_UPDATE_CARGO_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [CARGO_PLAN_ACTIONS.GET_CARGO_PLAN_PDF](
    { dispatch },
    { dischargeId }
  ) {
    try {
      return await api.getCargoPlanPdf(dischargeId);
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-cargo-plan-pdf',
        text: ERROR_GET_CARGO_PLAN_PDF,
        error
      },
        { root: true }
      );
    }
  }
};