<template>
  <v-dialog max-width="500" v-model="showDialog" ref="dialog" persistent>
      <v-card>
        <v-card-title class="headline">{{shipCoQ.id ? 'Edit' : 'Add'}} Ship
        Certificate of Quality (CoQ)</v-card-title>
        <v-card-text class="popup-input">
          <v-divider style = "margin-top:5px" class="divider"/>
          <v-layout>
            <v-flex xs12 pr-2 class="column-padding product-label-container">
              <v-select :items="productItems" v-model="shipCoQ.product" label="Product"
                        @change="resetAppearance" style="font-size: 16px;"
                        ref="product"
                        color="orange lighten-1"
                        item-color="orange" 
                        id="productInput"/>
            </v-flex>
            <v-flex xs12>
              <v-select :items="appearanceList"
                        v-model="shipCoQ.appearance"
                        :error-messages="appearanceErrors"
                        @input="$v.shipCoQ.appearance.$touch()"
                        label="Appearance" style="font-size: 16px; max-width:250px;"
                        item-color="orange"
                        color="orange lighten-1" 
                        id="appearanceInput"
                        ref="appearance"/>
            </v-flex>
          </v-layout>

          <v-layout> 
            <v-flex xs12>
              <v-flex xs12 pr-2>
                <v-text-field v-model="shipCoQ.coqNumber"
                              label="CoQ Number"
                              :error-messages="coqNumberErrors"
                              @input="$v.shipCoQ.coqNumber.$touch()"
                              color="orange lighten-1" 
                              id="coqNumberInput"
                              ref="coqNumber"/>
              </v-flex>
              <v-flex v-if="showFlashPoint(shipCoQ.product)" xs12 pr-2>
                  <v-currency-field v-model="shipCoQ.flashPoint"
                                    :label="'Flash Point \u2103'"
                                    :error-messages="flashPointErrors"
                                    @input="$v.shipCoQ.flashPoint.$touch()"
                                    ref="flashPoint"
                                    :decimalLength="1"
                                    :autoDecimalMode="false"
                                    color="orange lighten-1" 
                                    id="flashPointInput"/>
              </v-flex>
            </v-flex>
            <v-flex xs12>
              <v-flex xs12>
                <v-currency-field v-model="shipCoQ.density"
                                  :label="'Density kg/l @15\u2103'"
                                  :error-messages="densityErrors"
                                  @input="$v.shipCoQ.density.$touch()"
                                  ref="density"
                                  :decimalLength="4"
                                  :autoDecimalMode="false" 
                                  color="orange lighten-1"
                                  id="densityId"/>
              </v-flex>
              <v-flex v-if="showConductivity(shipCoQ.product)" xs12>
                <v-currency-field v-model="shipCoQ.conductivity"
                              label="Conductivity pS/m"
                              :error-messages="conductivityErrors"
                              @input="$v.shipCoQ.conductivity.$touch()"
                              v-mask="'###'"
                              ref="conductivity"
                              color="orange lighten-1"
                              id="conductivityInput"/>
              </v-flex>
            </v-flex>
          </v-layout>

         <v-divider class="divider" 
          v-if="showViscosity(shipCoQ.product) || showCloudPoint(shipCoQ.product) || showSulphur(shipCoQ.product)"></v-divider>
          <v-layout>
            <v-flex xs6 pr-2>
              <v-currency-field v-if="showViscosity(shipCoQ.product)"
                                v-model="shipCoQ.viscosity"
                                :label="viscosityLabel(shipCoQ.product)"
                                :decimalLength="3"
                                :autoDecimalMode="false"
                                :error-messages="viscosityErrors"
                                @input="$v.shipCoQ.viscosity.$touch()"
                                :defaultValue="null"
                                color="orange lighten-1"
                                id="viscosityInput"
                                ref="viscosity"/>
            </v-flex>

            <v-flex xs6 pr-2>
              <v-currency-field v-if="showCloudPoint(shipCoQ.product)"
                                v-model="shipCoQ.cloudPoint"
                                :label="'Cloud Point \u2103'"
                                :decimalLength="2"
                                :autoDecimalMode="false"
                                :allowNegative ="true"
                                :defaultValue="null"
                                :error-messages="cloudPointErrors"
                                @input="$v.shipCoQ.cloudPoint.$touch()"
                                color="orange lighten-1"
                                id="cloudPointInput"
                                ref="cloudPoint" />
            </v-flex>

          </v-layout>

          <v-layout>
            <v-flex xs6 pr-2>
              <v-currency-field v-if="showSulphur(shipCoQ.product)"
                                v-model="shipCoQ.sulphur"
                                label="Sulphur mg/kg"
                                :decimalLength="2"
                                :autoDecimalMode="false"
                                :error-messages="sulphurErrors"
                                @input="$v.shipCoQ.sulphur.$touch()"
                                :defaultValue="null"
                                color="orange lighten-1"
                                id="sulphurInput"
                                ref="sulphur"/>
            </v-flex>

            <v-flex xs6 pr-2>
              <v-currency-field v-if="showColdFilterPluggingPoint(shipCoQ.product)"
                                v-model="shipCoQ.coldFilterPluggingPoint"
                                :label="'Cold Filter Plugging Point \u2103'"
                                :decimalLength="2"
                                :autoDecimalMode="false"
                                :allowNegative ="true"
                                :defaultValue="null"
                                :error-messages="coldFilterPluggingPointErrors"
                                @input="$v.shipCoQ.coldFilterPluggingPoint.$touch()"
                                color="orange lighten-1"
                                id="coldFilterPluggingPointInput"
                                ref="coldFilterPluggingPoint"/>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs6 pr-2>
              <v-currency-field v-if="showPourPoint(shipCoQ.product)"
                                v-model="shipCoQ.pourPoint"
                                :label="'Pour Point \u2103'"
                                :decimalLength="2"
                                :autoDecimalMode="false"
                                :allowNegative ="true"
                                :error-messages="pourPointErrors"
                                @input="$v.shipCoQ.pourPoint.$touch()"
                                :defaultValue="null"
                                color="orange lighten-1"
                                id="pourPointInput"
                                ref="pourPoint"/>
            </v-flex>              
          </v-layout>
          <v-divider class="divider"/>
          <v-layout>
            <v-flex xs6 pr-2>
              <v-currency-field 
                          class="data-table"
                          v-model="shipCoQ.weight"
                          label="Weight"
                          v-mask="'###'"
                          :hint="percentageOfCargoHint"
                          :transition="false"
                          persistent-hint ref="weight"
                          color="orange lighten-1"
                          id="weightInput"/>
            </v-flex>
            <v-flex xs6 pr-2>
                <v-text-field                                
                          v-model="shipCoQ.initials"
                          label="Initials"
                          maxlength="10"
                          :error-messages="initialsErrors"                    
                          @input="$v.shipCoQ.initials.$touch()"
                          color="orange lighten-1"
                          id="initialsInput"
                          ref="initials" />
            </v-flex>
          </v-layout>       
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary lighten-3"
                 @click="() => this.$emit('close')"
                 :disabled="isLoading"
                 :loading="isLoading"
                 id="cancelPopupButton"
                 ref="cancel"
                 tabindex="14">Cancel</v-btn>

          <v-btn color="orange lighten-1"
                 @click="onSaveShipCoq"
                 :disabled="isLoading || !isShipCoQValid"
                 :loading="isLoading"
                  id="savePopupButton"
                  ref="save"
                  tabindex="15">Save</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SHIP_COQ } from '@/store/tankerDischarge/preArrival/shipCoq';
import { required, requiredIf, maxLength, minValue, maxValue } from 'vuelidate/lib/validators';
import { number } from '@/validators';
import { cloneDeep } from '@/helpers/object';
import {
  PRODUCT_ITEMS
} from '@/constants/products';
import {
  validateFlashPointValue,
  validateDensityValue,
  validateConductivity,
  validateAppearance,
  validateSulphur,
  validateViscosity
} from '@/helpers/validation/sharedThresholds';
import { getAppearanceList } from '@/helpers/appearance';
import { calculatePercentageOfCargo } from '@/helpers/coqCalculations';

function defaultCoQ() {
  return {
    product: 'Ago',
    appearance: '',
    density: 0,
    flashPoint: 0,
    conductivity: 0,
    coqNumber: '',
    weight: 1,
    sulphur: null,
    viscosity: null,
    cloudPoint: 0,
    coldFilterPluggingPoint: 0,
    pourPoint: null,
    initials: ''
  }
}

export default {
  props: {
      showDialog: false,
      shipCoQToEdit: null
  },
  data() {
    return {
      isLoading: false,
      productItems: PRODUCT_ITEMS,
      shipCoQ: defaultCoQ()
    };
  },
  watch: {
    "shipCoQ.product": function(){
      this.$nextTick(
          () => this.setTabIndex()
        );
    },
    showDialog: function (newValueForShow) {
      if(newValueForShow){
        if(this.shipCoQToEdit){
          this.shipCoQ = this.shipCoQToEdit;
        } else{
          this.shipCoQ = defaultCoQ();
        }
       
        this.$nextTick(
          () => this.setTabIndex()
        );
      }
    }
  },
  methods: {
    viscosityLabel(product) {
      if (product.toLowerCase() === 'ago') return 'Viscosity @40\u2103, cSt';
      
      return 'Viscosity @50\u2103';
    },
    resetAppearance() {
      this.shipCoQ.appearance = '';
    },
    async onSaveShipCoq() {
      this.isLoading = true;
      const shipCoQ = cloneDeep(this.shipCoQ);
      shipCoQ.weight = shipCoQ.weight || 1;
      let result;
      if (shipCoQ.id) {
        result = await this.$store.dispatch(
          SHIP_COQ.NS(SHIP_COQ.ACTIONS.EDIT_SHIP_COQ),
          {
            planId: this.planId,
            shipCoQ: shipCoQ
          }
        );
      } else {
        result = await this.$store.dispatch(
          SHIP_COQ.NS(SHIP_COQ.ACTIONS.CREATE_SHIP_COQ),
          {
            planId: this.planId,
            shipCoQ: shipCoQ
          }
        );
      }

      if (result && shipCoQ.id) {
        // only reload when adding new ship coq
        if (!shipCoQ.id) {
          this.loadShipCoqList();
        }
        this.resetShipCoQ();        
        this.$emit('close')
      }
      this.isLoading = false;
    },
    async loadShipCoqList() {
       this.$store.commit(
        SHIP_COQ.NS(SHIP_COQ.MUTATIONS.RESET_SHIP_COQ_LIST)
      );
      this.isLoading = true;

      await this.$store.dispatch(
        SHIP_COQ.NS(SHIP_COQ.ACTIONS.GET_SHIP_COQ_LIST), {
          planId: this.planId
        }
      );
    },
    resetShipCoQ() {
      this.shipCoQ = defaultCoQ();
    },
    setTabIndex() {
      this.$refs.product.focus();
      this.$refs.product.$refs.input.tabIndex = 1
      this.$refs.appearance.$refs.input.tabIndex = 2
      this.$refs.coqNumber.$refs.input.tabIndex = 3;
      this.$refs.density.$refs.textfield.$refs.input.tabIndex = 4;

      if (this.$refs.flashPoint) {
        this.$refs.flashPoint.$refs.textfield.$refs.input.tabIndex = 5;
      }
      if (this.$refs.conductivity) {
        this.$refs.conductivity.$refs.textfield.$refs.input.tabIndex = 6;
      }
      if (this.$refs.viscosity) {
        this.$refs.viscosity.$refs.textfield.$refs.input.tabIndex = 7;
      }
      if (this.$refs.cloudPoint) {
        this.$refs.cloudPoint.$refs.textfield.$refs.input.tabIndex = 8;
      }
      if (this.$refs.sulphur) {
        this.$refs.sulphur.$refs.textfield.$refs.input.tabIndex = 9;
      }
      if (this.$refs.coldFilterPluggingPoint) {
        this.$refs.coldFilterPluggingPoint.$refs.textfield.$refs.input.tabIndex = 10;
      }
      if (this.$refs.pourPoint) {
        this.$refs.pourPoint.$refs.textfield.$refs.input.tabIndex = 11;
      }      
      if (this.$refs.weight) {
        this.$refs.weight.$refs.textfield.$refs.input.tabIndex = 12;
      }
      this.$refs.initials.$refs.input.tabIndex = 13;
      this.$refs.cancel.$refs.link.tabIndex = 14; 
      this.$refs.save.$refs.link.tabIndex = 15;
    }
  },
  computed: {
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    isShipCoQValid() {
      if (!this.$v.shipCoQ.appearance.required || 
            !this.$v.shipCoQ.coqNumber.required) {
          return false;
      }
      if (this.$v.shipCoQ.$invalid && 
        !(
          !this.$v.shipCoQ.appearance.isValid ||
          !this.$v.shipCoQ.density.isValid ||
          !this.$v.shipCoQ.flashPoint.isValid ||
          !this.$v.shipCoQ.conductivity.isValid || 
          !this.$v.shipCoQ.viscosity.isValid ||
          !this.$v.shipCoQ.sulphur.isValid
        )
      ) {
        return false;
      }
      return true;
    },
    isVesselInfoValid() {
      return !this.$v.vessel.$invalid && !this.$v.voyage.$invalid &&
        !this.$v.pilotOnBoard.$invalid;
    },
    isVesselInfoDirty() {
      return this.$v.vessel.$anyDirty || this.$v.voyage.$anyDirty ||
        this.$v.pilotOnBoard.$anyDirty;
    },
    appearanceList() {
      if (this.shipCoQ && this.shipCoQ.product) {
        return getAppearanceList(this.shipCoQ.product);
      }
      return null;
    },
    appearanceErrors() {      
      if (!this.$v.shipCoQ.appearance.required) {
        return 'Value is required';
      }
      if (!this.$v.shipCoQ.appearance.isValid) {
        return 'Appearance value is invalid.';
      }
      if (
        !this.$v.shipCoQ.appearance.$dirty ||
        this.$v.shipCoQ.appearance.$pending
      ) {
        return [];
      }
      return null;
    },
    coqNumberErrors() {      
      if (!this.$v.shipCoQ.coqNumber.required) {
        return 'Value is required';
      }
      if (
        !this.$v.shipCoQ.coqNumber.$dirty ||
        this.$v.shipCoQ.coqNumber.$pending
      ) {
        return [];
      }
      return null;
    },
    densityErrors() {    
      if (!this.$v.shipCoQ.density.required) {
        return 'Value is required';
      }
      if (!this.$v.shipCoQ.density.number) {
        return 'Must be a number';
      }

      if (!this.$v.shipCoQ.density.isValid) {
        return 'Density value is outside the range for the given product.';
      }
      if (
        !this.$v.shipCoQ.density.$dirty ||
        this.$v.shipCoQ.density.$pending
      ) {
        return [];
      }
      return null;
    },
    flashPointErrors() {      
      if (!this.$v.shipCoQ.flashPoint.isValid) {
        return 'Flashpoint value is outside the range for the given product.';
      }
      if (!this.$v.shipCoQ.flashPoint.required) {
        return 'Value is required';
      }
      if (!this.$v.shipCoQ.flashPoint.number) {
        return 'Must be a number';
      }
        if (!this.$v.shipCoQ.flashPoint.minValue) {
        return 'Value should be from 0 to 1000';
      }
        if (!this.$v.shipCoQ.flashPoint.maxValue) {
        return 'Value should be from 0 to 1000';
      }
      if (
        !this.$v.shipCoQ.flashPoint.$dirty ||
        this.$v.shipCoQ.flashPoint.$pending
      ) {
        return [];
      }
      return null;
    },
    conductivityErrors() {      
      if (!this.$v.shipCoQ.conductivity.isValid) {
        return 'Conductivity value is outside the range for the given product.';
      }
      if (!this.$v.shipCoQ.conductivity.required) { 
        return 'Value is required';
      }
      if (
        !this.$v.shipCoQ.conductivity.$dirty ||
        this.$v.shipCoQ.conductivity.$pending
      ) {
        return [];
      }
      return null;
    },
    viscosityErrors() {      
      if (!this.$v.shipCoQ.viscosity.isValid) {
        return 'Viscosity value is outside the range for the given product.';
      }
      if (!this.$v.shipCoQ.viscosity.number) {
        return 'Must be a number';
      }
      if (!this.$v.shipCoQ.viscosity.minValue) {
        return 'Value should be from 0.000 to 100.000';
      }
      if (!this.$v.shipCoQ.viscosity.maxValue) {
        return 'Value should be from 0.000 to 100.000';
      }
      if (
        !this.$v.shipCoQ.viscosity.$dirty ||
        this.$v.shipCoQ.viscosity.$pending
      ) {
        return [];
      }
      return null;
    },
    sulphurErrors() {      
      if (!this.$v.shipCoQ.sulphur.isValid) {
        return 'Sulphur value is outside the range for the given product.';
      }
      if (!this.$v.shipCoQ.sulphur.number) {
        return 'Must be a number';
      }
      if (!this.$v.shipCoQ.sulphur.minValue) {
        return 'Value should be from 0.00 to 20,000.00';
      }
      if (!this.$v.shipCoQ.sulphur.maxValue) {
        return 'Value should be from 0.00 to 20,000.00';
      }
      if (
        !this.$v.shipCoQ.sulphur.$dirty ||
        this.$v.shipCoQ.sulphur.$pending
      ) {
        return [];
      }
      return null;
    },
    pourPointErrors() { 
      if (!this.$v.shipCoQ.pourPoint.number) {
        return 'Must be a number';
      }     
      if (!this.$v.shipCoQ.pourPoint.maxLength) {
        return 'Value should not be more than 7 digits.';
      }
      if (
        !this.$v.shipCoQ.pourPoint.$dirty ||
        this.$v.shipCoQ.pourPoint.$pending
      ) {
        return [];
      }
      return null;
    },
    cloudPointErrors() {  
      if (!this.$v.shipCoQ.cloudPoint.number) {
        return 'Must be a number';
      }         
      if (!this.$v.shipCoQ.cloudPoint.maxLength) {
        return 'Value should not be more than 7 digits.';
      }
      if (
        !this.$v.shipCoQ.cloudPoint.$dirty ||
        this.$v.shipCoQ.cloudPoint.$pending
      ) {
        return [];
      }
      return null;
    },
    coldFilterPluggingPointErrors() {  
      if (!this.$v.shipCoQ.coldFilterPluggingPoint.number) {
        return 'Must be a number';
      }    
      if (!this.$v.shipCoQ.coldFilterPluggingPoint.maxLength) {
        return 'Value should not be more than 7 digits.';
      }
      if (
        !this.$v.shipCoQ.coldFilterPluggingPoint.$dirty ||
        this.$v.shipCoQ.coldFilterPluggingPoint.$pending
      ) {
        return [];
      }
      return null;
    },
    initialsErrors() {
      if (!this.$v.shipCoQ.initials.required) {
        return 'Value is required';
      }
      if (
        !this.$v.shipCoQ.initials.$dirty ||
        this.$v.shipCoQ.initials.$pending
      ) {
        return [];
      }
      return null;
    },
    percentageOfCargoHint() {
      if (this.shipCoqList) {
        var percentage = calculatePercentageOfCargo(this.shipCoqList, this.shipCoQ);
        return 'Percentage of Cargo: ' + (percentage * 100).toFixed(2).replace(/[.,]00$/, "");
      }
      return null;
    }
  },
  validations: {
    shipCoQ: {
      product: {
        required
      },
      initials: {
        required
      },
      appearance: {
        isValid: function (value) {
          return validateAppearance(this.shipCoQ.product, value);
        },
        required
      },
      coqNumber: {
        required
      },
      density: {
        isValid: function (value) {
          return validateDensityValue(this.shipCoQ.product, value, null, false);
        },
        required,
        number
      },
      flashPoint: {
        required: requiredIf(function () {
          return this.showFlashPoint(this.shipCoQ.product);
        }),
        isValid: function (value) {
          return validateFlashPointValue(this.shipCoQ.product, value, null, false, false);
        },
        number,
        maxLength: (5),
        minValue: minValue(0),
        maxValue: maxValue(999.9)
      },
      conductivity: {
        required: requiredIf(function () {
          return this.showConductivity(this.shipCoQ.product);
        }),
        isValid: function (value) {
          return validateConductivity(this.shipCoQ.product, value);
        },
        maxLength: maxLength(3),
        number
      },
      sulphur: {
        isValid: function (value) {
          return value == null || validateSulphur(this.shipCoQ.product, value);
        },
        maxLength: maxLength(7),
        minValue: minValue(0.00),
        maxValue: maxValue(20000.00),
        number
      },
      viscosity: {
        isValid: function (value) {
          return value == null || validateViscosity(this.shipCoQ.product, value);
        },
        maxLength: maxLength(6),
        minValue: minValue(0.000),
        maxValue: maxValue(100.000),
        number
      },
      cloudPoint: {
        number,
        maxLength: maxLength(7)
      },
      pourPoint: {
        number,
        maxLength: maxLength(7)
      },
      coldFilterPluggingPoint: {
        number,
        maxLength: maxLength(7)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
 @import '../../../assets/styles/_responsive.scss';
 @import '../../../assets/styles/_variables.scss';
</style>