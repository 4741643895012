import { actions, PIPELINE_INFORMATION_ACTIONS } from './actions';
import { getters, PIPELINE_INFORMATION_GETTERS } from './getters';
import { mutations, PIPELINE_INFORMATION_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'pipelineInformation';

export const state = {
    pipelinesList: []
};

export function GET_DEFAULT_PIPELINE_DETAILS() {
    return {
        pipelineName: null,
        diameterNBmm: 0,
        lengthM: 0.00,
        operatingPressureBarg: 0.0,
        volumeLitres: 0,
        designStandard: null,
        notes: null,
        document: null
    }
};

export const PIPELINE_INFORMATION = {
    ACTIONS: PIPELINE_INFORMATION_ACTIONS,
    GETTERS: PIPELINE_INFORMATION_GETTERS,
    MUTATIONS: PIPELINE_INFORMATION_MUTATIONS,
    NAME_SPACE,
    NS: x => `${NAME_SPACE}/${x}`
};

export const pipelineInformation = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};