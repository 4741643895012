export const TANK_TO_TANK_GETTERS = {
  GET_TRANSFER_PLANS_BY_TERMINAL: 'getTransferPlansByTerminal',
  GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL: 'getTankTechnicalDetailsByTerminal'
};

export const getters = {
  [TANK_TO_TANK_GETTERS.GET_TRANSFER_PLANS_BY_TERMINAL](state) {
      return state.transferPlans;
  },
  [TANK_TO_TANK_GETTERS.GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL](state) {
    return state.tanksList;
  }
};