import { actions, SHIP_PORT_PERFORMANCE_ACTIONS } from './actions';
import { getters, SHIP_PORT_PERFORMANCE_GETTERS } from './getters';
import { mutations, SHIP_PORT_PERFORMANCE_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'shipPortPerformance';

export const state = {
  shipPortPerformance: null
};

export const SHIP_PORT_PERFORMANCE = {
  ACTIONS: SHIP_PORT_PERFORMANCE_ACTIONS,
  GETTERS: SHIP_PORT_PERFORMANCE_GETTERS,
  MUTATIONS: SHIP_PORT_PERFORMANCE_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const shipPortPerformance = {
  namespaced,
  state,
  mutations,
  getters,
  actions
};