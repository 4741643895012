<template>
  <v-card flat tile class="d-flex mb-2 pt-1">
    <v-card flat :class="{ 'cell-container px-5': true, 'cell-error': !!error }">
      <div class="cell-label">{{ label }}</div>

      <v-layout row wrap>
        <v-select color="orange lighten-1"
          item-color="orange lighten-1"
          :items="values"
          :label="label"
          :value="value"
          class="cell-column"
          @input="$emit('update-single-value', $event)"
          :error-messages="getErrorMessage"
          :id="idPrefix ? idPrefix + '_Input' : undefined" 
        ></v-select>
      </v-layout>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    singleValueErrors: Object,
    values: Array,
    value: {
      type: Number | String,
      default: null
    },
    label: String,
    error: String,
    idPrefix: {
      type: String,
      default: undefined
    }
  },
  computed: {
    getErrorMessage() {
      if (!this.singleValueErrors) {
        return;
      }
      if (!this.singleValueErrors.required) {
        return 'Value is required';
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';

.cell-container {
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px 3px 0 0;
  border-bottom: 0px solid #888;
  text-align: center;
}
.cell-error {
  border-bottom: 1px solid $error-color !important;
}
.cell-label {
  width: 100%;
  font-size: 14px !important;
  margin-bottom: 10px;
  font-weight: bold;
}
.cell-error .cell-label {
  color: $error-color !important;
}
.cell-column {
  font-size: 16px;
  width: 90%;
  white-space: nowrap;
}
</style>

