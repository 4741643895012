import { actions, CARGO_PLAN_ACTIONS } from './actions';
import { getters, CARGO_PLAN_GETTERS } from './getters';
import { mutations, CARGO_PLAN_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'cargoPlan';

export function DEFAULT_CARGO_PLAN(id) {
  return {
    amendment: '',
    date: '0001-01-01 00:00:00.0000000',
    dischargePlanId: id,
    dischargeNotes: '',
    cargoPlanProducts: DEFAULT_CARGO_PLAN_PRODUCTS(id)
  }
}

export function DEFAULT_CARGO_PLAN_PRODUCTS(planId) {
  return [
    {productGrade: null, receivingCompany: null, shipOrShore: null, method: null, dischargeQuantity: 0, method: null, CargoPlanId: planId, pumpingPressure: 7, slowPumpingPressure: 0.0, shipTankNumber: null, hoseConnectionNumberColour: null, shoreTankNumber: null, interfaceTankNumber: null, shorePipelineDiameter: 0, shorePipelineLength: 0, maxReceivingRate: 0, active: true, dischargeNotes: null},
    {productGrade: null, receivingCompany: null, shipOrShore: null, method: null, dischargeQuantity: 0, method: null, CargoPlanId: planId, pumpingPressure: 7, slowPumpingPressure: 0.0, shipTankNumber: null, hoseConnectionNumberColour: null, shoreTankNumber: null, interfaceTankNumber: null, shorePipelineDiameter: 0, shorePipelineLength: 0, maxReceivingRate: 0, active: true, dischargeNotes: null},  
    {productGrade: null, receivingCompany: null, shipOrShore: null, method: null, dischargeQuantity: 0, method: null, CargoPlanId: planId, pumpingPressure: 7, slowPumpingPressure: 0.0, shipTankNumber: null, hoseConnectionNumberColour: null, shoreTankNumber: null, interfaceTankNumber: null, shorePipelineDiameter: 0, shorePipelineLength: 0, maxReceivingRate: 0, active: true, dischargeNotes: null}, 
    {productGrade: null, receivingCompany: null, shipOrShore: null, method: null, dischargeQuantity: 0, method: null, CargoPlanId: planId, pumpingPressure: 7, slowPumpingPressure: 0.0, shipTankNumber: null, hoseConnectionNumberColour: null, shoreTankNumber: null, interfaceTankNumber: null, shorePipelineDiameter: 0, shorePipelineLength: 0, maxReceivingRate: 0, active: true, dischargeNotes: null}];
}

export function GET_DEFAULT_CARGO_PLAN_PRODUCT(planId) {
  return {
    productGrade: null,
    receivingCompany: null,
    shipOrShore: null,
    method: null,
    dischargeQuantity: 0,
    method: null,
    CargoPlanId: planId,
    pumpingPressure: 7,
    slowPumpingPressure: 0.0,
    shipTankNumber: null,
    hoseConnectionNumberColour: null,
    shoreTankNumber: null,
    interfaceTankNumber: null,
    shorePipelineDiameter: 0,
    shorePipelineLength: 0,
    active: true,
    dischargeNotes: null
  }
};

export const state = {
  cargoPlan: DEFAULT_CARGO_PLAN(),
  originalCargoPlan: null // used to compare if there are unsaved changes
};

export const CARGO_PLAN = {
  ACTIONS: CARGO_PLAN_ACTIONS,
  GETTERS: CARGO_PLAN_GETTERS,
  MUTATIONS: CARGO_PLAN_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const cargoPlan = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
