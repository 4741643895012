import { JL } from 'jsnlog';
import Vue from 'vue';

// global error handler for vue errors
Vue.config.errorHandler = (err) => {
  // `info` is a Vue-specific error info, e.g. which lifecycle hook the error was found in.
  // eslint-disable-next-line
  console.log(err);

  JL().fatalException('Uncaught Exception', err);
};

// error handler for any remaining uncaught errors
window.onerror = (error) => {
  try {
    JL().fatalException('windows.onerror', error);
  } catch (error) {
    // Do nothing..
  }

  // eslint-disable-next-line
  console.log('windows.onerror', error);
};
