export const UI_GETTERS = {
  IS_LOADING: 'isLoading',
  OPEN_DRAWER: 'drawer',
  EXPAND_MINI: 'mini',
  OPERATION_ID: 'operationId',
  LAST_TANK_ID: 'lastTankId',
  LAST_RECEIPT_PLAN_ID: 'lastReceiptPlanId',
  LAST_PIPELINE_PRODUCT: 'lastPipelineProduct'
};

export const getters = {
  [UI_GETTERS.IS_LOADING]: state => state.isLoading,
  [UI_GETTERS.OPEN_DRAWER]: state => state.drawer,
  [UI_GETTERS.EXPAND_MINI]: state => state.mini,
  [UI_GETTERS.OPERATION_ID]: state => state.operationId,
  [UI_GETTERS.LAST_TANK_ID]: state => state.lastTankId,
  [UI_GETTERS.LAST_RECEIPT_PLAN_ID]: state => state.lastReceiptPlanId,
  [UI_GETTERS.LAST_PIPELINE_PRODUCT]: state => state.lastPipelineProduct
};
