import { actions, TIME_SCALE_ACTIONS } from './actions';
import { getters, TIME_SCALE_GETTERS } from './getters';
import { mutations, TIME_SCALE_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'timeScale';

export function DEFAULT_TIME_SCALE() {
  return {
    receivingCompanies: '',
    startTime: '01-01-0001 00:00:00+00:00',
    cargoPlanId: null,
    timeScaleActivities: []
  }
}

export function DEFAULT_TIME_SCALE_ACTIVITY() {
  return {
    activityName: 'Pilot, Mooring and Gangway',
    activityStartTime: '',
    activityEndTime: '',
    product: '' 
  };
};

export function DEFAULT_ACTIVITIES() {
  return ['Pilot, Mooring and Gangway',
          'Hoses/Sampling/Testing',
          'Hose Change', 
          'Disconnect Hoses'];
} 

export const state = {
  timeScale: DEFAULT_TIME_SCALE(),
  originalTimeScale: null // used to compare if there are unsaved changes
};

export const TIME_SCALE = {
  ACTIONS: TIME_SCALE_ACTIONS,
  GETTERS: TIME_SCALE_GETTERS,
  MUTATIONS: TIME_SCALE_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const timeScale   = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
