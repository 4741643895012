<template>
  <v-container fluid fill-height row wrap>
    <v-dialog max-width="500" v-model="showDialog">
      <v-card>
        <v-card-title class="headline">{{dischargeLog && dischargeLog.id ? 'Edit' : 'Add'}} Log Entry / Notes</v-card-title>
        <v-card-text>
          <v-layout mb-2>
            <v-flex xs12>
              <v-text-field label="Log DateTime"
                            v-model="dischargeLog.logDateTime"
                            v-mask="'####-##-## ##:##'"
                            placeholder="yyyy-MM-dd HH:mm"
                            :error-messages="logDateTimeErrors"
                            @input="$v.dischargeLog.logDateTime.$touch()"
                            ref="logDateTime"
                            color="orange lighten-1" 
                            id="logDateTimeInput"
                            :disabled="dischargeLog.logSource ? dischargeLog.logSource.includes(monitoringDetailSource) : false"/>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-textarea outlined color="orange lighten-1"
                          v-model="dischargeLog.details"
                          label="Notes"
                          @input="$v.dischargeLog.details.$touch()"
                          tabindex="0" 
                          id="notesInput"/>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary lighten-3"
                 :loading="isLoading"
                 :disabled="isLoading"
                 @click="onCancelDialog"
                 id="cancelButton">
            Cancel
          </v-btn>
          <v-btn color="orange lighten-1"
                 @click="onSave"
                 :loading="isLoading"
                 :disabled="isLoading || $v.dischargeLog.$invalid"
                 id="saveButton">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap pa-6 justify-end>
      <v-flex xs12 pt-1>
        <data-table :headers="headers"
                    :items="sortedDischargeLogList"
                    :show-search="false"
                    :pagination.sync="pagination"
                    class="discharge-log-table no-sort-datatable"
                    :loading="isLoading">
          <template v-slot:default="slotProps">
            <td>
              {{ slotProps.item.logDateTime | formatDateTime('yyyy-MM-dd HH:mm') }}
            </td>
            <td :class="'details-align'">
              {{ slotProps.item.details }}
            </td>
            <td :class="'details-align'">
              {{ slotProps.item.logSource }}
            </td>
            <td>
              <div :class="'comment-date'">
                created by {{slotProps.item.createdBy}} on {{slotProps.item.createdOn | formatDateTime('yyyy-MM-dd HH:mm')}}
              </div>
              <div :class="'comment-date'"  v-if="slotProps.item.updatedOn !== slotProps.item.createdOn">
                last update by {{slotProps.item.updatedBy}} on {{slotProps.item.updatedOn | formatDateTime('yyyy-MM-dd HH:mm')}}
              </div>
            </td>
            <td>
               <v-icon small class="d-inline mr-2"
                 @click="onEdit(slotProps.item, slotProps.index)">
                 edit
               </v-icon>
            </td>
          </template>
        </data-table>
      </v-flex>
      <v-flex xs4 pt-2>
        <flat-button @click="onAddNew">Add Log Entry / Notes</flat-button>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { maskedDatetime } from '@/validators';
import { cloneDeep } from '@/helpers/object';
import { DISCHARGE_LOG } from '@/store/tankerDischarge/shared/dischargeLog';
import { formatDateTimeToMasked } from '@/helpers/date';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";

export default Vue.extend({
  async mounted() {
    this.isLoading = true;
    await this.loadList();
    this.isLoading = false;
  },
  data() {
    return {
      dischargeLog: {
        details: '',
        logDateTime: formatDateTimeToMasked(new Date(), DATE_TIME_FORMAT),
        id: null
      },
      monitoringDetailSource: 'Monitoring Details',
      showDialog: false,
      isLoading: false,
      sortDateAscending: true,
      headers: [
        { text: 'Log DateTime', value: 'logDateTime', width: 140 },
        { text: 'Event Log / Notes', value: 'details' , width: 550 },
        { text: 'Log Source', value: 'logSource', width: 180 },
        { text: 'Update details', value: 'updateDetails', width: 250 },
        { text: 'Actions', value: 'actions', width: 100 }
      ],
      pagination: {
        rowsPerPage: 10
      }
    };
  },
  computed: {
    dischargeLogList() {
      return this.$store.state.dischargeLog &&
        this.$store.state.dischargeLog.dischargeLogList;
    },
    sortedDischargeLogList() {
      return [...this.dischargeLogList].sort((a, b) => {
        const aDate = new Date(a.logDateTime);
        const bDate = new Date(b.logDateTime);
        return aDate === bDate ? b.id - a.id : aDate < bDate ? 1 : -1
      });
    },
    dischargePlanId() {
      return this.$route.params && this.$route.params.planId;
    },
    ...mapState({
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
      terminals: state => state.terminals.terminalsList
    }),
    logDateTimeErrors() {
      let errors = [];

      if (
        !this.$v.dischargeLog.logDateTime.$dirty ||
        this.$v.dischargeLog.logDateTime.$pending
      ) {
        return [];
      }

      if (
        !this.$v.dischargeLog.logDateTime.required ||
        !this.$v.dischargeLog.logDateTime.maskedDatetime
      ) {
        errors = ['A valid date is required (yyyy-MM-dd HH:mm)'];
      }
      return errors;
    }
  },
  methods: {
    async loadList() {
      this.$store.commit(
        DISCHARGE_LOG.NS(DISCHARGE_LOG.MUTATIONS.RESET_DISCHARGE_LOG_LIST)
      );

      await this.$store.dispatch(
        DISCHARGE_LOG.NS(DISCHARGE_LOG.ACTIONS.GET_DISCHARGE_LOG_LIST), {
          planId: this.dischargePlanId
        }
      );
    },
    onAddNew() {
      this.resetDetails();
      this.showDialog = true;
      this.$nextTick(() => this.$refs.logDateTime.focus());
    },
    onEdit(log) {
      this.dischargeLog = cloneDeep(log);
      this.dischargeLog.logDateTime = formatDateTimeToMasked(this.dischargeLog.logDateTime, DATE_TIME_FORMAT);
      this.showDialog = true;
      this.$nextTick(() => this.$refs.logDateTime.focus());
    },
    onCancelDialog() {
      this.resetDetails();
      this.showDialog = false;
    },
    async onSave() {
      this.isLoading = true;
      let action, data;
      var sourceName = this.$route.meta.parentGroup;
      var logSource = this.dischargeLog && this.dischargeLog.logSource && this.dischargeLog.logSource.includes(this.monitoringDetailSource)
                        ? this.dischargeLog.logSource : `${sourceName.charAt(0).toUpperCase()}${sourceName.slice(1)}`

      const dischargeLog = {
        id: this.dischargeLog.id,
        logDateTime: new Date(this.dischargeLog.logDateTime),
        details: this.dischargeLog.details,
        logSource: logSource,
        createdOn: this.dischargeLog.createdOn,
        createdBy: this.dischargeLog.createdBy,
        updatedOn: this.dischargeLog.updatedOn,
        updatedBy: this.dischargeLog.updatedBy
      };

      if (!dischargeLog.id) {
        action = DISCHARGE_LOG.NS(DISCHARGE_LOG.ACTIONS.CREATE_DISCHARGE_LOG);
        data = {
          planId: this.dischargePlanId,
          dischargeLog: dischargeLog
        };
      }
      else {
        action = DISCHARGE_LOG.NS(DISCHARGE_LOG.ACTIONS.EDIT_DISCHARGE_LOG);
        data = {
          planId: this.dischargePlanId,
          dischargeLog: dischargeLog
        };
      }
      const success = await this.$store.dispatch(action, data);

      if (success) {
        this.resetDetails();
      }
      this.isLoading = false;
      this.showDialog = false;
    },
    resetDetails() {
      this.dischargeLog = {
        details: '',
        logDateTime: formatDateTimeToMasked(new Date(), DATE_TIME_FORMAT),
        id: null
      }
    }
  },
  validations: {
    dischargeLog: {
      details: {
        required
      },
      logDateTime: {
        required,
        maskedDatetime
      }
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables.scss";
.sort {
  cursor: pointer;
}
.sort:hover {
  text-decoration: underline;
}
.comment-container {
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;
}
.comment-date {
  font-style: italic;
  text-align: right;
  font-size: 12px;
}
.updated-date {
  margin-right: 10px;
}

.details-align {
  text-align: left !important;
}

:deep(.discharge-log-table) td,
:deep(.discharge-log-table) th {
  text-align: center !important;
  padding: 0 12px !important;
}

</style>