import { format, parseISO, isValid } from "date-fns";
import { DATE_TIME_FORMAT, TIME_ZONE } from "@/constants/dateFormat";
const { utcToZonedTime } = require('date-fns-tz')

export default function formatDateTime(input, stringFormat = DATE_TIME_FORMAT) {
  if (!input)
    return '';
    
    if (input && input !== "Invalid Date") {
      if (typeof input !== 'string' && isValid(input)) {
        return format(input, stringFormat, { timeZone: TIME_ZONE, awareOfUnicodeTokens: true })
      } else if (isValid(parseISO(input))) {
        return format(utcToZonedTime(parseISO(input), TIME_ZONE), stringFormat, { timeZone: TIME_ZONE, awareOfUnicodeTokens: true });
      }
    }
    return input;
}