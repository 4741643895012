import Vue from 'vue';

export const FEATURES_MUTATIONS = {
  SET_FEATURE_STATE: 'setFeatureState',
  RESET_FEATURE_STATE: 'resetFeatureState',
  SET_CONFIG_SETTING: 'setConfigSetting'
};

export const mutations = {
  [FEATURES_MUTATIONS.SET_FEATURE_STATE](state, {key, value}) {
    Vue.set(state.featureStateList, key, value);
  },
  [FEATURES_MUTATIONS.RESET_FEATURE_STATE](state, key) {
    Vue.set(state.featureStateList, key, null);
  },
  [FEATURES_MUTATIONS.SET_CONFIG_SETTING](state, setting) {
    Vue.set(state, 'configSetting', setting);
  }
};