import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';
import { removeTimezone } from '@/helpers/date';
import { parseISO } from 'date-fns';

export const TIME_SCALE_MUTATIONS = {
  SET_TIME_SCALE: 'setTimeScale',
  SET_ORIGINAL_TIME_SCALE: 'setOriginalTimeScale',
};

export const mutations = {
  [TIME_SCALE_MUTATIONS.SET_TIME_SCALE](state, timeScale) {
    Vue.set(state, 'timeScale', cloneDeep(timeScale));
  },
  [TIME_SCALE_MUTATIONS.SET_ORIGINAL_TIME_SCALE](state, timeScale) {
    Vue.set(state, 'originalTimeScale', cloneDeep(timeScale));
  },
};
