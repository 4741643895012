import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';

export const SHIP_PORT_PERFORMANCE_MUTATIONS = {
  SET_SHIP_PORT_PERFORMANCE: 'setShipPortPerformance'
}

export const mutations = {
  [SHIP_PORT_PERFORMANCE_MUTATIONS.SET_SHIP_PORT_PERFORMANCE](state, shipPortPerformance) {
    Vue.set(state, 'shipPortPerformance', cloneDeep(shipPortPerformance));
  }
}