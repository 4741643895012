import defaultItems, {
  AGO_APPEARANCE_LIST,
  PMS_APPEARANCE_LIST,
  RMS_APPEARANCE_LIST,
  LFO_APPEARANCE_LIST,
  JETA1_APPEARANCE_LIST
} from '@/constants/appearanceItems';

export function getAppearanceList(product) {
  if (product) {
    switch (product.toLowerCase()) {
      case 'ago':
        return AGO_APPEARANCE_LIST;
      case 'lfo':
        return LFO_APPEARANCE_LIST;
      case 'pms':
        return PMS_APPEARANCE_LIST;
      case 'rms':
        return RMS_APPEARANCE_LIST;
      case 'jeta1':
        return JETA1_APPEARANCE_LIST;
    }
  }
  return defaultItems;
}