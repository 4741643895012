import { LINE_SAMPLE_MUTATIONS } from './mutations';
import api from './api';
import { UI } from '@/store/ui';

export const LINE_SAMPLE_ACTIONS = {
  GET_LINE_SAMPLE_LIST: 'getLineSampleList',
  CREATE_LINE_SAMPLE: 'createLineSample',
  EDIT_LINE_SAMPLE: 'editLineSample',
  DELETE_LINE_SAMPLE: 'deleteLineSample'
};

export const ERROR_GET_LINE_SAMPLE_LIST =
  'There was a problem trying to load the pipeline sample list';
export const ERROR_CREATE_LINE_SAMPLE =
  'There was a problem trying to create a pipeline sample';
export const ERROR_EDIT_LINE_SAMPLE =
  'There was a problem trying to edit the pipeline sample';
export const ERROR_DELETE_LINE_SAMPLE =
  'There was a problem trying to delete the pipeline sample';

export const actions = {
  async [LINE_SAMPLE_ACTIONS.GET_LINE_SAMPLE_LIST]({ commit, dispatch }, {
    planId,
    product
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      const response = await api.getLineSampleList(planId, product);
      commit(LINE_SAMPLE_MUTATIONS.SET_LINE_SAMPLE_LIST, response.data.data);
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          id: 'failed-get-line-sample-list',
          text: ERROR_GET_LINE_SAMPLE_LIST,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  async [LINE_SAMPLE_ACTIONS.CREATE_LINE_SAMPLE]({ commit, dispatch }, {
    planId,
    lineSample
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      const response = await api.createLineSample(
        planId,
        lineSample
      );
      lineSample.createdBy = response.data.createdBy;
      lineSample.id = response.data.id;
      commit(LINE_SAMPLE_MUTATIONS.ADD_LINE_SAMPLE_LIST, response.data);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          id: 'failed-create-line-sample',
          text: ERROR_CREATE_LINE_SAMPLE,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  },
  async [LINE_SAMPLE_ACTIONS.DELETE_LINE_SAMPLE]({ commit, dispatch }, {
      planId,
      lineSampleId
  }) {
      dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
          root: true
      });
      let success = false;
      try {
          await api.deleteLineSample(
              planId,
              lineSampleId
          );
          commit(LINE_SAMPLE_MUTATIONS.DELETE_LINE_SAMPLE_LIST, lineSampleId);
          success = true;
      } catch (error) {
          dispatch(
              UI.NS(UI.ACTIONS.ADD_TOAST), {
              id: 'failed-delete-line-sample',
              text: ERROR_DELETE_LINE_SAMPLE,
              error
          },
              { root: true }
          );
      }
      dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
          root: true
      });
      return success;
  },
  async [LINE_SAMPLE_ACTIONS.EDIT_LINE_SAMPLE]({ commit, dispatch }, {
    planId,
    lineSample
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      const response = await api.updateLineSample(
        planId,
        lineSample
      );
      commit(LINE_SAMPLE_MUTATIONS.EDIT_LINE_SAMPLE_LIST, lineSample);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-edit-line-sample',
        text: ERROR_EDIT_LINE_SAMPLE,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  }
};

