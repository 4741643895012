<template>
    <v-card flat>
      <v-layout row wrap :key="`${checklistSectionData.id}-sections`" mt-2 mb-2 pl-5>
        <template v-for="(question, index) in checklistSectionData.questions">
          <v-flex xs6 :key="`${question.id}-desc`" align-self-center>
            <span class="question">{{question.question}}</span>
          </v-flex>

          <v-flex xs3 :key="`${question.id}-questions`">
            <v-radio-group :value="question.submissionResult && question.submissionResult.submissions[0]"
                           :column="false" 
                           v-if="question.checklistQuestionToAnswerOptionGroup.answerType == 'Radio'">
              <v-radio v-for="answerOption in question.answerOptions"
                       :key="answerOption"  color="orange lighten-1"
                       v-bind:value="answerOption"
                       v-bind:label="answerOption"
                       @click.native="handleSubmissionOnChange(question.submissionResult.submissions, question.id, [answerOption])"
                       class="checkbox-padding" 
                       :disabled="isShipPortPerformanceDisabled" />
            </v-radio-group>

            <v-radio-group :value="question.submissionResult && question.submissionResult.submissions"
                           :column="false"
                           v-if="question.checklistQuestionToAnswerOptionGroup.answerType == 'Checkbox'">
              <v-checkbox v-for="answerOption in question.answerOptions"
                          :key="`${answerOption}-answers`"
                          v-model="question.submissionResult.submissions"
                          :label="answerOptions" :value="answerOptions"
                          class="checkbox-padding" color="orange lighten-1"/>
            </v-radio-group>
          </v-flex>
          <v-flex xs3 :key="`${question.id}-comment`">
            <v-text-field :value="question.submissionResult && question.submissionResult.comment"
                          class="custom-placeholder-color" color="orange lighten-1"
                          placeholder="Add name and comments"
                          :error-messages="commentsErrors(index)"
                          @change="handleCommentOnChange(question.submissionResult.comment, question.id, $event)" 
                          :readonly=isShipPortPerformanceDisabled />
          </v-flex>
        </template>
      </v-layout>
    </v-card>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators'
import { shipPortPerformanceChecklistSectionIds } from '@/constants/checklists';

export default {
  props: {
    checklistSectionData: null
  },
  emits: ['updateComment','updateSubmission'],
  methods: {
    commentsErrors(index) {
      if (!this.$v.checklistSectionData.questions.$each[index]) return null;
      if (!this.$v.checklistSectionData.questions.$each[index].submissionResult.comment.maxLength) {
        return 'The comment must be shorter than 100 characters long';
      }
    },
    handleCommentOnChange(previousComment, questionId, event) {
      if (previousComment !== event) {
        this.$emit('updateComment', this.checklistSectionData.id, questionId, event);
      }
    },
    handleSubmissionOnChange(previousSubmission, questionId, event) {
      if (previousSubmission[0] !== event[0]) {
        this.$emit('updateSubmission', this.checklistSectionData.id, questionId, event);
      } else {
        this.$emit('updateSubmission', this.checklistSectionData.id, questionId, []);
      }
    }
  },
  computed: {
    isShipPortPerformanceDisabled: {
      get() {
        if (shipPortPerformanceChecklistSectionIds.includes(this.checklistSectionData.id)) {
          return true;
        }
        return false;
      }
    }
  },
  validations: {
    checklistSectionData: {
      questions: {
        $each: {
          submissionResult: {
            comment: {
              maxLength: maxLength(100)
            }
          }
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import '../assets/styles/_responsive.scss';
@import '../assets/styles/_variables.scss';
.question {
  font-size: 120%;
}
// always show input labels in "active" position and display full label
:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}
.checkbox-padding {
  padding-right: 18px;
}
.v-expansion-panel {
  box-shadow: none !important;
}
.v-expansion-panel__container {
  border-top: none !important;
}
</style>
