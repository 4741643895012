<template>
  <data-table
    :headers="headers"
    :items="dischargePlanList"
    :totalItems="totalItems"
    :clientSideSearching="enableSearch"
    :loading="isLoading"
    @pagination="onPagination"
    :pagination.sync="pagination"
    @selectRow="goToDetails"
    :showArrows="true">
      <template v-slot:default="slotProps">
        <td>{{ slotProps.item.voyage }}</td>
        <td>{{ slotProps.item.vessel}}</td>
        <td>{{ slotProps.item.pilotOnBoard | formatDateTime('yyyy-MM-dd HH:mm') }}</td>
        <td>{{ slotProps.item.planStatus }}</td>
      </template>
  </data-table>
</template>

<script>
import { mapState } from 'vuex';
import { DISCHARGE_PLAN } from '@/store/tankerDischarge/preArrival/dischargePlan';
import { PAGINATION } from '@/store/pagination';
import { ROUTE_NAMES } from '@/routes';
import { RECEIPT_PLAN_LIST } from '@/constants/pagination';

export default {
  mounted() {
    // reset dischargePlan data
    this.$store.dispatch(
      DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.RESET_DISCHARGE_PLAN)
    );
  },
  watch: {
    activeTerminal: {
      immeditate: true,
      handler() {
        this.onPagination();
      }
    }
  },
  data() {
    return {
      isLoading: false,
      enableSearch: true,
      headers: [
        { text: 'Voyage', value: 'voyage' },
        { text: 'Vessel', value: 'vessel' },
        { text: 'Pilot on board', value: 'pilotOnBoard' },
        { text: 'Status', value: 'planStatus', sortable: false}
      ]
    };
  },
  computed: {
    pagination: {
      get() {
        return (
          this.$store.state.pagination &&
          this.$store.state.pagination[RECEIPT_PLAN_LIST]
        );
      },
      set(value) {
        this.$store.commit(PAGINATION.NS(PAGINATION.MUTATIONS.SET_PAGINATION), {
          key: RECEIPT_PLAN_LIST,
          value
        });
      }
    },
    ...mapState({
      activeTerminal: state =>
        state.terminals && state.terminals.activeTerminal,
      dischargePlanList: state =>
        state.dischargePlan && state.dischargePlan.dischargePlanList,
      totalItems: state =>
        state.dischargePlan && state.dischargePlan.terminalDischargeListTotal, 
      terminalsList: state => state.terminals && state.terminals.terminalsList
    })
  },
  methods: {
    async onPagination() {
      this.isLoading = true;

      await this.$store.dispatch(
        DISCHARGE_PLAN.NS(
          DISCHARGE_PLAN.ACTIONS.LOAD_DISCHARGE_LIST_BY_TERMINAL_ID
        ), {
          terminalId: this.activeTerminal.id,
          pagination: this.pagination
        }
      );
      
      this.isLoading = false;
    },
    goToDetails(planId) {
      this.$router.push({
        name: ROUTE_NAMES.VESSEL_INFO,
        params: { planId }
      });
    }
  },
  validations() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';
</style>
