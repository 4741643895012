import { UI_MUTATIONS } from './mutations';

export const UI_ACTIONS = {
  TOGGLE_IS_LOADING: 'toggleIsLoading',
  ADD_TOAST: 'addToast',
  REMOVE_TOAST_BY_ID: 'removeToastById',

  // Side menu props
  TOGGLE_OPEN_DRAWER: 'toggleOpenDrawer',
  TOGGLE_EXPAND_MINI: 'toggleExpandMini',

  // Discharge Menu props
  TOGGLE_OPERATION_ID: 'toggleOperationId',
  TOGGLE_LAST_TANK_ID: 'toggleLastTankId',
  TOGGLE_LAST_RECEIPT_PLAN_ID: 'toggleLastReceiptPlanId',
  TOGGLE_LAST_PIPELINE_PRODUCT: 'toggleLastPipelineProduct'
};

export const actions = {
  [UI_ACTIONS.TOGGLE_IS_LOADING]({ commit }, isLoading) {
    commit(UI_MUTATIONS.TOGGLE_IS_LOADING, isLoading);
  },
  [UI_ACTIONS.ADD_TOAST]({ commit }, { id, text, type, error }) {
    // if an error was passed
    if (error) {
      // set the type to error
      type = 'error';

      // if the toast is for a 400 response from the api
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data
      ) {
        // overwrite the text with the response error message
        let exception = error.response.data.exception;
        if (!exception) {
          // if no exception object is present, then the exception
          // probably didn't come from fluent val
          exception = error.response.data;
        }

        if (exception) {
          // check if the error message is an array or single string
          if (Array.isArray(exception)) {
            text = exception.map(ex => ex.errorMessage).join();
          } else {
            text = exception;
          }
        }
      }
    }

    commit(UI_MUTATIONS.ADD_TOAST, { id, text, type });
  },
  [UI_ACTIONS.REMOVE_TOAST_BY_ID]({ commit }, idToRemove) {
    commit(UI_MUTATIONS.REMOVE_TOAST_BY_ID, idToRemove);
  },
  [UI_ACTIONS.TOGGLE_OPEN_DRAWER]({ commit }, drawer) {
    commit(UI_MUTATIONS.TOGGLE_OPEN_DRAWER, drawer);
  },
  [UI_ACTIONS.TOGGLE_EXPAND_MINI]({ commit }, mini) {
    commit(UI_MUTATIONS.TOGGLE_EXPAND_MINI, mini);
  },
  [UI_ACTIONS.TOGGLE_OPERATION_ID]({ commit }, id) {
    commit(UI_MUTATIONS.TOGGLE_OPERATION_ID, id);
  },
  [UI_ACTIONS.TOGGLE_LAST_TANK_ID]({ commit }, id) {
    commit(UI_MUTATIONS.TOGGLE_LAST_TANK_ID, id);
  },
  [UI_ACTIONS.TOGGLE_LAST_RECEIPT_PLAN_ID]({ commit }, id) {
    commit(UI_MUTATIONS.TOGGLE_LAST_RECEIPT_PLAN_ID, id);
  },
  [UI_ACTIONS.TOGGLE_LAST_PIPELINE_PRODUCT]({ commit }, product) {
    commit(UI_MUTATIONS.TOGGLE_LAST_PIPELINE_PRODUCT, product);
  }
};