<template>
  <v-list expand nav>
    <template v-for="(item, i) in routes">
      <v-list-group :key="i" no-action :group="handleRoute(item.path)" color="orange">
            <v-icon slot="prependIcon" class="ma-0" id='menu-icon'>{{item.meta.icon}}</v-icon>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title :id="item.meta.title + '-meta-title'" class="menu-header-item pl-0" v-text="item.meta.title"/>
          </v-list-item-content>
        </template>
        <template v-for="(subItem, j) in item.children">
          <v-list-item :id="subItem.meta.title + '-orange-menu-item'" color="orange" :key="j"
            :to="handleRoute(item.path + '/' + subItem.path)" 
            :class="[pageName === subItem.name ? 'v-list-item--active' : '']">
            <v-list-item-content>      
              <v-list-item-title :id="subItem.meta.title + '-menu-item'" v-text="subItem.meta.title" class="menu-item"/>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
import { ROUTE_NAMES } from '@/routes';
import { mapState } from 'vuex';

export default {
  methods: {
    handleRoute(path) {
      if (path.includes(":terminalId")) path = path.replace(":terminalId", this.activeTerminalId);
      if (this.$route.path !== path) return path;
    }
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
    routes: {
      get() {
        return this.$router.options.routes.filter(t => t.name === ROUTE_NAMES.TERMINAL_DATA)[0].children;
      }
    },
    ...mapState({
      activeTerminalId: state => state.terminals && state.terminals.activeTerminal && state.terminals.activeTerminal.id
    })
  }
};
</script>

<style lang="scss" scoped>
</style>