<template>
  <v-card flat tile class="d-flex mb-5">
    <v-card flat class="d-flex-row pa-1 cell-container" :class="[ !!error ? 'cell-error' : '', orangeHighlighting ? safeFillError ? 'red-highlighting' : 'orange-highlighting' : '' ]" 
            width="100%" :outlined="orangeHighlighting">
      <div :class="['cell-label', highlight ? 'highlight' : '']" >{{ label }}</div>

      <div v-if="!isSingleValue" class="d-flex">
        <div :class="['cell-column', highlight ? 'highlight' : '']">{{ millimetres ? `${millimetres} mm` : '&nbsp;' | commaSeperateNumber}}</div>
        <v-spacer />
        <div :class="['cell-column', highlight ? 'highlight' : '']">{{ litres ? `${litres} L` : '&nbsp;' | commaSeperateNumber }}</div>
      </div>
      <div v-else class="d-flex">
        <div :class="['cell-column', highlight ? 'highlight' : '']">{{ singleValue }}&nbsp;</div>
        <v-spacer />
        <div v-if="hasSingleValueSuffix" :class="['cell-column', highlight ? 'highlight' : '']">{{ singleValueSuffix }}</div>
      </div>
    </v-card>
    <v-card class="cell-error-message">{{ error }}</v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    litres: {
      type: Number | String,
      default: null
    },
    millimetres: {
      type: Number | String,
      default: null
    },
    label: String,
    error: String,
    singleValue: {
      type: Number | String,
      default: null
    },
    singleValueSuffix: {
      type: Number | String,
      default: null
    },
    singleValueAlignRight: Boolean,
    highlight: Boolean,
    orangeHighlighting: {
      type: Boolean,
      default: false
    },
    safeFillError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSingleValue() {
      return this.singleValue != null;
    },
    hasSingleValueSuffix() {
      return this.singleValueSuffix != null;
    }
  }

};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';

$error-color: #ff2424;

.cell-container {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #888;
  box-shadow: inset 0px -2px 1px 0px rgba(0,0,0,.50);
}
.cell-error {
  border-bottom: 1px solid $error-color !important;
}
.cell-label {
  font-size: 12px !important;
  margin-bottom: 10px;
  font-weight: bold;
}
.cell-error .cell-label {
  color: $error-color !important;
}
.cell-column {
  font-size: 14px;
  min-height: 20px;
  white-space: nowrap;
  float:left;
}
.cell-error-message {
  position: absolute;
  font-size: 12px;
  color: $error-color !important;
}
.highlight {
  font-weight: bold;
  font-size: 18px !important;
}
.orange-highlighting {
  color: orange;
  border-color: orange;
}
.red-highlighting {
  color: red;
  border-color: red;
}
</style>