/**
 * Takes an object and creates a deep clone of it.
 * @param {any} obj The object to clone.
 * @returns {any} The cloned object.
 */

import clone from 'lodash.clonedeep';

export function cloneDeep(obj) {
  return clone(obj);
}