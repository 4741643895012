import { http } from '@/api';

export default {
  getTransferPlansByTerminal: (terminalId) => http.get(`/v1/tanktotank/${terminalId}/listplans`),
  getTankTechnicalDetailsByTerminal: (terminalId) => http.get(`/v1/tankTechnicalDetails/${terminalId}/tanktotank`),
  createTransferPlan: (
    terminalId,
    transferPlan
  ) => http.post('/v1/tanktotank/newplan', 
    {
      terminalId,
      Model: transferPlan
    }
  ),
  updateTransferPlan: (transferPlanId, transferPlan) => http.put(`/v1/tanktotank/${transferPlanId}`, 
    {
      Model: transferPlan
    }
  ),
  getTankToTankTransferPlan: (id) => http.get(`/v1/tanktotank/${id}`),
  abandonTransferPlan: planId => http.put(`/v1/tanktotank/${planId}/abandon`,
    {
      planId
    }
  ),
  updateTankPlans: (
    transferPlanId,
    sourceTankPlanId,
    targetTankPlanId,
    lastSourceReleaseFormDate, 
    lastTargetReleaseFormDate, 
    sourceTankPlan, 
    targetTankPlan
  ) => http.put(`/v1/tanktotank/${transferPlanId}/tankplans/${sourceTankPlanId}/${targetTankPlanId}`, 
    {
      transferPlanId,
      lastSourceReleaseFormDate,
      lastTargetReleaseFormDate,
      SourceTankPlan: sourceTankPlan,
      TargetTankPlan: targetTankPlan
    }
  ),
}