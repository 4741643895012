import Vue from "vue";

export const EQUIPMENT_INFORMATION_MUTATIONS = {
    SET_EQUIPMENT_INFORMATION_BY_TERMINAL: 'setEquipmentInformationByTerminal'
};

export const mutations = {
    [EQUIPMENT_INFORMATION_MUTATIONS.SET_EQUIPMENT_INFORMATION_BY_TERMINAL](state, equipmentsList) {
        Vue.set(state, 'equipments', equipmentsList);
    }
};