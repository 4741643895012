<template>
  <v-container fluid fill-height>
    <v-layout row wrap pa-4>
      <v-flex xs12>
        <h2>{{message}}</h2>
      </v-flex>
      <v-flex mt-4>
        <pre >{{formList}}</pre>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    message() {
      return 'Coming soon, please refer to existing process for the following forms:';
    },
    formList() {
      return "Wharf Paperwork \n" +
        "\t  -- Wharf Inspection Log - TO-IOA-FOR-005 \n" +
        "\t  -- Wharf Attendant Log - TO-IOA-FOR-026 \n" +
        "\t  -- Wharf Pump-back Completion Checklist - TO-IOA-FOR-029 \n" +
        "\t  -- Wharf pre-berthing Inspection Checklist - TO-IOA-FOR-035 \n" +
        "\t  -- Wharf post-discharge berthing Inspection Checklist - TO-IOA-FOR-036 \n" +
        "\t  -- Wharf Attendant Shift Handover - TO-IOA-FOR-037 \n" +
        "Pipeline Patrol Log - TO-IOA-FOR-034 \n";
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';
</style>
