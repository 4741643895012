import {
    flashpointRangeThresholds,
    lineFlashpointRangeThresholds,
    flashpointThresholds,
    densityRangeThresholds,
    lineDensityRangeThresholds,
    densityThresholds,
    appearanceThresholds,
    conductivityThresholds,
    viscosityThresholds,
    sulphurThresholds,
    cloudPointThresholds,
    coldFilterPluggingPointThresholds,
    pourPointThresholds,
    colourThresholds
} from '@/constants/thresholds';

export function validateFlashPointValue(product, value, cef, qualityCheck, qualityReport) {
    if (!product || (value === null || value === undefined)) {
      return false;
    }
    if (qualityCheck && (cef === null || cef === undefined)) { 
      return false;
    }

    switch (product.toLowerCase()) {
        case 'ago':
            if (value < (qualityReport ? flashpointThresholds.agoQrLow : flashpointThresholds.agoLow)) {
              return false;
            }
            if (qualityCheck) { 
              if (value < (cef - flashpointRangeThresholds.ago) || value > (cef + flashpointRangeThresholds.ago)) {
                return false;
              }
            } else {
              if (value > flashpointThresholds.agoHigh) {
                return false;
              }
            }
            return true;
        case 'jeta1':
            if (value < flashpointThresholds.jetA1Low) {
              return false;
            }
            if (qualityCheck) {
              if (value < (cef - flashpointRangeThresholds.jetA1) || value > (cef + flashpointRangeThresholds.jetA1)) {
                return false;
              }
            } else {
              if (value > flashpointThresholds.jetA1High) {
                return false;
              }
            }

            return true;
        case 'lfo':
            if (value < flashpointThresholds.lfoLow) {
              return false;
            }
            if (qualityCheck) {
              if (value < (cef - flashpointRangeThresholds.lfo) || value > (cef + flashpointRangeThresholds.lfo)) {
                return false;
              }
            } else {
              if (value > flashpointThresholds.lfoHigh) {
                return false;
              }
            }
            return true;
        case 'pms':
        case 'rms':
        default:
            return false;
    }
}

export function validatePipelineFlashPoint(product, value, lowThreshold, highThreshold) {
    if (!product || !value || !lowThreshold || !highThreshold) {
        return false;
    }
    switch (product.toLowerCase()) {
        case 'ago':
            if (value < flashpointThresholds.agoLow) {
                return false;
            }
            if (value < (lowThreshold - lineFlashpointRangeThresholds.ago) ||
                value > (highThreshold + lineFlashpointRangeThresholds.ago)) {

                return false;
            }
            return true;
        case 'jeta1':
            if (value < flashpointThresholds.jetA1Low) {
                return false;
            }
            if (value < (lowThreshold - lineFlashpointRangeThresholds.jetA1) ||
                value > (highThreshold + lineFlashpointRangeThresholds.jetA1)) {
                return false;
            }
            return true;
        case 'lfo':
            if (value < flashpointThresholds.lfoLow) {
                return false;
            }
            if (value < (lowThreshold - lineFlashpointRangeThresholds.lfo) ||
                value > (highThreshold + lineFlashpointRangeThresholds.lfo)) {
                return false;
            }
            return true;
        case 'pms':
        case 'rms':
        default:
            return false;
    }
}

export function validateDensityValue(product, value, ced, qualityCheck) {
    if (!product || (value === null || value === undefined)) {
      return false;
    }
    if (qualityCheck && (ced === null || ced === undefined)) { 
      return false;
    }

    // set decimal for more accurate comparison
    ced = parseFloat(Number(ced).toFixed(4));
    switch (product.toLowerCase()) {
        case 'ago':
            if (value < densityThresholds.agoLow || value > densityThresholds.agoHigh) {
              return false;
            }
            if (qualityCheck) {
                if (value < (ced - densityRangeThresholds.ago) || value > (ced + densityRangeThresholds.ago)) {
                return false;
              }
            }
            return true;
        case 'jeta1':
            if (value < densityThresholds.jetA1Low || value > densityThresholds.jetA1High) {
                return false;
            }
            if (qualityCheck) {
              if (value < (ced - densityRangeThresholds.jetA1) || value > (ced + densityRangeThresholds.jetA1)) {
                return false;
              }
            }
            return true;
        case 'lfo':
            if (value < 0 || value > densityThresholds.lfo) {
                return false;
            }
            if (qualityCheck) {
              if (value < (ced - densityRangeThresholds.lfo) || value > (ced + densityRangeThresholds.lfo)) {
                return false;
              }
            }
            return true;
        case 'pms':
            if (qualityCheck) {
              if (value < (ced - densityRangeThresholds.pms) || value > (ced + densityRangeThresholds.pms)) {
                return false;
              }
            } else if (value < densityThresholds.pmsLow || value > densityThresholds.pmsHigh)
            {
              return false;
            }
            return true;
        case 'rms':
            if (qualityCheck) {
              if (value < (ced - densityRangeThresholds.rms) || value > (ced + densityRangeThresholds.rms)) {
                return false;
              }
            } else if (value < densityThresholds.rmsLow || value > densityThresholds.rmsHigh)
            {
              return false;
            }
            return true;
        default:
            if (value < 0)
                return false;
            return true;
    }
}

export function validatePipelineDensity(product, value, lowThreshold, highThreshold) {
    if (!product || !value || !lowThreshold || !highThreshold) {
        return false;
    }
    // set decimal for more accurate comparison
    lowThreshold = parseFloat(Number(lowThreshold).toFixed(4));
    highThreshold = parseFloat(Number(highThreshold).toFixed(4));
    switch (product.toLowerCase()) {
        case 'ago':
            if (value < densityThresholds.agoLow ||
                value > densityThresholds.agoHigh) {
                return false;
            }
            if (value < (lowThreshold - lineDensityRangeThresholds.ago) ||
                value > (highThreshold + lineDensityRangeThresholds.ago)) {
                return false;
            }
            return true;
        case 'jeta1':
            if (value < densityThresholds.jetA1Low ||
                value > densityThresholds.jetA1High) {
                return false;
            }
            if (value < (lowThreshold - lineDensityRangeThresholds.jetA1) ||
                value > (highThreshold + lineDensityRangeThresholds.jetA1)) {
                return false;
            }
            return true;
        case 'lfo':
            if (value > densityThresholds.lfo) {
                return false;
            }
            if (value < (lowThreshold - lineDensityRangeThresholds.lfo) ||
                value > (highThreshold + lineDensityRangeThresholds.lfo)) {
                return false;
            }
            return true;
        case 'pms':
            if (value < (lowThreshold - lineDensityRangeThresholds.pms) ||
                value > (highThreshold + lineDensityRangeThresholds.pms)) {
                return false;
            }
            return true;
        case 'rms':
            if (value < (lowThreshold - lineDensityRangeThresholds.rms) ||
                value > (highThreshold + lineDensityRangeThresholds.rms)) {
                return false;
            }
            return true;
        default:
            return false;
    }
}

export function validateSulphur(product, value) {
  if (!product || value < 0) {
    return false;
  }
  switch (product.toLowerCase()) {
    case 'ago':
        if (value > sulphurThresholds.ago) {
          return false;
        }
        return true;
    case 'jeta1':
        if (value > sulphurThresholds.jetA1) {
          return false;
        }
        return true;
    case 'lfo':
      if (value > sulphurThresholds.lfo) {
        return false;
      }
      return true;
    case 'pms':
      if (value > sulphurThresholds.pms) {
        return false;
      }
      return true;
    case 'rms':
      if (value > sulphurThresholds.rms) {
        return false;
      }
      return true;
    default:
      return false;
  }
}

export function validateViscosity(product, value) {
  if (!product) {
    return false;
  }
  switch (product.toLowerCase()) {
    case 'ago':
        if (value < viscosityThresholds.agoLow || 
            value > viscosityThresholds.agoHigh) {
          return false;
        }
        return true;
    case 'lfo':
        if (value < viscosityThresholds.lfoLow || 
            value > viscosityThresholds.lfoHigh) {
          return false;
        }
        return true;
    case 'jeta1':
    case 'pms':
    case 'rms':
    default:
      return false;
  }
}

export function validateConductivity(product, value) {
    if (!product || !value) {
        return false;
    }
    switch (product.toLowerCase()) {
        case 'ago':
            if (value < conductivityThresholds.agoLow || 
                value > conductivityThresholds.agoHigh) {
              return false;
            }
            return true;
        case 'jeta1':
            if (value < conductivityThresholds.jetA1Low || 
                value > conductivityThresholds.jetA1High) {
              return false;
            }
            return true;
        case 'lfo':
        case 'pms':
        case 'rms':
        default:
            return false;
    }
}

export function validateAppearance(product, value) {
    if (!product || !value) {
        return false;
    }
    switch (product.toLowerCase()) {
        case 'ago':
            return appearanceThresholds.agoPass.includes(value);
        case 'jeta1':
            return appearanceThresholds.jetA1Pass.includes(value);
        case 'lfo':
            return appearanceThresholds.lfoPass.includes(value);
        case 'pms':
            return appearanceThresholds.pmsPass.includes(value);
        case 'rms':
            return appearanceThresholds.rmsPass.includes(value);
        default:
            return false;
    }
}

// Tank to Tank
export function validateConductivityTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < conductivityThresholds.agoLow || 
              value > conductivityThresholds.agoHigh) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validateFlashPointTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < flashpointThresholds.agoLow) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validateDensityTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < densityThresholds.agoLow ||
              value > densityThresholds.agoHigh ) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validateViscosityTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < densityThresholds.agoLow ||
              value > densityThresholds.agoHigh ) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validateCloudPointTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < cloudPointThresholds.agoLow ||
              value > cloudPointThresholds.agoHigh ) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validateColdFilterPluggingPointTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < coldFilterPluggingPointThresholds.agoLow ||
              value > coldFilterPluggingPointThresholds.agoHigh ) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validatePourPointTankToTank(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          if (value < pourPointThresholds.agoLow ||
              value > pourPointThresholds.agoHigh ) {
            return false;
          }
          return true;
      default:
          return true;
  }
}

export function validateColour(product, value) {
  if (!product || !value) {
      return false;
  }
  switch (product.toLowerCase()) {
      case 'ago':
          return colourThresholds.agoPass.includes(value);
      case 'pms':
          return colourThresholds.pmsPass.includes(value);
      case 'rms':
          return colourThresholds.rmsPass.includes(value);
      default:
          return false;
  }
}