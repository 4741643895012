import { getters, UI_GETTERS } from './getters';
import { actions, UI_ACTIONS } from './actions';
import { mutations, UI_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'ui';

export const state = {
  isLoading: false,
  lastTankId: null,
  lastReceiptPlanId: null,
  lastPipelineProduct: null,
  operationId: null,
  drawer: false,
  mini: false,
  toasts: []
};

export const UI = {
  ACTIONS: UI_ACTIONS,
  GETTERS: UI_GETTERS,
  MUTATIONS: UI_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const ui = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
