import { actions, TERMINALS_ACTIONS } from './actions';
import { getters, TERMINALS_GETTERS } from './getters';
import { mutations, TERMINALS_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'terminals';

export const state = {
  terminalsList: null,
  activeTerminal: null
};

export const TERMINALS = {
  ACTIONS: TERMINALS_ACTIONS,
  GETTERS: TERMINALS_GETTERS,
  MUTATIONS: TERMINALS_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const terminals = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
