import { formatDateTimeToMasked } from '@/helpers/date';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";
import { actions, LINE_SAMPLE_ACTIONS } from './actions';
import { getters, LINE_SAMPLE_GETTERS } from './getters';
import { mutations, LINE_SAMPLE_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'lineSample';

export function GET_DEFAULT_LINE_SAMPLE() {
  return {
    monitoredAt: formatDateTimeToMasked(new Date(), DATE_TIME_FORMAT),
    product: '',
    vesselTank: '',
    appearance: '',
    temperature: 0,
    density: 0,
    flashResult: null,
    conductivity: null,
    resultPassFail: '',
    comments: ''
  };
}

export const state = {
  lineSampleList: []
};

export const LINE_SAMPLE = {
  ACTIONS: LINE_SAMPLE_ACTIONS,
  GETTERS: LINE_SAMPLE_GETTERS,
  MUTATIONS: LINE_SAMPLE_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const lineSample = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};