import { http } from '@/api';

export default {
  getQualityReport: ({ planId, receiptPlanId, tankPlanId }) => http.get(
    `v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport`
  ),
  getQualityReportPdf: ({ planId, receiptPlanId, tankPlanId }) => http.get(
    `v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport/pdf`, { 
      responseType: 'arraybuffer' 
    }
  ),   
  createQualityReport: ({ planId, receiptPlanId, tankPlanId, qualityReport }) => http.post(
    `/v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport`, {
      planId,
      receiptPlanId,
      tankPlanId,
      model: qualityReport
    }
  ),
  updateQualityReport: ({ planId, receiptPlanId, tankPlanId, qualityReport }) => http.put(
    `/v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport`, {
      planId,
      receiptPlanId,
      tankPlanId,
      model: qualityReport
    }
  ),
  releaseTank: (planId, receiptPlanId, tankPlanId, releaseDetails) => http.put(
    `v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport/releaseTank`, {
      ...releaseDetails
    }
  ),
  updateQualityReportInternalNotes: (planId, receiptPlanId, tankPlanId, internalNotes) => http.put(
    `v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport/internalNotes`, {
      internalNotes
    }
  ),
  createPostReceiptStorageTankSamples: (planId, receiptPlanId, tankPlanId, postReceiptStorageTankSamples) => http.post(
    `v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport/postreceiptstoragetanksample`, {
      model: postReceiptStorageTankSamples
    }
  ),
  updatePostReceiptStorageTankSamples: (planId, receiptPlanId, tankPlanId, postReceiptStorageTankSamples) => http.put(
    `v1/plans/${planId}/receiptplans/${receiptPlanId}/tankplans/${tankPlanId}/qualityreport/postreceiptstoragetanksample`, {
      model: postReceiptStorageTankSamples
    }
  )
}