<template>
  <v-dialog persistent max-width="500" v-model="showDialog">
    <v-card>
      <div class="dialog-content-container">
        <v-card-text>
            <v-flex xs12 v-if="tank">
                <v-flex :style=" {
                    border: `1px solid ${mapProductToColor(
                      tank.product,
                      true,
                      true
                    )}`,
                    borderRadius: '1px'}">
                  <h3 :style="{
                        color: mapProductToColor(tank.product),
                        backgroundColor: mapProductToColor(tank.product, true),
                        textAlign: 'center',
                        textTransform: 'uppercase'
                      }">
                        {{tank.product}}
                  </h3>
                  <h3 :style="{
                        color: mapProductToColor(tank.product),
                        backgroundColor: mapProductToColor(tank.product, true),
                        textAlign: 'center'
                      }">
                        {{tank.name}}
                  </h3>
                </v-flex>
                <levels-of-concern-drop-down-edit-cell :label="LABEL_NAMES.PRODUCT"
                                        :values="PRODUCT_ITEMS"
                                        :value="tank.product"
                                        v-on:update-single-value="updateTankProperty('product', $event)"
                                        :idPrefix="`product_${tank.name}`"  />

                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.NAME"
                                          :value="tank.name"
                                          :singleValueErrors="$v.tank.name"
                                          v-on:update-single-value="updateTankProperty('name', $event)"
                                          :idPrefix="`name_${tank.name}`"
                                          :isValidErrorMessage="nameValidationMessage"
                                          type="text"/>

                <levels-of-concern-edit-cell :label="LABEL_NAMES.OVERFILL"
                                             :litresErrors="$v.tank.overfillLitres"
                                             :millimetresErrors="$v.tank.overfillMillimetres"
                                             :litres="tank.overfillLitres"
                                             :millimetres="tank.overfillMillimetres"
                                             v-on:update-millimetres="updateTankProperty('overfillMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('overfillLitres', $event)" 
                                             :idPrefix="`overfill_${tank.name}`"/>
                <levels-of-concern-edit-cell :label="LABEL_NAMES.HIGH_HIGH_LEVEL"
                                             :litresErrors="$v.tank.highHighLevelAlarmLitres"
                                             :millimetresErrors="$v.tank.highHighLevelAlarmMillimetres"
                                             :litres="tank.highHighLevelAlarmLitres"
                                             :millimetres="tank.highHighLevelAlarmMillimetres"
                                             v-on:update-millimetres="updateTankProperty('highHighLevelAlarmMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('highHighLevelAlarmLitres', $event)" 
                                             :idPrefix="`highHighLevel_${tank.name}`"/>
                <levels-of-concern-edit-cell :label="LABEL_NAMES.HIGH_LEVEL"
                                             :litresErrors="$v.tank.highLevelAlarmLitres"
                                             :millimetresErrors="$v.tank.highLevelAlarmMillimetres"
                                             :litres="tank.highLevelAlarmLitres"
                                             :millimetres="tank.highLevelAlarmMillimetres"
                                             v-on:update-millimetres="updateTankProperty('highLevelAlarmMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('highLevelAlarmLitres', $event)" 
                                             :idPrefix="`highLevelAlarm_${tank.name}`"/>
                <levels-of-concern-edit-cell :label="LABEL_NAMES.OPERATIONAL_LEVEL"
                                             :litresErrors="$v.tank.operationalLevelLitres"
                                             :millimetresErrors="$v.tank.operationalLevelMillimetres"
                                             :litres="tank.operationalLevelLitres"
                                             :millimetres="tank.operationalLevelMillimetres"
                                             v-on:update-millimetres="updateTankProperty('operationalLevelMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('operationalLevelLitres', $event)" 
                                             :idPrefix="`operationalLevel_${tank.name}`"/>

                <levels-of-concern-edit-cell :label="LABEL_NAMES.OPERATOR_LEVEL_ALARM"
                                             :litresErrors="$v.tank.operatorLevelAlarmLitres"
                                             :millimetresErrors="$v.tank.operatorLevelAlarmMillimetres"
                                             :litres="tank.operatorLevelAlarmLitres"
                                             :millimetres="tank.operatorLevelAlarmMillimetres"
                                             v-on:update-millimetres="updateTankProperty('operatorLevelAlarmMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('operatorLevelAlarmLitres', $event)" 
                                             :idPrefix="`operatorLevelAlarm_${tank.name}`"/>
                <levels-of-concern-edit-cell :label="LABEL_NAMES.SLOW_PUMP_LEVEL"
                                             :litresErrors="$v.tank.slowPumpLevelLitres"
                                             :millimetresErrors="$v.tank.slowPumpLevelMillimetres"
                                             :litres="tank.slowPumpLevelLitres"
                                             :millimetres="tank.slowPumpLevelMillimetres"
                                             v-on:update-millimetres="updateTankProperty('slowPumpLevelMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('slowPumpLevelLitres', $event)" 
                                             :idPrefix="`slowPumpLevel_${tank.name}`"/>
                <levels-of-concern-edit-cell :label="LABEL_NAMES.LOW_LEVEL"
                                             :litresErrors="$v.tank.lowLevelAlarmLitres"
                                             :millimetresErrors="$v.tank.lowLevelAlarmMillimetres"
                                             :litres="tank.lowLevelAlarmLitres"
                                             :millimetres="tank.lowLevelAlarmMillimetres"
                                             v-on:update-millimetres="updateTankProperty('lowLevelAlarmMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('lowLevelAlarmLitres', $event)" 
                                             :idPrefix="`lowLevelAlarm_${tank.name}`"
                                             />
                <levels-of-concern-edit-cell :label="LABEL_NAMES.LOW_LOW_LEVEL"
                                             :litresErrors="$v.tank.lowLowLevelAlarmLitres"
                                             :millimetresErrors="$v.tank.lowLowLevelAlarmMillimetres"
                                             :litres="tank.lowLowLevelAlarmLitres"
                                             :millimetres="tank.lowLowLevelAlarmMillimetres"
                                             v-on:update-millimetres="updateTankProperty('lowLowLevelAlarmMillimetres', $event)"
                                             v-on:update-litres="updateTankProperty('lowLowLevelAlarmLitres', $event)"
                                             :idPrefix="`lowLowLevelAlarm_${tank.name}`"/>

                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.TANK_BOTTOM_LEVEL"
                                                          :singleValueErrors="$v.tank.tankBottomLevelLitres"
                                                          :value="tank.tankBottomLevelLitres"
                                                          v-on:update-single-value="updateTankProperty('tankBottomLevelLitres', $event)" 
                                                          :idPrefix="`tankBottomLevel_${tank.name}`"/>

                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.MAX_FLOW_RATE_PER_MINUTE"
                                                          :singleValueErrors="$v.tank.maxFlowRateLitresPerMinute"
                                                          :value="tank.maxFlowRateLitresPerMinute"
                                                          v-on:update-single-value="updateTankProperty('maxFlowRateLitresPerMinute', $event)" 
                                                          :idPrefix="`maxFlowRateLitresPerMinute_${tank.name}`"/>

                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.MAX_SLOW_PUMPING_FLOW_RATE_PER_MINUTE"
                                                          :singleValueErrors="$v.tank.maxSlowPumpingFlowRateLitresPerMinute"
                                                          :value="tank.maxSlowPumpingFlowRateLitresPerMinute"
                                                          v-on:update-single-value="updateTankProperty('maxSlowPumpingFlowRateLitresPerMinute', $event)"
                                                          :idPrefix="`maxSlowPumpingFlowRateLitresPerMinute_${tank.name}`" />

                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.LITRES_PER_MILLIMETRE"
                                                          :singleValueErrors="$v.tank.litresPerMillimetre"
                                                          :value="tank.litresPerMillimetre"
                                                          v-on:update-single-value="updateTankProperty('litresPerMillimetre', $event)" 
                                                          :idPrefix="`litresPerMillimetre_${tank.name}`" />

                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.IFRW"
                                                          :singleValueErrors="$v.tank.ifrw"
                                                          :value="tank.ifrw"
                                                          v-on:update-single-value="updateTankProperty('ifrw', $event)"
                                                          :idPrefix="`ifrw_${tank.name}`" />

                <levels-of-concern-drop-down-edit-cell :label="LABEL_NAMES.TANK_STATUS"
                                                       :values="[ 'Active', 'Decommissioned', 'Under Maintenance']"
                                                       :value="tank.tankStatus ==  'UnderMaintenance' ? 'Under Maintenance' : tank.tankStatus"
                                                       :singleValueErrors="$v.tank.tankStatus"
                                                       v-on:update-single-value="updateTankProperty('tankStatus', ($event === 'Under Maintenance' ? 'UnderMaintenance': $event))"
                                                      :idPrefix="`tankStatus_${tank.name}`"  />
                <levels-of-concern-single-value-edit-cell :label="LABEL_NAMES.CHART_ID"
                                                          :singleValueErrors="$v.tank.chartId"
                                                          :value="tank.chartId"
                                                          type="text"
                                                          v-on:update-single-value="updateTankProperty('chartId', $event)" 
                                                          :idPrefix="`chartId_${tank.name}`" />
            </v-flex>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="discardChangesPopup_Button" color="secondary lighten-3" v-on:click="onClose">Discard Changes</v-btn>
        <v-btn id="savePopup_Button" color="orange lighten-1" v-on:click="onSave" :disabled="$v.$invalid">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapProductToColor, mapProductToTextColor } from '../../helpers/ColorMapper';
import { cloneDeep } from '@/helpers/object';
import { UI } from '@/store/ui';
import { TERMINALS } from '@/store/terminals';
import { required } from 'vuelidate/lib/validators';
import { number } from '@/validators';
import LABEL_NAMES from '@/constants/levelsOfConcernLabels';
import {PRODUCT_ITEMS} from '@/constants/products';

export default {
  mounted() {
    this.tank = cloneDeep(this.selectedTank);
  },
  props: {
    showDialog: false,
    selectedTank: null,
    tankNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tank: null,
      LABEL_NAMES,
      PRODUCT_ITEMS
    };
  },
  computed: {
    nameValidationMessage(){
      return `The tank name must be unique`;
    }
  },
  methods: {
    mapProductToColor(product, background = false, border = false) {
      if (!product) return;
      if (background) return mapProductToColor(product, border);
      return mapProductToTextColor(product);
    },
    updateTankProperty(key, value) {
      this.tank[key] = value;
    },
    onClose() {
      this.$emit('close-modal');
    },
    async onSave() {
      const success = await this.$store.dispatch(
        TERMINALS.NS(
          TERMINALS.ACTIONS.UPDATE_ACTIVE_TERMINAL_TANK_TECHINCIAL_DETAILS
        ),
        this.tank
      );
      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-discharge-plan',
            text: 'Successfully updated tank details'
          },
          { root: true }
        );
        this.$emit('close-modal-saved');
      } else {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'error-update-discharge-plan',
            text: 'Updating tank details failed',
            error: true
          },
          { root: true }
        );
      }
    }
  },
  validations() {
    // some of the properties are not editable so it shouldn't have validation
    const exemptions = ['tankCalibrations',
      'midFillLevelLitres', 'midFillLevelMillimetres',
      'nearOperationalLevelLitres', 'nearOperationalLevelMillimetres'];

    // Dynamically create validations based on all keys
    const tankValidations = Object.keys(this.tank || {})
      .filter(key => !exemptions.includes(key))
      .reduce((validations, key) => {
        validations[key] = {
          required,
          number
        };
        return validations;
      },
      {}
    );
    // these are special validations
    tankValidations.chartId = {
      required
    };
    tankValidations.tankStatus = {
      required
    };
    tankValidations.name = {
      required
    };
    tankValidations.product = {
      required
    };
    tankValidations.name = {
      required,
      isValid(nestedModel){
        if (this.tankNames) return !this.tankNames.filter(t => t != this.selectedTank.name.toLowerCase()).includes(nestedModel.toLowerCase());
        return false;
      }
    };
    return {
      tank: tankValidations
    };
  }
};
</script>

<style lang="scss">
.dialog-content-container {
  overflow-y: scroll;
  max-height: 60vh;
}
</style>
