import api from './api';
import { SHIP_COQ_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';

export const ERROR_GET_SHIP_COQ_LIST =
  'There was a problem trying to load the ship CoQ list';
export const ERROR_CREATE_SHIP_COQ =
  'There was a problem trying to create a ship CoQ';
export const ERROR_EDIT_SHIP_COQ =
  'There was a problem trying to edit the ship CoQ';
export const ERROR_DELETE_SHIP_COQ =
  'There was a problem trying to delete the ship CoQ';

export const SHIP_COQ_ACTIONS = {
  GET_SHIP_COQ_LIST: 'getShipCoQList',
  CREATE_SHIP_COQ: 'createShipCoQ',
  EDIT_SHIP_COQ: 'editShipCoQ',
  DELETE_SHIP_COQ: 'deleteShipCoQ'
}

export const actions = {
  async [SHIP_COQ_ACTIONS.GET_SHIP_COQ_LIST]({ commit, dispatch }, {
    planId
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      const response = await api.getShipCoQList(planId);
      commit(SHIP_COQ_MUTATIONS.SET_SHIP_COQ_LIST, response.data.data);
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-ship-coq-list',
        text: ERROR_GET_SHIP_COQ_LIST,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  async [SHIP_COQ_ACTIONS.CREATE_SHIP_COQ]({ commit, dispatch }, {
    planId,
    shipCoQ
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      const response = await api.createShipCoQ(
        planId,
        shipCoQ
      );
      shipCoQ.id = response.data.id;
      commit(SHIP_COQ_MUTATIONS.ADD_SHIP_COQ_LIST, response.data);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-create-ship-coq',
        text: ERROR_CREATE_SHIP_COQ,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  },
  async [SHIP_COQ_ACTIONS.EDIT_SHIP_COQ]({ commit, dispatch }, {
    planId,
    shipCoQ
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      const response = await api.updateShipCoQ(
        planId,
        shipCoQ
      );
      commit(SHIP_COQ_MUTATIONS.EDIT_SHIP_COQ_LIST, shipCoQ);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-edit-ship-coq',
        text: ERROR_EDIT_SHIP_COQ,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  },
  async [SHIP_COQ_ACTIONS.DELETE_SHIP_COQ]({ commit, dispatch }, {
    planId,
    shipCoQId
  }) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    let success = false;
    try {
      await api.deleteShipCoQ(
        planId,
        shipCoQId
      );
      commit(SHIP_COQ_MUTATIONS.DELETE_SHIP_COQ_LIST, shipCoQId);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-delete-ship-coq',
        text: ERROR_DELETE_SHIP_COQ,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  }
}