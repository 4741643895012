
export function convertMMToLitres(mm, tankTechnicalDetails) {
  // check first if the tank has tank calibration, if not use LoC
  if (tankTechnicalDetails.tankCalibrations && tankTechnicalDetails.tankCalibrations.length > 0) {
    const sortedTankCalibrations = [...tankTechnicalDetails.tankCalibrations].sort((a, b) => {
      return a.millimetres < b.millimetres ? -1 : 1
    });
    for (var i = 0; i < sortedTankCalibrations.length; i++) {
      if (sortedTankCalibrations[i].millimetres === mm) {
        return Math.ceil(sortedTankCalibrations[i].litres);
      }
      else if (sortedTankCalibrations[i].millimetres > mm) {
        // first entry which is the bottom of the tank so we default to the bottom liters
        if (i === 0) {
          return Math.ceil(sortedTankCalibrations[i].litres)
        } else {
          // calculate l/mm
          const literPerMM = (sortedTankCalibrations[i].litres - sortedTankCalibrations[i - 1].litres) /
            (sortedTankCalibrations[i].millimetres - sortedTankCalibrations[i - 1].millimetres);
          return Math.ceil(sortedTankCalibrations[i - 1].litres + ((mm - sortedTankCalibrations[i-1].millimetres) * literPerMM));
        }
      }
    }
    // that means the mm is over the tank calibration so we will use the last entry as reference
    const literPerMM = (sortedTankCalibrations[sortedTankCalibrations.length - 1].litres -
      sortedTankCalibrations[sortedTankCalibrations.length - 2].litres) /
      (sortedTankCalibrations[sortedTankCalibrations.length - 1].millimetres -
      sortedTankCalibrations[sortedTankCalibrations.length - 2].millimetres);
    return Math.ceil(sortedTankCalibrations[sortedTankCalibrations.length - 1].litres +
      ((mm - sortedTankCalibrations[sortedTankCalibrations.length - 1].millimetres) * literPerMM));
  }

  // use LoC, this is for older receipt plans or Terminals without Tank Calibrations yet
  const {
    tankBottomLevelMillimetres,
    tankBottomLevelLitres,
    slowPumpLevelMillimetres,
    litresPerMillimetre,
    slowPumpLevelLitres,
    operationalLevelMillimetres,
    operationalLevelLitres,
  } = tankTechnicalDetails;

  let litres;
  if (mm <= tankBottomLevelMillimetres) {
    litres = tankBottomLevelLitres;
  } else if (mm > tankBottomLevelMillimetres && mm < slowPumpLevelMillimetres) {
    litres = tankBottomLevelLitres + ((mm - tankBottomLevelMillimetres) * litresPerMillimetre);
  } else if (mm == slowPumpLevelMillimetres) {
    litres = slowPumpLevelLitres;
  } else if (mm > slowPumpLevelMillimetres && mm < operationalLevelMillimetres) {
    litres = slowPumpLevelLitres + ((mm - slowPumpLevelMillimetres) * litresPerMillimetre);
  } else if (mm == operationalLevelMillimetres) {
    litres = operationalLevelLitres;
  } else if (mm > operationalLevelMillimetres) {
    litres = operationalLevelLitres + ((mm - operationalLevelMillimetres) * litresPerMillimetre);
  }

  return litres;
}

export function convertLitresToMM(litre, tankTechnicalDetails) {
  // check first if the tank has tank calibration, if not use LoC
  if (tankTechnicalDetails.tankCalibrations && tankTechnicalDetails.tankCalibrations.length > 0) {
    const sortedTankCalibrations = [...tankTechnicalDetails.tankCalibrations].sort((a, b) => {
      return a.millimetres < b.millimetres ? -1 : 1
    });
    for (var i = 0; i < sortedTankCalibrations.length; i++) {
      if (sortedTankCalibrations[i].litres === mm) {
        return Math.ceil(sortedTankCalibrations[i].millimetres);
      }
      else if (sortedTankCalibrations[i].litres > litre) {
        // first entry which is the bottom of the tank so we default to the bottom mm
        if (i === 0) {
          return Math.ceil(sortedTankCalibrations[i].millimetres)
        } else {
          // calculate l/mm
          const literPerMM = (sortedTankCalibrations[i].litres - sortedTankCalibrations[i - 1].litres) /
            (sortedTankCalibrations[i].millimetres - sortedTankCalibrations[i - 1].millimetres);
          return Math.ceil(sortedTankCalibrations[i - 1].millimetres + ((litre - sortedTankCalibrations[i - 1].litres) / literPerMM));
        }
      }
    }
    // that means the liter is over the tank calibration so we will use the last entry as reference
    const literPerMM = (sortedTankCalibrations[sortedTankCalibrations.length - 1].litres -
      sortedTankCalibrations[sortedTankCalibrations.length - 2].litres) /
      (sortedTankCalibrations[sortedTankCalibrations.length - 1].millimetres -
        sortedTankCalibrations[sortedTankCalibrations.length - 2].millimetres);

    return Math.ceil(sortedTankCalibrations[sortedTankCalibrations.length - 1].millimetres +
      ((litre - sortedTankCalibrations[sortedTankCalibrations.length - 1].litres) / literPerMM));
  }
  // use LoC, this is for older receipt plans or Terminals without Tank Calibrations yet
  const {
    tankBottomLevelMillimetres,
    tankBottomLevelLitres,
    slowPumpLevelMillimetres,
    litresPerMillimetre,
    slowPumpLevelLitres,
    operationalLevelMillimetres,
    operationalLevelLitres,
  } = tankTechnicalDetails;
  let mm;
  if (litre <= tankBottomLevelLitres) {
    mm = tankBottomLevelMillimetres;
  } else if (litre > tankBottomLevelLitres && litre < slowPumpLevelLitres) {
    mm = tankBottomLevelMillimetres + Math.round((litre - tankBottomLevelLitres) / litresPerMillimetre);
  } else if (litre == slowPumpLevelLitres) {
    mm = slowPumpLevelMillimetres;
  } else if (litre > slowPumpLevelLitres && litre < operationalLevelLitres) {
    mm = slowPumpLevelMillimetres + Math.round((litre - slowPumpLevelLitres) / litresPerMillimetre);
  } else if (litre == operationalLevelLitres) {
    mm = operationalLevelMillimetres;
  } else if (litre > operationalLevelLitres) {
    mm = operationalLevelMillimetres + Math.round((litre - operationalLevelLitres) / litresPerMillimetre);
  }
  return mm;
}
