import api from './api';
import { PRE_ARRIVAL_INFO_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';

export const ERROR_GET_PRE_ARRIVAL_INFO =
  'There was a problem trying to load the pre arrival information details';
export const ERROR_UPDATE_PRE_ARRIVAL_INFO =
  'There was a problem trying to update the discharge\'s pre arrival information';
export const ERROR_GET_PRE_ARRIVAL_INFO_INIT_DATA =
  'There was a problem trying to load the pre arrival information details';

export const PRE_ARRIVAL_INFO_ACTIONS = {
  GET_PRE_ARRIVAL_INFO: 'getPreArrivalInfo',
  UPDATE_PRE_ARRIVAL_INFO: 'UpdatePreArrivalInfo',
  GET_PRE_ARRIVAL_INFO_INIT_DATA: 'getPreArrivalInfoInitData'
}

export const actions = {
  async [PRE_ARRIVAL_INFO_ACTIONS.GET_PRE_ARRIVAL_INFO](
    { commit },
    { planId }
  ) {
    let success = false;
    try {
      const response = await api.getPreArrivalInfo({
        planId
      });
      commit(PRE_ARRIVAL_INFO_MUTATIONS.SET_PRE_ARRIVAL_INFO, {
        preArrivalInfo: response.data
      });
      success = true;
    } catch (error) {
      // don't display error cause we need to get init data
    }
    return success;
  },
  async [PRE_ARRIVAL_INFO_ACTIONS.GET_PRE_ARRIVAL_INFO_INIT_DATA](
    { commit, dispatch },
    { planId }
  ) {
    let success = false;
    try {
      const response = await api.getPreArrivalInfoInitData({
        planId
      });
      commit(PRE_ARRIVAL_INFO_MUTATIONS.SET_PRE_ARRIVAL_INFO_INIT_DATA, {
        preArrivalInfoInitData: response.data
      });
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-pre-arrival-info-init-data',
        text: ERROR_GET_PRE_ARRIVAL_INFO_INIT_DATA,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [PRE_ARRIVAL_INFO_ACTIONS.UPDATE_PRE_ARRIVAL_INFO](
    { dispatch },
    { planId, preArrivalInfo }
  ) {
    let success = false;
    try {
      await api.updatePreArrivalInfo({
        planId, preArrivalInfo
      });
      success = true;
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-update-pre-arrival-info',
        text: 'Successfully updated pre arrival info',
      },
        { root: true }
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-update-pre-arrival-info',
        text: ERROR_UPDATE_PRE_ARRIVAL_INFO,
        error
      },
        { root: true }
      );
    }
    return success;
  }
}