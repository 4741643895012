export const PRODUCTS = [
  'AGO',
  'JETA1',
  'PMS',
  'RMS'
];

export const RECEIVING_COMPANIES = [
  'Z Energy', 
  'Z Energy North', 
  'Z Energy South', 
  'Z Energy - Site 1',
  'Z Energy - Site 2',
  'NZOSL', 
  'NZOSL HC', 
  'NZOSL SV', 
  'Mobil', 
  'TNZ (Gull)', 
  'TOSL'
];

export const SHIP_OR_SHORE_OPTIONS = [
  'Ship', 
  'Shore'
];

export const METHOD_OPTIONS = [
  'Cleared by following product (interfaced)', 
  'Shore suction pump - no air by ship',
  'N/A'
];

export const AGO_COLOR = '#ccffcc';
export const JETA1_COLOR = '#e6e6e6';
export const PMS_COLOR = '#ffcccc';
export const RMS_COLOR = '#ffffb3';
export const HIGHLIGHT_COLOR = '#e67300'

export const PRODUCTS_MIN = 1;
export const PRODUCTS_MAX = 9;