export const AGO_APPEARANCE_LIST = [
  { text: 'Haze 1: Clear & Bright', value: 'AGO_Haze1' },
  { text: 'Haze 2: Slight Haze', value: 'AGO_Haze2' },
  { text: 'Haze 3: Cloudy/Hazy', value: 'AGO_Haze3' },
  { text: 'Haze 4: Very Cloudy/Hazy', value: 'AGO_Haze4' },
]

export const PMS_APPEARANCE_LIST = [
  { text: 'Clear & Bright', value: 'PMS_ClearAndBright' },
  { text: 'Not Clear & Bright', value: 'PMS_NotClearAndBright' },
]

export const RMS_APPEARANCE_LIST = [
  { text: 'Clear & Bright', value: 'RMS_ClearAndBright' },
  { text: 'Not Clear & Bright', value: 'RMS_NotClearAndBright' },
]

export const LFO_APPEARANCE_LIST = [
  { text: 'Black with no visible free water', value: 'LFO_Black' },
  { text: 'Not Black and/or contains visible free water', value: 'LFO_NotBlack' },
]

export const JETA1_APPEARANCE_LIST = [
  { text: 'Clear & Bright - Free from solid matter & water', value: 'JETA1_ClearAndBright' },
  { text: 'Not Clear & Bright - Not Free from solid matter & water', value: 'JETA1_NotClearAndBright' },
]

export default [
  ...AGO_APPEARANCE_LIST,
  ...PMS_APPEARANCE_LIST,
  ...RMS_APPEARANCE_LIST,
  ...JETA1_APPEARANCE_LIST,
  ...LFO_APPEARANCE_LIST
];
