export const RECEIPT_PLAN_STATUS = {
  ACTIVE: 'Active',
  ABANDONED: 'Abandoned',
  COMPLETED: 'Completed'
};

export const DISABLED_STATUSES = [
  RECEIPT_PLAN_STATUS.COMPLETED,
  RECEIPT_PLAN_STATUS.ABANDONED
];
