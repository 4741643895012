import Vue from 'vue';
import Vuex from 'vuex';
// Shared
import { AUTH, auth } from './auth';
import { UI, ui } from './ui';
import { PAGINATION, pagination } from './pagination';
import { FEATURES, features } from './features';
import { TERMINALS, terminals } from './terminals';
import { CHECKLIST, checklist } from './checklists';
// Tanker Discharge / Shared
import { DISCHARGE_LOG, dischargeLog } from './tankerDischarge/shared/dischargeLog';
// Tanker Discharge / Pre-Arrival
import { SHIP_COQ, shipCoq } from './tankerDischarge/preArrival/shipCoq';
import { PRE_ARRIVAL_INFO, preArrivalInfo } from './tankerDischarge/preArrival/preArrivalInfo';
import { DISCHARGE_PLAN, dischargePlan } from './tankerDischarge/preArrival/dischargePlan';
// Tanker Discharge / Arrival
import { SHIP_SAMPLE, shipSample } from './tankerDischarge/arrival/shipSample';
// Tanker Discharge / Discharge
import { LINE_SAMPLE, lineSample } from './tankerDischarge/discharge/lineSample';
import { MONITORING_DETAILS, monitoringDetails } from './tankerDischarge/discharge/monitorDetails';
// Tanker Discharge / Post-Discharge
import { SHIP_PORT_PERFORMANCE, shipPortPerformance } from './tankerDischarge/postDischarge/shipPortPerformance';
import { QUALITY_REPORT, qualityReport } from './tankerDischarge/postDischarge/qualityReport';
// Tank To Tank / Pre-Arrival
import { TANK_TO_TANK_TRANSFER, tankToTankTransfer } from './tankToTankTransfer';
import { CARGO_PLAN, cargoPlan } from './tankerDischarge/preArrival/cargoPlan';
import { TIME_SCALE, timeScale } from './tankerDischarge/preArrival/timeScale';

// Terminal Data / Terminal Information
import { TERMINAL_INFORMATION, terminalInformation } from './terminalData/terminalInformation';
import { PIPELINE_INFORMATION, pipelineInformation } from './terminalData/pipelineInformation';
import { CARGO_DOCK_HOSE_INFORMATION, cargoDockHoseInformation } from './terminalData/cargoDockHoseInformation';
import { EQUIPMENT_INFORMATION, equipmentInformation } from './terminalData/equipmentInformation';

Vue.use(Vuex);

const store = {
  strict: process.env.NODE_ENV !== 'production',
  // todo - make the modules self registering.
  modules: {
    // Shared
    [AUTH.NAME_SPACE]: auth,
    [UI.NAME_SPACE]: ui,
    [PAGINATION.NAME_SPACE]: pagination,
    [FEATURES.NAME_SPACE]: features,
    [TERMINALS.NAME_SPACE]: terminals,
    [CHECKLIST.NAME_SPACE]: checklist,

    // Tanker Discharge / Shared
    [DISCHARGE_LOG.NAME_SPACE]: dischargeLog,

    // Tanker Discharge / Pre-Arrival
    [SHIP_COQ.NAME_SPACE]: shipCoq,
    [PRE_ARRIVAL_INFO.NAME_SPACE]: preArrivalInfo,
    [DISCHARGE_PLAN.NAME_SPACE]: dischargePlan,
    [CARGO_PLAN.NAME_SPACE]: cargoPlan,
    [TIME_SCALE.NAME_SPACE]: timeScale,

    // Tanker Discharge / Arrival
    [SHIP_SAMPLE.NAME_SPACE]: shipSample,
    
    // Tanker Discharge / Discharge
    [LINE_SAMPLE.NAME_SPACE]: lineSample, 
    [MONITORING_DETAILS.NAME_SPACE]: monitoringDetails,

    // Tanker Discharge / Post-Discharge
    [SHIP_PORT_PERFORMANCE.NAME_SPACE]: shipPortPerformance,
    [QUALITY_REPORT.NAME_SPACE]: qualityReport,
    
    // Tank To Tank / Pre-Arrival
    [TANK_TO_TANK_TRANSFER.NAME_SPACE]: tankToTankTransfer,

    // Terminal Data / Terminal Information
    [TERMINAL_INFORMATION.NAME_SPACE]: terminalInformation,

    // Terminal Data / Pipeline Information
    [PIPELINE_INFORMATION.NAME_SPACE]: pipelineInformation,

    // Terminal Data / Cargo Dock Hose Information
    [CARGO_DOCK_HOSE_INFORMATION.NAME_SPACE]: cargoDockHoseInformation,

    // Terminal Data / Equipment Information
    [EQUIPMENT_INFORMATION.NAME_SPACE]: equipmentInformation
  }
};

export default new Vuex.Store(store); 