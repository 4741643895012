import api from './api';
import { http } from '@/api';
import { TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';

export const TANK_TO_TANK_TRANSFER_PLAN_ACTIONS = {
  GET_TRANSFER_PLANS_BY_TERMINAL: 'getTransferPlansByTerminal',
  GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL: 'getTankTechnicalDetailsByTerminal',
  CREATE_TRANSFER_PLAN: "createTransferPlan",
  UPDATE_TANK_TO_TANK_TRANSFER_PLAN: "updateTransferPlan",
  GET_TANK_TO_TANK_TRANSFER_PLAN: 'getTankToTankTransferPlan',
  UPDATE_TANK_PLANS: "updateTankPlans",
  ABANDON_TRANSFER_PLAN: "abandonTransferPlan"
};

export const ERROR_GET_TRANSFER_PLANS_BY_TERMINAL =
  'There was a problem trying to get the tank to tank plans';
export const ERROR_GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL =
  'There was a problem trying to get the tanks technical details';
export const ERROR_CREATE_TRANSFER_PLAN = 
  "There was a problem trying to create the transfer plan";
export const ERROR_UPDATE_TANK_TO_TANK_TRANSFER_PLAN = 
  "There was a problem trying to update the tank to tank transfer";
export const ERROR_UPDATE_TANK_PLANS =
  "There was a problem trying to save the tank plans";
export const ERROR_LOAD_TANK_TO_TANK_TRANSFER_PLAN = 
  "There was a problem trying to load the tank to tank transfer";

export const actions = {
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.GET_TRANSFER_PLANS_BY_TERMINAL]({ commit, dispatch }, terminalId, ) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      let response = await api.getTransferPlansByTerminal(terminalId);
      commit(TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TRANSFER_PLAN_LIST, response.data);
    }
    catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          id: 'failed-load-transfer-plan-list',
          text: ERROR_GET_TRANSFER_PLANS_BY_TERMINAL,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL]({ commit, dispatch }, terminalId) {
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });
    try {
      let response = await api.getTankTechnicalDetailsByTerminal(terminalId);
      commit(TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TANKS_LIST, response.data);
    } 
    catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST),
        {
          id: 'failed-load-tank-technical-details-by-terminal',
          text: ERROR_GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL,
          error
        },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
  },
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.CREATE_TRANSFER_PLAN]( { dispatch }, { 
    terminalId,
    transferPlan
  }) 
  {
    let success = false;
    try {
      const response = await api.createTransferPlan(terminalId, transferPlan);
      // the response has the newly created transfer plan Id
      const hateoasGetUrl = response && response.headers && response.headers.location;

      if (hateoasGetUrl) {
        return await dispatch(TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.GET_TANK_TO_TANK_TRANSFER_PLAN, {
          id: null,
          hateoasGetUrl
        });
      }
      dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
      {
        id: 'successfully-created-transfer-plan',
        text: 'Successfully created transfer plan'
      },
      { root: true }
    );
    success = true;
    } catch (error) {
      dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
        {
          id: 'failed-create-transfer-plan',
          text: ERROR_CREATE_TRANSFER_PLAN,
          error
        },
        { root: true }
      );
      success = false;
    }
    return success;
  }, 
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.UPDATE_TANK_TO_TANK_TRANSFER_PLAN]( { state, commit, dispatch }, { 
      transferPlan
    })
    {
    let success = false;
    try {
      await api.updateTransferPlan(transferPlan.id, transferPlan);
      commit(TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TANK_TO_TANK_TRANSFER_PLAN, transferPlan);
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-updating-tank-to-tank',
        text: 'Successfully updated transfer plan'
      },
        { root: true }
      );
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-updating-tank-to-tank',
        text: ERROR_UPDATE_TANK_TO_TANK_TRANSFER_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.GET_TANK_TO_TANK_TRANSFER_PLAN](
    { state, commit, dispatch },
    { id, hateoasGetUrl }
  ) {
    let success = false;
    try {
      let response;

      if (id) {
        response = await api.getTankToTankTransferPlan(id);
      } else {
        // Temporarily replace http: with https:
        response = await http.get(hateoasGetUrl.replace('http:', 'https:'));
      }
      commit(TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TANK_TO_TANK_TRANSFER_PLAN, response.data);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-load-tank-to-tank',
        text: ERROR_LOAD_TANK_TO_TANK_TRANSFER_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.UPDATE_TANK_PLANS]({ state, commit, dispatch },
    { id, sourceTankPlanId, targetTankPlanId, lastSourceReleaseFormDate, lastTargetReleaseFormDate, sourceTankPlan, targetTankPlan }
  ){
    let success = false;
    try {
      await api.updateTankPlans(
        id,
        sourceTankPlanId,
        targetTankPlanId,
        lastSourceReleaseFormDate,
        lastTargetReleaseFormDate,
        sourceTankPlan,
        targetTankPlan
      );
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'success-updating-tank-plans',
        text: 'Successfully updated tank plans'
      },
        { root: true }
      );
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-updating-tank-plans',
        text: ERROR_UPDATE_TANK_PLANS,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [TANK_TO_TANK_TRANSFER_PLAN_ACTIONS.ABANDON_TRANSFER_PLAN](
    { state, commit, dispatch },
    { planId }
  ){
    let success = false;
    try {
      await api.abandonTransferPlan(planId);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-load-tank-to-tank',
        text: ERROR_UPDATE_TANK_TO_TANK_TRANSFER_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
};