<template>
  <v-card flat tile class="d-flex mb-2 pt-1">
    <v-card flat class="cell-container px-5" :class="{ 'cell-error': !!error }">
      <div class="cell-label">{{ label }}</div>

      <v-layout row wrap>
        <v-flex xs6>
          <v-text-field color="orange lighten-1"
            class="cell-column"
            label="Millimeters"
            :value="millimetres"
            type="number"
            @input="$emit('update-millimetres', $event)"
            :error-messages="millimetresErrorMessages"
            :id="idPrefix ? idPrefix + '_Mm_Input' : undefined" 
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-text-field color="orange lighten-1"
            class="cell-column float-right"
            label="Litres"
            :value="litres"
            type="number"
            @input="$emit('update-litres', $event)"
            :error-messages="litresErrorMessages"
            :id="idPrefix ? idPrefix + '_L_Input' : undefined" 
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    litresErrors: Object,
    millimetresErrors: Object,
    millimetres: Number | String,
    singleValue: {
      type: Number | String,
      default: null
    },
    litres: Number | String,
    label: String,
    error: String,
    idPrefix: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isSingleValue() {
      return this.isSingleValue != null;
    },
    millimetresErrorMessages() {
      if (!this.millimetresErrors) {
        return;
      }
      if (!this.millimetresErrors.number) {
        return 'Value must be a number';
      }
      if (!this.millimetresErrors.required) {
        return 'Value is required';
      }
      return null;
    },
    litresErrorMessages() {
      if (!this.litresErrors) {
        return;
      }
      if (!this.litresErrors.number) {
        return 'Value must be a number';
      }
      if (!this.litresErrors.required) {
        return 'Value is required';
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';

.cell-container {
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px 3px 0 0;
  border-bottom: 0px solid #888;
  text-align: center;
}
.cell-error {
  border-bottom: 1px solid $error-color !important;
}
.cell-label {
  width: 100%;
  font-size: 14px !important;
  margin-bottom: 10px;
  font-weight: bold;
}
.cell-error .cell-label {
  color: $error-color !important;
}
.cell-column {
  font-size: 16px;
  width: 90%;
  white-space: nowrap;
}
.float-right {
  float: right;
}
:deep(input::-webkit-outer-spin-button),
:deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
}
</style>

