import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
import { VueMaskDirective } from 'v-mask';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import Dropdown from 'vue-simple-search-dropdown';

import './plugins/vuelidate';
import "./plugins/vuethemask";
import './plugins/error-handler';
import vuetify from './plugins/vuetify';
import AutoRequireComponents from './plugins/auto-require-components';
import filters from "./plugins/filters";
import showMeasurments from './mixins/showMeasurments';

sync(store, router);

Vue.component('v-text-field', VTextField);
Vue.directive('mask', VueMaskDirective);
Vue.use(VCurrencyField, {
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
});

Vue.mixin(showMeasurments);

Vue.use(AutoRequireComponents, {
  requireComponent: require.context(
    './components',
    true, // include subfolders?
    /[A-Z]\w+\.(vue|js|ts)$/
  )
});
Vue.use(filters);

Vue.use(Dropdown);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
