<template>
   <v-dialog max-width="650" v-model="showDialog" persistent>
    <v-card>
      <v-card-title class="headline">Release Tank</v-card-title>
      <v-divider class="divider"/>
      <v-card-text>
          <v-layout fill-height fluid>
            <v-col class="popup-input ma-0 pa-0">
              <v-text-field 
                label="Name" 
                color="orange lighten-1" 
                id="nameInput" 
                v-model="responsiblePersonsName"
                @input="$v.responsiblePersonsName.$touch()"
                :error-messages="responsiblePersonsNameErrors"/>
              <v-text-field 
                label="Position Title" 
                color="orange lighten-1" 
                id="positionTitleInput" 
                :error-messages="responsiblePersonsPositionTitleErrors"
                v-model="responsiblePersonsPositionTitle"/>
              <v-text-field 
                label="Batch Number"
                v-model="batchNumber"
                color="orange lighten-1"
                :error-messages="batchNumberErrors"
                @input="$v.batchNumber.$touch()"
                id="batchNumberInput"/>
              <v-text-field 
                label="Date & time of tank release"
                v-model="date"
                color="orange lighten-1"
                v-mask="'####-##-## ##:##'"
                placeholder="yyyy-MM-dd HH:mm"
                :error-messages="dateErrors"
                @input="$v.date.$touch()"
                id="releaseTankDateInput"/>
            </v-col>
            <v-col  >
              <v-container class="fill-height ma-0 pa-0">
                <v-textarea 
                v-model="notesForPrintedDocument" 
                hide-details="auto" 
                label="Printed document notes" 
                rows="9" 
                outlined 
                :error-messages="notesForPrintedDocumentErrors"
                color="orange lighten-1" 
                id="notesForPrintedDocumentInput"/>
              </v-container>
            </v-col>
          </v-layout>
        </v-card-text>
          <v-card-actions class="mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn  color="secondary lighten-3" @click="() => this.$emit('close')" id="cancelButton">Cancel</v-btn>
          <v-btn color="orange lighten-1" @click="onSave" id="saveButton"  :disabled="$v.$invalid">Release</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import { QUALITY_REPORT } from '@/store/tankerDischarge/postDischarge/qualityReport';
import { formatDateTimeToMasked } from '@/helpers/date';
import { maskedDatetime } from '@/validators';
import { required } from 'vuelidate/lib/validators';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";
import maxLength from 'vuelidate/lib/validators/maxLength'
import { mapState } from "vuex";

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showDialog: {
      immeditate: true,
      handler(showDialog) {
          if(showDialog) {
           this.setDefaults();
          }
      }
    }
  },
  data() {
    return {
        date: new Date(),
        notesForPrintedDocument: '',
        responsiblePersonsName: '',
        responsiblePersonsPositionTitle: '',
        batchNumber: ''
    }
  },
  computed: {
    dateErrors(){
      if (!this.$v.date.required || !this.$v.date.maskedDatetime) {
        return ['A valid date is required (yyyy-MM-dd HH:mm)'];
      }
      if(!this.$v.date.isValid){
          return "Date cannot be in the future"
      }
      return [];
    },
    responsiblePersonsPositionTitleErrors(){
      if(!this.$v.responsiblePersonsPositionTitle.required) return ['A position title is required'];
      if(!this.$v.responsiblePersonsPositionTitle.maxLength) return ['Position title must be shorter than 50 characters long']
      return [];
    },
    responsiblePersonsNameErrors(){
      if(this.$v.responsiblePersonsName.$dirty){
      if(!this.$v.responsiblePersonsName.required) return ['A name is required'];
      if(!this.$v.responsiblePersonsName.maxLength) return ['Name must be shorter than 50 characters long']
      }
     
      return [];
    },
    batchNumberErrors(){
      if(this.$v.batchNumber.$dirty){
      if(!this.$v.batchNumber.required) return ['Batch number is required'];
      if(!this.$v.batchNumber.maxLength) return ['Batch number must be shorter than 50 characters long']
      }
      return [];
    },
    notesForPrintedDocumentErrors(){
      if(!this.$v.notesForPrintedDocument.maxLength) return ['The notes must be shorter than 500 characters long']
      return [];
    },
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    receiptPlanId() {
      return this.$route.params && this.$route.params.receiptPlanId;
    },
    tankPlanId() {
      return this.$route.params && this.$route.params.tankPlanId;
    },
    ...mapState({
      qualityReport: (state) => state.qualityReport && state.qualityReport.qualityReport,
      voyage: (state) => state.dischargePlan && state.dischargePlan.dischargePlan && state.dischargePlan.dischargePlan.voyage
    })    
  },
  methods: {
    setDefaults(){
      this.$v.$reset();
      this.date = formatDateTimeToMasked(new Date(), DATE_TIME_FORMAT);
      this.notesForPrintedDocument = this.qualityReport.notesForPrintedDocument;
      this.responsiblePersonsName = '';
      this.responsiblePersonsPositionTitle = 'Testing Officer';
      this.batchNumber = this.qualityReport.batchNumber || this.voyage;
    },
    async onSave(){
        const success = await this.$store.dispatch(
          QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.RELEASE_TANK),
          {
            planId: this.planId,
            receiptPlanId: this.receiptPlanId,
            tankPlanId: this.tankPlanId,
            releaseDetails: {
              date: new Date(this.date),
              notesForPrintedDocument: this.notesForPrintedDocument,
              responsiblePersonsName: this.responsiblePersonsName,
              responsiblePersonsPositionTitle: this.responsiblePersonsPositionTitle,
              batchNumber: this.batchNumber
            }
          }
        );
        if(success) {
          await this.$store.dispatch(
            QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.GET_QUALITY_REPORT),
            { 
              tankPlanId: this.tankPlanId,
              planId: this.planId, 
              receiptPlanId: this.receiptPlanId
            });
          this.$emit('close');
        }
    }
  },
  validations: {
    date: {
      required,
      maskedDatetime,
      isValid: function(value) {
          return new Date(value) <= new Date();
      }
    },
    responsiblePersonsName: {
      required,
      maxLength: maxLength(50)
    },
    responsiblePersonsPositionTitle: {
      required,
      maxLength: maxLength(50)
    },
    notesForPrintedDocument: {
      maxLength: maxLength(500)
    },
    batchNumber: {
      required,
      maxLength: maxLength(50)
    }
  }
}
</script>
<style scoped>

</style>