import { http } from '@/api';

export default {
    getEquipmentInformationByTerminal: (terminalId) => http.get(`/v1/equipmentInformation/${terminalId}`),
    updateEquipmentDetails: (equipmentInformationId, equipmentInformation) => http.put(
        `v1/equipmentInformation/${equipmentInformationId}`, {
        EquipmentInformation: equipmentInformation
    }),
    createNewEquipmentDetails: (terminalId, equipmentDetails) => http.post(`v1/equipmentInformation/newequipmentinformation`,
    {
        TerminalId: terminalId,
        Model: equipmentDetails
    })
};