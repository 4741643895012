<template>
  <v-list expand nav v-if="show">
    <template v-for="(item, i) in routes">
      <v-list-group :key="i" no-action :group="handleRoute(item.path)"
          color="orange" v-show="isAllowed(item.meta.display)">
            <v-icon slot="prependIcon" class="ma-0" >{{item.meta.icon}}</v-icon>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="menu-header-item pl-0" v-text="item.meta.title"/>
          </v-list-item-content>
        </template>
        <template v-for="(subItem, j) in item.children">
          <v-list-item v-if="!subItem.children" color="orange"
            :key="j" :to="handleRoute(item.path + '/' + subItem.path)" :class="[pageName === subItem.name ? 'v-list-item--active' : 
                          (!isAllowed(subItem.meta.display) ? 'v-list-item--disabled' : '') ]"
            :disabled="!isAllowed(subItem.meta.display)">
            <v-list-item-content>      
              <v-list-item-title v-text="subItem.meta.title" class="menu-item"/>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :key="j" :group="subItem.path"
              sub-group no-action color="orange">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="menu-item" v-text="subItem.meta.title"/>
              </v-list-item-content>
            </template>
            <template v-for="(subSubItem, k) in subItem.children">
              <v-list-item v-if="!subSubItem.children" :key="k" :to="handleRoute(item.path + '/' + subItem.path + '/' + subSubItem.path)"
                  v-show="isAllowed(subSubItem.meta.display)" color="orange" :value="subSubItem"
                  :class="[pageName === subSubItem.name ? 'v-list-item--active' : '']">
                <v-list-item-content>      
                  <v-list-item-title v-text="subSubItem.meta.title"/>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { ROUTE_NAMES, DISPLAY_BREADCRUMB } from '@/routes';
import { UI } from '@/store/ui';
import { DISCHARGE_PLAN } from '@/store/tankerDischarge/preArrival/dischargePlan';

export default Vue.extend({
  watch: {
    'isReceiptPlan': {
      handler: function (data) {
        if (!data) {
          Object.assign(this.$data, this.setDefaultData());
        }
      }
    }
  },
  methods: {
    setDefaultData() {
      this.setLastTankId(null),
      this.setLastReceiptPlanId(null),
      this.setLastPipelineProduct(null)
    },
    isAllowed(key) {
        if (!key) return false;

        if (key === DISPLAY_BREADCRUMB.PREARRIVAL || key === DISPLAY_BREADCRUMB.ARRIVAL || 
                    key === DISPLAY_BREADCRUMB.DISCHARGE || key === DISPLAY_BREADCRUMB.POSTDISCHARGE) return true;
        
        if (key === DISPLAY_BREADCRUMB.ACTIVETANKS) return this.activeTanks && this.activeTanks.length > 0;
        
        if (key === DISPLAY_BREADCRUMB.ACTIVETANKTABS) return this.activeTankPlanId && this.activeReceiptPlanId;

        if (key === DISPLAY_BREADCRUMB.PREARRIVALFORMS) return this.$route && this.$route.name && this.$route.meta.showPreArrivalForms;
        
        if (key === DISPLAY_BREADCRUMB.LINESAMPLINGMENU) return this.activeProducts && this.activeProducts.length > 0;

        if (key === DISPLAY_BREADCRUMB.POSTDISCHARGEFORMS) return this.$route && this.$route.name && this.$route.name === ROUTE_NAMES.POST_DISCHARGE_FORMS;

        if (key === DISPLAY_BREADCRUMB.ACTIVELINESAMPLINGPRODUCT) return this.$route.meta.showProductTabs && this.activeProducts && this.activeProducts.length > 0;
    },
    handleRoute(path) {
      if (path.includes(":terminalId")) path = path.replace(":terminalId", this.activeTerminalId);

      if (path.includes(":planId")) path = path.replace(":planId", `${this.dischargePlanId}`);
        
      if (path.includes(":receiptPlanId")) {
        if (this.dischargePlan && this.dischargePlan.terminalId && 
                !this.lastReceiptPlanId && this.activeTanks && this.activeTanks.length > 0) {
          this.setLastReceiptPlanId(this.activeTanks[0].receiptPlanId);
        }
        path = path.replace(":receiptPlanId", `${this.lastReceiptPlanId}`);
      }
      
      if (path.includes(":tankPlanId")) {
        if (this.dischargePlan && this.dischargePlan.terminalId && 
                !this.lastTankId && this.activeTanks && this.activeTanks.length > 0) {
          this.setLastTankId(this.activeTanks[0].id);
        }
        path = path.replace(":tankPlanId", `${this.lastTankId}`);
      }

      if (path.includes(":product")) {
        if (this.dischargePlan && this.dischargePlan.terminalId && 
                this.lastPipelineProduct === null && this.activeProducts && this.activeProducts.length > 0) {
          this.setLastPipelineProduct(this.activeProducts[0]);
        }
        path = path.replace(":product", `${this.lastPipelineProduct}`);
      }

      if (this.$route.path !== path) return path;
    },
    setLastTankId(id) {
      this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_TANK_ID), id);
    },
    setLastReceiptPlanId(id) {
      this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_RECEIPT_PLAN_ID), id);
    },
    setLastPipelineProduct(product) {
      this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_PIPELINE_PRODUCT), product);
    }
  },
  computed: {
    show(){
      return this.$route.name != ROUTE_NAMES.RECEIPT_PLAN_LIST;
    },
    lastTankId() {
      return this.$store.getters[UI.NS(UI.GETTERS.LAST_TANK_ID)];
    },
    lastReceiptPlanId() {
      return this.$store.getters[UI.NS(UI.GETTERS.LAST_RECEIPT_PLAN_ID)];
    },
    lastPipelineProduct() {
      return this.$store.getters[UI.NS(UI.GETTERS.LAST_PIPELINE_PRODUCT)];
    },
    isReceiptPlan() {
      return this.$route.meta && this.$route.meta.receiptPlan;
    },
    dischargePlanId() {
      return this.$route.params && this.$route.params.planId;
    },
    isLoading() {
      return this.$store.getters[UI.NS(UI.GETTERS.IS_LOADING)];
    },
    receiptPlans() {
      if (this.activeTerminalId && this.dischargePlanId && !this.dischargePlan.receiptPlans) {
        this.$store.dispatch(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
            { id: this.dischargePlanId }
        );
      }
      return this.dischargePlan && this.dischargePlan.receiptPlans;
    },
    hasPlannedReceipt() {
      return this.receiptPlans &&
        this.receiptPlans[this.activeReceiptPlanId] &&
        this.receiptPlans[this.activeReceiptPlanId].tankPlans[this.activeTankPlanId] &&
        this.receiptPlans[this.activeReceiptPlanId].tankPlans[this.activeTankPlanId].plannedReceipt &&
        this.receiptPlans[this.activeReceiptPlanId].tankPlans[this.activeTankPlanId].plannedReceipt > 0;
    },
    activeTanks() {
      // active tanks are tanks with receipt plan or pipeline adjustments
      return (
        this.receiptPlans &&
        Object.values(this.receiptPlans)
          .reduce(
            (tanks, receiptPlan) => tanks.concat(
                Object.values(receiptPlan.tankPlans).filter(
                  tank => !!tank.plannedReceipt || !!tank.pipelineAdjustments
                )
              ),
            []
          )
      );
    },
    activeProducts() {
      return (this.receiptPlans &&
        Object.values(this.receiptPlans)
          .reduce(
            (tanks, receiptPlan) => tanks.concat(
                Object.values(receiptPlan.tankPlans).filter(
                  tank => !!tank.plannedReceipt || !!tank.pipelineAdjustments
                ).map(tank => tank.product.toLowerCase())
              ),
            []
          ).filter((v, i, s) => s.indexOf(v) === i) // make the product list unique
      );
    },
    terminalName() {
      return this.dischargePlan && this.dischargePlan.terminalName;
    },
    pageName() {
      return this.$route.name;
    },
    routes: {
      get() {
          return this.$router.options.routes.filter(t => t.name === ROUTE_NAMES.TANKER_DISCHARGE)[0].children;
      }
    },
    ...mapState({
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
      lastTankId: state => state.ui.lastTankId,
      lastReceiptPlanId: state => state.ui.lastReceiptPlanId,
      lastPipelineProduct: state => state.ui.lastPipelineProduct,
      activeTerminalId: state => state.terminals && state.terminals.activeTerminal && state.terminals.activeTerminal.id
    })
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_variables.scss';

.v-list-item--disabled {
  color: rgb(94, 94, 94) !important;
}
</style>