import { http } from '@/api';

export default {
  getCargoPlanByDischargeId: dischargePlanId => http.get(`/v1/cargoplan/${dischargePlanId}/cargoplan`),
  updateCargoPlan: (dischargePlanId, cargoPlan) => http.put(
    `/v1/cargoplan/${dischargePlanId}/updatecargoplan`, {
      cargoPlanModel: cargoPlan
    }
  ),
  getCargoPlanPdf: (dischargeId) => http.get(
    `/v1/cargoplan/${dischargeId}/cargoplan/pdf`, {
      responseType: 'arraybuffer'
    }
  )
}