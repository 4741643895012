<template>
  <v-container>
    <v-flex v-if="dischargePlan" xs5 pa-2>
      <v-text-field color="orange lighten-1"
        class="input-width"
        label="Terminal"
        :value="dischargePlan.terminalName"
        :readonly="true"
      />
    </v-flex>
    <tanker-discharge-receipt-plan-info />
    <v-flex v-if="receiptPlans">
      <div class="scroll-container">
        <v-layout fluid fill-height align-start>
          <v-flex
            v-for="receiptPlan in receiptPlans"
            v-bind:key="receiptPlan.id"
            class="header-margin"
          >
            <v-flex xs12 class="upper-case">
              <h3 class="product-header" :style="{
                  color:mapProductToColor(receiptPlan.product),
                  backgroundColor: mapProductToColor(receiptPlan.product, true),
                  border: `1px solid ${mapProductToColor(
                    receiptPlan.product,
                    true,
                    true
                  )}`,
                  borderRadius: '1px'
                }">
                  {{receiptPlan.product}}
              </h3>
            </v-flex>
            <v-layout>
              <v-flex
                xs12
                v-for="tank in filterTankPlans(receiptPlan.tankPlans)"
                v-bind:key="tank.id"
              >
                <template class="wrapper">
                  <h3 class="product-header" :style="{
                    color:mapProductToColor(receiptPlan.product),
                    backgroundColor: mapProductToColor(receiptPlan.product, true),
                    border: `1px solid ${mapProductToColor(
                      receiptPlan.product,
                      true,
                      true
                    )}`,
                    borderRadius: '1px'
                  }">
                    {{tank.name}}
                  </h3>
                  <levels-of-concern-receipt-plan-cell
                    label="Opening Balance"
                    :millimetres="tank.preDischargeMillimetres"
                    :litres="tank.preDischargeLitres"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Closing Balance"
                    :millimetres="tank.postDischargeMillimetres"
                    :litres="tank.postDischargeLitres"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Total Received Volume"
                    :millimetres="totalReceivedMM(tank)"
                    :litres="totalReceivedLitres(tank)"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Open Time"
                    :singleValue="tank.dischargeMonitoringDetails[0].createdOn | formatDateTime"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="End Time"
                    :singleValue="tank.dischargeMonitoringDetails[tank.dischargeMonitoringDetails.length -1].createdOn | formatDateTime"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Pipeline Volume"
                    :litres="doPipelineAdjustments(tank.pipelineAdjustments)"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Tank Adjustment"
                    :litres="tank.tankAdjustments"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Product Adjustment"
                    :litres="tank.productAdjustments"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Adjustment Comments"
                    :singleValue="tank.adjustmentComments"
                  />
                </template>
                  <levels-of-concern-receipt-plan-cell
                    label="Pre-Discharge Density"
                    :singleValue="tank.qualityReport && tank.qualityReport.preDischargeDensity | toDecimalPlaces(densityPrecision)"
                  />
                  <levels-of-concern-receipt-plan-cell
                    label="Ship Density"
                    :singleValue="tank.qualityReport && tank.qualityReport.shipDensity | toDecimalPlaces(densityPrecision)"
                  />
                  <levels-of-concern-receipt-plan-cell
                    v-if="showConductivity(receiptPlan.product)"
                    label="Conductivity"
                    :singleValue="tank.qualityReport && tank.qualityReport.testConductivity"
                  />
                  <template v-if="showFlashPoint(receiptPlan.product)">
                    <levels-of-concern-receipt-plan-cell
                      label="Pre Flash Point"
                      :singleValue="tank.qualityReport && tank.qualityReport.preDischargeFlashPoint | toDecimalPlaces(flashpointPrecision)"
                    />
                    <levels-of-concern-receipt-plan-cell
                      label="Ship Flash Point"
                      :singleValue="tank.qualityReport && tank.qualityReport.shipFlashPoint | toDecimalPlaces(flashpointPrecision)"
                    />
                  </template>              
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </v-container>
</template>
 

<script>
import { 
  DISCHARGE_PLAN 
} from '@/store/tankerDischarge/preArrival/dischargePlan';
import { mapProductToColor, mapProductToTextColor } from '../../../helpers/ColorMapper';
export default {
  async mounted() {
    await this.$store.dispatch(
      DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
      { id: this.planId }
    );
  },
  data() {
    return {
        densityPrecision: 4,
        flashpointPrecision: 1
    }
  },
  computed: {
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    receiptPlanId() {
      return this.$route.params && this.$route.params.receiptPlanId;
    },
    tankPlanId() {
      return this.$route.params && this.$route.params.tankPlanId;
    },
    dischargePlan() {
      return (
        this.$store.state.dischargePlan &&
        this.$store.state.dischargePlan.dischargePlan
      );
    },
    receiptPlan() {
      return (
        (this.dischargePlan &&
          this.dischargePlan.receiptPlans &&
          this.dischargePlan.receiptPlans[this.receiptPlanId]) ||
        {}
      );
    },
    dischargeMonitoringDetails() {
      return (
        this.receiptPlan.tankPlans[this.tankPlanId] &&
        this.receiptPlan.tankPlans[this.tankPlanId].dischargeMonitoringDetails
      );
    },
    receiptPlans() {
      // Display only receipt plans with plannedReceipt or pipeline adjustments
      return (
        this.dischargePlan &&
        this.dischargePlan.receiptPlans &&
        Object.values(this.dischargePlan.receiptPlans).filter(function (receiptPlan) {
          var tanksWithDischarge = Object.values(receiptPlan.tankPlans).filter(function (tank) {
            return !!tank.plannedReceipt || !!tank.pipelineAdjustments;
          })
          return tanksWithDischarge && tanksWithDischarge.length > 0;
        })   
      );
    }
  },
  methods: {
    mapProductToColor(product, background = false, border = false) {
      if (!product) return;
      if (background) return mapProductToColor(product, border);
      return mapProductToTextColor(product);
    },
    filterTankPlans(tankPlans) {
      // return only tankPlans with plannedReceipt or pipelineAdjustment and at
      // least 2 monitoring details (start, end)
      return (
        tankPlans &&
        (Object.values(tankPlans).filter(tank => (!!tank.plannedReceipt || 
          !!tank.pipelineAdjustments) &&
          tank.dischargeMonitoringDetails.length >= 2) || [])
      );
    },
    totalReceivedMM(tank) {
      return (
        tank.postDischargeMillimetres &&
        tank.preDischargeMillimetres &&
        tank.postDischargeMillimetres - tank.preDischargeMillimetres
      );
    },
    totalReceivedLitres(tank) {
      return (
        tank.postDischargeLitres &&
        tank.preDischargeLitres &&
        tank.postDischargeLitres - tank.preDischargeLitres
      );
    },
    doPipelineAdjustments(value) {
      // we display opposite of the set value in summary
      return value * -1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
.upper-case {
  text-transform: uppercase;
}
.wrapper {
  min-height: 0% !important;
  margin-bottom: 5px !important;
}

.scroll-container {
  overflow-x: auto !important;
}

.header-margin {
  margin-left: 10px;
}

.product-header {
  text-align: center;
}
</style>
