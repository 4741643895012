import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';

export const TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS = {
    SET_TRANSFER_PLAN_LIST: 'setTankToTankTransferPlanList',
    SET_TANKS_LIST: 'setTanksList',
    SET_TANK_TO_TANK_TRANSFER_PLAN: 'setTankToTankTransferPlan',
    SET_TANK_TO_TANK_TRANSFER_PLAN_LIST_TOTAL: 'setTankToTankTransferPlanListTotal'
};

export const mutations = {
  [TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TRANSFER_PLAN_LIST](state, tankToTankPlans) {
    Vue.set(state, 'transferPlans', tankToTankPlans);
  },
  [TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TANKS_LIST](state, tanks) {
    Vue.set(state, 'tanksList', tanks);
  },
  [TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TANK_TO_TANK_TRANSFER_PLAN](state, tankToTankTransferPlan) {
    Vue.set(state, 'tankToTankTransferPlan', cloneDeep(tankToTankTransferPlan));
  },
  [TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS.SET_TANK_TO_TANK_TRANSFER_PLAN_LIST_TOTAL](state, total) {
    state.tankToTankTransferPlanList.total = total;
  },
};
