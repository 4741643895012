import { http } from '@/api';

export default {
  getChecklist: ({ checklistType, planId, checklistId, sectionId }) => http.get(
    `v1/checklists/checklistTypeId/${checklistType}/planId/${planId}/checklist/${checklistId}/section/${sectionId}`
  ),
  updateChecklist: ({ checklistType, planId, submissionResults }) => http.post(
      `v1/checklists/checklistTypeId/${checklistType}/planId/${planId}`, {
        checklistSubmissionResults: submissionResults
    }
  )
}