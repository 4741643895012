import { parseDate } from '@/helpers/date';
import { 
  AGO_COLOR,
  JETA1_COLOR,
  PMS_COLOR,
  RMS_COLOR,
} from '@/constants/cargoPlanDropdown.js';

export function DEFAULT_TIMESCALE_TASK() {
  return {
    id: 0,
    label: '',
    start: 1709244000000,
    end: 1709244000001,
    product: '',
    taskDuration: 0,
    progress: 100,
    type: 'task',
  };
}

export function DEFAULT_TIMESCALE_OPTIONS() {
  return {
    times: {
      timeZoom: 3.2
    },
    scope: {
      before: 0,
      after: 0
    },
    maxRows: 100,
    maxHeight: 5000,
    title: {
      label: "Planning Time Scale",
      html: false
    },
    row: {
      height: 50
    },
    calendar: {
      hour: {
        display: true
      }
    },
    taskList: {
      expander: {
        straight: true
      },
      columns: [
        {
          id: 1,
          label: "Activity",
          value: "label",
          width: 175
        },
        {
          id: 2,
          label: "Product",
          value: "product",
          width: 75,
        },
        {
          id: 3,
          label: "Duration",
          value: "taskDuration",
          width: 75
        },
        
      ]
    },
    locale: {
      name: 'en',
      weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split(
        '_'
      ),
      weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
      weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
      months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
        '_'
      ),
      monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
      weekStart: 1,
      
    },
  };
}

export function getDate(dateTime) {
  var dateTimeSplit = dateTime.split('T');
  var date = parseDate(dateTimeSplit[0], 'yyyy-MM-dd').getTime();
  var timeSplit = dateTimeSplit[1].split(':');
  return new Date(date + (timeSplit[0] * 60 *60 * 1000) + (timeSplit[1] * 60 * 1000)).getTime();
} 

function getProductColor(product) {
  if (product == 'AGO') {
    return AGO_COLOR;
  }
  else if (product == 'JETA1') {
    return JETA1_COLOR;
  }
  else if (product == 'PMS') {
    return PMS_COLOR;
  }
  else if (product == 'RMS') {
    return RMS_COLOR;
  }
}