import { http } from '@/api';

export default {
    getPipelineInformationByTerminal: (terminalId) => http.get(`/v1/pipelineInformation/${terminalId}`),
    updatePipelineDetails: (pipelineInformationId, pipelineInformation) => http.put(
        `v1/pipelineInformation/${pipelineInformationId}`, {
        PipelineInformation: pipelineInformation
    }),
    createNewPipelineDetails: (terminalId, pipelineDetails) => http.post(`v1/pipelineInformation/newpipelineinformation`,
    {
        TerminalId: terminalId,
        Model: pipelineDetails
    })
};