import { req } from "vuelidate/lib/validators/common";
import { parse, isValid } from "date-fns";

const DATE_FORMAT = "yyyy-MM-dd";

export default (value) => {
  // Check if a value has been passed in
  if (!req(value)) {
    return true;
  }

  // If it's a string
  if (typeof value === "string" || value instanceof String) {
    // Check a full date string as been supplied
    if (value.length !== DATE_FORMAT.length) {
      // When the dates come back from the API, they can be in a different
      // format to what we expect. To handle this, allow the validator
      // to test if string if the value is longer than the expected format.
      if (value.length > DATE_FORMAT.length) {
        // custom date format
        try {
          return isValid(new Date(value));
        } catch (err) {
          return false;
        }
      }

      return false;
    }

    const date = parse(value, DATE_FORMAT, new Date());
    return isValid(date);
  }

  return isValid(value);
};
