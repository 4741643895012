import { http } from '@/api';

export default {
  createShipSample: (planId, shipSample) => http.post(
    `/v1/plans/${planId}/shipsamples`,
    {
      model: shipSample
    }
  ),
  updateShipSample: (planId, shipSample) => http.put(
      `/v1/plans/${planId}/shipsamples`, {
        model: shipSample
    }
  ),
  getShipSampleList: (planId) => http.get(
    `/v1/plans/${planId}/shipsamples`
  ),
  deleteShipSample: (planId, shipSampleId) => http.delete(
    `/v1/plans/${planId}/shipsamples/${shipSampleId}`
  ),
  getShipSamplePdf: ({ planId, shipSampleIds }) => http.post(
    `v1/plans/${planId}/shipsamples/pdf`, {planId, shipSampleIds}, { responseType: 'arraybuffer' }
  )
}