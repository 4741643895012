<template>
  <v-container fluid pt-8 px-3 class="menu-font"> 
    <v-layout row wrap dense justify-space-between>
      <v-flex v-for="operation in operations"
          :key="operation.value" v-bind="{ [`xs4`]: true }">
        <v-hover>
          <v-card slot-scope="{ hover }"
                :class="`elevation-${hover ? 12 : 2}`" 
                class="my-4 mx-auto"
                shaped max-width="400">
              <v-img class="white--text align-end" contain shaped
                        :src="operation.img" min-width="400px" min-height="200px"
                        max-height="200px" max-width="400px"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        v-on:click="goToOperation(operation.value)">
                    <v-card-title>{{operation.text}}</v-card-title>
              </v-img>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { UI } from '@/store/ui';
import { ROUTE_NAMES } from '@/routes';

export default Vue.extend({
  mounted() {
    this.resetOperationId();
  },
  data() {
    return {
        operations: [
          { value: 0, text: 'Tanker Receipt', img: '/images/MarineTanker.png', name: `${ROUTE_NAMES.RECEIPT_PLAN_LIST}`},
          { value: 1, text: 'Tank to Tank Transfer', img: '/images/TankToTankDischarge.png', name: `${ROUTE_NAMES.TANK_TO_TANK_RECEIPT_PLAN_LIST}` },
          { value: 2, text: 'Levels of Concern', img: '/images/Warning.png', name: `${ROUTE_NAMES.LEVELS_OF_CONCERN}` },
          { value: 3, text: 'Terminal Data', img: '/images/Data.png', name: `${ROUTE_NAMES.TERMINAL_DATA_TERMINAL_INFORMATION}` }
        ]
    };
  },
  computed: {
      isLoading() {
          return this.$store.getters[UI.NS(UI.GETTERS.IS_LOADING)];
      },
      ...mapState({
          isLoading: state => state.ui.isLoading
      })
    },
    methods: {
      resetOperationId() {
        this.$store.commit(
          UI.NS(UI.MUTATIONS.RESET_DEFAULT_OPERATION_ID)
        );
      },
      goToOperation(operationId) {
        this.$store.commit(
          UI.NS(UI.MUTATIONS.TOGGLE_OPERATION_ID),
          operationId
        );
        this.$router.push({
          name: this.operations[operationId].name
        });
      }
    }
});
</script>

<style lang="scss" scoped>
  .resultContainer {
    height: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .item {
    min-height: 50px;
    min-width: 80px;
    max-width: 85%;
  }
  .v-list-item__title {
    font-size: 80px !important;
  }
</style>