export default {
  OVERFILL: 'OVERFILL DAMAGE LEVEL',
  HIGH_HIGH_LEVEL: 'HIGH HIGH LEVEL (ALARM)',
  HIGH_LEVEL: 'HIGH LEVEL (ALARM)',
  SAFEFILL_LEVEL: 'SAFE FILL LEVEL - API',
  OPERATIONAL_LEVEL: 'NORMAL/SAFE FILL LEVEL',
  OPERATOR_LEVEL_ALARM: 'OPERATOR LEVEL (ALERT)',
  SLOW_PUMP_LEVEL: 'SLOW PUMPING LEVEL',
  LOW_LEVEL: 'LOW LEVEL (ALERT)',
  LOW_LOW_LEVEL: 'DESIGN LOW LEVEL & LOW LOW LEVEL (ALARM)',
  TANK_BOTTOM_LEVEL: 'Tank Heel (Deadstock) volume',
  MAX_FLOW_RATE_PER_MINUTE: 'Max Flow Rate Per Minute',
  MAX_SLOW_PUMPING_FLOW_RATE_PER_MINUTE: 'Max Slow Pumping Flow Rate - Litres Per Minute',
  LITRES_PER_MILLIMETRE: 'Litres Per Millimetre',
  IFRW: 'IFRW (KG)',
  TANK_STATUS: 'Tank Status',
  CHART_ID: 'Chart ID',
  NAME: 'Tank Name',
  PRODUCT: 'Product'
};