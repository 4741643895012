import { http } from '@/api';

export default {
  createShipCoQ: (planId, shipCoQ) => http.post(
    `/v1/plans/${planId}/shipcoqs`,
    {
      model: shipCoQ
    }
  ),
  updateShipCoQ: (planId, shipCoQ) => http.put(
    `/v1/plans/${planId}/shipcoqs`, {
      model: shipCoQ
    }
  ),
  getShipCoQList: (planId) => http.get(
    `/v1/plans/${planId}/shipcoqs`
  ),
  deleteShipCoQ: (planId, shipCoQId) => http.delete(
    `/v1/plans/${planId}/shipcoqs/${shipCoQId}`
  )
}