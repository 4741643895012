import AuthenticationContext from 'adal-angular';
import jwt_decode from 'jwt-decode';
import {
  AD_APP_CLIENTID,
  AD_APP_INSTANCE,
  AD_APP_TENANT,
  AD_API_APP_ID,
  API_BASE_URL
} from '@/helpers/config';
import { AUTH_MUTATIONS } from './mutations';

export const AUTH_ACTIONS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  ACQUIRE_TOKEN: 'acquireToken',
  AUTHENTICATE: 'authenticate',
  ASSUME_ROLE: 'assumeRole',
  RESET_ROLES: 'resetRoles'
};

const context = new AuthenticationContext({
  instance: AD_APP_INSTANCE,
  tenant: AD_APP_TENANT,
  clientId: AD_APP_CLIENTID,
  redirectUri: `${window.location.origin}${process.env.BASE_URL}`,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: 'localStorage',
  endpoints: {
    [API_BASE_URL]: AD_API_APP_ID
  }
});

// if the user was redirected here after logging in with Azure AD,
// let the adal context grab the token information from the url and setup the user.
if (context.isCallback(window.location.hash) || window.self !== window.top) {
  context.handleWindowCallback();
}

export const actions = {
  [AUTH_ACTIONS.LOGIN]() {
    context.login();
  },
  [AUTH_ACTIONS.LOGOUT]() {
    context.logOut();
  },
  [AUTH_ACTIONS.ACQUIRE_TOKEN](_, resource = AD_API_APP_ID) {
    // wrap the original method that takes a callback into a
    // promise to make it easier to consume.
    return new Promise((resolve, reject) => {
      context.acquireToken(resource, (err, token) => {
        if (err) {
          // reject the Promise with an error.
          return reject(err);
        }

        // resolve/fulfill the promise with data.
        return resolve(token);
      });
    });
  },
  async [AUTH_ACTIONS.AUTHENTICATE]({ dispatch, state, commit }) {
    // try pull the user out of local storage.
    const user = context.getCachedUser();

    if (user) {
      commit(AUTH_MUTATIONS.SET_USER, user);

      if (!state.roles) {
        // load the user roles for the user.
        // get them from the jwt access token since they aren't in the id token.
        const token = await dispatch(AUTH_ACTIONS.ACQUIRE_TOKEN);
        const userInfo = jwt_decode(token);
        commit(AUTH_MUTATIONS.SET_ROLES, userInfo.roles);
        commit(AUTH_MUTATIONS.SET_ISSUED_ROLES, userInfo.roles);
      }

      // this needs to be set after roles to prevent role auth firing before
      // the roles are set.
      commit(AUTH_MUTATIONS.SET_IS_AUTHENTICATED, true);
      return;
    }

    // the user is not authenticated.
    commit(AUTH_MUTATIONS.SET_USER, undefined);
    commit(AUTH_MUTATIONS.SET_ROLES, undefined);
    commit(AUTH_MUTATIONS.SET_ISSUED_ROLES, undefined);
    commit(AUTH_MUTATIONS.SET_IS_AUTHENTICATED, false);
    throw Error('User is not authenticated');
  },
  [AUTH_ACTIONS.ASSUME_ROLE](actionContext, role) {
    if (actionContext.state.issuedRoles.includes(role)) {
      actionContext.commit(AUTH_MUTATIONS.SET_IS_ASSUMING_ROLE, true);
      actionContext.commit(AUTH_MUTATIONS.SET_ROLES, [role]);
    } else {
      throw Error('Cannot Assume role');
    }
  },
  [AUTH_ACTIONS.RESET_ROLES](actionContext) {
    actionContext.commit(AUTH_MUTATIONS.SET_IS_ASSUMING_ROLE, false);
    actionContext.commit(
      AUTH_MUTATIONS.SET_ROLES,
      actionContext.state.issuedRoles
    );
  }
};
