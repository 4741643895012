import api from './api';
import { MONITORING_DETAILS_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';

export const ERROR_CREATE_MONITOR_DETAILS =
'There was a problem trying to create the monitor details';
export const ERROR_UPDATE_MONITOR_DETAILS =
'There was a problem trying to update the monitor details';
export const ERROR_DELETE_MONITOR_DETAILS =
'There was a problem trying to delete the monitor details';
export const ERROR_GET_MONITOR_DETAILS =
'There was a problem trying to get the monitor details';

export const MONITORING_DETAILS_ACTIONS = {
  ADD_MONITOR_DETAILS: 'addMonitorDetails',
  CREATE_MONITOR_DETAILS: 'createMonitorDetails',
  UPDATE_MONITOR_DETAILS: 'updateMonitorDetails',
  DELETE_MONITOR_DETAILS: 'deleteMonitorDetails',
  LOAD_MONITORING_DETAILS: 'loadMonitoringDetails'
}

export const actions = {
  [MONITORING_DETAILS_ACTIONS.ADD_MONITOR_DETAILS](
    { commit },
    { monitorDetails }
  ) {
    commit(MONITORING_DETAILS_MUTATIONS.ADD_MONITOR_DETAILS, {
      monitorDetails
    });
  },
  async [MONITORING_DETAILS_ACTIONS.UPDATE_MONITOR_DETAILS](
    { commit, dispatch },
    { planId, receiptPlanId, tankPlanId, monitorDetailsId, monitorDetails }
  ) {
    let success = false;
    try {
      await api.updateMonitorDetails(
        planId,
        receiptPlanId,
        tankPlanId,
        monitorDetailsId,
        monitorDetails
      );  
      commit(MONITORING_DETAILS_MUTATIONS.SET_MONITOR_DETAILS_BY_ID, {
        monitorDetailsId,
        monitorDetails
      });
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-update-monitor-details',
        text: ERROR_UPDATE_MONITOR_DETAILS,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [MONITORING_DETAILS_ACTIONS.CREATE_MONITOR_DETAILS](
    { commit, dispatch },
    { planId, receiptPlanId, tankPlanId, monitorDetails }
  ) {
    let success = false;
    try {
      const response = await api.createMonitorDetails(
        planId,
        receiptPlanId,
        tankPlanId,
        monitorDetails
      );
      monitorDetails.id = response.data.id;
      commit(MONITORING_DETAILS_MUTATIONS.ADD_MONITOR_DETAILS, {
        monitorDetails
      });
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-create-monitor-details',
        text: ERROR_CREATE_MONITOR_DETAILS,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [MONITORING_DETAILS_ACTIONS.DELETE_MONITOR_DETAILS](
    { commit, dispatch },
    { planId, receiptPlanId, tankPlanId, monitorDetailsId }
  ) {
    let success = false;
    try {
      await api.deleteMonitorDetails(
        planId,
        receiptPlanId,
        tankPlanId,
        monitorDetailsId
      );  
      commit(MONITORING_DETAILS_MUTATIONS.DELETE_MONITOR_DETAILS, {
        monitorDetailsId
      });
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-delete-monitor-details',
        text: ERROR_DELETE_MONITOR_DETAILS,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [MONITORING_DETAILS_ACTIONS.LOAD_MONITORING_DETAILS](
    { commit, dispatch },
    { planId, receiptPlanId, tankPlanId }
  ) {
    try {
      const response = await api.getMonitorDetails(
        planId,
        receiptPlanId,
        tankPlanId,
      );
      commit(MONITORING_DETAILS_MUTATIONS.MONITOR_DETAILS_CONVERT_DATE_FIELDS_TO_DATES);
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-monitor-details',
        text: ERROR_GET_MONITOR_DETAILS,
        error
      },
        { root: true }
      );
    }
    return null;
  }
}