import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';

export const TERMINALS_MUTATIONS = {
  SET_TERMINALS: 'setTerminals',
  SET_ACTIVE_TERMINAL: 'setActiveTerminal',
  RESET_DEFAULT_ACTIVE_TERMINAL: 'resetDefaultActiveTerminal',
  UPDATE_ACTIVE_TERMINAL_TANK: 'updateActiveTerminalTank',
  SET_TANK_TECHNICAL_DETAILS: 'setTankTechnicalDetails'
};

export const mutations = {
  [TERMINALS_MUTATIONS.SET_TERMINALS](state, terminals) {
    Vue.set(state, 'terminalsList', terminals);
  },
  [TERMINALS_MUTATIONS.SET_ACTIVE_TERMINAL](state, terminal) {
    const clonedTerminal = cloneDeep(terminal);
    localStorage.lastUsedTerminalId = terminal.id;
    Vue.set(state, 'activeTerminal', clonedTerminal);
  },
  [TERMINALS_MUTATIONS.RESET_DEFAULT_ACTIVE_TERMINAL](state) {
    Vue.set(state, 'activeTerminal', null);
    localStorage.removeItem('lastUsedTerminalId');
  },
  [TERMINALS_MUTATIONS.SET_TANK_TECHNICAL_DETAILS](state, tankTechnicalDetails) {
    const clonedTerminal = cloneDeep(state.activeTerminal);
    clonedTerminal.tankTechnicalDetails = tankTechnicalDetails;
    Vue.set(state, 'activeTerminal', clonedTerminal);
  }
};