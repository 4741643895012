<template>
    <v-container fluid>
        <v-dialog v-model="showDialog" max-width="500" persistent style="text-align: center;">
            <v-card>
                <v-card-title class="edit-dialog-header text-h6" style="font-weight: bold;">Edit {{ terminalInformation.name }} Tank Details</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <h4 style="color: orange;">Maximum Flow Rate</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-currency-field v-model="detailsInput.maximumFlowRatePressureBarg" :decimalLength="oneDecimalPlace"
                                    :autoDecimalMode="false" label="Pressure (barg)" color="orange lighten-1"
                                    :id="'maximumFlowRate-' + terminalInformation.name + '-presure-dialog'">
                                </v-currency-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <h4 style="color: orange;">Maximum Slow Pumping Flow Rate</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-currency-field v-model="detailsInput.maximumSlowPumpingFlowRatePressureBarg"
                                    :decimalLength="oneDecimalPlace" :autoDecimalMode="false" label="Pressure (barg)" color="orange lighten-1"
                                    :id="'maximumSlowPumpingFlowRate-' + terminalInformation.name + '-pressure-dialog'">
                                </v-currency-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="secondary lighten-3" variant="text" @click="onClose" class="cancel-btn" :loading="isLoading"
                        :id="'tank' + terminalInformation.name + 'cancel-btn'">Cancel</v-btn>
                    <v-btn color="success" variant="text" @click="onSave" class="save-btn" :loading="isLoading"
                        :id="'tank' + terminalInformation.name + 'save-btn'">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card>
            <v-toolbar flat>
                <th class="table-header">
                    <v-flex xs12 class="upper-case" :style="{
                        border: `1px solid ${mapProductToColor(
                            terminalInformation.tankTechnicalDetails.product,
                            true,
                            true
                        )}`,
                        borderRadius: '1px'
                    }">
                        <h3 :style="{
                            color: mapProductToColor(terminalInformation.tankTechnicalDetails.product),
                            backgroundColor: mapProductToColor(terminalInformation.tankTechnicalDetails.product, true)
                        }" :id="`product_${terminalInformation.name}_Header`">
                            {{ terminalInformation.tankTechnicalDetails.product }}</h3>
                        <h3 :style="{
                            color: mapProductToColor(terminalInformation.tankTechnicalDetails.product),
                            backgroundColor: mapProductToColor(terminalInformation.tankTechnicalDetails.product, true)
                        }" :id="`tankName_${terminalInformation.name}_Header`">{{ terminalInformation.name }}</h3>
                    </v-flex>
                </th>
                <v-flex v-if="hasEditRole" class="edit-button">
                    <v-icon size="small" :id="'tank' + terminalInformation.name + '-edit-btn'" class="me-2 tdp-bar-color"
                        @click="editTankInformationTable()">
                        edit
                    </v-icon>
                </v-flex>
            </v-toolbar>
            <v-data-table :items="tankInformation" :headers="headers" hide-default-footer class="elevation-1"
                :id="'tank' + terminalInformation.name + '-information-table'" :loading="isLoading">
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td :id="'tank' + terminalInformation.name + '-row-header' + index">{{ item.row }}</td>
                            <td :id="'tank' + terminalInformation.name + '-max-flow-rate-klhr-row-' + index">{{ convertToMaximumFlowRateKlHr(item.flowRateLmin) | commaSeperateNumber }}</td>
                            <td :id="'tank' + terminalInformation.name + '-flow-rate-msec-row-' + index">{{ item.flowRateMsec | toDecimalPlaces(oneDecimalPlace) }}</td>
                            <td :id="'tank' + terminalInformation.name + '-pressure-row-' + index">{{ item.pressureBarg | toDecimalPlaces(oneDecimalPlace) }}</td>
                            <td :id="'tank' + terminalInformation.name + '-document-row-' + index">{{ item.document }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
        <v-spacer />
    </v-container>
</template>

<script>
import {
    GET_DEFAULT_TERMINAL_INFORMATION_LINE_ITEM, GET_DEFAULT_TERMINAL_INFORMATION_DETAILS,
    TERMINAL_INFORMATION
} from '@/store/terminalData/terminalInformation';
import { isInSomeRole } from '@/helpers/auth'
import { ADMIN, ENGINEER } from '@/constants/roles'
import { mapProductToColor, mapProductToTextColor } from '@/helpers/ColorMapper';
import { UI } from '@/store/ui';
import { cloneDeep } from '@/helpers/object';

export default {
    props: {
        terminalInformation: Object
    },

    data: () => ({
        showDialog: false,
        oneDecimalPlace: 1,
        detailsInput: GET_DEFAULT_TERMINAL_INFORMATION_DETAILS(),
        isLoading: false,
        headers: [
            { text: '', value: 'row', sortable: false },
            { text: 'Flow Rate (kl/hr)', value: 'flowRateLmin', sortable: false },
            { text: 'Flow Rate (m/sec)', value: 'flowRateMsec', sortable: false },
            { text: 'Pressure (barg)', value: 'pressureBarg', sortable: false },
            { text: 'Document', value: 'document', sortable: false }
        ],
        rowHeaders: [
            { row: 'Maximum Flow Rate', value: 'maximumFlowRate' },
            { row: 'Maximum Slow Pumping Flow Rate', value: 'maximumSlowPumpingFlowRate' }
        ]
    }),

    computed: {
        tankInformation() {
            if (!this.terminalInformation) return [];
            var details = [];
            this.rowHeaders.forEach(row => {
                var rowItems = GET_DEFAULT_TERMINAL_INFORMATION_LINE_ITEM();
                rowItems.value = row.value;
                rowItems.row = row.row;
                switch (row.value) {
                    case 'maximumFlowRate':
                        rowItems.flowRateLmin = this.terminalInformation.tankTechnicalDetails.maxFlowRateLitresPerMinute;
                        rowItems.flowRateMsec = this.terminalInformation.maximumFlowRateMsec;
                        rowItems.pressureBarg = this.terminalInformation.maximumFlowRatePressureBarg;
                        rowItems.document = this.terminalInformation.document;
                        break;
                    case 'maximumSlowPumpingFlowRate':
                        rowItems.flowRateLmin = this.terminalInformation.tankTechnicalDetails.maxSlowPumpingFlowRateLitresPerMinute;
                        rowItems.flowRateMsec = this.terminalInformation.maximumSlowPumpingFlowRateMsec;
                        rowItems.pressureBarg = this.terminalInformation.maximumSlowPumpingFlowRatePressureBarg;
                        rowItems.document = this.terminalInformation.document;
                        break;
                }
                details.push(rowItems);
            })
            return details;
        },
        hasEditRole() {
            return isInSomeRole([ADMIN, ENGINEER]);
        }
    },

    methods: {
        mapProductToColor(product, background = false, border = false) {
            if (!product) return;
            if (background) return mapProductToColor(product, border);
            return mapProductToTextColor(product);
        },
        convertToMaximumFlowRateKlHr(flowRateLmin) {
            return Math.round((flowRateLmin / 1000) * 60);
        },
        editTankInformationTable() {
            this.detailsInput = cloneDeep({
                ...this.terminalInformation
            });
            this.showDialog = true;
        },
        onClose() {
            this.showDialog = false;
            this.detailsInput = GET_DEFAULT_TERMINAL_INFORMATION_DETAILS();
            this.isLoading = false;
        },
        async onSave() {
            this.isLoading = true;
            const terminalInformationDetails = cloneDeep({ ...this.detailsInput });
            await this.$store.dispatch(
                TERMINAL_INFORMATION.NS(TERMINAL_INFORMATION.ACTIONS.UPDATE_TERMINAL_INFORMATION_DETAILS), {
                id: this.terminalInformation.id,
                terminalInformationDetails
            });
            const terminalId = this.$route.params && this.$route.params.terminalId;
            await this.$store.dispatch(
                TERMINAL_INFORMATION.NS(TERMINAL_INFORMATION.ACTIONS.GET_TERMINAL_INFORMATION_BY_TERMINAL),
                terminalId
            );
            this.onClose();
            this.isLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.info-table-container {
    padding: 10px;
}

.upper-case {
    text-transform: uppercase;
}

.table-header {
    width: 400px;
    text-align: center;
    padding-top: 20px;
}

.edit-button {
    text-align: right;
}

.tdp-bar-color {
  color: rgba(30, 25, 106, 255);
}

.save-btn {
  color: white;
  transition: 0.3s;
}

.cancel-btn {
  color: white;
  transition: 0.3s;
}

.save-btn:hover {
  color: rgb(13, 90, 13);
}

.cancel-btn:hover {
  color: orange;
}

.edit-dialog-header {
  color: orange;
}
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
</style>