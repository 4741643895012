<template>
  <v-container fluid fill-height>
    <v-layout row wrap pa-4>
      <v-flex xs12>
        <h2>{{message}}</h2>
      </v-flex>
      <v-flex mt-4>
        <pre>{{formList}}</pre>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    message() {
      return 'Coming soon, please refer to existing process for the following forms:';
    },
    formList() {
      return "Quantity Receipt Form \n" +
             "Coll Out Turn Report Form \n";
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
</style>
