<template>
  <v-container fluid fill-height class="no-print-padding">
    <v-dialog max-width="500" v-model="showDialog" persistent>
      <v-card>
        <v-card-title class="headline">{{ isEdit ? 'Edit' : 'Create' }} transfer plan</v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <v-text-field
              id="date-dialog-text-field"
              label="Date (YYYY-MM-DD HH:mm)"
              color="orange lighten-1"
              v-mask="'####-##-## ##:##'"
              placeholder="yyyy-MM-dd HH:mm"
              v-model="date"
              :error-messages="dateErrors"
              @input="$v.date.$touch()"
              :readonly="!isEdit"/>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-layout>
                <v-flex xs8 pr-2>
                  <v-select color="orange lighten-1"
                  :items="getTankTechnicalDetailsForTerminal"
                  label="Source Tank"
                  id="source-tank-dialog-select"
                  v-model="sourceTankName"
                  :error-messages="sourceTankNameErrors"
                  @input="$v.sourceTankName.$touch()">
                  </v-select>
                </v-flex>
                <v-flex xs8 pr-2>
                  <v-select color="orange lighten-1"
                  :items="getTankTechnicalDetailsForTerminal"
                  label="Target Tank"
                  id="target-tank-dialog-select"
                  v-model="targetTankName"
                  :error-messages="targetTankNameErrors"
                  @input="$v.targetTankName.$touch()"
                  :disabled="!isSourceSelected">
                  </v-select>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-textarea
              label="Comments"
              id="comments-dialog-text-field"
              outlined
              clearable
              color="orange lighten-1"
              counter
              clear-icon="mdi-close-circle"
              v-model="comment"
              :error-messages="commentErrors"
              @input="$v.comment.$touch()">
              </v-textarea>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          id="cancel-transfer-plan-btn"
          color="secondary lighten-3"
          :loading="isLoading"
          :disabled="isLoading"
          @click="onCloseDialog">
            Cancel
          </v-btn>
          <v-btn
          id="save-new-transfer-plan-btn"
          color="orange lighten-1"
          :loading="isLoading"
          :disabled="saveIsDisabled || isLoading"
          @click="onSave">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout row wrap justify-end>
      <v-flex xs12 pa-4>
        <v-data-table 
        :items="getTankToTankTransferPlans"
        :headers="headers"
        class="elevation-1"
        id='tank-to-tank-plans-table'
        :loading="isLoading"
        v-model="selectedRow"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :showArrows="true">
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index" @click="goToDetails(item)">
                <td :id="'source-tank-name-' + index">{{ item.sourceTankTechnicalDetails.name }}</td> 
                <td :id="'target-tank-name-' + index">{{ item.targetTankTechnicalDetails.name }}</td>
                <td :id="'comment-' + index">{{ item.comment }}</td>
                <td :id="'date-' + index">{{ item.date | formatDateTime('yyyy-MM-dd HH:mm') }}</td>
                <td :id="'plan-status-' + index">{{ item.planStatus }}</td>
                <v-flex>
                  <v-icon
                  size="small"
                  id="edit-transfer-plan-btn"
                  class="me-2 tdp-bar-color"
                  @click="onEditTransferPlan(item)">
                    edit
                  </v-icon>
                </v-flex>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-flex>

      <v-flex xs3 px-4 pb-3>
        <flat-button
        :loading="isLoading"
        id="create-new-transfer-plan-btn"
        @click="onCreateTransferPlan"
        :disabled="isLoading">
          Create transfer Plan
        </flat-button>
      </v-flex>

    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { TERMINALS } from '@/store/terminals';
import { TANK_TO_TANK_TRANSFER, GET_DEFAULT_TRANSFER_PLAN_DETAILS } from '@/store/tankToTankTransfer';
import { required, maxLength } from 'vuelidate/lib/validators';
import { maskedDatetime } from '@/validators';
import { formatDateTimeToMasked, isValidMaskedDateTime } from '@/helpers/date';
import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import { cloneDeep } from '@/helpers/object';
import { PRODUCT_SORT_ORDER } from '@/constants/products';

export default {
  async created() {
    await this.fetchData();
  },

  data: () => ({
    headers: [
      { text: 'Source Tank', value: 'sourceTankName',sortable: true },
      { text: 'Target Tank', value: 'targetTankName', sortable: true },
      { text: 'Comments', value: 'comment', sortable: true },
      { text: 'Date', value: 'date', sortable: true },
      { text: 'Status', value: 'planStatus', sortable: true }
    ],
    isLoading: false,
    showDialog: false,
    selectedRow: [],
    transferPlanInput: GET_DEFAULT_TRANSFER_PLAN_DETAILS(),
    isSourceAndTargetTankValid: false,
    isEdit: false,
    dateString: '',
    sortBy: 'date',
    sortDesc: true,
    isSourceSelected: false
  }),

  computed: {
    ...mapState({
      transferPlans: state => state.tankToTankTransfer.transferPlans,
      tanksList: state => state.tankToTankTransfer.tanksList,
      terminalsByKey: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.reduce((terminals, terminal) => {
          terminals[terminal.id] = { ...terminal };
          return terminals;
        }, {}),
      activeTerminal: state => state.terminals.activeTerminal,
      tankToTankTransferPlan: (state) => state.tankToTankTransfer &&
        state.tankToTankTransfer.tankToTankTransferPlan
    }),
    getTankToTankTransferPlans() {
      return this.transferPlans;
    },
    getTankTechnicalDetailsForTerminal() {
      let tanks = [];
      if (this.activeTerminal && this.tanksList) {
        let tankObjects = [];
        tankObjects = [...this.tanksList].sort((a, b) => {
          tankObjects.push(PRODUCT_SORT_ORDER.indexOf(a.product) - PRODUCT_SORT_ORDER.indexOf(b.product));
        });
        tankObjects.forEach(tank =>  tanks.push(tank.name));
      }
      return tanks;
    },
    dateErrors() {
      if (!this.$v.date.$dirty || this.$v.date.$pending) {
        return [];
      }
      if (!this.$v.date.required || !this.$v.date.maskedDatetime) {
        return ['A valid date time is required (YYYY-MM-DD HH:MM)'];
      }
    },
    sourceTankNameErrors() {
      if (!this.$v.sourceTankName.required) {
        return ['Source Tank is required'];
      }
      if (!this.$v.sourceTankName.$dirty || this.$v.sourceTankName.$pending) {
        return [];
      }
      if (!this.$v.sourceTankName.validSourceTank) {
        return ['Source and Target tanks cannot be the same'];
      }
    },
    targetTankNameErrors() {
      if (!this.$v.targetTankName.required) {
        return ['Target Tank is required'];
      }
      if (!this.$v.targetTankName.$dirty || this.$v.targetTankName.$pending) {
        return [];
      }
      if (!this.$v.targetTankName.validTargetTank) {
        return ['Source and Target tanks cannot be the same'];
      }
    },
    commentErrors() {
      if (!this.$v.comment.$dirty || this.$v.comment.$pending) {
        return [];
      };
      if (!this.$v.comment.maxLength) {
        return ['Comment exceeds 100 characters.'];
      };
    },
    date: {
      get() {
        return this.dateString;
      }, 
      set(value) {
        this.dateString = value;
        if (isValidMaskedDateTime(value, DATE_TIME_FORMAT)) {
          this.transferPlanInput.date = value;
        }
      }
    },
    sourceTankName: {
      get() {
        return this.transferPlanInput.sourceTankName;
      },
      set(value) {
        this.transferPlanInput.sourceTankName = value;
        if (this.transferPlanInput.sourceTankName !== null) {
          this.isSourceSelected = true;
        }
      }
    },
    targetTankName: {
      get() {
        return this.transferPlanInput.targetTankName;
      },
      set(value) {
        this.transferPlanInput.targetTankName = value;
      }
    },
    comment: {
      get() {
        return this.transferPlanInput.comment;
      },
      set(value) {
        this.transferPlanInput.comment = value;
      }
    },
    saveIsDisabled() {
      return !this.$v.date.required
      || !this.$v.date.maskedDatetime
      || !this.$v.sourceTankName.required
      || !this.$v.targetTankName.required
      || !this.$v.comment.maxLength
      || !this.isSourceAndTargetTankValid;
    }
  },

  methods: {
    async fetchData() {
      this.isLoading = true;
      const terminalId = this.$route.params && this.$route.params.terminalId;
      if (!this.terminalsList) {
        await this.$store.dispatch(
          TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS)
        );
      }
      const terminal = this.terminalsByKey[terminalId];
      await this.$store.dispatch(
        TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.GET_TRANSFER_PLANS_BY_TERMINAL),
        terminalId
      );
      if (this.$route.params.terminalId) {
        this.$store.commit(
          TERMINALS.NS(TERMINALS.MUTATIONS.SET_ACTIVE_TERMINAL),
          terminal
        );
      }
      await this.$store.dispatch(
        TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.GET_TANK_TECHNICAL_DETAILS_BY_TERMINAL),
        terminalId
      );
      this.isLoading = false;
    },
    goToDetails(transferPlan) {
      if (this.isEdit == false) {
        this.$router.push({
        path: `/terminal/${transferPlan.terminalId}/tank-to-tank/${transferPlan.id}/pre-transfer/transfer-plan`
        });
      }
    },
    onCreateTransferPlan() {
      this.isEdit = false;
      this.isLoading = true;
      this.dateString = formatDateTimeToMasked(new Date(), DATE_TIME_FORMAT);
      this.transferPlanInput.date = this.dateString;
      this.showDialog = true;
      this.isLoading = false;
    },
    onEditTransferPlan(item) {
      this.isEdit = true;
      this.loading = true;
      this.isSourceSelected = true;
      this.isSourceAndTargetTankValid = true;
      this.transferPlans.forEach(tank => {
        if (tank.id == item.id) {
          this.dateString = formatDateTimeToMasked(tank.date, DATE_TIME_FORMAT);
          this.transferPlanInput = cloneDeep(tank);
          this.transferPlanInput.sourceTankName = tank.sourceTankTechnicalDetails.name;
          this.transferPlanInput.targetTankName = tank.targetTankTechnicalDetails.name;
        }
      });
      this.loading = false;
      this.showDialog = true;
    },
    onCloseDialog() {
      this.showDialog = false;
      this.transferPlanInput = GET_DEFAULT_TRANSFER_PLAN_DETAILS();
      this.isSourceSelected = false;
      this.isSourceAndTargetTankValid = false;
      this.isLoading = false;
    },
    async onSave() {
      this.isLoading = true;
      const terminalId = this.$route.params && this.$route.params.terminalId;
      this.transferPlanInput.sourceTankTechnicalDetailsId = this.getTankTechnicalDetailsIdByTankName(this.transferPlanInput.sourceTankName);
      this.transferPlanInput.targetTankTechnicalDetailsId = this.getTankTechnicalDetailsIdByTankName(this.transferPlanInput.targetTankName);
      const transferPlan = this.transferPlanInput;
      let success;

      if (this.transferPlanInput.id) {
        success = await this.$store.dispatch(
          TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.UPDATE_TANK_TO_TANK_TRANSFER_PLAN), {
            id: this.transferPlanInput.id,
            transferPlan
        });
      }
      else {
        success = await this.$store.dispatch(
          TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.CREATE_TRANSFER_PLAN), {
            terminalId: terminalId,
            transferPlan
        });
      }
      
      if (success) {
        this.$store.dispatch(
          TANK_TO_TANK_TRANSFER.NS(TANK_TO_TANK_TRANSFER.ACTIONS.GET_TRANSFER_PLANS_BY_TERMINAL),
          terminalId
        );
        this.onCloseDialog();
        if (this.isEdit == false) {
          const transferPlanId = this.tankToTankTransferPlan && this.tankToTankTransferPlan.id;
          this.$router.push({
            path: `/terminal/${this.activeTerminal.id}/tank-to-tank/${transferPlanId}/pre-transfer/transfer-plan`
          });
        }
      }
      this.isEdit = false;
      this.isLoading = false;
    },
    getTankTechnicalDetailsIdByTankName(tankName) {
      var tankId = 0;
      this.tanksList.forEach(tank => {
        if (tank.name == tankName) {
          tankId = tank.id;
        }
      });
      return tankId;
    },
    isTargetTankIdenticalToSourceTank(value) {
      if (value == this.transferPlanInput.sourceTankName && value == this.transferPlanInput.targetTankName) {
        this.isSourceAndTargetTankValid = false;
        return false;
      }
      else {
        this.isSourceAndTargetTankValid = true;
        return true;
      }
    }
  },

  validations: {
    date: { 
      required, 
      maskedDatetime 
    },
    sourceTankName: { 
      required,
      validSourceTank: function (value) {
        return this.isTargetTankIdenticalToSourceTank(value);
      }
    },
    targetTankName: { 
      required,
      validTargetTank: function (value) {
        return this.isTargetTankIdenticalToSourceTank(value);
      }
    },
    comment: { 
      maxLength: maxLength(100) 
    }
  }
};
</script>