import api from './api';
import { CHECKLIST_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';

export const ERROR_GET_CHECKLIST =
  'There was a problem trying to load the checklist';
export const ERROR_UPDATE_CHECKLIST =
  'There was a problem trying to update the checklist';

export const CHECKLIST_ACTIONS = {
  GET_CHECKLIST: 'getChecklist',
  UPDATE_CHECKLIST_ANSWERS: 'updateChecklistAnswers'
}

export const actions = {
  async [CHECKLIST_ACTIONS.GET_CHECKLIST](
    { commit, dispatch },
    { checklistType, planId, checklistId, sectionId }
  ) {
    let success = false;
    try {  
      const response = await api.getChecklist({ checklistType, planId, checklistId, sectionId});
      commit(CHECKLIST_MUTATIONS.SET_CHECKLIST, response.data);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-get-checklist',
        text: ERROR_GET_CHECKLIST,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [CHECKLIST_ACTIONS.UPDATE_CHECKLIST_ANSWERS](
    { commit, dispatch }, 
    { submissionResults, checklistType, planId }
  ) {
    let success = false;
    try {
      await api.updateChecklist({
        checklistType, planId, submissionResults
      });
      success = true;
    } catch (error) {
      success = false;
    }
    return success;
  }
}