<template>
  <v-container fluid fill-height pa-4>
    <pdf-preview header="Cargo Planning Sheet PDF" fileName="CargoPlanningSheet.pdf" :showDialog="pdf.showDialog" :url="pdf.url" @close="pdf.showDialog = false" :data="pdf.data" />
    <v-dialog max-width="300" v-model="this.showDeleteConfirmation" persistent>
      <v-card>
        <v-card-text>
          <v-layout>
            <v-flex xs12 mt-3>
              <strong class="save-title">Delete confirmation</strong>
              <div class="save-message">{{ cargoPlanProductIdToDelete ? '' : 'This product has unsaved changes.' }} 
                Are you sure you want to delete Discharge sequence {{ this.dischargeSequenceToDelete }}?</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="cargoPlanProductIdToDelete"
            color="orange lighten-1"
            @click="onDeleteProductConfirmation"
            id="confirm-delete-product-btn"
          >Yes</v-btn>
          <v-btn v-if="!cargoPlanProductIdToDelete"
            color="orange lighten-1"
            @click="onDeleteUnsavedProduct"
            id="confirm-delete-unsaved-product-btn"
          >Yes</v-btn>

          <v-btn color="secondary lighten-3"
            @click="closeDeleteConfirmation"
            id="deletePopupNoButton"
          >No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-shim class="no-print" :is-loading="uiIsLoading" />
    <v-container v-if="!hasLoaded">
      <v-row style="height:80vh" align="center"> 
        <v-col  fill-height align="center">
          <loading-circle />
          </v-col>
        </v-row>
    </v-container>    
    <v-dialog class="no-print" max-width="300" v-model="showSaveConfirmation">
      <v-card>
        <v-card-text>
          <v-layout>
            <v-col xs12>
              <strong class="save-title"
                >You have changed the Discharge Quantity.</strong
              >
              <div class="save-message">
                Are you sure that you want to save?
              </div>
            </v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange lighten-1"
            @click="onSaveConfirmation"
            :disabled="isLoading"
            :loading="isLoading"
             id="popupSaveButton"
            >Save</v-btn
          >
          <v-btn color="secondary lighten-3"
            @click="onSaveConfirmationCancel"
            :disabled="isLoading"
            :loading="isLoading"
             id="popupCancelButton"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

  
  
    <v-layout row wrap v-if="hasLoaded">

      <edit-planning-time-scale
      :showDialog="showDialog"
      v-on:close="closeDialog()"
      v-on:edit="editTimeScale()"
      v-on:closeSave="closeDialogSave()"
      :cargoPlanId="getCargoPlanId"      
      :voyageId="voyage"  
      :receivingCompaniesList="receivingCompanies"
      :productsList="products"
      :cargoPlanDate="date"
      ref="timeScaleEdit"
      />

      <v-layout row wrap my-4>
        <v-col xs3 pr-3>
          <v-text-field class="input-width"
                        color="orange lighten-1"
                        v-model="vessel"
                        label="Vessel"
                        @input="$v.vessel.$touch()"
                        :readonly="readOnly"
                        id="vessel"/>
        </v-col>
        <v-col xs3 pr-3>
          <v-text-field class="input-width"
                        color="orange lighten-1"
                        v-model="voyage"
                        label="Voyage"
                        @input="$v.voyage.$touch()" 
                        :readonly="readOnly"
                        id="voyage"/>
        </v-col>
        <v-col xs3 pr-3>
          <v-text-field class="input-width"
                        color="orange lighten-1"
                        v-model="terminalBerthName"
                        label="Port and Berth"
                        @input="$v.voyage.$touch()" 
                        :readonly="readOnly"
                        id="portAndBerth"/>
        </v-col>            
        <v-col xs3>
          <v-text-field label="Date (DD-MM-YYYY)"
                        class="input-width"
                        color="orange lighten-1"
                        v-model="date"
                        v-mask="'##-##-####'"
                        placeholder="DD-MM-YYYY"
                        :error-messages="dateErrors"
                        @input="$v.date.$touch()" 
                        id="date"/>
        </v-col>
        <v-col xs3 pr-3>
          <v-text-field class="input-width"
                        color="orange lighten-1"
                        v-model="amendment"
                        label="Amendment"
                        :error-messages="amendmentErrors"
                        @input="$v.amendment.$touch()"
                        id="amendment"/>
        </v-col>
      </v-layout>
      
      <v-container fluid>
        <v-row no-gutters class="slide-group-row">
          <v-slide-group
          class="slide-group"  
          show-arrows="always" 
          center-active 
          id="slide-group">
            <cargo-planning-column v-if="product.active == true" v-for="product in cargoPlanProducts" v-bind:key=activeCargoPlanProducts.indexOf(product)
            :cargoPlanProduct="product"
            :dischargePlan="dischargePlan"
            :columnIndex="activeCargoPlanProducts.indexOf(product)"
            :id="'cargoPlanProduct' + cargoPlanProducts.indexOf(product)"
            @deleteProduct="showDeleteProductDialog($event)"
            @deleteUnsavedProduct="showDeleteUnsavedProductDialog($event)"
            :activeCargoPlanProductsLength="activeCargoPlanProducts.length"
            v-on:changeDischarge="dischargeQuantityChanged"
            :displayDischargeChangeWarning="hasDischargeQuantityChanged"
            class="cargo-planning-col"
            />
            <v-col xs3>
              <v-btn
              @click="onNewProduct()"
              class="add-product-card-btn"
              id="add-product-btn"
              color="success"
              style="height: 1466px;"
              :disabled="isNewProductButtonDisabled">
                <div class="new-product-divider"></div>
                + New Product
              </v-btn>
            </v-col>
          </v-slide-group>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-layout>
          <img class="logo" width="50" :src="`${publicPath}Z-logo.svg`" alt="Z" />
          <v-col>
            <v-textarea 
              label="Discharge Notes/Comments:" 
              id="discharge-notes"                         
              outlined 
              color="orange lighten-1" 
              counter
              v-model="dischargeNotes"
              :error-messages="dischargeNotesErrors"
              hide-details="auto"
              @input="$v.dischargeNotes.$touch()"/> 
          </v-col>
          <v-col>
            <v-row>
              <h6>Plan Agreement - to be signed by both parties:</h6>
            </v-row>
            <v-row>
              <v-text-field class="input-width"
                            color="orange lighten-1"
                            label="Ship"
                            :readonly="readOnly"
                            id="ship-notes"/>
            </v-row>
            <v-row>
              <v-text-field class="input-width"
                            color="orange lighten-1"
                            label="Shore"
                            :readonly="readOnly"
                            id="shore-notes"/>
              </v-row>
          </v-col>
        </v-layout>
        <v-layout>
          <v-row>
            <p>
              TO-IOA-FOR-011 | &copy; Z Energy Limited March 2024            
              <strong> PLEASE NOTE THIS IS A GUIDE ONLY - TANKER DISCHARGE TIMES CAN VARY</strong>
            </p>
          </v-row>
        </v-layout>
      </v-container>

      <v-container fluid>
        <v-layout class="no-print" row pa-2 justify-end>
          <v-flex xs3 pa-1>
            <flat-button color="orange lighten-1"
              class="button" 
              @click="onSaveButtonClick"
              :loading="isLoading"
              :disabled="isLoading || !isCargoPlanValid"
               id="saveCargoPlanButton"
               >Save Cargo plan</flat-button>
          </v-flex>
        </v-layout>
      </v-container>



      <v-container fluid>   
        <v-divider style="margin-top:5px" class="divider"/>
        <v-layout row>
          <h2 class="text-xs-left">Planning Time Scale</h2>
          <v-layout justify-end>
            <v-flex xs2 pa-1>
              <flat-button 
                @click="onEditTimeScale"
                class="no-print"
                :disabled="isTimeScaleDisabled"
                id="editTimeScale">{{ timeScaleToEdit ? 'Edit Plan' : 'Create Plan' }}
              </flat-button>
            </v-flex>
            <v-flex xs4 pa-1>
              <flat-button
                class="button"
                @click="onPrintCargoPlanningSheet"
                :loading="pdf.isLoading"
                id="print-cargo-plan-btn"
                :disabled = "isLoading || !isPrintValid"> 
                Print Cargo Planning Sheet
              </flat-button>
            </v-flex>
          </v-layout>
        </v-layout>      
      </v-container> 

      <v-container fluid>
        <gantt-elastic
        v-if="displayTimeScale"
          style="width: 100%; height: 100%;min-height: 100px; min-width: 100px;"
          :tasks="timeScaleTasks"
          :options="timeScaleOptions">
        </gantt-elastic>
      </v-container>
    </v-layout>      
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { DISCHARGE_PLAN } from '@/store/tankerDischarge/preArrival/dischargePlan';
import { CARGO_PLAN, DEFAULT_CARGO_PLAN_PRODUCTS, GET_DEFAULT_CARGO_PLAN_PRODUCT } from '@/store/tankerDischarge/preArrival/cargoPlan';
import { AGO_COLOR, JETA1_COLOR, PMS_COLOR, RMS_COLOR } from '@/constants/cargoPlanDropdown.js';
import { cloneDeep } from '@/helpers/object';
import { ROUTE_NAMES } from '@/routes';
import { formatDateTimeToMasked, isValidMaskedDateTime, convertNzDateToUs } from '@/helpers/date';
import { UI } from '@/store/ui';
import { DATE_FORMAT } from '@/constants/dateFormat';
import { dateFormat } from '@/validators';
import { required, maxLength } from 'vuelidate/lib/validators';
import CargoPlanningColumn from './CargoPlanningColumn.vue';
import EditPlanningTimeScale from './EditPlanningTimeScale.vue';
import { PRODUCTS_MAX } from '@/constants/cargoPlanDropdown.js';
import { BASE_URL } from '@/helpers/config'; 
import GanttElastic  from 'gantt-elastic';
import { DEFAULT_TIMESCALE_TASK, DEFAULT_TIMESCALE_OPTIONS, getDate } from '@/helpers/timeScaleHelper'
import { format } from 'date-fns'

export default {
  async created() {
    this.isLoading = true;
    await this.loadData();        
    this.isLoading = false;
    if(! this.cargoPlan.id) {
      this.cargoPlan.date = format(Date.now(), 'dd-MM-yyyy');
    }
    this.dateString = formatDateTimeToMasked(this.cargoPlan.date, DATE_FORMAT);
    
  },
  data () {
    return {
      publicPath: BASE_URL,
      dischargePlan: null,
      cargoPlan: null,
      isLoading: false,
      dateString: '',
      listRouteName: ROUTE_NAMES.CARGO_PLAN,
      timeScaleToEdit: false,
      showDialog: false,
      timeScale: {type: Object},
      hasDischargeQuantityChanged: false,
      showSaveConfirmation: false,
      showDeleteConfirmation: false,
      isNewProductButtonDisabled: false,
      cargoPlanProductIdToDelete: 0,
      dischargeSequenceToDelete: 0,
      pdf: {
        isLoading: false,
        showDialog: false,
        data: null,
        url: null
      }
    }
  },
  props: {
    readOnly: {
        type: Boolean,
        default: true
      },      
  },
  components: {
    CargoPlanningColumn,
    EditPlanningTimeScale,
    GanttElastic
  },
  methods: {    
    async loadData(){
      await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
        { id: this.planId }
      );
      this.dischargePlan = cloneDeep(this.$store.state.dischargePlan.dischargePlan);
      await this.$store.dispatch(
        CARGO_PLAN.NS(CARGO_PLAN.ACTIONS.GET_CARGO_PLAN),
        { dischargePlanId: this.planId }
      );
      this.cargoPlan = cloneDeep(this.$store.state.cargoPlan.cargoPlan);
      this.checkProductsAmount();
    },
    async onSaveConfirmation() {
      this.hasDischargeQuantityChanged = false;
      this.showSaveConfirmation = false;
      await this.onSave();
    },
    onSaveConfirmationCancel() {
      this.showSaveConfirmation = false;
    },
    async onSaveButtonClick() {
      if(this.hasDischargeQuantityChanged) {
        this.showSaveConfirmation = true;
      } else {
        await this.onSave();
      }
    },
    async onSave() {
      this.loading = true;
      const success = await this.$store.dispatch(
        CARGO_PLAN.NS(CARGO_PLAN.ACTIONS.UPDATE_CARGO_PLAN),
        { dischargePlanId: this.planId, cargoPlan: this.cargoPlan }
      );
      if (success) {
        if(!this.loading) {
          await this.loadData();
          $v.$reset();
        }
      }
      await this.loadData();
      this.loading = false;      
    },
    onEditTimeScale() {
      this.showDialog = true;            
    },
    editTimeScale() { 
      this.timeScaleToEdit = true;
    },
    async closeDialogSave() {
      this.showDialog = false;
      this.hasDischargeQuantityChanged = false;
      this.showSaveConfirmation = false;
      await this.onSave();
    },
    closeDialog() {
      this.showDialog = false;
    },
    dischargeQuantityChanged() {
      this.hasDischargeQuantityChanged = true;
    },
    onNewProduct() {
      this.cargoPlanProducts.push(GET_DEFAULT_CARGO_PLAN_PRODUCT(this.planId));
      this.checkProductsAmount();
    },
    showDeleteUnsavedProductDialog(dischargeSequence) {
      this.dischargeSequenceToDelete = dischargeSequence;
      this.showDeleteConfirmation = true;
    },
    onDeleteUnsavedProduct() {
      this.cargoPlanProducts.pop(GET_DEFAULT_CARGO_PLAN_PRODUCT(this.planId));
      this.cargoPlanProducts = cloneDeep(...this.activeCargoPlanProducts);
      this.checkProductsAmount();
      this.closeDeleteConfirmation();
      this.$store.dispatch( UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'delete-cargo-plan-product-successful',
        text: 'Product deleted'
      });
    },
    showDeleteProductDialog(properties) {
      this.dischargeSequenceToDelete = properties.dischargeSequence;
      this.cargoPlanProductIdToDelete = properties.productId;
      this.showDeleteConfirmation = true;
    },
    checkProductsAmount() {
      if (this.activeCargoPlanProducts.length < PRODUCTS_MAX) { 
        this.isNewProductButtonDisabled = false;
      }
      else {
        this.isNewProductButtonDisabled = true;
        this.$store.dispatch( UI.NS(UI.ACTIONS.ADD_TOAST), {
          id: 'maximum-cargo-plan-products-reached',
          text: 'Maximum Products have been reached'
        });
      }
    },
    async onDeleteProductConfirmation() {
      this.isLoading = true;
      if (this.cargoPlanProductIdToDelete) {
        this.activeCargoPlanProducts[this.activeCargoPlanProducts.length-1].active = false;
        this.cargoPlanProducts = cloneDeep( ...this.activeCargoPlanProducts );
        const success = await this.$store.dispatch(
          CARGO_PLAN.NS(CARGO_PLAN.ACTIONS.UPDATE_CARGO_PLAN),
          { dischargePlanId:this.planId,
            cargoPlan: this.cargoPlan }
        );
        if (success) {
          this.$store.dispatch( UI.NS(UI.ACTIONS.ADD_TOAST), {
            id: 'delete-cargo-plan-product-successful',
            text: 'Product deleted'
          });
          await this.loadData();
        }
      }
      this.closeDeleteConfirmation();
    },
    closeDeleteConfirmation() {
      this.dischargeSequenceToDelete = 0;
      this.cargoPlanProductIdToDelete = 0;
      this.showDeleteConfirmation = false;
      this.isLoading = false;
    },
    getProductColor(product) {
      if (product == 'AGO') {
        return AGO_COLOR;
      }
      else if (product == 'JETA1') {
        return JETA1_COLOR;
      }
      else if (product == 'PMS') {
        return PMS_COLOR;
      }
      else if (product == 'RMS') {
        return RMS_COLOR;
      }
    },
    async onPrintCargoPlanningSheet() {
      this.pdf.loading = true;
      const res = await this.$store.dispatch(
        CARGO_PLAN.NS(CARGO_PLAN.ACTIONS.GET_CARGO_PLAN_PDF),
        { dischargeId: this.planId }
      );
      const file = new Blob([res.data], {type: 'application/pdf'});
      this.pdf.data = file;
      const fileURL = URL.createObjectURL(file);
      this.pdf.url = fileURL
      this.pdf.showDialog = true;
      this.pdf.loading = false;
    }
  },
  computed: {
    receivingCompanies() {
      let companies = [];

      if(this.cargoPlan == null) {
        return companies;
      }

      for(var product in this.cargoPlan.cargoPlanProducts) {
        let company = this.cargoPlan.cargoPlanProducts[product].receivingCompany;
        companies = [...companies, company];        
      }
      return companies;      
    },
    products() {
      let products = [];

      if(this.cargoPlan == null) {
        return products;
      }

      for(var cargoProduct in this.cargoPlan.cargoPlanProducts) {
        let product = this.cargoPlan.cargoPlanProducts[cargoProduct].productGrade;
        if(product != null){
          products = [...products, product];  
        }         
      }
      
      return products;      
    },
    cargoPlanProducts:{
      get(){
        if(this.cargoPlan.cargoPlanProducts == null || this.cargoPlan.cargoPlanProducts.length < 1){ 
          this.cargoPlan.cargoPlanProducts = DEFAULT_CARGO_PLAN_PRODUCTS(this.planId);
        }       
        return this.cargoPlan && this.cargoPlan.cargoPlanProducts;     
      },
      set() {}
    },
    planId() {      
      return this.$route.params && this.$route.params.planId; 
    },
    hasLoaded() {
      return Boolean(this.dischargePlan && this.dischargePlan.id && this.cargoPlan && this.cargoPlan.dischargePlanId);
    },
    isCargoPlanValid(){
      if(!this.$v.date.required || 
          !this.$v.date.dateFormat || 
          !this.$v.dischargeNotes.maxLength) {
        return false;
      }

      return true;
    },
    isPrintValid() {
      if (!this.isCargoPlanValid
      || this.cargoPlan.cargoPlanProducts === null
      || !this.displayTimeScale) {
        return false;
      }
      return true;
    },
    isTimeScaleDisabled() {
      if(this.cargoPlan.id) {
        return false;
      }
      return true;
    },
    activeCargoPlanProducts: {
      get() {
        var activeProducts = [];
        this.cargoPlanProducts.forEach(product => {
          if (product.active == true) {
            activeProducts.push(product);
          }
        });
      return activeProducts;
      }
    },
    getCargoPlanId: {
      get() {
        return this.cargoPlan.id;
      }
    },
    vessel: {
      get() {
        return this.dischargePlan && this.dischargePlan.vessel;
      }
    },
    voyage: {
      get() {
        return this.dischargePlan && this.dischargePlan.voyage;
      }
    },
    terminalBerthName: {
      get() {
        return this.dischargePlan && this.dischargePlan.terminalBerthName;
      }
    },
    amendment: {
      get() {       
        return this.cargoPlan && this.cargoPlan.amendment;
      },
      set(value) {
        this.cargoPlan.amendment = value;
      }
    },
    date: {
      get() {
        return this.dateString;
      },
      set(value) {
        this.dateString = value;
        if (isValidMaskedDateTime(value, DATE_FORMAT)) {
          this.cargoPlan.date = convertNzDateToUs(value);
        }
      }
    },
    dischargeNotes: {
      get(){
        return this.cargoPlan && this.cargoPlan.dischargeNotes
      },
      set(value){ 
        this.cargoPlan.dischargeNotes = value;
      }
    },
    timeScaleTasks() {
      let tasks = [];
      var timeScale = this.$store.state.timeScale.timeScale;
      if(timeScale != null && timeScale.startTime != null) {
        var pilotOnBoard = DEFAULT_TIMESCALE_TASK();
        pilotOnBoard.start = getDate(timeScale.startTime);
        pilotOnBoard.duration = 3600000;
        pilotOnBoard.label = 'Pilot on Board ETA'
        pilotOnBoard.taskDuration = 1 + ' Hrs';

        tasks = [... tasks, pilotOnBoard];

        for(var activity in timeScale.timeScaleActivities) {
          var task = DEFAULT_TIMESCALE_TASK();
          
          task.id = activity + 1;
          task.label = timeScale.timeScaleActivities[activity].activityName;
          task.start = getDate(timeScale.timeScaleActivities[activity].activityStartTime);
          task.end = getDate(timeScale.timeScaleActivities[activity].activityEndTime);
          task.product = timeScale.timeScaleActivities[activity].product;
          task.taskDuration = (task.end - task.start) / 1000 / 60 / 60 + ' Hrs';
          if(timeScale.timeScaleActivities[activity].product != '') {
            task.style = {
              base: {
                fill: this.getProductColor(timeScale.timeScaleActivities[activity].product)
              }
            }
          }
          tasks = [... tasks, task];
        }
      }

      
      return tasks;
    },
    timeScaleOptions() {
      var options = DEFAULT_TIMESCALE_OPTIONS();
      return options;
    },
    displayTimeScale(){
      var timeScale = this.$store.state.timeScale.timeScale;
      if(this.timeScaleToEdit && timeScale != null && timeScale.cargoPlanId != null && timeScale.startTime != null){
        return true;
      }
      return false;
    },
    dateErrors() {
      let errors = [];

      if (!this.$v.date.$dirty || this.$v.date.$pending) {
        return [];
      }

      if (!this.$v.date.required || !this.$v.date.dateFormat) {
        errors = [...errors, 'A valid date is required (DD-MM-YYYY)'];
      }

      return errors;
    },
    dischargeNotesErrors() {
      let errors = []
      if (!this.$v.dischargeNotes.$dirty || this.$v.dischargeNotes.$pending) {
        return [];
      }
      if( !this.$v.dischargeNotes.maxLength ) {
        errors = [...errors, 'Discharge notes exceeds 250 characters.'];
      }
      return errors;
    },
    amendmentErrors() {
      let errors = []
      if (!this.$v.amendment.$dirty || this.$v.amendment.$pending) {
        return [];
      }
      if( !this.$v.amendment.maxLength ) {
        errors = [...errors, 'Amendment cnnot be longer than 20 characters'];
      }
      return errors;
    },
    ...mapState({
      originalCargoPlan: (state) => 
        state.cargoPlan && state.cargoPlan.originalCargoPlan,
      uiIsLoading: (state) => state.ui.isLoading
    })
  },  
  validations: {
    date: {
      required,
      dateFormat
    },
    dischargeNotes: {
      maxLength: maxLength(250)
    },
    amendment: {
      maxLength: maxLength(20)
    } 
  }
}    



</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

.v-input {
  font-size: 14px;
}

.upper-case {
  text-transform: uppercase;
}
h2,
h3,
h4 {
  text-align: center;
}
.input-width {
  max-width: 200px;
}
:deep(.v-text-field--filled) .v-text-field__suffix {
  margin-top: 22px;
}
// always show input labels in "active" position and display full label
:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}
// remove padding from filled class inputs
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details,
:deep(.v-text-field.v-text-field--enclosed)
  > .v-input__control
  > .v-input__slot {
  padding: 0 3px !important;
}

.button {
  margin-right: 10px !important;
}

.stay-put-sticky-position {
  position: sticky;
  top: 112px;
  z-index: 8;
  background: white;
}
.slide-group {
  background-color:rgb(242, 242, 242);
}
.slide-group-row {
  display: flex;
  justify-content: center;
}
.cargo-planning-col {
  padding-right: 10px;
  width: 300px;
}
.new-product-divider {
  height: 60px;
}
.reduced-padding {
  padding: 10px 15px 10px 0 !important;
}
.save-title {
  font-size: 120%;
}
.save-message {
  margin: 10px 0;
}
.save-error {
  color: $error-color;
  font-style: italic;
}
@media print {
  .container {
    padding: 0 !important;
  }
}
.add-product-card-btn {
  color: white;
  transition: 0.5s;
  text-decoration: none;
  font-size: 16px;
  border-radius: 6px;
  outline: none;
  border: 2px solid transparent;
  position: relative;
  width: 230px;
  color: rgb(0, 78, 0);
  display: inline-flex;
  align-items: flex-start;
}
.add-product-card-btn:hover {
  color: orange;
}
.inputHightlight {
  .v-input {
      margin: 2px;
      border-radius: 8px;
      border-style: solid;
      border: white;
      border-top-width: 10px;
      border-left-width: 5px;
      border-style:solid;
  }
  .v-input:focus-within{
      background: rgb(240, 240, 240) ;
      border-color:rgb(240, 240, 240);
  }
}
</style>