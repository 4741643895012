import { http } from '@/api';

export default {
  getDischargePlansByTerminalId: (terminalId, pagination) => http.get(
    `/v1/terminals/${terminalId}/plans`, {
      params: {
        offset: pagination.offset,
        limit: pagination.rowsPerPage,
        sortBy: pagination.sortBy,
        sortDirection: pagination.sortDirection,
        filter: pagination.search
      }
    }
  ),
  getDischargePlan: id => http.get(`/v1/plans/${id}`),
  updateDischargePlan: (id, dischargePlan) => http.put(
    `/v1/plans/${id}`, {
      planModel: dischargePlan
    }
  ),
  createDischargePlan: (terminalId, vessel, voyage, pilotOnBoard) => http.post(
    '/v1/plans/newplan', {
      terminalId,
      vessel,
      voyage,
      pilotOnBoard
    }
  ),
  abandonDischargePlan: planId => http.put(
    `/v1/plans/${planId}/abandon`, {
      planId
    }
  ),
  reactivateDischargePlan: planId => http.put(
    `/v1/plans/${planId}/reactivate`, {
      planId
    }
  )
}