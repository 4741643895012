export const shipPerformanceStatus = {
  NA: { id: 10, description: 'Not Started', colour: 'orange' },
  Good: { id: 20, description: 'Good', colour: 'green' },
  Fair: { id: 30, description: 'Fair', colour: 'orange' },
  Poor: { id: 40, description: 'Poor', colour: 'red' }
};

export const reportStatus = {
  NotStarted: { id: 10, description: 'Not Started', colour: 'orange' },
  InProgress: { id: 20, description: 'In Progress', colour: 'orange' },
  Completed: { id: 30, description: 'Completed', colour: 'green' }
}