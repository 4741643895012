<template>
  <v-container fluid fill-height>
    <v-layout fluid fill-height row wrap v-if="checklistData">
      <tanker-discharge-receipt-plan-info />

      <v-flex xs12 pa-3>
        <v-flex xs12 pb-2 class="print-100 print-center">
          <h1>{{ checklistSectionData.title }}</h1>
        </v-flex>
        <checklist-section 
          :checklistSectionData="checklistSectionData"
          @updateComment="updateCommentInChecklistSection"
          @updateSubmission="updateSubmissionInChecklistSection" />
        <v-layout class="no-print" row mt-4 pa-3 justify-end>
          <v-flex xs2 mt-3 pa-2>
            <flat-button 
              class="button"
              @click="onSave"
              :loading="isLoading"
              :disabled="isSaveDisabled"> Save 
            </flat-button>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { UI } from '@/store/ui';
import { CHECKLIST } from '@/store/checklists';
import { cloneDeep } from '@/helpers/object';
import isEqual from 'lodash/isEqual';
import { checklist, checklistSectionType } from '@/constants/checklists';
import { ROUTE_NAMES } from '../routes'
import ChecklistSection from './ChecklistSection.vue';

export default {
  components: { ChecklistSection },
  async created() {
    await this.fetchData();
    this.checklistData = cloneDeep(this.$store.state.checklist.checklist);
    this.checklistData.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.submissionResult == null) {
          question.submissionResult = {
            submissions: [],
            comment: ''
          };
        }
        question.submissionResult.planId = this.idFromRoute;
        question.submissionResult.checklistQuestionId = question.id;
        question.submissionResult.checklistType = this.checklistType;
      });
    });

    this.baseChecklistData = cloneDeep(this.checklistData);
    this.autoSaveInterval = setInterval(() => {this.runAutoSaveInterval()}, 180000); // set timer to 3 minutes
  },
  async beforeRouteLeave(to, from, next) {
    clearInterval(this.autoSaveInterval);
    if (this.isThereUnsavedChanges()) {
      await this.autoSave();
    } 
    next();
  },
  watch: {
    $route: 'fetchData'
  },
  data() {
    return {
      loading: false,
      checklistData: null,
      baseChecklistData: null,
      autoSaveInterval: null,
      baseChecklistSectionData: null
    };
  },
  computed: {
    checklistSectionData(){      
      return this.checklistData.sections.filter(cs => cs.id === this.checklistSectionType)[0];
    },
    checklistType(){
      if(this.pathIsChildOf(ROUTE_NAMES.TANKER_DISCHARGE)){
        return checklist.tankerReceipt.type;
      }
      else if(this.pathIsChildOf(ROUTE_NAMES.TANK_TO_TANK)){
        return checklist.tankToTank.type;
      }
      throw 'Checklist type could not be resolved';
    },
    checklistId(){
      if(this.pathIsChildOf(ROUTE_NAMES.TANKER_DISCHARGE)){
        return checklist.tankerReceipt.id;
      }
      else if(this.pathIsChildOf(ROUTE_NAMES.TANK_TO_TANK)){
        return checklist.tankToTank.id;
      }
      throw 'Checklist id could not be resolved';
    },
    idFromRoute(){
      if(this.checklistType == checklist.tankerReceipt.type){
        return this.$route.params.planId;
      }else if(this.checklistType == checklist.tankToTank.type){
        return this.$route.params.tankToTankPlanId;
      }
      throw 'Id from route could not be resolved';
    },
    checklistSectionType(){
      if(this.checklistType == checklist.tankerReceipt.type){
        if(this.pathIsChildOf(ROUTE_NAMES.PRE_ARRIVAL)){
          return checklistSectionType.dischargePreArrivalId;
        }
        else if (this.pathIsChildOf(ROUTE_NAMES.ARRIVAL)) {
          return checklistSectionType.dischargeArrivalId;
        } 
        else if (this.pathIsChildOf(ROUTE_NAMES.DISCHARGE)) {
          return checklistSectionType.dischargeDischargeId;
        } 
        else if (this.pathIsChildOf(ROUTE_NAMES.POST_DISCHARGE)) {
          return checklistSectionType.dischargeAfterDischargeId;
        }
      }
      else if(this.checklistType == checklist.tankToTank.type){
        if (this.pathIsChildOf(ROUTE_NAMES.PRE_TRANSFER)) {
          return checklistSectionType.tankToTankPreArrivalId;
        } 
        else if (this.pathIsChildOf(ROUTE_NAMES.TRANSFER)) {
          return checklistSectionType.tankToTankDischargeId;
        }
        else if (this.pathIsChildOf(ROUTE_NAMES.POST_TRANSFER)) {
          return checklistSectionType.tankToTankAfterDischargeId;
        }
      }
      throw 'Section type could not be resolved.'
    },
    isLoading() {
      return this.uiIsLoading || this.loading;
    },
    isSaveDisabled() {
      return this.isLoading;
    },
    ...mapState({
      checklist: state => state.checklist,
      uiIsLoading: state => state.ui.isLoading
    })
  },
  methods: {
    updateCommentInChecklistSection(sectionId, questionId, newComment) {
      this.checklistData.sections.forEach((section) => {
        if (section.id === this.checklistSectionType)
        {
          section.questions.forEach((question) => {
            if (question.id === questionId) {
              question.submissionResult.comment = newComment;
            }            
          })
        }
      })                                          
    },
    updateSubmissionInChecklistSection(sectionId, questionId, newSubmissions) {
      this.checklistData.sections.forEach((section) => {
        if (section.id === this.checklistSectionType)
        {
          section.questions.forEach((question) => {
            if (question.id === questionId) {
              question.submissionResult.submissions = newSubmissions;
            }
          })
        }
      })                                          
    },
    pathIsChildOf(parentPath){
      return this.$route.matched.flatMap(x => x.name).includes(parentPath);
    },
    onSave() {
      this.loading = true;
      const success = this.saveCheckList();
      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-checklist',
            text: 'Successfully updated checklist'
          },
          { root: true }
        );
      } else {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'error-update-checklist',
            text: 'Updating checklist failed',
            error: true
          },
          { root: true }
        );
      }

      this.loading = false;
    },
    async saveCheckList() {
      const submissionResults = this.checklistData.sections.reduce(
        (submissionResults, section) => {
          submissionResults.push(...section.questions.map(q => q.submissionResult));
          return submissionResults;
        },
        []
      );

      const success = await this.$store.dispatch(
        CHECKLIST.NS(CHECKLIST.ACTIONS.UPDATE_CHECKLIST_ANSWERS),
        { 
          submissionResults, 
          planId: this.idFromRoute, 
          checklistType: this.checklistType 
        }
      );
      return success;
    },
    async fetchData() {
      await this.$store.dispatch(
        CHECKLIST.NS(CHECKLIST.ACTIONS.GET_CHECKLIST),
        { 
          planId: this.idFromRoute, 
          checklistId: this.checklistId,
          checklistType: this.checklistType, 
          sectionId: this.checklistSectionType
        }
      );
    },
    async autoSave() {
      await this.saveCheckList();
    },
    isThereUnsavedChanges() {
      return !isEqual(
        this.checklistData,
        this.baseChecklistData
      );
    },
    runAutoSaveInterval() {
      if (this.isThereUnsavedChanges()) {
        this.autoSave();
        this.baseChecklistData = cloneDeep(this.checklistData);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/_responsive.scss';
@import '../assets/styles/_variables.scss';
.question {
  font-size: 120%;
}
// always show input labels in "active" position and display full label
:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}
.checkbox-padding {
  padding-right: 18px;
}
.v-expansion-panel {
  box-shadow: none !important;
}
.v-expansion-panel__container {
  border-top: none !important;
}

@media print {
   :deep(.custom-placeholder-color) input::-webkit-input-placeholder {
    color: transparent !important;
  }
  :deep(.custom-placeholder-color) input::placeholder {
    color: transparent !important;
  }

  :deep(.custom-placeholder-color) input::-moz-placeholder {
    color: transparent !important;
  }

  :deep(.custom-placeholder-color) input::-ms-placeholder {
    color: transparent !important;
  }
}
</style>