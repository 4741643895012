import { http } from '@/api';

export default {
  getTimeScaleByCargoPlanId: cargoPlanId => http.get(`/v1/cargoplan/${cargoPlanId}/timeScale`),

  updateTimeScale: ( timeScale ) => http.put(
    `/v1/cargoplan/updatetimescale`, {
      timeScaleModel: timeScale
    }
  ),

  createTimeScale: ( timeScale) => http.post(
    `/v1/cargoplan/createtimescale`, {
      timeScaleModel: timeScale
    }
  ),
}