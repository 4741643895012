import Vue from "vue";

export const CARGO_DOCK_HOSE_INFORMATION_MUTATIONS = {
    SET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL: 'setCargoDockHoseInformationByTerminal'
};

export const mutations = {
    [CARGO_DOCK_HOSE_INFORMATION_MUTATIONS.SET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL](state, cargoDockHosesList) {
        Vue.set(state, 'cargoDockHoses', cargoDockHosesList);
    }
};