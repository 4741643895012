<template>
  <v-toolbar v-if="activeTerminal && !(operationValue === null)" dense dark flat color="grey lighten-1" :class="['sticky-toolbar']">
    <v-toolbar-title class="route-name-font" :id="pageNameRoute + '-sub-bar-heading'">{{ pageNameRoute }}</v-toolbar-title>
    <v-spacer />
    <v-card flat dark color="grey lighten-1" v-if="planStatus" px-1 style="font-size: 16px; color: black;">
      Status: &nbsp;
      <strong>{{ planStatus }}</strong>
    </v-card>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark @click="$router.go(-1)" v-bind="attrs" v-on="on" px-1>
          <v-icon color="black" id='back-btn'>keyboard_return</v-icon>
        </v-btn>
      </template>
      <span>Back</span>
    </v-tooltip>
    <div v-if="displayExitButton">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark @click="exitToLandingPage()" v-bind="attrs" v-on="on" px-1>
            <v-icon color="black">meeting_room</v-icon>
          </v-btn>
        </template>
        <span>Exit</span>
      </v-tooltip>
    </div>
    
    <template v-if="showActiveTanks || viewProductTabs" v-slot:extension>
        <v-tabs v-if="showActiveTanks && !viewProductTabs" align-with-title fixed-tabs>
            <v-tabs-slider color="#1e196a"/>
            <v-tab v-for="(tank, i) in activeTanks" :key="`${tank}-link-${i}`" 
              v-on:click.native="handleTankLink(tank.id, tank.receiptPlanId)"
              :style="{ color:mapTankProductColour(tank), backgroundColor:mapTankProductColour(tank, true) }"
              :class="[isActiveTankItem(tank) ? activeSelection() : inactiveItem()]">
              {{ tank.name }} ({{ tank.product }})
            </v-tab>
        </v-tabs>
        <v-tabs v-if="viewProductTabs && !showActiveTanks" align-with-title fixed-tabs>
          <v-tabs-slider color="#1e196a"/>
          <v-tab v-for="(product, i) in activeProducts" :key="`${product}-link-${i}`"
            v-on:click.native="handleProductLink(product)"
            :style="{ color:mapProductColour(product), backgroundColor:mapProductColour(product, true) }"
            :class="[isActiveProductItem(product) ? activeSelection() : inactiveItem()]">
            {{ product }}
          </v-tab>
        </v-tabs>
    </template>
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { mapProductToColor, mapProductToTextColor } from '../../helpers/ColorMapper';
import { UI } from '@/store/ui';
import { ROUTE_NAMES } from '@/routes';

export default Vue.extend({
  methods: {
    isActiveProductItem(product) {
      return (`${this.activeProduct}` === `${product}`);
    },
    isActiveTankItem(tank) {
      return (`${this.activeTankId}` === `${tank.id}`);
    },
    activeSelection() {
      return `v-tabs__item--active v-tabs__div active-selection font-weight-bold`;
    },
    inactiveItem() {
      return `v-tabs__div text-subtitle-1 white--text`;
    },
    mapTankProductColour(tank, background = false) {
      if (!tank || !this.isActiveTankItem(tank)) return "grey";
      return this.mapColor(tank.product, background)
    },
    mapProductColour(product, background = false) {
      if (!product || !this.isActiveProductItem(product)) return "grey";
      return this.mapColor(product, background)
    },
    mapColor(product, background = false) {
      if (background) return mapProductToColor(product);
      return mapProductToTextColor(product);
    },
    exitToLandingPage() {
      const params = {
        terminalId: this.$route.params.terminalId
      };

      if (this.$route.path.includes(ROUTE_NAMES.TANKER_DISCHARGE)) { 
        this.$router.replace({name: ROUTE_NAMES.RECEIPT_PLAN_LIST, params: params})
      } else if (this.$route.path.includes(ROUTE_NAMES.TANK_TO_TANK)) {
        this.$router.replace({name: ROUTE_NAMES.TANK_TO_TANK_RECEIPT_PLAN_LIST, params: params})
      }
    },
    setLastTankId(id) {
      this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_TANK_ID), id);
    },
    setLastReceiptPlanId(id) {
      this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_RECEIPT_PLAN_ID), id);
    },
    setLastPipelineProduct(product) {
      this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_LAST_PIPELINE_PRODUCT), product);
    },
    handleTankLink(tankId, receiptPlanId) {
        let route = "";

        if (this.$route.path.includes(ROUTE_NAMES.DISCHARGE_QUALITY_REPORT)) {
          route = ROUTE_NAMES.DISCHARGE_QUALITY_REPORT;
        }else{
          route = ROUTE_NAMES.DISCHARGE_TANK_MONITORING;
        }
        this.setLastReceiptPlanId(receiptPlanId);
        this.setLastTankId(tankId);
        if(this.$route.params.receiptPlanId != receiptPlanId || this.$route.params.tankPlanId != tankId) {
          const params = {
            receiptPlanId: this.lastReceiptPlanId,
            tankPlanId: this.lastTankId 
          }
          this.$router.replace({name: route, params: params})
        }
    },
    handleProductLink(product) {
        this.setLastPipelineProduct(product);
        if (this.$route.params.product !== product) {
          const params = {
            product: product
          }
          this.$router.replace({name: ROUTE_NAMES.PIPELINE_SAMPLING, params: params})
        }
    }
  },
  computed: {
    displayExitButton() {
      if (this.$route.path.includes(ROUTE_NAMES.TANKER_DISCHARGE) && this.receiptPlanId) {
          return true;
      } else if (this.$route.path.includes(ROUTE_NAMES.TANK_TO_TANK) && this.tankToTankPlanId) {
          return true;
      }

      return false;
    },
    qualityReportRoute() {
        return this.$router.options.routes
                    .filter(t => t.name === ROUTE_NAMES.TANKER_DISCHARGE)[0].children
                    .filter(t => t.name === ROUTE_NAMES.POST_DISCHARGE)[0].children
                    .filter(t => t.name === ROUTE_NAMES.DISCHARGE_QUALITY_REPORT)[0].path;
    },
    dischargeRoutes() {
        return this.$router.options.routes
                    .filter(t => t.name === ROUTE_NAMES.TANKER_DISCHARGE)[0].children
                    .filter(t => t.name === ROUTE_NAMES.DISCHARGE)[0].children;
    },
    lineSamplingRoute() {
        return this.dischargeRoutes.filter(t => t.name === ROUTE_NAMES.PIPELINE_SAMPLING)[0].path;
    },
    monitoringRoute() {
        return this.dischargeRoutes.filter(t => t.name === ROUTE_NAMES.DISCHARGE_TANK_MONITORING)[0].path;
    },
    lastTankId() {
      return this.$store.getters[UI.NS(UI.GETTERS.LAST_TANK_ID)];
    },
    lastReceiptPlanId() {
      return this.$store.getters[UI.NS(UI.GETTERS.LAST_RECEIPT_PLAN_ID)];
    },
    lastPipelineProduct() {
      return this.$store.getters[UI.NS(UI.GETTERS.LAST_PIPELINE_PRODUCT)];
    },
    activeReceiptPlanId() {
      return this.$route.params && this.$route.params.receiptPlanId;
    },
    activeTankId() {
      return this.$route.params && this.$route.params.tankPlanId;
    },
    activeProduct() {
      return this.$route.params && this.$route.params.product;
    },
    pageNameRoute() {
      return this.$route.meta.title;
    },
    planStatus() {
      return this.dischargePlan && this.dischargePlan.planStatus;
    },
    receiptPlanId() {
      return this.$route.params && this.$route.params.planId;
    },
    tankToTankPlanId() {
      return this.$route.params && this.$route.params.tankToTankPlanId;
    },
    showActiveTanks() {
        return ((this.activeTankId && this.activeReceiptPlanId) &&
                  (this.activeTanks && this.activeTanks.length > 0));
    },
    viewProductTabs() {
        return this.$route.meta.showProductTabs && this.activeProducts && this.activeProducts.length > 0;
    },
    receiptPlans() {
      return this.dischargePlan && this.dischargePlan.receiptPlans;
    },
    activeProducts() {
      return (this.receiptPlans &&
        Object.values(this.receiptPlans)
          .reduce(
            (tanks, receiptPlan) => tanks.concat(
                Object.values(receiptPlan.tankPlans).filter(
                  tank => !!tank.plannedReceipt || !!tank.pipelineAdjustments
                ).map(tank => tank.product.toLowerCase())
              ),
            []
          ).filter((v, i, s) => s.indexOf(v) === i) // make the product list unique
      );
    },
    activeTanks() {
      // active tanks are tanks with receipt plan or pipeline adjustments
      return (
        this.receiptPlans &&
        Object.values(this.receiptPlans)
          .reduce(
            (tanks, receiptPlan) => tanks.concat(
                Object.values(receiptPlan.tankPlans).filter(
                  tank => !!tank.plannedReceipt || !!tank.pipelineAdjustments
                )
              ),
            []
          )
      );
    },
    ...mapState({
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
      activeTerminal: state => state.terminals && state.terminals.activeTerminal,
      operationId: state => state.ui.operationId
    }),
    ...mapGetters({
      operationValue: UI.NS(UI.GETTERS.OPERATION_ID)
    })
  }
});

</script>

<style lang="scss" scoped>
@import '../../assets/styles/_variables.scss';
.navigation {
  position: sticky;
  width: 100%;
  z-index: 6;
  top: 64px;
}

.sticky-toolbar {
  position: sticky; 
  width: 100%;
  z-index: 6;
  top: 64px;
}

.v-tabs__div {
  margin: 0 5px;
  border-radius: 3px 3px 0 0;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.v-tab {
  top: 6px;
}

.active-selection {
  font-size: 30px;
  top: 0px !important;
  border-style: solid;
  border-bottom-style: none;
  border-color: #363636ea;
  border-width: 0.5px;
}

.tank-sub-menu {
  background-color: $mid-charcoal;
}
.tab-label {
  color: #333;
}
.tank-name {
  padding: 0 10px;
}
.route-name-font{
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  font-size: 26px; 
  color: black;
}
</style>