<template>
  <div v-if="isLoading" class="loading-shim"></div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
.loading-shim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: width 0.2s, opacity 0.4s;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
