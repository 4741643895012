 <template>
  <v-layout row wrap v-if="hasLoaded" pa-5>
    <v-col xs3 pr-3>
      <v-text-field color="orange lighten-1"
        class="input-width"
        v-model="vessel"
        label="Vessel"
        :rules="[
          () => $v.vessel.required || 'Vessel is required'
        ]"
        :disabled="isDisabled"
        @input="$v.vessel.$touch()"
        :readonly="readOnly"
        id="vesselTextFeild"
      />
    </v-col>
    <v-col xs3 pr-3>
      <v-text-field color="orange lighten-1"
        class="input-width"
        v-model="voyage"
        label="Voyage"
        :rules="[
          () => $v.voyage.required || 'Voyage is required'
        ]"
        :disabled="isDisabled"
        @input="$v.voyage.$touch()"
        :readonly="readOnly"
        id="voyageTextFeild"        
      />
    </v-col>
    <v-col xs3>
      <v-datetime-picker
        v-if="!readOnly" color="orange lighten-1"
        label="Pilot on board"
        v-model="pilotOnBoard"
        :datePickerProps="{
            showCurrent:false
          }"
        :textFieldProps="{
            errorMessages: pilotOnBoardErrors
          }"
        @input="$v.pilotOnBoard.$touch()"
        :disabled="isDisabled || readOnly"      
      />
      <v-text-field color="orange lighten-1"
        v-if="readOnly"
        class="input-width"
        :value="pilotOnBoard | formatDateTime('yyyy-MM-dd HH:mm')"
        label="Pilot on board"
        :readonly="true"
        id="pilotOnBoardTextFeild"  
      />
    </v-col>
    <v-col xs3 v-if="showSave && $v.$anyDirty">
      <v-btn color="orange lighten-1"
        @click="onSave"
        :loading="isLoading"
        :disabled="isLoading || isDisabled || $v.$invalid "
        id="saveVesselInfoButton"
      >Save</v-btn>
    </v-col>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import { DISCHARGE_PLAN } from '@/store/tankerDischarge/preArrival/dischargePlan';
import { required } from 'vuelidate/lib/validators';
import { date } from '@/validators';
import { formatDateTimeToMasked } from '@/helpers/date';
import {
  DISABLED_STATUSES
} from '@/constants/receiptPlan';
import { UI } from '@/store/ui';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";

export default {
  watch: {
    "$v.$invalid": {
      immediate: true,
      handler: function (value) {
        this.$emit('validityChanged', value);
      }
    }
  },
  data() {
    return {
      isDirty: false,
      loading: false
    };
  },
  props: {
    showSave: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    onValidityChange: {
      type: Function,
      default: () => { }
    }
  },
  methods: {
    async save() {
      if (this.$v.$anyDirty) {
        await this.onSave();
      }
    },
    async onSave() {
      this.loading = true;
      const dischargePlan = this.dischargePlan;

      const success = await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.UPDATE_DISCHARGE_PLAN),
        { id: dischargePlan.id, dischargePlan }
      );

      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-discharge-plan',
            text: 'Successfully updated receipt plan'
          },
          { root: true }
        );
      }
      this.loading = false;
    }
  },
  computed: {
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    planStatus() {
      return this.dischargePlan && this.dischargePlan.planStatus;
    },
    hasLoaded() {
      return Boolean(this.dischargePlan && this.dischargePlan.id);
    },
    isLoading() {
      return this.loading;
    },
    isDisabled() {
      return DISABLED_STATUSES.includes(this.planStatus);
    },
    vessel: {
      get() {
        return this.dischargePlan && this.dischargePlan.vessel;
      },
      set(value) {
        this.$store.commit(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_VESSEL),
          value
        );
        this.isDirty = true;
      }
    },
    voyage: {
      get() {
        return this.dischargePlan && this.dischargePlan.voyage;
      },
      set(value) {
        this.$store.commit(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_VOYAGE),
          value
        );
        this.isDirty = true;
      }
    },
    pilotOnBoard: {
      get() {
        return formatDateTimeToMasked(this.dischargePlan && this.dischargePlan.pilotOnBoard, DATE_TIME_FORMAT);
      },
      set(value) {
        this.$store.commit(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_PILOT_ON_BOARD),
          value
        );
        this.isDirty = true;
      }
    },
    pilotOnBoardErrors() {
      let errors = [];

      if (!this.$v.pilotOnBoard.$dirty || this.$v.pilotOnBoard.$pending) {
        return [];
      }

      if (!this.$v.pilotOnBoard.required || !this.$v.pilotOnBoard.date) {
        errors = [...errors, 'A valid date is required'];
      }

      return errors;
    },
    ...mapState({
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan
    })
  },
  validations() {
    return {
      vessel: {
        required
      },
      voyage: {
        required
      },
      pilotOnBoard: {
        required,
        date
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';
</style>
