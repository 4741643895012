import { http } from '@/api';

export default {
  getPreArrivalInfo: ({ planId }) => http.get(
    `v1/plans/${planId}/prearrivalinfos`
  ),
  updatePreArrivalInfo: ({ planId, preArrivalInfo }) => http.put(
    `/v1/plans/${planId}/prearrivalinfos`, {
      planId,
      model: preArrivalInfo
    }
  ),
  getPreArrivalInfoInitData: ({ planId }) => http.get(
    `v1/plans/${planId}/prearrivalinfos/init`
  )
}