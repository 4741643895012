<template>
   <v-dialog max-width="550" v-model="showDialog" persistent>
    <v-card>
      <v-card-title class="headline">Post Receipt Storage Tank Sample</v-card-title>
      <v-divider class="divider"/>
      <v-card-text class="ma-0 pa-0">
        <v-tabs
          color="orange lighten-1"
          center
        >
          <v-tab
            v-for="item in postReceiptStorageTankSamples"
            :key="item.name + 'Tab'">
            {{ item.name }}
          </v-tab>
          <v-tab-item
            v-for="item , index in postReceiptStorageTankSamples"
            :key="item.name + 'TabItem'"
          >
          <v-container class="popup-input">
            <v-layout>
              <v-flex xs6 pr-2>
                 <v-select  item-color="orange lighten-1"
                            label="Appearance" color="orange lighten-1"
                            :items="appearanceList"
                            v-model="item.appearance"
                            clearable
                            id="appearanceInput"/>
              </v-flex>
              <v-flex xs6 pr-2>
                <v-select item-color="orange lighten-1"
                          label="Colour" 
                          color="orange lighten-1"
                          :items="productColourItems"
                          v-model="item.colour"
                          clearable
                          id="colourInput"/>
              </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs6 pr-2>
                  <v-currency-field 
                    v-model="item.temperature" 
                    :defaultValue="null"
                    :label="'Temperature \u2103'" 
                    color="orange lighten-1" 
                    :decimalLength="1" 
                    :max="inputConfig.temperature.max"
                    :autoDecimalMode="false"
                    id="temperatureInput"/>
              </v-flex>
              <v-flex xs6 pr-2>
                  <v-currency-field 
                    v-model="item.density" 
                    :defaultValue="null"
                    :label="'Density kg/l @15\u2103'"
                    color="orange lighten-1"  
                    :decimalLength="4" 
                    :autoDecimalMode="false"
                    :max="inputConfig.density.max"
                    id="densityInput"/>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6 pr-2 v-if="showFlashPoint(product)">
                   <v-currency-field 
                    :key="index + 'flashPoint'" 
                    v-model="item.flashPoint"
                    :defaultValue="null" 
                    :label="'Flash Point \u2103'"  
                    color="orange lighten-1"                         
                    :decimalLength="1" 
                    :autoDecimalMode="false"
                    :max="inputConfig.flashPoint.max"
                    id="flashPointInput"/>
              </v-flex>
              <v-flex xs6 pr-2 v-if="showConductivity(product)">
                  <v-currency-field 
                    v-model="item.conductivity" 
                    :defaultValue="null"
                    label="Conductivity pS/m" 
                    color="orange lighten-1" 
                    id="conductivityInput"
                    :max="inputConfig.conductivity.max"
                    :error-messages="conductivityErrors(index)"
                    v-mask="'###'"/>
              </v-flex>          
            </v-layout>
            <v-layout>
                <v-flex xs6 pr-2 v-if="showDensitometerSerialNumber(item.name)">
                  <v-text-field 
                  v-model="item.densitometerSerialNumber" 
                  label="Densitometer Serial Number"  
                  color="orange lighten-1" 
                  id="densitometerInput"/>
              </v-flex>
              <v-flex xs6 pr-2 v-if="showDateOfSample(item.name)">
                <v-text-field 
                  label="Date of Sample"
                  v-model="item.dateOfSample"
                  color="orange lighten-1"
                  v-mask="'####-##-##'"
                  :error-messages="dateOfSampleErrors"
                  placeholder="yyyy-MM-dd"
                  id="dateofSampleInput"/>
              </v-flex>
            </v-layout>
  
            <v-divider class="divider" v-if="showCertificationNumber(index) && showHoursSettled(index) && showStaticDissipatorAdditive(index)"/>
  
            <v-layout>
              <v-flex xs6 pr-2 v-if="showCertificationNumber(index)">
                <v-text-field 
                  label="Certification Test Report Number"
                  v-model="item.certificationTestReportNumber"
                  color="orange lighten-1"
                  id="certificationTestReportNumberInput"/>
              </v-flex>
              <v-flex xs6 pr-2 v-if="showHoursSettled(index)">
                <v-currency-field
                  label="Hours Settled"
                  v-model="item.hoursSettled"
                  color="orange lighten-1"
                  :decimalLength="2" 
                  :defaultValue="null"
                  :autoDecimalMode="false"
                  :max="inputConfig.hoursSettled.max"
                  id="hoursSettledInput"/>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs6 pr-2 v-if="showStaticDissipatorAdditive(index)">
                <v-currency-field 
                  v-model="item.staticDissipatorAdditive" 
                  :defaultValue="null"
                  label="Static Dissipator Additive mg/L"  
                  color="orange lighten-1"  
                  :decimalLength="2" 
                  :autoDecimalMode="false"
                  :max="inputConfig.staticDissipatorAdditive.max"
                  id="staticDissipatorAdditiveInput"/>
              </v-flex>
            </v-layout>
          </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions >
          <v-menu v-if="testMethods" v-model="testMethodPopOver" persistent :close-on-content-click="false" id="testMethodPopOver" top  open-on-hover offset-y >
            <template v-slot:activator="{ on, attrs }">
                 <v-btn  v-bind="attrs" v-on="on" slot="activator" attach="testMethodPopOver"  elevation="2" small color="white lighten-3 ">Test Method ▲</v-btn>             
            </template>
            <v-card  >
              <v-card-title>Test Methods</v-card-title>
              <v-divider></v-divider>
              <table >
                <tr>
                  <th>Density</th>
                  <td>{{testMethods.density}}</td>
                </tr>
                <tr>
                  <th>Flash Point</th>
                  <td>{{testMethods.flashPoint}}</td>
                </tr>
                <tr>
                  <th>Conductivity</th>
                  <td>{{testMethods.conductivity}}</td>
                </tr>
                 <tr>
                  <th>Clarity</th>
                  <td>{{testMethods.clarity}}</td>
                </tr>
              </table>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary lighten-3"
                  @click="testMethodPopOver = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        <v-spacer></v-spacer>
        <v-btn  color="secondary lighten-3" @click="() => this.$emit('close')" id="cancelButton">Cancel</v-btn>
        <v-btn color="orange lighten-1" @click="onSave" id="saveButton" :disabled="disableSave || isLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import { getAppearanceList } from '@/helpers/appearance';
import { QUALITY_REPORT } from '@/store/tankerDischarge/postDischarge/qualityReport';
import { cloneDeep } from '@/helpers/object';
import { mapState } from "vuex";
import { formatDate } from '@/helpers/date';
import { maskedDate } from '@/validators';
import { PRODUCTS } from "@/constants/products";
import {DATE_PICKER_DATE_FORMAT} from "@/constants/dateFormat"
import { format } from 'date-fns'
import { validateConductivity } from '@/helpers/validation/sharedThresholds';
import inputConfig from "@/constants/inputConfig"

const sampleTypes = {
  upperTank: {
    name: "Upper Tank",
    type: "UpperTank"
  },
  middleTank: {
    name: "Middle Tank",
    type: "MiddleTank"
  },
  lowerTank: {
    name: "Lower Tank",
    type: "LowerTank"
  },
  composite: {
    name: "Composite",
    type: "CompositeTank"
  }
}

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    testMethods: {
      type: Object
    },
    product: {
      type: String
    }
  },
  created() {
    this.postReceiptStorageTankSamples = this.defaultPostReceiptStorageTankSamples;
  },
  data() {
    return {
      isLoading: false,
      testMethodPopOver: false,
      productColourItems: [
        { text: 'Purple', value: 'Purple' },
        { text: 'Red', value: 'Red' },
        { text: 'Straw', value: 'Straw' },
        { text: 'Water white', value: 'WaterWhite' },
        { text: 'Yellow', value: 'Yellow' },
        { text: 'Black', value: 'Black' },
        { text: 'Pink', value: 'Pink' },
        { text: 'Orange', value: 'Orange' },
        { text: 'Pale Straw', value: 'PaleStraw' },
        { text: 'Colourless', value: 'Colourless' },
        { text: 'Other', value: 'Other' }
      ],
      postReceiptStorageTankSamples:[]
    }
  },
  watch: {
    showDialog: {
      immeditate: true,
      handler(showDialog) {
        if(showDialog){    
          if(this.savedPostReceiptStorageTankSamples && this.savedPostReceiptStorageTankSamples.length > 0){
            this.postReceiptStorageTankSamples = cloneDeep(this.savedPostReceiptStorageTankSamples).map(sample => {
              return {
                ...sample,
                dateOfSample: formatDate(sample.dateOfSample, DATE_PICKER_DATE_FORMAT),
                name: this.tabNameFromSampleType(sample.type)
              }
            });
          }
        }
      }
    }
  },
  computed: {
    inputConfig(){
      return inputConfig;
    },
    indexOfCompositeSample(){
      return Object.values(sampleTypes).findIndex(t => t.type == sampleTypes.composite.type)
    },
    dateOfSampleErrors(){
      if (!this.$v.postReceiptStorageTankSamples.$each[this.indexOfCompositeSample].dateOfSample.maskedDate) {
        return ['A valid date is required'];
      }
      if(!this.$v.postReceiptStorageTankSamples.$each[this.indexOfCompositeSample].dateOfSample.isValid){
          return "Date cannot be in the future"
      }
      return [];
    },
    appearanceList(){
      return this.product ? getAppearanceList(this.product) : [];
    },
    defaultPostReceiptStorageTankSamples(){
      return Object.values(sampleTypes).map(t => {
        return {
          type: t.type,
          name: t.name,
          density: null,
          flashPoint: null,
          conductivity: null,
          temperature: null,
          colour: null,
          dateOfSample: formatDate(new Date(), DATE_PICKER_DATE_FORMAT),
          staticDissipatorAdditive: null,
          hoursSettled: null,
          certificationNumber: null
        }
      })
    },
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    receiptPlanId() {
      return this.$route.params && this.$route.params.receiptPlanId;
    },
    tankPlanId() {
      return this.$route.params && this.$route.params.tankPlanId;
    },
    savedPostReceiptStorageTankSamples(){
      return this.qualityReport && this.qualityReport.postReceiptStorageTankSamples
    },
    disableSave(){
      return this.$v.postReceiptStorageTankSamples.$each[3].dateOfSample.$invalid;
    },
    ...mapState({
      qualityReport: (state) => state.qualityReport && state.qualityReport.qualityReport,
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan
    }) 
  },
  methods: {
    tabNameFromSampleType(type){
      return Object.values(sampleTypes).filter(t => t.type == type)[0].name;
    },
    showDensitometerSerialNumber(tab){
      return tab == sampleTypes.composite.name;
    },
    showDateOfSample(tab){
      return tab == sampleTypes.composite.name && this.product == PRODUCTS.AGO;
    },
    conductivityErrors(index){
      if(this.postReceiptStorageTankSamples[index].conductivity == null) return [];

      if(this.$v.postReceiptStorageTankSamples.$each[index].conductivity.passWithDailyMonitoring){
        return "Daily monitoring is required"
      }
      if(!this.$v.postReceiptStorageTankSamples.$each[index].conductivity.isValid){
          return "Conductivity value is outside the range for the given product."
      }
      return [];  
    },
    showCertificationNumber(sampleIndex){
      return this.product == PRODUCTS.JETA1 && sampleIndex == this.indexOfCompositeSample;
    },
    showHoursSettled(sampleIndex){
      return this.product == PRODUCTS.JETA1 && sampleIndex == this.indexOfCompositeSample;
    },
    showStaticDissipatorAdditive(sampleIndex){
      return this.product == PRODUCTS.JETA1 && sampleIndex == this.indexOfCompositeSample;
    },
    async onSave(){
      this.isLoading = true;
      let success = false;
      if(this.savedPostReceiptStorageTankSamples && this.savedPostReceiptStorageTankSamples.length > 0){
        success = await this.$store.dispatch(
          QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.UPDATE_POST_RECEIPT_STORAGE_TANK_SAMPLE),
          {
            planId: this.planId,
            receiptPlanId: this.receiptPlanId,
            tankPlanId: this.tankPlanId,
            postReceiptStorageTankSamples: this.postReceiptStorageTankSamples
          });
      } else {
        success = await this.$store.dispatch(
          QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.CREATE_POST_RECEIPT_STORAGE_TANK_SAMPLE),
          {
            planId: this.planId,
            receiptPlanId: this.receiptPlanId,
            tankPlanId: this.tankPlanId,
            postReceiptStorageTankSamples: this.postReceiptStorageTankSamples
          }
        );
      }
      if(success){
         await this.$store.dispatch(
          QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.GET_QUALITY_REPORT),
          { tankPlanId: this.tankPlanId,
          planId: this.planId,receiptPlanId: this.receiptPlanId}
        );
        this.$emit('close')
      }
      this.isLoading = false;
    }
  },
  validations: {
    postReceiptStorageTankSamples: {
      $each: {
        dateOfSample: {
          maskedDate,
          isValid: function(value) {
              return !value || format(new Date(value), DATE_PICKER_DATE_FORMAT) <= format(new Date(), DATE_PICKER_DATE_FORMAT);
          }
        },
        conductivity: {
          passWithDailyMonitoring(value){
            return this.product == PRODUCTS.AGO && value >= 50 && value <= 74;
          },
          isValid: function(value){
            return validateConductivity(this.product, value);
          }
        }
      }
    }
  }
};
</script>
<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 12px;
}
th, td {
  padding:5px;
}
td {
  width:60%;
}
th {
  text-align: left;
  background: #F0F0F0;
  width:40%;
}
table{
  margin:14px;
}
</style>