import { mutations, PAGINATION_MUTATIONS } from './mutations';
import { RECEIPT_PLAN_LIST, TANK_TO_TANK_TRANSFER_PLAN_LIST } from '@/constants/pagination';

const namespaced = true;
const NAME_SPACE = 'pagination';
const GET_DEFAULT_PAGINATION = () => {
  return {
    descending: true,
    page: 1,
    rowsPerPage: 10,
    sortBy: 'pilotOnBoard',
    totalItems: 0,
    search: null
  };
};
const TANK_TO_TANK_TRANSFER_PLAN_LIST_PAGINATION  = () => {
  return {
    descending: true,
    page: 1,
    rowsPerPage: 10,
    sortBy: 'date',
    totalItems: 0,
    search: null
  };
};

export const state = {
  [RECEIPT_PLAN_LIST]: GET_DEFAULT_PAGINATION(),
  [TANK_TO_TANK_TRANSFER_PLAN_LIST]: TANK_TO_TANK_TRANSFER_PLAN_LIST_PAGINATION()
};

export const PAGINATION = {
  MUTATIONS: PAGINATION_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const pagination = {
  namespaced,
  state,
  mutations
};
