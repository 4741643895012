<template>
  <v-container fluid fill-height class="page">
    <v-layout row wrap pa-4>
      <v-flex xs12>
        <v-dialog v-model="showDialog" max-width="600" persistent>
          <v-card>
            <v-responsive>
              <v-card-title class="edit-new-dialog-header text-h6">{{ isEdit ? 'Edit' : 'New' }} Pipeline Detail</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field v-model="pipelineName" label="Pipeline Name"
                        id="pipeline-name-dialog" color="orange lighten-1" @input="$v.pipelineName.$touch()" :error-messages="pipelineNameErrors">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-currency-field v-model="diameterNBmm" :autoDecimalMode="false" label="Diameter NB (mm)"
                        id="diameterNBmm-dialog" color="orange lighten-1" @input="$v.diameterNBmm.$touch()" :error-messages="diameterNBmmErrors">
                      </v-currency-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-currency-field v-model="lengthM" :decimalLength="oneDecimalPlace"
                        :autoDecimalMode="false" label="Length (m)" id="lengthM-dialog" color="orange lighten-1" @input="$v.lengthM.$touch()" :error-messages="lengthMErrors">
                      </v-currency-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-currency-field v-model="operatingPressureBarg" :decimalLength="oneDecimalPlace"
                        :autoDecimalMode="false" label="Operating Pressure (barg)" id="operating-pressure-barg-dialog"
                        color="orange lighten-1" @input="$v.operatingPressureBarg.$touch()" :error-messages="operatingPressureBargErrors">
                      </v-currency-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-currency-field v-model="volumeLitres" label="Volume (litres)" id="volume-litres-dialog"
                        color="orange lighten-1" @input="$v.volumeLitres.$touch()" :error-messages="volumeLitresErrors">
                      </v-currency-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea v-model="designStandard" label="Design Standard" id="design-standard-dialog" clearable
                        outlined color="orange lighten-1" counter clear-icon="mdi-close-circle" @input="$v.designStandard.$touch()" :error-messages="designStandardErrors">
                      </v-textarea>
                    </v-col>
                    <v-col>
                      <v-textarea v-model="notes" label="Notes" id="notes-dialog" outlined clearable
                        color="orange lighten-1" counter clear-icon="mdi-close-circle" @input="$v.notes.$touch()" :error-messages="notesErrors">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="secondary lighten-3" variant="text" @click="onClose" id='cancel-btn' class="cancel-btn">Cancel</v-btn>
                <v-btn :disabled="saveIsDisabled || isLoading" color="success" variant="text" @click="onSave" id='save-btn'
                  class="save-btn" :loading="isLoading">Save</v-btn>
              </v-card-actions>
            </v-responsive>
          </v-card>
        </v-dialog>

        <v-card>
          <v-toolbar flat height="100px" style="background-color: #242D40;">
            <v-flex xs12>
              <v-img class="pipeline-img" src="@/assets/icons/pipeline.png" />
              <h3 style="color: white;" class="toolbar-header-text">Pipelines</h3>
            </v-flex>
            <v-flex>
              <v-btn v-if="hasCreateDeleteRole" class="new-btn" @click="onAddNewPipelineDetails" :rounded=true height="50px" color="success"
                id="new-pipeline-btn">+ New Pipeline</v-btn>
            </v-flex>
          </v-toolbar>
          <v-data-table :items="pipelinesList" :headers="headers" class="elevation-1" id="pipeline-information-table"
            hide-default-footer disable-pagination fixed-header :loading="isLoading">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="( item, index ) in  items " :key="index">
                  <td :id="removeSpecialCharsFromString(item.pipelineName) + '-pipeline-name'">{{ item.pipelineName }}</td>
                  <td :id="removeSpecialCharsFromString(item.pipelineName) + '-diameterNBmm'">{{ item.diameterNBmm }}</td>
                  <td :id="removeSpecialCharsFromString(item.pipelineName) + '-lengthM'">{{ item.lengthM | toDecimalPlaces(oneDecimalPlace) }}</td>
                  <td :id="removeSpecialCharsFromString(item.pipelineName) + '-operating-pressure-barg'">{{ item.operatingPressureBarg | toDecimalPlaces(oneDecimalPlace) }}</td>
                  <td :id="removeSpecialCharsFromString(item.pipelineName) + '-volume-litres'">{{ item.volumeLitres | commaSeperateNumber }}</td>
                  <v-menu v-if="item.designStandard !== null" offset-x :nudge-width="100" open-on-hover open-on-click
                    max-height="100" max-width="160">
                    <template v-slot:activator="{ on, attrs }">
                      <td :key="index" v-bind="attrs" v-on="on" :class="'truncate-text'"
                        :id="removeSpecialCharsFromString(item.pipelineName) + '-design-standard'">{{ item.designStandard }}</td>
                    </template>
                    <v-card :class="'comment-box'" elevation="2" hover outlined rounded>
                      <v-card-subtitle>
                        <div class="font-weight-light font-italic">{{ item.designStandard }}</div>
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                  <td v-else :id="removeSpecialCharsFromString(item.pipelineName) + '-design-standard'">{{ item.designStandard}}</td>
                  <v-menu v-if="item.notes !== null" offset-x :nudge-width="100" open-on-hover open-on-click
                    max-height="100" max-width="160">
                    <template v-slot:activator="{ on, attrs }">
                      <td :key="index" v-bind="attrs" v-on="on" :class="'truncate-text'"
                        :id="removeSpecialCharsFromString(item.pipelineName) + '-notes'">{{ item.notes }}</td>
                    </template>
                    <v-card :class="'comment-box'" elevation="2" hover outlined rounded>
                      <v-card-subtitle>
                        <div class="font-weight-light font-italic">{{ item.notes }}</div>
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                  <td v-else :id="removeSpecialCharsFromString(item.pipelineName) + '-notes'">{{ item.notes }}</td>
                  <td :id="removeSpecialCharsFromString(item.pipelineName) + '-documents'">{{ item.documents }}</td>
                  <td>
                    <v-flex>
                      <v-icon v-if="hasEditRole" size="small" :id="removeSpecialCharsFromString(item.pipelineName) + '-edit-btn'"
                        class="me-2 tdp-bar-color" @click="onEditPipelineDetails(index)">edit</v-icon>
                    </v-flex>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { PIPELINE_INFORMATION, GET_DEFAULT_PIPELINE_DETAILS } from '@/store/terminalData/pipelineInformation';
import { TERMINALS } from '@/store/terminals';
import { mapState } from 'vuex';
import { cloneDeep } from '@/helpers/object';
import { required, integer, maxLength } from 'vuelidate/lib/validators';
import { number } from '@/validators';
import { isInSomeRole } from '@/helpers/auth'
import { ADMIN, ENGINEER } from '@/constants/roles'
import { removeSpecialCharsFromString } from '@/helpers/removeSpecialChars';

export default {
  async created() {
    await this.fetchData();
  },

  data: () => ({
    isLoading: false,
    oneDecimalPlace: 1,
    showDialog: false,
    isEdit: false,
    showDeleteConfirmationDialog: false,
    rowInput: GET_DEFAULT_PIPELINE_DETAILS(),
    removeSpecialCharsFromString,
    headers: [
      { text: 'Pipeline Name', value: 'pipelineName', sortable: false },
      { text: 'Diameter NB (mm)', value: 'diameterNBmm', sortable: false },
      { text: 'Length (m)', value: 'lengthM', sortable: false },
      { text: 'Operating Pressure (barg)', value: 'operatingPressureBarg', sortable: false },
      { text: 'Volume (litres)', value: 'volumeLitres', sortable: false },
      { text: 'Design Standard', value: 'designStandard', sortable: false },
      { text: 'Notes', value: 'notes', sortable: false },
      { text: 'Documents', value: 'documents', sortable: false },
      { text: '', value: 'actions', sortable: false }
    ]
  }),

  computed: {
    ...mapState({
      pipelinesList: state => state.pipelineInformation.pipelinesList,
      terminalsList: state => state.terminals.terminalsList,
      terminalsByKey: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.reduce((terminals, terminal) => {
          terminals[terminal.id] = { ...terminal };
          return terminals;
        }, {}),
      activeTerminal: state => state.terminals.activeTerminal
    }),
    hasCreateDeleteRole() {
      return isInSomeRole([ADMIN]);
    },
    hasEditRole() {
      return isInSomeRole([ADMIN, ENGINEER]);
    },
    pipelineNameErrors() {
      if ( !this.$v.pipelineName.$dirty || this.$v.pipelineName.$pending ) {
        return [];
      }
      if ( !this.$v.pipelineName.required ) {
        return 'Pipeline name is required';
      }
      return null;
    },
    diameterNBmmErrors() {
      if ( !this.$v.diameterNBmm.$dirty || this.$v.diameterNBmm.$pending ) {
        return [];
      }
      if ( !this.$v.diameterNBmm.required || !this.$v.diameterNBmm.integer ) {
        return 'Diameter is required';
      }
      return null;
    },
    lengthMErrors() {
      if ( !this.$v.lengthM.$dirty || this.$v.lengthM.$pending ) {
        return [];
      }
      if ( !this.$v.lengthM.required || !this.$v.lengthM.number ) {
        return 'Length is required';
      }
      return null;
    },
    operatingPressureBargErrors() {
      if ( !this.$v.operatingPressureBarg.$dirty || this.$v.operatingPressureBarg.$pending ) {
        return [];
      }
      if ( !this.$v.operatingPressureBarg.required || !this.$v.operatingPressureBarg.number ) {
        return 'Operating pressure is required';
      }
      return null;
    },
    volumeLitresErrors() {
      if ( !this.$v.volumeLitres.$dirty || this.$v.volumeLitres.$pending ) {
        return [];
      }
      if ( !this.$v.volumeLitres.required || !this.$v.volumeLitres.integer ) {
        return 'Volume is required';
      }
      return null;
    },
    designStandardErrors() {
      if ( !this.$v.designStandard.$dirty || this.$v.designStandard.$pending ) {
        return [];
      };
      if ( !this.$v.designStandard.maxLength ) {
        return 'Design standard exceeds 250 characters.';
      };
      return null;
    },
    notesErrors() {
      if ( !this.$v.notes.$dirty || this.$v.notes.$pending ) {
        return [];
      };
      if ( !this.$v.notes.maxLength ) {
        return 'Note exceeds 250 characters.';
      };
      return null;
    },
    pipelineName: {
      get() {
        return this.rowInput.pipelineName;
      },
      set(value) {
        this.rowInput.pipelineName = value;
      }
    },
    diameterNBmm: {
      get() {
        return this.rowInput.diameterNBmm;
      },
      set(value) {
        this.rowInput.diameterNBmm = value;
      }
    },
    lengthM: {
      get() {
        return this.rowInput.lengthM;
      },
      set(value) {
        this.rowInput.lengthM = value;
      }
    },
    operatingPressureBarg: {
      get() {
        return this.rowInput.operatingPressureBarg;
      },
      set(value) {
        this.rowInput.operatingPressureBarg = value;
      }
    },
    volumeLitres: {
      get() {
        return this.rowInput.volumeLitres;
      },
      set(value) {
        this.rowInput.volumeLitres = value;
      }
    },
    designStandard: {
      get() {
        return this.rowInput.designStandard;
      },
      set(value) {
        this.rowInput.designStandard = value;
      }
    },
    notes: {
      get() {
        return this.rowInput.notes;
      },
      set(value) {
        this.rowInput.notes = value;
      }
    },
    saveIsDisabled() {
      return !this.$v.pipelineName.required
      || !this.$v.diameterNBmm.required
      || !this.$v.lengthM.required
      || !this.$v.operatingPressureBarg.required
      || !this.$v.volumeLitres.required
      || !this.$v.designStandard.maxLength
      || !this.$v.notes.maxLength;
    }
  },

  methods: {
    async fetchData() {
      const terminalId = this.$route.params && this.$route.params.terminalId;
      if (!this.terminalsList) {
        await this.$store.dispatch(
          TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS)
        );
      }
      const terminal = this.terminalsByKey[terminalId];
      this.$store.dispatch(
        PIPELINE_INFORMATION.NS(PIPELINE_INFORMATION.ACTIONS.GET_PIPELINE_INFORMATION_BY_TERMINAL),
        terminalId
      );

      if (this.$route.params.terminalId) {
        this.$store.commit(
          TERMINALS.NS(TERMINALS.MUTATIONS.SET_ACTIVE_TERMINAL),
          terminal
        );
      }
    },
    onEditPipelineDetails(index) {
      this.rowInput = cloneDeep({
        ...this.pipelinesList[index]
      });
      this.isEdit = true;
      this.showDialog = true;
    },
    onAddNewPipelineDetails() {
      this.rowInput = GET_DEFAULT_PIPELINE_DETAILS()
      this.isEdit = false;
      this.showDialog = true;
    },
    async onSave() {
      this.isLoading = true;
      const pipelineDetails = cloneDeep({ ...this.rowInput });
      const terminalId = this.$route.params && this.$route.params.terminalId;
      let success;
      if (pipelineDetails.id) {
        success = await this.$store.dispatch(
          PIPELINE_INFORMATION.NS(PIPELINE_INFORMATION.ACTIONS.UPDATE_PIPELINE_DETAILS), {
          id: pipelineDetails.id,
          pipelineDetails
        });
      }
      else {
        success = await this.$store.dispatch(
          PIPELINE_INFORMATION.NS(PIPELINE_INFORMATION.ACTIONS.CREATE_NEW_PIPELINE_DETAILS), {
          terminalId: terminalId,
          pipelineDetails
        });
      }
      if (success) {
        this.$store.dispatch(
          PIPELINE_INFORMATION.NS(PIPELINE_INFORMATION.ACTIONS.GET_PIPELINE_INFORMATION_BY_TERMINAL),
          terminalId
        )
        this.onClose();
      }
      this.isLoading = false;
    },
    onClose() {
      this.isEdit = false
      this.showDialog = false;
      this.rowInput = GET_DEFAULT_PIPELINE_DETAILS();
    },
    deletePipelineDetails() {
      this.showDeleteConfirmationDialog = true;
    },
    onDeleteConfirmationConfirm() {
      this.onCloseDeleteDialog();
    },
    onCloseDeleteDialog() {
      this.showDeleteConfirmationDialog = false;
    }
  },

  validations: {
    pipelineName: { required },
    diameterNBmm: { required, integer },
    lengthM: { required, number },
    operatingPressureBarg: { required, number },
    volumeLitres: { required, integer },
    designStandard: { maxLength: maxLength(250) },
    notes: { maxLength: maxLength(250) }
  }
};
</script>

<style lang="scss" scoped>
.new-btn {
  text-align: right;
  color: white;
  font-size: 13px;
  text-decoration: none;
  position: relative;
  right: 20px;
  outline: none;
  transition: 0.3s;
  border: 2px solid transparent;
  color: white;
}

.save-btn {
  color: white;
  transition: 0.3s;
}

.cancel-btn {
  color: white;
  transition: 0.3s;
}

.new-btn:hover {
  color: rgb(13, 90, 13);
}

.save-btn:hover {
  color: rgb(13, 90, 13);
}

.cancel-btn:hover {
  color: orange;
}

.tdp-bar-color {
  color: rgba(30, 25, 106, 255);
}

.page {
  background-color: rgb(242, 242, 242);
}

.comment-box {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 2px;
}

.truncate-text {
  max-width: 1px;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pipeline-img {
  float: left;
  position: relative;
  left: 20px;
  width: 60px;
  height: 60px;
}

.toolbar-header-text {
  position: relative;
  left: 40px;
  top: 14px;
}

.edit-new-dialog-header {
  color: orange;
  font-weight: bold;
}
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';</style>