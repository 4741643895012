<template>
  <v-dialog persistent max-width="530" v-model="showDialog">
    <v-card>
      <div class="dialog-content-container">
        <v-card-text>
          <v-flex xs12 v-if="tank">
            <v-flex class="elevation-2" :style=" {
                    border: `1px solid ${mapProductToColor(
                      tank.product,
                      true,
                      true
                    )}`,
                    borderRadius: '1px'}">
              <h3
                :style="{
                  color: mapProductToColor(tank.product),
                  backgroundColor: mapProductToColor(tank.product, true),
                  textAlign: 'center',
                  textTransform: 'uppercase'
                }"
              >{{tank.product}}</h3>
              <h3
                :style="{
                  color: mapProductToColor(tank.product),
                  backgroundColor: mapProductToColor(tank.product, true),
                  textAlign: 'center'          
                }"
              >{{tank.name}}</h3>
            </v-flex>
            <v-flex>
              <data-table :headers="headers"
                          :items="tankCalibrations"
                          :show-search="false"
                          :pagination.sync="pagination"
                          class="tank-calibration-table no-sort-datatable">
                        <template v-slot:default="slotProps">
                          <td>
                            <v-currency-field
                              v-model="slotProps.item.millimetres"        
                              :key="slotProps.index + 'tankCalibrationsMillimetres'"
                              :error-messages="getMillimetresErrors(slotProps.index)"
                              @input="recalculuateLitrePerMM(slotProps.index)"
                              :readonly="!hasEditRole"
                            ></v-currency-field>
                          </td>
                            <v-currency-field
                              v-model="slotProps.item.litres"
                              :error-messages="getLitresErrors(slotProps.index)"
                              @input="recalculuateLitrePerMM(slotProps.index)" 
                              :readonly="!hasEditRole"
                            ></v-currency-field>
                          <td>
                            <v-currency-field
                              v-model="slotProps.item.litersPerMM"
                              readonly
                              :decimalLength="4"
                            ></v-currency-field>
                          </td>
                        </template>
              </data-table>
            </v-flex>
          </v-flex>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="closePopup_Button" color="secondary lighten-3" v-on:click="onClose">{{closeButtonText}}</v-btn>
        <v-btn id="savePopup_Button" color="orange lighten-1" v-on:click="onSave" :disabled="disableSaveButton">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapProductToColor, mapProductToTextColor } from '../../helpers/ColorMapper';
import { cloneDeep } from '@/helpers/object';
import LABEL_NAMES from '@/constants/levelsOfConcernLabels';
import { required } from 'vuelidate/lib/validators';
import { UI } from '@/store/ui';
import { TERMINALS } from '@/store/terminals';
import { isInSomeRole } from '@/helpers/auth'
import { ADMIN } from '@/constants/roles'
import { ENGINEER } from '@/constants/roles'

export default {
  mounted() {
    this.tank = cloneDeep(this.selectedTank);
    this.tank.tankCalibrations = this.tank.tankCalibrations.sort((a, b) => {
        return a.millimetres < b.millimetres ? -1 : 1
    });
    this.calculateAllLitrePerMMEstimates();
  },
  props: {
    showDialog: false,
    selectedTank: null
  },
  data() {
    return {
      tank: null,
      LABEL_NAMES,
      headers: [
        { text: 'Dip (mm)', value: 'millimetres' },
        { text: 'Volumes (Litres)', value: 'litres' },
        { text: 'Average Litres/mm', value: 'litersPerMM' },
      ],
      pagination: {
        rowsPerPage: 10
      },
    };
  },
  computed: {
    tankCalibrations() {
      return this.tank && this.tank.tankCalibrations
    },
    closeButtonText(){
      return this.$v.$dirty ? "Discard Changes" : "Close"
    },
    hasEditRole() {
      return isInSomeRole([ ADMIN, ENGINEER ]);
    },
    disableSaveButton(){
      return !this.$v.$dirty || this.$v.$invalid;
    }
  },
  methods: {
    getLitresErrors(index) {
      if(!this.$v.tankCalibrations.$each[index]) return null;
      if(!this.$v.tankCalibrations.$each[index].litres.required){
        return "Litre reading is required";
      }
      if(!this.$v.tankCalibrations.$each[index].litres.isUnique){
        return "Litre reading must be unique"
      }
      return null;
    },
    getMillimetresErrors(index){
      if(!this.$v.tankCalibrations.$each[index]) return null;
      if(!this.$v.tankCalibrations.$each[index].millimetres.required){
        return "Millimetre reading is required";
      }
      if(!this.$v.tankCalibrations.$each[index].millimetres.isUnique){
        return "Millimetre reading must be unique"
      }
      return null;
    },
    mapProductToColor(product, background = false, border = false) {
      if (!product) return;
      if (background)
        return mapProductToColor(product, border);
      return mapProductToTextColor(product);
    },
    onClose() {
      this.$emit('close-modal');
    },
    async onSave(){
      let editedCalibrations = this.tank.tankCalibrations.filter((t)=> {
        var orginalValue = this.selectedTank.tankCalibrations.find(st => st.id == t.id);
        return orginalValue.litres != t.litres || orginalValue.millimetres != t.millimetres;
      });
      let success = await this.$store.dispatch(
        TERMINALS.NS(
          TERMINALS.ACTIONS.UPDATE_TANK_CALIBRATIONS
        ),
        {
          id: this.tank.id,
          tankCalibrations: editedCalibrations
        }
      );
      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-discharge-plan',
            text: 'Successfully updated tank calibrations'
          },
          { root: true }
        );
        this.$emit('close-modal-saved');
      } else {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'error-update-discharge-plan',
            text: 'Updating tank calibrations failed',
            error: true
          },
          { root: true }
        );
      }
    },
    calculateAllLitrePerMMEstimates(){
      for (var i = 0; i < this.tankCalibrations.length; i++) {
        if (i === 0) {
          this.tankCalibrations[i].litersPerMM = 0;
        }
        else {
          this.tankCalibrations[i].litersPerMM = this.calculuateLitrePerMM(i);
        }
      }
    },
    calculuateLitrePerMM(index){
      if (index === 0) return 0;
      return ((this.tankCalibrations[index].litres - this.tankCalibrations[index - 1].litres) /
        (this.tankCalibrations[index].millimetres - this.tankCalibrations[index - 1].millimetres)).toFixed(4);
    },
    recalculuateLitrePerMM(index){
      this.$v.tankCalibrations.$touch()
      this.tankCalibrations[index].litersPerMM = this.calculuateLitrePerMM(index);
    }
  },
  validations() {
    return {
      tankCalibrations: {
          $each:{
            millimetres: {
              required,
              isUnique: function(val, nestedModel) {
                return !this.tankCalibrations.find(t => t.id !== nestedModel.id && t.millimetres == val);
              }
            },
            litres: {
              required,
              isUnique: function(val, nestedModel) {
                return !this.tankCalibrations.find(t => t.id !== nestedModel.id && t.litres == val);
              },
            }
          }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content-container {
  overflow-y: scroll;
  max-height: 60vh;
}

:deep(.tank-calibration-table) table thead tr td,
:deep(.tank-calibration-table) table thead tr th {
  text-align: center !important;
  padding: 0 12px !important;
}

.number-style {
  font-variant-numeric: tabular-nums;
}
</style>
