<template>
  <v-container v-if="lastTankToTankPlanId && isTankToTankPlan">
    <v-list expand nav>
      <template v-for="(item, i) in routes">
        <v-list-group :key="i" no-action :group="handleRoute(item.path)"
            color="orange" v-show="item.meta.display">
          <v-icon slot="prependIcon" class="ma-0" >{{item.meta.icon}}</v-icon>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="menu-header-item pl-0" v-text="item.meta.title"/>
            </v-list-item-content>
          </template>
          <template v-for="(subItem, j) in item.children">
            <v-list-item :key="j" :to="handleRoute(item.path + '/' + subItem.path)" color="orange"
                v-show="subItem.meta.display"
                :class="[pageName === subItem.name ? 'v-list-item--active' : '']">
              <v-list-item-content>      
                <v-list-item-title class="menu-item" v-text="subItem.meta.title"/>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { ROUTE_NAMES } from '@/routes';
import { mapState } from 'vuex';

export default Vue.extend({
  methods: {
    handleRoute(path) {
      if (path.includes(":terminalId")) path = path.replace(":terminalId", this.activeTerminalId);
      if (path.includes(":tankToTankPlanId")) path = path.replace(":tankToTankPlanId", this.lastTankToTankPlanId);
      if (this.$route.path !== path) return path;
    }
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
    lastTankToTankPlanId() {
      return this.$route.params && this.$route.params.tankToTankPlanId;
    },
    isTankToTankPlan() {
      return this.$route.meta && this.$route.meta.tankToTankPlan;
    },
    routes: {
      get() {
        return this.$router.options.routes.filter(t => t.name === ROUTE_NAMES.TANK_TO_TANK)[0].children;
      }
    },
    ...mapState({
      activeTerminalId: state => state.terminals && state.terminals.activeTerminal && state.terminals.activeTerminal.id
    })
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_variables.scss';

.v-list-item--disabled {
    color: rgb(94, 94, 94) !important;
}
</style>