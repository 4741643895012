import { actions, TERMINAL_INFORMATION_ACTIONS } from './actions';
import { getters, TERMINAL_INFORMATION_GETTERS } from './getters';
import { mutations, TERMINAL_INFORMATION_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'terminalInformation';

export const state = {
    tanksList: []
};

export function GET_DEFAULT_DOCUMENT_REPOSITORY_LINK() {
    return {
        documentRepositoryLink: null
    }
};

export function GET_DEFAULT_TERMINAL_INFORMATION_DETAILS() {
    return {
        maximumFlowRateMsec: 0,
        maximumSlowPumpingFlowRateMsec: 0,
        maximumFlowRatePressureBarg: 0,
        maximumSlowPumpingFlowRatePressureBarg: 0,
        document: null
    }
};

export function GET_DEFAULT_TERMINAL_INFORMATION_LINE_ITEM() {
    return {
        flowRateKlHr: 0,
        flowRateMsec: 0,
        pressureBarg: 0,
        document: null
    };
};

export const TERMINAL_INFORMATION = {
    ACTIONS: TERMINAL_INFORMATION_ACTIONS,
    GETTERS: TERMINAL_INFORMATION_GETTERS,
    MUTATIONS: TERMINAL_INFORMATION_MUTATIONS,
    NAME_SPACE,
    NS: x => `${NAME_SPACE}/${x}`
};

export const terminalInformation = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};