import { actions, SHIP_COQ_ACTIONS } from './actions';
import { getters, SHIP_COQ_GETTERS } from './getters';
import { mutations, SHIP_COQ_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'shipCoq';

export const state = {
  shipCoQList: []
};

export const SHIP_COQ = {
  ACTIONS: SHIP_COQ_ACTIONS,
  GETTERS: SHIP_COQ_GETTERS,
  MUTATIONS: SHIP_COQ_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const shipCoq = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};