<template>
    <v-dialog max-width="550" v-model="showDialog" persistent>
      <v-card>
        <v-card-title class="headline">Email Ship Port Performance</v-card-title>
        <v-divider class="divider"/>
        <v-container class="popup-input">
          <v-layout row wrap>
            <v-flex xs12 pa-1>
              <v-text-field 
                v-model="emailModel.toRecipients" 
                label="To" 
                color="orange lighten-1"
                :error-messages="toRecipientsErrors"
                @input="$v.emailModel.toRecipients.$touch()"
                :placeholder="defaultToRecipients" />
            </v-flex>
  
            <v-flex xs12 pa-1>
              <v-text-field
                v-model="emailModel.ccRecipients" 
                label="CC" 
                color="orange lighten-1"
                placeholder="supply@z.co.nz; example@z.co.nz" />
            </v-flex>
  
            <v-flex xs12 pa-1>
              <v-text-field
                v-model="emailModel.subject" 
                label="Subject" 
                color="orange lighten-1"
                :placeholder="defaultSubject" />
            </v-flex>
  
            <v-flex xs12 pa-1>
              <v-textarea
                v-model="emailModel.body"
                label="Body" 
                rows="4" 
                outlined
                color="orange lighten-1" />
            </v-flex>
          </v-layout>
        </v-container>
        <v-flex xs12 px-3 py-1>
          <v-divider />
        </v-flex>
        <v-card-actions>
          <v-spacer/>
          <v-btn  color="secondary lighten-3" @click="() => this.$emit('close')" id="cancelButton">Cancel</v-btn>
          <v-btn color="orange lighten-1" @click="() => this.$emit('emailReport', emailModel)" id="emailButton" :disabled="!disableEmail">Email</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import { TERMINALS } from '@/store/terminals';
  export default {
    props: {
      showDialog: {
        type: Boolean,
        default: false
      },
      shipPerformanceStatus: {
        type: String
      },
      defaultToRecipients: {
        type: String
      }
    },
    watch: {
      showDialog: {
        immeditate: true,
        handler(showDialog) {
          if(showDialog && this.activeTerminal === null){  
            const activeTerminal = this.terminalsByKey[this.$route.params && this.$route.params.terminalId];
            this.$store.dispatch(
              TERMINALS.NS(TERMINALS.ACTIONS.SET_ACTIVE_TERMINAL),
              activeTerminal
            );
          }
          this.emailModel.subject = this.defaultSubject;
        }
      }
    },
    async created() {
      await this.fetchTerminals();
    },
    data() {
      return {
        emailModel: {
          toRecipients: this.defaultToRecipients,
          ccRecipients: null,
          body: null,
          subject: null
        }
      }
    },
    computed: {
      defaultSubject() {
        if (!this.activeTerminal) return null;
        return `SPPR - ${this.activeTerminal.shortName} - ${this.vessel} - ${this.imoNumber} - ${this.voyage} - ${this.shipPerformanceStatus}`;
      },
      planId() {
        return this.$route.params && this.$route.params.planId;
      },
      disableEmail() {
        return this.$v.emailModel.toRecipients.required;
      },
      toRecipientsErrors() {
        if (!this.$v.emailModel.toRecipients.required) return 'Recipients list is required';
        return null;
      },
      voyage() {
        return this.dischargePlan && this.dischargePlan.voyage;
      },
      vessel() {
        return this.dischargePlan && this.dischargePlan.vessel;
      },
      imoNumber() {
        return this.dischargePlan && this.dischargePlan.imoNumber;
      },
      ...mapState({
        dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
        terminalsByKey: state => state.terminals && state.terminals.terminalsList &&
          state.terminals.terminalsList.reduce((terminals, terminal) => {
            terminals[terminal.id] = { ...terminal };
            return terminals;
          }, {}),
        activeTerminal: state => state.terminals && state.terminals.activeTerminal
      })
    },
    methods: {
      async fetchTerminals() {
        await this.$store.dispatch(TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS));
      }
    },
    validations: {
      emailModel: {
        toRecipients: {
          required
        }
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  @import '../../../assets/styles/_responsive.scss';
  @import '../../../assets/styles/_variables.scss';
  .popup-input .v-input {
    max-width: 500px;
  }
  </style>