export const PRODUCTS = {
  AGO: 'Ago',
  B100: 'B100',
  LFO: 'Lfo',
  JETA1: 'JetA1',
  PMS: 'Pms',
  RMS: 'Rms',
  SLOPS: 'Slops'
};

export const PRODUCT_SORT_ORDER = [
  'Pms',
  'Ago',
  'Rms',
  'Lfo',
  'JetA1',
  'B100',
  'Slops'
]

export const PRODUCT_ITEMS =
[
  { text: PRODUCTS.AGO.toUpperCase(), value: PRODUCTS.AGO },
  { text: PRODUCTS.LFO.toUpperCase(), value: PRODUCTS.LFO },
  { text: PRODUCTS.JETA1.toUpperCase(), value: PRODUCTS.JETA1 },
  { text: PRODUCTS.PMS.toUpperCase(), value: PRODUCTS.PMS },
  { text: PRODUCTS.RMS.toUpperCase(), value: PRODUCTS.RMS },
  { text: PRODUCTS.SLOPS.toUpperCase(), value: PRODUCTS.SLOPS }
];

export const PRODUCTS_WITH_FLASHPOINT = [
  PRODUCTS.AGO,
  PRODUCTS.LFO,
  PRODUCTS.JETA1
];

export const PRODUCTS_WITH_CONDUCTIVITY = [
  PRODUCTS.AGO,
  PRODUCTS.JETA1
];

export const PRODUCTS_WITH_VISCOSITY = [
  PRODUCTS.AGO,
  PRODUCTS.LFO
];

export const PRODUCTS_WITH_CLOUD_POINT = [
  PRODUCTS.AGO
];

export const PRODUCTS_WITH_SULPHUR = [
  PRODUCTS.LFO,
  PRODUCTS.AGO
];

export const PRODUCTS_WITH_COLD_FILTER_PLUGGING_POINT = [
  PRODUCTS.AGO,
];

export const PRODUCTS_WITH_POUR_POINT = [
  PRODUCTS.AGO,
];

export const DISPLAYABLE_PRODUCTS = [
  PRODUCTS.AGO,
  PRODUCTS.LFO,
  PRODUCTS.PMS,
  PRODUCTS.RMS,
  PRODUCTS.JETA1,
  PRODUCTS.SLOPS
];