import { EQUIPMENT_INFORMATION_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
import api from './api';

export const EQUIPMENT_INFORMATION_ACTIONS = {
    GET_EQUIPMENT_INFORMATION_BY_TERMINAL: 'getEquipmentInformationByTerminal',
    UPDATE_EQUIPMENT_DETAILS: 'updateEquipmentDetails',
    CREATE_NEW_EQUIPMENT_DETAILS: 'createNewEquipmentDetails'
};

export const ERROR_GET_EQUIPMENT_INFORMATION_BY_TERMINAL =
    'There was a problem with loading the equipment information';

export const ERROR_UPDATE_EQUIPMENT_DETAILS =
    'There was a problem with updating the equipment details';

export const ERROR_CREATE_NEW_EQUIPMENT_DETAILS =
    'There was a problem with creating a new equipment detail';
    
export const actions = {
    async [EQUIPMENT_INFORMATION_ACTIONS.GET_EQUIPMENT_INFORMATION_BY_TERMINAL]({ commit, dispatch }, terminalId) {
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
            root: true
        });
        try {
            let response = await api.getEquipmentInformationByTerminal(terminalId);
            commit(EQUIPMENT_INFORMATION_MUTATIONS.SET_EQUIPMENT_INFORMATION_BY_TERMINAL, response.data);
        }
        catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'failed-load-equipment-information',
                    text: ERROR_GET_EQUIPMENT_INFORMATION_BY_TERMINAL,
                    error
                },
                { root: true }
            );
        }
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
            root: true
        });
    },
    async [EQUIPMENT_INFORMATION_ACTIONS.UPDATE_EQUIPMENT_DETAILS]({ dispatch }, equipmentDetails) {
        let success = false;
        try {
            await api.updateEquipmentDetails(equipmentDetails.id, equipmentDetails.equipmentDetails);
            dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'success-update-equipment-details',
                    text: 'Successfully updated equipment details'
                },
                { root: true }
            );
            success = true;
        } catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'error-update-equipment-details',
                    text: 'Updating equipment details failed',
                    error: true
                },
                { root: true }
            );
        }
        return success;
    },
    async [EQUIPMENT_INFORMATION_ACTIONS.CREATE_NEW_EQUIPMENT_DETAILS]({ commit, dispatch }, { terminalId, equipmentDetails }
    ) {
        let success = false;
        try {
            const response = await api.createNewEquipmentDetails(terminalId, equipmentDetails);
            equipmentDetails.id = response.data.id;
            dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'successfully-created-new-equipment-details',
                    text: 'Successfully created new equipment'
                },
                { root: true }
            );
            success = true;
        } catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST), {
                id: 'failed-to-create-new-equipment-details',
                text: ERROR_CREATE_NEW_PIPELINE_DETAILS,
                error
            },
                { root: true }
            );
        }
        return success;
    }
};