import api from './api';
import { http } from '@/api';
import { cloneDeep } from '@/helpers/object';
import { TIME_SCALE_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
export const ERROR_GET_TIME_SCALE =
  'There was a problem trying to load the planning time scale';
export const ERROR_CREATE_TIME_SCALE =
  'There was a problem trying to create the planning time scale';
export const ERROR_UPDATE_TIME_SCALE =
  'There was a problem trying to update the planning time scale';

export const TIME_SCALE_ACTIONS = {
  GET_TIME_SCALE: 'getTimeScale',
  CREATE_TIME_SCALE: 'createTimeScale',
  UPDATE_TIME_SCALE: 'updateTimeScale'
};

export const actions = {
  async [TIME_SCALE_ACTIONS.GET_TIME_SCALE](
    { state, commit, dispatch },
    { cargoPlanId, hateoasGetUrl }
  ) {
    let success = false;
    try {
      let response;

      if (cargoPlanId) {
        response = await api.getTimeScaleByCargoPlanId(cargoPlanId);
        
      } else {
        // Temporarily replace http: with https:
        response = await http.get(hateoasGetUrl.replace('http:', 'https:'));
      }
      commit(TIME_SCALE_MUTATIONS.SET_TIME_SCALE, response.data);
      commit(TIME_SCALE_MUTATIONS.SET_ORIGINAL_TIME_SCALE, state.timeScale);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
          cargoPlanId: 'failed-load-timescale-id',
        text: ERROR_GET_TIME_SCALE,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [TIME_SCALE_ACTIONS.CREATE_TIME_SCALE](
    { commit, dispatch },
    { timeScale }
  ) {

    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
      root: true
    });

    let success = false;
    try {
      const response = await api.createTimeScale( timeScale);

      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-create-time-scale',
        text: ERROR_CREATE_TIME_SCALE,
        error
      },
        { root: true }
      );
    }
    dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
      root: true
    });
    return success;
  },
  async [TIME_SCALE_ACTIONS.UPDATE_TIME_SCALE](
    { state, dispatch, commit },
    { timeScale }
  ) {
    let success = false;
    try {
      
      const timeScaleModel = cloneDeep(timeScale);

      await api.updateTimeScale( timeScaleModel) ;
      success = true;
      // update 'original' state
      commit(
        TIME_SCALE_MUTATIONS.SET_ORIGINAL_TIME_SCALE, cloneDeep(timeScale)
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-update-time-scale',
        text: ERROR_UPDATE_TIME_SCALE,
        error
      },
        { root: true }
      );
    }
    return success;
  }
};