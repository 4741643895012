import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';

export const CHECKLIST_MUTATIONS = {
  SET_CHECKLIST: 'setChecklist'
}

export const mutations = {
  [CHECKLIST_MUTATIONS.SET_CHECKLIST](state, checklist) {
    Vue.set(state, 'checklist', cloneDeep(checklist));
  }
}