import { actions, MONITORING_DETAILS_ACTIONS } from './actions';
import { getters, MONITORING_DETAILS_GETTERS } from './getters';
import { mutations, MONITORING_DETAILS_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'monitoringDetails';

export function GET_DEFAULT_MONITOR_DETAILS() {
    return {
      monitoredAt: new Date(),
      tankGaugeReadingMillimetres: null,
      tankGaugeReadingKiloLitres: 0,
      receivedMillimetres: 0,
      receivedKiloLitres: 0,
      dischargeRemainingMillimetres: 0,
      dischargeRemainingKiloLitres: 0,
      tankCapacityRemainingMillimetres: 0,
      tankCapacityRemainingKiloLitres: 0,
      estimatedTimeToFinishHours: 0,
      estimatedTimeToFinishMinutes: 0,
      comments: ''
    };
  }
  

export const state = {
  monitoringDetails: []
};

export const MONITORING_DETAILS = {
  ACTIONS: MONITORING_DETAILS_ACTIONS,
  GETTERS: MONITORING_DETAILS_GETTERS,
  MUTATIONS: MONITORING_DETAILS_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const monitoringDetails = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};