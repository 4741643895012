import { parseISO } from 'date-fns';

export const DISCHARGE_LOG_MUTATIONS = {
  SET_DISCHARGE_LOG_LIST: 'setDischargeLogList',
  ADD_DISCHARGE_LOG_LIST: 'addDischargeLogList',
  RESET_DISCHARGE_LOG_LIST: 'resetDischargeLogList',
  EDIT_DISCHARGE_LOG_LIST: 'editDischargeLogList',
};

export const mutations = {
  [DISCHARGE_LOG_MUTATIONS.SET_DISCHARGE_LOG_LIST](state, dischargeLogList) {
    state.dischargeLogList = dischargeLogList;
    state.dischargeLogList.forEach(d => d.logDateTime = parseISO(d.logDateTime));
  },
  [DISCHARGE_LOG_MUTATIONS.RESET_DISCHARGE_LOG_LIST](state) {
    state.dischargeLogList = [];
  },
  [DISCHARGE_LOG_MUTATIONS.ADD_DISCHARGE_LOG_LIST](state, dischargeLog) {
    state.dischargeLogList.push(dischargeLog);
  },
  [DISCHARGE_LOG_MUTATIONS.EDIT_DISCHARGE_LOG_LIST](state, dischargeLog) {
    const index = state.dischargeLogList.findIndex(d => d.id === dischargeLog.id);
    const currentDischargeLog = dischargeLog;
    state.dischargeLogList.splice(index, 1, currentDischargeLog);
  }
};
