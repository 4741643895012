import { format, parseISO, isValid } from "date-fns";
import { DATE_FORMAT } from "@/constants/dateFormat";

export default function formatDate(input, stringFormat = DATE_FORMAT) {
  if (!input) {
    return "";
  }
  // need to parse date if its not a valid date
  return input instanceof Date && isValid(input)
    ? format(input, stringFormat)
    : format(parseISO(input), stringFormat);
}
