<template>
  <v-layout row wrap pa-4>
    <h2>Pre-Arrival Info Sheet coming soon</h2>
  </v-layout>

   <!--Temporarily hidden because Cargo Planning page is not done and there are still requirement updates pending from the business-->

  <!--
  <v-container fluid fill-height>
    <loading-shim :is-loading="isLoading" />

    <v-layout fluid fill-height row wrap>
      <v-flex xs12 pb-3>
        <v-layout>
          <v-flex xs10>
            <h1>Cargo Plan & Pre-Arrival Information</h1>
          </v-flex>
          <v-flex xs2>
            <div class="label-page-header">Ref: ISGOTT 6 - 21.2.3</div>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12>
        <v-flex mt-2>
          <v-flex xs12 mb-4>
            <v-layout>
              <v-flex xs2 pr-2>
                <v-text-field :value="dischargePlan.vessel"
                              label="Vessel"
                              readonly />
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field :value="dischargePlan.voyage"
                              label="Voyage/Cargo Number"
                              readonly />
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field :value="dischargePlan.terminalName"
                              label="Receiving Port"
                              readonly />
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field label="Vessel ETA"
                              :value="dischargePlan.pilotOnBoard | formatDate('yyyy-MM-dd HH:mm')"
                              readonly />
              </v-flex>
              <v-flex xs2 pr-2>
                <v-text-field label="Berth"
                              :value="dischargePlan.terminalBerthName"
                              readonly>

                </v-text-field>
              </v-flex>
              <v-flex xs2 pr-2>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-expansion-panel expand v-model="panels">
            <v-expansion-panel-content row
                                       wrap
                                       mb-4 mr-2>
              <template v-slot:header>
                <h2>Section 1: Cargo Plan</h2>
              </template>
              <v-layout row wrap mt-2 mb-2 px-5>
                <v-flex xs12 mt-3>
                  <h2>
                    <router-link :to="`/${dischargePlanRoute}/${dischargePlanId}/pre-arrival-forms/cargo-planning`">Cargo Planning Sheet</router-link>
                  </h2>
                </v-flex>
                <v-flex xs12 mt-4 pr-2>
                  <v-textarea label="Cargo Plan additional comments/information"
                              outlined
                              v-model="localPreArrivalInfo.cargoPlanComments"
                              no-resize>
                  </v-textarea>

                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel expand v-model="panels">
            <v-expansion-panel-content row
                                       wrap
                                       mb-4 mr-2>
              <template v-slot:header>
                <h2>Section 2: Terminal & Berth Information</h2>
              </template>
              <v-layout row wrap mt-2 mb-2 px-5>
                <v-flex xs12 mt-3>
                  <h2>
                    Terminal Contact Details/Sender
                  </h2>
                </v-flex>
                <v-flex xs12>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Contact Name/Sender"
                                    v-model="localPreArrivalInfo.contactName">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Telephone number"
                                    v-model="localPreArrivalInfo.telephoneNumber">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="After Hours Mobile"
                                    v-model="localPreArrivalInfo.afterHoursMobile">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Email address"
                                    v-model="localPreArrivalInfo.emailAddress">

                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Title"
                                    v-model="localPreArrivalInfo.title">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Terminal"
                                    v-model="localPreArrivalInfo.terminal">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Fax"
                                    v-model="localPreArrivalInfo.fax">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 mt-4>
                  <h2>
                    Shore Pipeline Details
                  </h2>
                </v-flex>
                <v-flex xs12 mt-4>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <h3 class="shore-info-header-main">
                        Shore Information
                      </h3>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <h3 class="shore-info-header-sub">
                        Information
                      </h3>
                    </v-flex>
                    <v-flex xs6 pr-2>
                      <h3 class="shore-info-header-sub">
                        Notes
                      </h3>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout v-for="(detail, index) in localPreArrivalInfo.shorePipelineDetails"
                            :key="index">
                    <v-flex xs3 pr-2 pt-4>
                      <div class="label-header">
                        {{detail.label}}
                      </div>
                    </v-flex>
                    <v-flex xs3 pr-4>
                      <v-text-field v-model="detail.information">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 pr-2>
                      <v-text-field v-model="detail.notes">
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 mt-4>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <h3 class="shore-info-header-main">
                        AOP's Valve data
                      </h3>
                    </v-flex>
                    <v-flex xs3 pr-2>
                    </v-flex>
                    <v-flex xs6 pr-2>
                      <div class="label-header">Ref: ISGOTT 6 - 12.1.4</div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout v-for="(data, index) in localPreArrivalInfo.valveData"
                            :key="index">
                    <v-flex xs3 pr-2 pt-4>
                      <div class="label-header">
                        {{data.label}}
                      </div>
                    </v-flex>
                    <v-flex xs3 pr-4>
                      <v-text-field v-model="data.information">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 pr-2>
                      <v-text-field v-model="data.notes">
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 mt-4>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 mt-4>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <h2>
                        Berth Information
                      </h2>
                    </v-flex>
                    <v-flex xs3 pr-2>
                    </v-flex>
                    <v-flex xs6 pr-2>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout v-for="(info, index) in localPreArrivalInfo.berthInfo"
                            :key="index">
                    <v-flex xs3 pr-2 pt-4>
                      <div class="label-header">
                        {{info.label}}
                      </div>
                    </v-flex>
                    <v-flex xs9 pr-2>
                      <v-text-field v-model="info.information">
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>

              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel expand v-model="panels">
            <v-expansion-panel-content row
                                       wrap
                                       mb-4 mr-2>
              <template v-slot:header>
                <h2>Section 3: Information to be completed / provided by ship personnel</h2>
              </template>
              <v-layout row wrap mt-2 mb-2 px-5>
                <v-flex xs12 mt-3>
                  <h2>
                    Vessel details
                  </h2>
                </v-flex>
                <v-flex xs12>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Vessel Name & Call sign"
                                    v-model="localPreArrivalInfo.vesselName">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Country of registration"
                                    v-model="localPreArrivalInfo.countryOfRegistration">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs2 pr-2>
                      <v-text-field label="LOA"
                                    v-model="localPreArrivalInfo.loa">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs2 pr-2>
                      <v-text-field label="Beam"
                                    v-model="localPreArrivalInfo.beam">

                      </v-text-field>
                    </v-flex>
                    <v-flex xs2 pr-2>
                      <v-text-field label="ETA (Pilot Station)"
                                    v-model="localPreArrivalInfo.eta">
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Draught on arrival"
                                    v-model="localPreArrivalInfo.draughtOnArrival">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs3 pr-2>
                      <v-text-field label="Displacement on arrival"
                                    v-model="localPreArrivalInfo.displacementOnArrival">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 pr-2>
                      <v-text-field label="Max expected draught whilst alongside"
                                    v-model="localPreArrivalInfo.maxExpectedDraught">
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 mt-3>
                  <h2>
                    Please confirm the following items:
                  </h2>
                </v-flex>
                <v-flex xs12 mt-2>
                  <v-layout v-for="(checklist, index) in localPreArrivalInfo.shipPersonnelChecklist"
                            :key="index">
                    <v-flex xs9 pr-2 pt-4>
                      <div class="label-header">
                        {{index + 1}}. {{checklist.description}}
                      </div>
                    </v-flex>
                    <v-flex xs3 pr-4>
                      <v-layout v-if="checklist.type == 'Text'">
                        <v-text-field v-model="checklist.answer">
                        </v-text-field>
                      </v-layout>
                      <v-layout v-else-if="checklist.type == 'PAndS'">
                        <v-radio-group :column="false" 
                                       multiple="false"
                                       v-model="checklist.answer">
                          <v-radio label="P" value="P"/> &nbsp;&nbsp;&nbsp;&nbsp;
                          <v-radio label="S" value="S"/>
                        </v-radio-group>
                      </v-layout>
                      <v-layout v-else>
                        <v-radio-group :column="false" 
                                       multiple="false"
                                       v-model="checklist.answer">
                          <v-radio label="Yes" value="Yes" />
                          <v-radio label="No" value="No"/>
                        </v-radio-group>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 mt-2>
                  <v-textarea label="Details"
                              v-model="localPreArrivalInfo.shipPersonnelInformationDetails"
                              outlined
                              no-resize>
                  </v-textarea>

                </v-flex>
                <v-flex xs12 mt-3>
                  <h2>
                    Alternative Vessel Cargo Plan Request: (Fill out section below, or attach vessels alternative plan)
                  </h2>
                </v-flex>
                <v-flex xs12>
                  <data-table :headers="alternativeVesselCargoPlanRequestHeader"
                              :items="localPreArrivalInfo.alternativeVesselCargoPlanRequestItems"
                              :hide-actions="true"
                              :show-search="false">
                    <template slot="items" slot-scope="props">
                      <td class="table-cell"><v-text-field v-model="props.item.seq"></v-text-field></td>
                      <td class="table-cell"><v-text-field v-model="props.item.product"></v-text-field></td>
                      <td class="table-cell"><v-text-field v-model="props.item.tonnes"></v-text-field></td>
                      <td class="table-cell"><v-text-field v-model="props.item.fromShipsTank"></v-text-field></td>
                      <td class="table-cell"><v-text-field v-model="props.item.fromManifoldNumber"></v-text-field></td>
                      <td class="table-cell"><v-text-field v-model="props.item.expectedPumpingPressure"></v-text-field></td>
                      <td class="table-cell"><v-text-field v-model="props.item.comment"></v-text-field></td>
                    </template>
                  </data-table>
                </v-flex>
                <v-flex xs12 mt-3>
                  <v-textarea label="Additional comments"
                              v-model="localPreArrivalInfo.alternativeVesselCargoPlanRequestsAdditionalComments"
                              outlined
                              no-resize>
                  </v-textarea>

                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-layout class="no-print" row mt-4 pl-2>
            <v-flex xs6>
              <flat-button class="button"
                           @click="onSave"
                           :loading="isLoading">Save</flat-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
  -->
</template>

<script>
import { mapState } from 'vuex';
import { ROUTE_NAMES } from '@/routes';
import { PRE_ARRIVAL_INFO } from '@/store/tankerDischarge/preArrival/preArrivalInfo';
import { cloneDeep } from '@/helpers/object';

function defaultPreArrivalInfo() {
  return {
    cargoPlanComments: null,
    contactName: null,
    telephoneNumber: null,
    afterHoursMobile: null,
    emailAddress: null,
    title: null,
    terminal: null,
    fax: null,
    shorePipelineDetails: [
      { label: "Pipeline name or number", information: null, notes: null },
      { label: "Length", information: null, notes: null },
      { label: "Receiving tank height above sea level", information: null, notes: null },
      { label: "Pipeline diameter", information: null, notes: null },
      { label: "Max pump pressure", information: null, notes: null },
      { label: "Max receiving rate (per/Hr)", information: null, notes: null },
      { label: "Hose size", information: null, notes: null },
    ],
    valveData: [
      { label: "Automated shut down valve installed", information: null, notes: null },
      { label: "Auto close time", information: null, notes: null },
      { label: "HHLA alarm sounder on wharf above hut", information: null, notes: null },
    ],
    berthInfo: [
      { label: "Berth Owner & Contact details", information: null },
      { label: "Ship/Shore Access", information: null },
      { label: "Wind Limitation for shore personnel", information: null },
      { label: "Ship engines/maintenance", information: null },
      { label: "Berth Restrictions - Guide", information: null },
      { label: "Security Level Status", information: null },
      { label: "Moorings", information: null },
      { label: "Inert Gas System (IG)", information: null },
      { label: "Sampling", information: null },
      { label: "Slops, Oily Ballast Residues & Garbage", information: null },
      { label: "Pilotage & Tugs", information: null },
      { label: "Documentation", information: null },
      { label: "Cargo/Stowage Plans & Manifold", information: null },
      { label: "Port & Terminal Information Handbook", information: null },
    ],
    vesselName: null,
    countryOfRegistration: null,
    loa: null,
    beam: null,
    eta: null,
    draughtOnArrival: null,
    displacementOnArrival: null,
    maxExpectedDraught: null,
    shipPersonnelChecklist: [],
    shipPersonnelInformationDetails: null,
    alternativeVesselCargoPlanRequestItems: [
      { id: null, seq: null, product: null, tonnes: null, fromShipsTank: null, fromManifoldNumber: null, expectedPumpingPressure: null, comment: null },
      { id: null, seq: null, product: null, tonnes: null, fromShipsTank: null, fromManifoldNumber: null, expectedPumpingPressure: null, comment: null },
      { id: null, seq: null, product: null, tonnes: null, fromShipsTank: null, fromManifoldNumber: null, expectedPumpingPressure: null, comment: null },
      { id: null, seq: null, product: null, tonnes: null, fromShipsTank: null, fromManifoldNumber: null, expectedPumpingPressure: null, comment: null },
      { id: null, seq: null, product: null, tonnes: null, fromShipsTank: null, fromManifoldNumber: null, expectedPumpingPressure: null, comment: null },
    ],
    alternativeVesselCargoPlanRequestsAdditionalComments: null
  }
}

export default {
  created() {
    this.panels = [true, true, true];
  },
  mounted() {
    // Temporarily hidden because Cargo Planning page is not done and there are still requirement updates pending from the business
    //this.$store.commit(
    //  PRE_ARRIVAL_INFO.NS(PRE_ARRIVAL_INFO.MUTATIONS.RESET_PRE_ARRIVAL_INFO)
    //);
    //this.loadPreArrivalInfo();
  },
  data() {
    return {
      loading: false,
      alternativeVesselCargoPlanRequestHeader: [
        { text: "Seq.", sortable: false, width: "5%" },
        { text: "Product", sortable: false, width: "15%" },
        { text: "Tonnes", sortable: false, width: "15%" },
        { text: "From Ship's Tank/s", sortable: false, width: "15%" },
        { text: "From Manifold #", sortable: false, width: "15%" },
        { text: "Expected pumping pressure", sortable: false, width: "20%" },
        { text: "Comment", sortable: false, width: "15%" }
      ],
      localPreArrivalInfo: defaultPreArrivalInfo()
    };
  },
  computed: {
    isLoading() {
      return this.uiIsLoading || this.loading;
    },
    dischargePlanRoute() {
      return ROUTE_NAMES.DISCHARGE_PLAN;
    },
    dischargePlanId() {
      return this.$route.params && this.$route.params.planId;
    },
    preArrivalInfo() {
      return this.$store.state.preArrivalInfo &&
        this.$store.state.preArrivalInfo.preArrivalInfo;
    },
    preArrivalInfoInitData() {
      return this.$store.state.preArrivalInfo &&
        this.$store.state.preArrivalInfo.preArrivalInfoInitData;
    },
    ...mapState({
      preArrivalInfo: state => state.preArrivalInfo && state.preArrivalInfo.preArrivalInfo,
      uiIsLoading: state => state.ui.isLoading
    })
  },
  methods: {
    async loadPreArrivalInfo() {
      this.loading = true;
      if (this.dischargePlanId) {
        const result = await this.$store.dispatch(
          PRE_ARRIVAL_INFO.NS(PRE_ARRIVAL_INFO.ACTIONS.GET_PRE_ARRIVAL_INFO),
          {
            planId: this.dischargePlanId
          }
        );
        if (!result) {
          // if the pre arrival info is not set yet, the api returns 404
          // axios by default logs the 404 error in the console
          // to suppress this error from appearing, we clear the console
          console.clear();

          // if no existing pre arrival info, get init data
          // init data contains the list of ship personnel checklist
          // and last shore pipeline details of the same terminal
          const result = await this.$store.dispatch(
            PRE_ARRIVAL_INFO.NS(PRE_ARRIVAL_INFO.ACTIONS.GET_PRE_ARRIVAL_INFO_INIT_DATA),
            {
              planId: this.dischargePlanId
            }
          );
        } 
      }
      this.setupLocalPreArrivalInfoDetails();
      this.loading = false;
    },
    setupLocalPreArrivalInfoDetails() {
      if (this.preArrivalInfo && Object.keys(this.preArrivalInfo).length !== 0) {
        // copy the fields except the array objects
        Object.keys(this.localPreArrivalInfo).forEach(key => this.localPreArrivalInfo[key] =
          (key in this.preArrivalInfo ? this.preArrivalInfo : this.localPreArrivalInfo)[key]);
        this.localPreArrivalInfo.id = this.preArrivalInfo.id;
        this.setupShorePipelineDetails();
        this.setupValveData();
        this.setupBerthInfo();
        this.setupShipPersonnelChecklist();
        this.setupAlternativeVesselCargoPlan();
      } else if (this.preArrivalInfoInitData) {
        // auto remember previous shore information of the same terminal
        this.localPreArrivalInfo.shorePipelineDetails[0].information = this.preArrivalInfoInitData.pipelineName;
        this.localPreArrivalInfo.shorePipelineDetails[1].information = this.preArrivalInfoInitData.length;
        this.localPreArrivalInfo.shorePipelineDetails[2].information = this.preArrivalInfoInitData.receivingTankHeight;
        this.localPreArrivalInfo.shorePipelineDetails[3].information = this.preArrivalInfoInitData.pipelineDiameter;
        this.localPreArrivalInfo.shorePipelineDetails[4].information = this.preArrivalInfoInitData.maxPumpPressure;
        this.localPreArrivalInfo.shorePipelineDetails[5].information = this.preArrivalInfoInitData.maxReceivingRate;
        this.localPreArrivalInfo.shorePipelineDetails[6].information = this.preArrivalInfoInitData.hoseSize;
        // set the checklist
        if (this.preArrivalInfoInitData.preArrivalInfoChecklistQuestions) {
          this.localPreArrivalInfo.shipPersonnelChecklist =
            this.preArrivalInfoInitData.preArrivalInfoChecklistQuestions.map(item => {
              return {
                preArrivalInfoChecklistQuestionId: item.id,
                description: item.description,
                answer: null,
                type: item.type
              }
            });
        }
      }
    },
    // we represent the shore pipeline details, valve data, berth info
    // as an array so we don't have to maintain 
    // individual getter/setter for all the fields
    // it also makes the ui code manageable by using loops
    setupShorePipelineDetails() {
      if (this.localPreArrivalInfo.shorePipelineDetails) {
        this.localPreArrivalInfo.shorePipelineDetails[0].information = this.preArrivalInfo.pipelineName;
        this.localPreArrivalInfo.shorePipelineDetails[1].information = this.preArrivalInfo.length;
        this.localPreArrivalInfo.shorePipelineDetails[2].information = this.preArrivalInfo.receivingTankHeight;
        this.localPreArrivalInfo.shorePipelineDetails[3].information = this.preArrivalInfo.pipelineDiameter;
        this.localPreArrivalInfo.shorePipelineDetails[4].information = this.preArrivalInfo.maxPumpPressure;
        this.localPreArrivalInfo.shorePipelineDetails[5].information = this.preArrivalInfo.maxReceivingRate;
        this.localPreArrivalInfo.shorePipelineDetails[6].information = this.preArrivalInfo.hoseSize;
        this.localPreArrivalInfo.shorePipelineDetails[0].notes = this.preArrivalInfo.pipelineNameNotes;
        this.localPreArrivalInfo.shorePipelineDetails[1].notes = this.preArrivalInfo.lengthNotes;
        this.localPreArrivalInfo.shorePipelineDetails[2].notes = this.preArrivalInfo.receivingTankHeightNotes;
        this.localPreArrivalInfo.shorePipelineDetails[3].notes = this.preArrivalInfo.pipelineDiameterNotes;
        this.localPreArrivalInfo.shorePipelineDetails[4].notes = this.preArrivalInfo.maxPumpPressureNotes;
        this.localPreArrivalInfo.shorePipelineDetails[5].notes = this.preArrivalInfo.maxReceivingRateNotes;
        this.localPreArrivalInfo.shorePipelineDetails[6].notes = this.preArrivalInfo.hoseSizeNotes;
      }
    },
    setupValveData() {
      if (this.localPreArrivalInfo.valveData) {
        this.localPreArrivalInfo.valveData[0].information = this.preArrivalInfo.automatedShutDownValveInstalled;
        this.localPreArrivalInfo.valveData[1].information = this.preArrivalInfo.autoCloseTime;
        this.localPreArrivalInfo.valveData[2].information = this.preArrivalInfo.hhlaAlarmSounder;
        this.localPreArrivalInfo.valveData[0].notes = this.preArrivalInfo.automatedShutDownValveInstalledNotes;
        this.localPreArrivalInfo.valveData[1].notes = this.preArrivalInfo.autoCloseTimeNotes;
        this.localPreArrivalInfo.valveData[2].notes = this.preArrivalInfo.hhlaAlarmSounderNotes;
      }
    },
    setupBerthInfo() {
      if (this.localPreArrivalInfo.berthInfo) {
        this.localPreArrivalInfo.berthInfo[0].information = this.preArrivalInfo.berthOwner;
        this.localPreArrivalInfo.berthInfo[1].information = this.preArrivalInfo.shipShoreAccess;
        this.localPreArrivalInfo.berthInfo[2].information = this.preArrivalInfo.windLimitation;
        this.localPreArrivalInfo.berthInfo[3].information = this.preArrivalInfo.shipEngines;
        this.localPreArrivalInfo.berthInfo[4].information = this.preArrivalInfo.berthRestrictions;
        this.localPreArrivalInfo.berthInfo[5].information = this.preArrivalInfo.securityLevelStatus;
        this.localPreArrivalInfo.berthInfo[6].information = this.preArrivalInfo.moorings;
        this.localPreArrivalInfo.berthInfo[7].information = this.preArrivalInfo.inertGasSystem;
        this.localPreArrivalInfo.berthInfo[8].information = this.preArrivalInfo.sampling;
        this.localPreArrivalInfo.berthInfo[9].information = this.preArrivalInfo.slopsOilyBallastGarbage;
        this.localPreArrivalInfo.berthInfo[10].information = this.preArrivalInfo.pilotageAndTugs;
        this.localPreArrivalInfo.berthInfo[11].information = this.preArrivalInfo.documenation;
        this.localPreArrivalInfo.berthInfo[12].information = this.preArrivalInfo.stowagePlanAndManifold;
        this.localPreArrivalInfo.berthInfo[13].information = this.preArrivalInfo.portAndTerminalInformationHandbook;
      }
    },
    setupShipPersonnelChecklist() {
      if (this.localPreArrivalInfo.shipPersonnelChecklist) {
        this.localPreArrivalInfo.shipPersonnelChecklist =
          this.preArrivalInfo.preArrivalInfoChecklists.map(item => {
            return {
              id: item.id,
              preArrivalInfoId: item.preArrivalInfoId,
              preArrivalInfoChecklistQuestionId: item.preArrivalInfoChecklistQuestionId,
              description: item.description,
              answer: item.answer,
              type: item.type
            }
          });
      }
    },
    setupAlternativeVesselCargoPlan() {
      // we hardcode to 5 entries
      if (this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems) {
        for (var i = 0;i<this.preArrivalInfo.alternativeVesselCargoPlanRequests.length && 5;i++) {
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].id = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].id;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].seq = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].seq;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].product = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].product;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].tonnes = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].tonnes;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].fromShipsTank = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].fromShipsTank;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].fromManifoldNumber = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].fromManifoldNumber;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].expectedPumpingPressure = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].expectedPumpingPressure;
          this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].comment = this.preArrivalInfo.alternativeVesselCargoPlanRequests[i].comment;
        }
      }
    },
    async onSave() {
      this.loading = true;
      const data = this.transformPreArrivalInfoToApiModel();
      await this.$store.dispatch(
        PRE_ARRIVAL_INFO.NS(PRE_ARRIVAL_INFO.ACTIONS.UPDATE_PRE_ARRIVAL_INFO),
        {
          planId: this.dischargePlanId,
          preArrivalInfo: data
        }
      );
      this.loading = false;
    },
    // because we changed the model here to use array, we need to transform it back
    // to the api model
    transformPreArrivalInfoToApiModel() {
      const data = cloneDeep(this.localPreArrivalInfo);
      // remove id if it's not saved yet
      //if (!data.id) {
      //  delete data.id;
      //}
      this.transformShorePipelineDetails(data);
      this.transformBerthInfo(data);
      this.transformChecklist(data);
      this.transformAlternativeVesselCargoPlan(data);

      return data;
    },
    transformShorePipelineDetails(data) {
      if (data) {
        data.pipelineName = this.localPreArrivalInfo.shorePipelineDetails[0].information;
        data.length = this.localPreArrivalInfo.shorePipelineDetails[1].information;
        data.receivingTankHeight = this.localPreArrivalInfo.shorePipelineDetails[2].information;
        data.pipelineDiameter = this.localPreArrivalInfo.shorePipelineDetails[3].information;
        data.maxPumpPressure = this.localPreArrivalInfo.shorePipelineDetails[4].information;
        data.maxReceivingRate = this.localPreArrivalInfo.shorePipelineDetails[5].information;
        data.hoseSize = this.localPreArrivalInfo.shorePipelineDetails[6].information;

        data.pipelineNameNotes = this.localPreArrivalInfo.shorePipelineDetails[0].notes;
        data.lengthNotes = this.localPreArrivalInfo.shorePipelineDetails[1].notes;
        data.receivingTankHeightNotes = this.localPreArrivalInfo.shorePipelineDetails[2].notes;
        data.pipelineDiameterNotes = this.localPreArrivalInfo.shorePipelineDetails[3].notes;
        data.maxPumpPressureNotes = this.localPreArrivalInfo.shorePipelineDetails[4].notes;
        data.maxReceivingRateNotes = this.localPreArrivalInfo.shorePipelineDetails[5].notes;
        data.hoseSizeNotes = this.localPreArrivalInfo.shorePipelineDetails[6].notes;

        data.automatedShutDownValveInstalled = this.localPreArrivalInfo.valveData[0].information;
        data.autoCloseTime = this.localPreArrivalInfo.valveData[1].information;
        data.hhlaAlarmSounder = this.localPreArrivalInfo.valveData[2].information;
        data.automatedShutDownValveInstalledNotes = this.localPreArrivalInfo.valveData[0].notes;
        data.autoCloseTimeNotes = this.localPreArrivalInfo.valveData[1].notes;
        data.hhlaAlarmSounderNotes = this.localPreArrivalInfo.valveData[2].notes;
      }
    },
    transformBerthInfo(data) {
      if (data) {
        data.berthOwner = this.localPreArrivalInfo.berthInfo[0].information;
        data.shipShoreAccess = this.localPreArrivalInfo.berthInfo[1].information;
        data.windLimitation = this.localPreArrivalInfo.berthInfo[2].information;
        data.shipEngines = this.localPreArrivalInfo.berthInfo[3].information;
        data.berthRestrictions = this.localPreArrivalInfo.berthInfo[4].information;
        data.securityLevelStatus = this.localPreArrivalInfo.berthInfo[5].information;
        data.moorings = this.localPreArrivalInfo.berthInfo[6].information;
        data.inertGasSystem = this.localPreArrivalInfo.berthInfo[7].information;
        data.sampling = this.localPreArrivalInfo.berthInfo[8].information;
        data.slopsOilyBallastGarbage = this.localPreArrivalInfo.berthInfo[9].information;
        data.pilotageAndTugs = this.localPreArrivalInfo.berthInfo[10].information;
        data.documenation = this.localPreArrivalInfo.berthInfo[11].information;
        data.stowagePlanAndManifold = this.localPreArrivalInfo.berthInfo[12].information;
        data.portAndTerminalInformationHandbook = this.localPreArrivalInfo.berthInfo[13].information;
      }
    },
    transformChecklist(data) {
      if (data) {
        data.preArrivalInfoChecklists =
          this.localPreArrivalInfo.shipPersonnelChecklist.map(item => {
            return {
              preArrivalInfoChecklistQuestionId: item.preArrivalInfoChecklistQuestionId,
              answer: item.answer
            }
          });
      }
    },
    transformAlternativeVesselCargoPlan(data) {
      if (data) {
        if (!data.alternativeVesselCargoPlanRequests) {
          data.alternativeVesselCargoPlanRequests = [];
        }
        for (var i = 0;i < this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems.length;i++) {
          var item = {};
          item.seq = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].seq;
          item.product = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].product;
          item.tonnes = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].tonnes;
          item.fromShipsTank = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].fromShipsTank;
          item.fromManifoldNumber = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].fromManifoldNumber;
          item.expectedPumpingPressure = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].expectedPumpingPressure;
          item.comment = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].comment;
          // do not include id if it's null
          if (this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].id) {
            item.id = this.localPreArrivalInfo.alternativeVesselCargoPlanRequestItems[i].id;
          } 
          data.alternativeVesselCargoPlanRequests.push(item);          
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
.v-expansion-panel {
  box-shadow: none !important;
}
.v-expansion-panel__container {
  border-top: none !important;
}

:deep(.v-expansion-panel__header) {
  background: #4f4f4f !important;
  color: white !important;
  border: white 1px solid !important;
}

:deep(.v-expansion-panel__header__icon) .v-icon {
  color: white !important;
}

.label-header {
  font-size: 16px;
}

.label-page-header {
  font-size: 20px;
  font-weight: 500;
}

:deep(.v-datatable) .thead .tr .th {
  padding: 0 2px !important;
}

.table-cell {
  padding: 0 2px !important;
}

.shore-info-header-main {
  text-decoration: underline;
}

.shore-info-header-sub {
  font-weight: normal; 
  text-decoration: underline;
}

</style>
