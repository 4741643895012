<template>
    <v-container fluid fill-height>
      <pdf-preview header="Ship Port Performance Report PDF" fileName="ShipPortPerformanceReport.pdf" :showDialog="pdfOptions.showDialog" :url="pdfOptions.url" @close="pdfOptions.showDialog = false" :data="pdfOptions.data" />
      <tanker-discharge-post-discharge-generate-new-ship-port-performance-email
        v-on:close="onCancel" 
        v-on:emailReport="emailShipPortPerformanceReport" 
        :showDialog="showGenerateEmailDialog" 
        :planId="idFromRoute" 
        :defaultToRecipients="emailList"
        :shipPerformanceStatus="shipPerformanceStatus.description" />
      <v-layout fluid fill-height row wrap v-if="shipPortPerformanceData">
        <tanker-discharge-receipt-plan-info />
        <v-card flat xs12 :class="['sticky-bar']">
          <v-flex xs10 px-3 pt-3 row wrap align-center justify-space-between>
            <v-card flat>
                <h2 style="color: red;">PLEASE NOTE: SPPR should now be completed via AMAS. This page is for visibility of historic records.</h2>
                <v-text-field color="orange lighten-1"
                    style="width: 300px;"
                    class="imo-number input-width pa-5"
                    v-model="imoNumber"
                    type="number"
                    label="IMO Number"
                    hide-spin-buttons
                    :defaultValue="null"
                    onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                    @input="$v.dischargePlanData.imoNumber.$touch()"
                    id="imoNumberField"
                    :error-messages="imoNumberError()"
                    :readonly=true
              />
            </v-card>
            <v-card flat pr-10 class="text-center">
              <v-layout row wrap justify-space-between>
                <v-flex px-3>
                  <h4>Form Completion Status</h4>
                  <v-progress-circular
                    :value="reportProgress" 
                    :color="reportStatus.colour"
                    :rotate="-90"
                    :size="60"
                    :width="8">
                    {{ reportProgress }}%
                  </v-progress-circular>
                </v-flex>
                <v-flex px-3>
                  <h4>Ship Performance</h4>
                  <h4 :style="`color:${shipPerformanceStatus.colour};`">{{ shipPerformanceStatus.description }}</h4>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 px-3 pt-2>
            <v-divider />
          </v-flex>
        </v-card>
        <v-flex xs12 pa-3>
          <v-layout row wrap pa-2 :key="`${shipPortPerformanceSectionData.id}-sections`">
            <template v-for="(section, index) in shipPortPerformanceSectionData">
              <v-flex xs12 pb-2 class="print-100 print-center" :key="`${section.id}-title`">
                <h1>
                  {{ section.title }}
                  <v-menu offset-x open-on-hover open-on-click max-height="200" max-width="1000"
                          v-if="section.guideline && section.guideline !== ''">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon right>info</v-icon>
                      </span>
                    </template>
                    <v-card :class="'guideline-box'" elevation="2" hover outlined rounded>
                      <v-card-subtitle>
                        <pre class="format-guideline">{{section.guideline}}</pre>
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                </h1>
              </v-flex>
              <v-flex column :key="`${section.id}-section-details`">
                <v-container :key="`${section.id}-questions`">
                  <checklist-section 
                    :checklistSectionData="section"
                    @updateComment="updateCommentInChecklistSection"
                    @updateSubmission="updateSubmissionInChecklistSection" />
                </v-container>
                <v-flex xs4 pb-2 :key="`${section.id}-comment`">
                  <v-textarea 
                    hint="Add any other section comments/details"
                    v-model="section.comment" 
                    label="Section Notes" 
                    rows="4" 
                    outlined
                    :error-messages="commentsErrors(index)"
                    color="orange lighten-1" 
                    @input="section.comment = $event"
                    :readonly=true />
                </v-flex>
              </v-flex>
            </template>
          </v-layout>
          <v-layout row wrap justify-end>
            <v-flex column xs2 mb-2 mr-3>
              <flat-button
                class="button"
                @click="printReport"
                :disabled="isShipPortPerformanceReportReadyToPrint"
                :loading="loading.pdf"
                id="printCertificateButton"> 
                Print Report
              </flat-button>
            </v-flex>
            <v-flex column xs2 mb-2 mr-3>
                <!-- email and save are disabled as we now use AMAS, SPPR is kept for historical data. -->
              <flat-button
                class="button"
                @click="openEmailReportDialog"
                :disabled=true
                :loading="loading.email"
                id="printCertificateButton"> 
                Email Report
              </flat-button>
              <div v-if="shipPortPerformanceData.lastEmailedOn" class="hint-text" style="margin: 2px;">
                <p>Last emailed on: <em>{{ shipPortPerformanceData.lastEmailedOn | formatDateTime('yyyy-MM-dd HH:mm')}}</em></p>
              </div>
            </v-flex>
            <v-flex column xs2 mb-2>
              <flat-button
                class="button"
                @click="onSave"
                :loading="loading.report"
                :disabled="true"> 
                Save 
              </flat-button>
              <div v-if="shipPortPerformanceData.updatedBy" class="hint-text" style="margin: 2px;">
                  <p>Last updated by <em>{{ shipPortPerformanceData.updatedBy }}</em>;</p>
                  <p>On: <em>{{ shipPortPerformanceData.updatedOn | formatDateTime('yyyy-MM-dd HH:mm')}}</em></p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import { UI } from '@/store/ui';
  import { 
    SHIP_PORT_PERFORMANCE 
  } from '@/store/tankerDischarge/postDischarge/shipPortPerformance';
  import { maxLength } from 'vuelidate/lib/validators'
  import { checklist, checklistSectionType } from '@/constants/checklists';
  import { shipPerformanceStatus, reportStatus } from '@/constants/statuses'
  import { cloneDeep } from '@/helpers/object';
  import isEqual from 'lodash/isEqual';
  import ChecklistSection from './../../ChecklistSection.vue';
  import { 
    DISCHARGE_PLAN 
  } from '@/store/tankerDischarge/preArrival/dischargePlan';
  export default {
    components: { ChecklistSection },
    async created() {
      await this.fetchAndRemapChecklistData();
      this.autoSaveInterval = setInterval(() => {this.runAutoSaveInterval()}, 180000); // set timer to 3 minutes
      await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
        { id: this.idFromRoute }
      );
      this.dischargePlanData = cloneDeep(this.dischargePlan);
    },
    watch: {
      $route: 'fetchData'
    },
    data() {
      return {
        totalQuestions: 0,
        totalSubmissions: 0,
        loading: {
          report: false,
          pdf: false,
          email: false
        },
        pdfOptions:{
          url: null,
          data: null,
          showDialog: false
        },
        showGenerateEmailDialog: false,
        shipPortPerformanceData: null,
        baseShipPortPerformanceData: null,
        autoSaveInterval: null,
        dischargePlanData: null
      };
    },
    computed: {
      emailList() {
        return "ampolvetting@ampol.com.sg; " +
               "DLPrimaryDistribution@zisfornz.onmicrosoft.com; " +
               "Steve.Flanagan@z.co.nz; " +
               "Brendan.Wannop@z.co.nz; " +
               "martin.burley@z.co.nz";
      },
      isLoading() {
        return this.uiIsLoading || this.loading.report;
      },
      isSaveDisabled() {
        return this.isLoading;
      },
      checklistType(){
        return checklist.shipPortPerformance.type;
      },
      reportProgress(){
        if (!this.shipPortPerformanceData) return 0;
        var totalAnswered = this.imoNumberHasValue ? this.totalSubmissions + 1 : this.totalSubmissions;
        return Math.round((totalAnswered / (this.totalQuestions + 1)) * 100);
      },
      reportStatus(){
        if (this.reportProgress === 0) return reportStatus.NotStarted;
        if (this.reportProgress === 100) return reportStatus.Completed;
        return reportStatus.InProgress;
      },
      shipPerformanceStatus(){
        if (this.reportProgress === 0) return shipPerformanceStatus.NA;
        if (this.submissionResults.filter(sr => sr.submissions && sr.submissions[0] === 'No').length > 0){
          if (this.shipPerformanceTerminalAcceptanceResult &&
              this.shipPerformanceTerminalAcceptanceResult.submissions[0] === 'No') return shipPerformanceStatus.Poor;
          
          return shipPerformanceStatus.Fair;
        }
        return shipPerformanceStatus.Good;
      },
      imoNumberHasValue() {
        return this.imoNumber !== null && this.imoNumber !== '' && this.$v.dischargePlanData.imoNumber.maxLength;
      },
      isShipPortPerformanceReportReadyToPrint(){
        return !this.imoNumberHasValue || (this.submissionResults.filter(sr => sr.submissions.length === 0).length > 0);
      },
      shipPortPerformanceSectionData() {
        return this.shipPortPerformanceData.checklist.sections;
      },
      idFromRoute(){
        return this.$route.params.planId;
      },    
      imoNumber: {
        get() {
          return this.dischargePlanData && this.dischargePlanData.imoNumber;
        },
        set(value) {
          const plan = this.dischargePlanData;
          plan.imoNumber = value;
          if(value && value.length < 11){           
            this.$store.dispatch(
              DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.UPDATE_DISCHARGE_PLAN),
              { id: plan.id, dischargePlan: plan }
            );
            this.$store.commit(
              DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_IMO_NUMBER),
              value
            );
          }         
        }
      },
      shipPerformanceTerminalAcceptanceResult(){
        return this.shipPortPerformanceData.checklist.sections
                   .filter((section) => section.id === checklistSectionType.shipPortPerformanceTerminalAcceptance)[0]
                   .questions[0].submissionResult;
      },
      submissionResults() {
        return this.shipPortPerformanceData.checklist.sections.reduce(
          (submissionResults, section) => {
            submissionResults.push(...section.questions.map(q => q.submissionResult));
            return submissionResults;
          },
          []
        );
      },
      ...mapState({
        shipPortPerformance: state => state.shipPortPerformance && state.shipPortPerformance,
        uiIsLoading: state => state.ui.isLoading,
        dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan
      })
    },
    methods: {
      commentsErrors(index) {
        if (!this.$v.shipPortPerformanceSectionData.$each[index]) return null;
        if (!this.$v.shipPortPerformanceSectionData.$each[index].comment.maxLength) {
          return 'The notes must be shorter than 200 characters long';
        }
      },
      updateCommentInChecklistSection(sectionId, questionId, newComment) {
        this.shipPortPerformanceData.checklist.sections.forEach((section) => {
          if (section.id === sectionId)
          {
            section.questions.forEach((question) => {
              if (question.id === questionId) {
                question.submissionResult.comment = newComment;
              }            
            })
          }
        })                                          
      },
      imoNumberError(){
        if (!this.$v.dischargePlanData.imoNumber) return null;
        if (!this.$v.dischargePlanData.imoNumber.maxLength) {
          return 'IMO Number must be less 10 numbers or less';
        }
      },
      updateSubmissionInChecklistSection(sectionId, questionId, newSubmissions) {
        this.shipPortPerformanceData.checklist.sections.forEach((section) => {
          if (section.id === sectionId)
          {
            section.questions.forEach((question) => {
              if (question.id === questionId) {
                if (question.submissionResult.submissions.length !== newSubmissions.length){
                  this.totalSubmissions = newSubmissions.length > 0 ? this.totalSubmissions + 1 : this.totalSubmissions - 1;
                }
                question.submissionResult.submissions = newSubmissions;
              }
            })
          }
        })                                          
      },
      async fetchAndRemapChecklistData() {
        await this.fetchData();
        this.shipPortPerformanceData = cloneDeep(this.$store.state.shipPortPerformance.shipPortPerformance);
        this.totalQuestions = 0;
        this.totalSubmissions = 0;
        this.shipPortPerformanceData.checklist.sections.forEach(section => {
          section.questions.forEach(question => {
            if (question.submissionResult == null) {
              question.submissionResult = {
                submissions: [],
                comment: ''
              };
            }
            this.totalQuestions = this.totalQuestions + 1;
            this.totalSubmissions = question.submissionResult.submissions.length > 0 ? this.totalSubmissions + 1 : this.totalSubmissions;
            question.submissionResult.planId = this.idFromRoute;
            question.submissionResult.checklistQuestionId = question.id;
            question.submissionResult.checklistType = this.checklistType;
          });
        });
        this.baseShipPortPerformanceData = cloneDeep(this.shipPortPerformanceData);
        if (this.showGenerateEmailDialog) this.onCancel();
      },
      async printReport() {
        this.loading.pdf = true;
        if (await this.saveShipPortPerformance(false)) {
          const res = await this.$store.dispatch(
            SHIP_PORT_PERFORMANCE.NS(SHIP_PORT_PERFORMANCE.ACTIONS.GET_SHIP_PORT_PERFORMANCE_REPORT_PDF),
            { planId: this.idFromRoute }
          );
          const file = new Blob([res.data], {type: 'application/pdf'});
          this.pdfOptions.data = file;
          const fileURL = URL.createObjectURL(file);
          this.pdfOptions.url = fileURL
          this.pdfOptions.showDialog = true;
        } 
        this.loading.pdf = false;
      },
      onCancel(){
        this.showGenerateEmailDialog = false;
      },
      openEmailReportDialog() {
        this.showGenerateEmailDialog = true;
      },
      async emailShipPortPerformanceReport(emailModel){
        this.loading.email = true;
        if (await this.saveShipPortPerformance(false, false)) {
          var toRecipients = emailModel.toRecipients.split(';').reduce(
              (toRecipients, email) => {
                toRecipients.push(email.trim());
                return toRecipients;
              },
              []
          );
         
          if (emailModel.ccRecipients) {
            var ccRecipients = emailModel.ccRecipients.split(';').reduce(
              (ccRecipients, email) => {
                ccRecipients.push(email.trim());
                return ccRecipients;
              },
              []
            );
          }
    
          var success = await this.$store.dispatch(
            SHIP_PORT_PERFORMANCE.NS(SHIP_PORT_PERFORMANCE.ACTIONS.EMAIL_SHIP_PORT_PERFORMANCE_REPORT_PDF),
            {
                planId: this.idFromRoute,
                emailModel: {
                  body: emailModel.body,
                  subject: emailModel.subject,
                  toRecipients: toRecipients,
                  ccRecipients: ccRecipients
                }
            });
    
          if(success){
            await this.fetchAndRemapChecklistData();
            this.$store.dispatch(
              UI.NS(UI.ACTIONS.ADD_TOAST),
              {
                id: 'success-email-ship-port-performance',
                text: 'Successfully emailed Ship Port Performance Report'
              },
              { root: true }
            );
          } else {
            this.$store.dispatch(
              UI.NS(UI.ACTIONS.ADD_TOAST),
              {
                id: 'error-email-ship-port-performance',
                text: 'Emailing Ship Port Performance Form failed',
                error: true
              },
              { root: true }
            );
          }
        } else {
          this.$store.dispatch(
            UI.NS(UI.ACTIONS.ADD_TOAST),
            {
              id: 'error-update-ship-port-performance',
              text: 'Updating Ship Port Performance Report failed',
              error: true
            },
            { root: true }
          );
        }
        this.showGenerateEmailDialog = false;
        this.loading.email = false;
      },
      async onSave() {
        this.loading.report = true;
        await this.saveShipPortPerformance();
        this.loading.report = false;
      },
      async saveShipPortPerformance(printSuccessToast = true, remapChecklist = true) {
        const sectionComments = this.shipPortPerformanceData.checklist.sections.reduce(
          (sectionComments, section) => {
            var sectionComment = {
              shipPortPerformanceId: this.shipPortPerformanceData.id,
              checklistSectionId: section.id,
              comment: section.comment
            }
            sectionComments.push(sectionComment);
            return sectionComments;
          },
          []
        );
        const success = await this.$store.dispatch(
          SHIP_PORT_PERFORMANCE.NS(SHIP_PORT_PERFORMANCE.ACTIONS.UPDATE_SHIP_PORT_PERFORMANCE_ANSWERS),
          { 
            submissionResults: this.submissionResults, 
            sectionComments, 
            shipPerformanceStatus: this.shipPerformanceStatus.id,
            planId: this.idFromRoute 
          }
        );
        if (success) {
          if (remapChecklist) await this.fetchAndRemapChecklistData();
          if (printSuccessToast) {
            this.$store.dispatch(
              UI.NS(UI.ACTIONS.ADD_TOAST),
              {
                id: 'success-update-ship-port-performance',
                text: 'Successfully updated Ship Port Performance Report'
              },
              { root: true }
            );
          }
        } else {
          this.$store.dispatch(
            UI.NS(UI.ACTIONS.ADD_TOAST),
            {
              id: 'error-update-ship-port-performance',
              text: 'Updating Ship Port Performance Report failed',
              error: true
            },
            { root: true }
          );
        }
        return success;
      },
      async fetchData() {
        await this.$store.dispatch(
          SHIP_PORT_PERFORMANCE.NS(SHIP_PORT_PERFORMANCE.ACTIONS.GET_SHIP_PORT_PERFORMANCE),
          { planId: this.idFromRoute }
        );
      },
      async autoSave() {
        await this.saveShipPortPerformance();
      },
      isThereUnsavedChanges() {
        return !isEqual(
          this.shipPortPerformanceData,
          this.baseShipPortPerformanceData
        );
      },
      runAutoSaveInterval() {
        if (this.isThereUnsavedChanges()) {
          this.autoSave();
          this.baseShipPortPerformanceData = cloneDeep(this.shipPortPerformanceData);
        }
      }
    },
    validations: {
      shipPortPerformanceSectionData: {
        $each: {
          comment: {
            maxLength: maxLength(200)
          }
        }
      },
      dischargePlanData: {
        imoNumber: {
          maxLength: maxLength(10)
        }
      }    
    }
  }
  </script>
  <style lang="scss" scoped>
  @import '../../../assets/styles/_responsive.scss';
  @import '../../../assets/styles/_variables.scss';
  .question {
    font-size: 120%;
  }
  .hint-text{
    font-size: 14px;
    color: grey
  }
  p { margin:0 }
  // always show input labels in "active" position and display full label
  :deep(.v-text-field) .v-label {
    transform: translateY(-18px) scale(0.75);
    max-width: initial !important;
  }
  .checkbox-padding {
    padding-right: 18px;
  }
  .v-expansion-panel {
    box-shadow: none !important;
  }
  .v-expansion-panel__container {
    border-top: none !important;
  }
  .sticky-bar {
    position: sticky; 
    width: 100%;
    z-index: 6;
    top: 111.2px;
  }
  .imo-number ::v-deep input[type=number] {
    -moz-appearance: textfield;
  }
  .imo-number ::v-deep input[type=number]::-webkit-outer-spin-button,
  .imo-number ::v-deep input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .guideline-box {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 2px;
  }
  .format-guideline {
    word-wrap: break-word;
    white-space: pre;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    font-size: 16px;
  }
  @media print {
     :deep(.custom-placeholder-color) input::-webkit-input-placeholder {
      color: transparent !important;
    }
    :deep(.custom-placeholder-color) input::placeholder {
      color: transparent !important;
    }
    :deep(.custom-placeholder-color) input::-moz-placeholder {
      color: transparent !important;
    }
    :deep(.custom-placeholder-color) input::-ms-placeholder {
      color: transparent !important;
    }
  }
  </style>