<template>
  <v-container fluid fill-height class="no-print-padding">
    <v-dialog max-width="500" v-model="showDialog">
      <v-card>
        <v-card-title class="headline">Create Receipt plan</v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <v-text-field label="Pilot on board"
                    v-model="vesselInfo.pilotOnBoard"
                    v-mask="'####-##-## ##:##'"
                    placeholder="yyyy-MM-dd HH:mm"
                    :error-messages="pilotOnBoardErrors"
                    @input="$v.vesselInfo.pilotOnBoard.$touch()"
                    ref="pilotOnBoard"
                    color="orange lighten-1"/>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs6 pr-3>
              <v-text-field class="input-width"
                v-model="vesselInfo.vessel"
                label="Vessel"
                :rules="[()=> $v.vesselInfo.vessel.required || 'Vessel is required']"
                @input="$v.vesselInfo.vessel.$touch()"
                ref="vessel" color="orange lighten-1"
                id="vesselInput"
              />
            </v-flex>
            <v-flex xs6 pr-3>
              <v-text-field class="input-width"
                v-model="vesselInfo.voyage"
                label="Voyage"
                :rules="[()=> $v.vesselInfo.voyage.required || 'Voyage is required']"
                @input="$v.vesselInfo.voyage.$touch()"
                ref="voyage" color="orange lighten-1"
                id="voyageInput"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary lighten-3"
                  :loading="loading"
                  :disabled="loading"
                  @click="onCancelDialog"
                  id="cancelButton">
            Cancel
          </v-btn>
          <v-btn color="orange lighten-1"
                  @click="onSave"
                  :loading="loading"
                  :disabled="loading || $v.vesselInfo.$invalid"
                  id="saveButton">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-layout row wrap justify-end>
      <v-flex xs3 px-4 pb-3>
        <flat-button v-if="dischargePlanTerminal"
                     :loading="loading"
                     @click="onCreate"
                     :disabled="loading"
                     id="createNewReceiptPlanButton">
          Create new Receipt Plan
        </flat-button>
      </v-flex>
      <v-flex xs12 pa-4>
        <tanker-discharge-receipt-plan-table v-if="dischargePlanTerminal" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { UI } from '@/store/ui';
import { DISCHARGE_PLAN } from '@/store/tankerDischarge/preArrival/dischargePlan';
import { TERMINALS } from '@/store/terminals';
import { ROUTE_NAMES } from '@/routes';
import { maskedDatetime } from '@/validators';
import { removeTimezone, formatDateTimeToMasked } from '@/helpers/date';
import { required } from 'vuelidate/lib/validators';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";

function defaultVesselInfo() {
  return {
    pilotOnBoard: '',
    vessel: '',
    voyage: ''
  };
}

export default {
  data() {
    return {
      showDialog: false,
      vesselInfo: defaultVesselInfo()
    };
  },
  computed: {
    loading: {
        get() {
            return this.$store.getters[UI.NS(UI.GETTERS.IS_LOADING)];
        },
        set(load) {
            this.$store.commit(
              UI.NS(UI.MUTATIONS.TOGGLE_IS_LOADING),
              load
            );
        }
    },
    activeTerminal: {
      get() {
        const activeTerminal =
          this.$store.state.terminals &&
          this.$store.state.terminals.activeTerminal &&
          this.$store.state.terminals.activeTerminal.id;
        return activeTerminal;
      },
      set(activeTerminalId) {
        const activeTerminal = this.terminalsByKey[activeTerminalId];

        this.$store.dispatch(
          TERMINALS.NS(TERMINALS.ACTIONS.SET_ACTIVE_TERMINAL),
          activeTerminal
        );
      }
    },
    pilotOnBoardErrors() {
      let errors = [];

      if (!this.$v.vesselInfo.pilotOnBoard.$dirty || this.$v.vesselInfo.pilotOnBoard.$pending) {
        return [];
      }

      if (!this.$v.vesselInfo.pilotOnBoard.required || !this.$v.vesselInfo.pilotOnBoard.maskedDatetime) {
        errors = [...errors, 'A valid date is required (yyyy-MM-dd HH:mm)'];
      }

      return errors;
    },
    ...mapState({
      terminal: state => state.terminals.activeTerminal,
      terminalsByKey: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.reduce((terminals, terminal) => {
          terminals[terminal.id] = { ...terminal };
          return terminals;
        }, {}),
      terminalsSelectItems: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.map(terminal => ({
          value: terminal.id,
          text: terminal.name
        })),
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
      dischargePlanTerminal: state => state.terminals && state.terminals.activeTerminal,
      isLoading: state => state.ui.isLoading
    })
  },
  methods: {
    async onSave() {
      this.loading = true;

      const success = await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.CREATE_DISCHARGE_PLAN), {
        terminalId: this.terminal.id,
        vessel: this.vesselInfo.vessel,
        voyage: this.vesselInfo.voyage,
        pilotOnBoard: removeTimezone(new Date(this.vesselInfo.pilotOnBoard))
      });

      if (success) {
        const planId = this.dischargePlan && this.dischargePlan.id;
        this.$router.push({
          name: ROUTE_NAMES.VESSEL_INFO,
          params: { planId }
        });
      }

      this.loading = false;
    },    
    onCreate() {
      this.showDialog = true;
      this.resetVesselInfo();
      this.vesselInfo.pilotOnBoard = formatDateTimeToMasked(new Date(), DATE_TIME_FORMAT);
      this.resetValidation();
      this.$nextTick(() => this.$refs.pilotOnBoard.focus());
    },
    onCancelDialog() {
      this.showDialog = false;
    },
    resetVesselInfo() {
      this.vesselInfo = defaultVesselInfo();
    },
    resetValidation() {
      this.$nextTick(() => this.$refs.vessel.reset());
      this.$nextTick(() => this.$refs.voyage.reset());
    }
  },
  validations: {
    vesselInfo: {
      vessel: {
        required
      },
      voyage: {
        required
      },
      pilotOnBoard: {
        required,
        maskedDatetime
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';
@media print {
  .no-print-padding {
    padding: 0 !important;
  }
}
</style>
