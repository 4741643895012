 <template>
  <v-layout row wrap pa-4>
    <h2>{{message}}</h2>
  </v-layout>
</template>

<script>
export default {
  computed: {
    message() {
      return this.$route.meta && this.$route.meta.message || 'Coming soon, please refer to existing process.';
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
</style>
