import { actions, EQUIPMENT_INFORMATION_ACTIONS } from './actions';
import { getters, EQUIPMENT_INFORMATION_GETTERS } from './getters';
import { mutations, EQUIPMENT_INFORMATION_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'equipmentInformation';

export const state = {
    equipments: []
};

export function GET_DEFAULT_EQUIPMENT_DETAILS() {
    return {
        equipmentName: null,
        serialNumber: null,
        testCertDate: null,
        testCertExpiry: null,
        notes: null,
        documents: null
    };
};

export const EQUIPMENT_INFORMATION = {
    ACTIONS: EQUIPMENT_INFORMATION_ACTIONS,
    GETTERS: EQUIPMENT_INFORMATION_GETTERS,
    MUTATIONS: EQUIPMENT_INFORMATION_MUTATIONS,
    NAME_SPACE,
    NS: x => `${NAME_SPACE}/${x}`
};

export const equipmentInformation = {
    namespaced,
    state,
    actions,
    getters,
    mutations
};