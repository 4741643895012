import Vue from 'vue';
export const PAGINATION_MUTATIONS = {
  SET_PAGINATION: 'setPagination'
};

export const mutations = {
  [PAGINATION_MUTATIONS.SET_PAGINATION](state, { key, value }) {
    Vue.set(state, key, value);
  }
};
