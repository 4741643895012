<template>
  <v-app v-if="isAuthenticated">
    <v-app-bar app class="toolbar no-print" :color="statusBarColor" fixed dark clipped-left>
      <template v-for="item in mainLinks">
        <router-link :key="item.name" :to="{ name: item.name }" @click.native="resetNavigationValues">
          <img class="logo" width="50" :src="`${publicPath}Z-logo.svg`" alt="Z" />
        </router-link>
      </template>

      <v-layout mt-7 v-if="activeTerminal">
          <div class="d-flex pt-2">
            <template v-if="!(operationValue === null)">
              <v-tooltip bottom v-if="setDrawer">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text fab 
                      v-bind="attrs" v-on="on"
                      @click.stop="setDrawer = !setDrawer">
                    <v-icon large color="white">fullscreen_exit</v-icon>
                  </v-btn>
                </template>
                <span>Collapse Menu</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!setDrawer">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text fab 
                      v-bind="attrs" v-on="on"
                      @click.stop="setDrawer = !setDrawer">
                    <v-icon large color="white">fullscreen</v-icon>
                  </v-btn>
                </template>
                <span>Expand Menu</span>
              </v-tooltip>
            </template>
          </div>
          <terminal-select />
      </v-layout>
      <v-layout v-else>
          <span class="terminalHeader">Terminals</span>
      </v-layout>
      <v-spacer />
      <div class="environmentText">{{environmentText}}</div>
      <div class="loading-circle">
        <loading-circle v-show="isLoading" style="margin-right:25px"/>
      </div>
      <a class="link" name="ApplicationMenuLinkSignOut" @click="signOut">Sign Out</a>
      <assume-role-menu />
      <span class="label" id="frontendVersion">v{{ frontendVersion }}</span>
      <v-btn v-if="jiraIntegrationFeature" px-2 icon dark>
        <router-link :to="{ name: jiraRoute }" target="_blank" :for="jiraRoute" replace>
          <v-icon color="white">feedback</v-icon>
        </router-link>
      </v-btn>
    </v-app-bar>

    <menu-navigation />

    <v-main>
      <menu-sub-bar />
      <v-layout fluid row wrap justify-center>
        <v-flex xs12>
          <router-view :key="$route.fullPath" class="sub-menu-offset"/>
          <toast-handler />
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { routes, ROUTE_NAMES } from '@/routes';
import { mapGetters, mapState, mapActions } from 'vuex';
import { TERMINALS } from '@/store/terminals';
import { AUTH } from '@/store/auth';
import { UI } from '@/store/ui';
import { BASE_URL, ENVIRONMENT } from '@/helpers/config';
import { FEATURES } from '@/store/features';
import { FEATURES_LIST } from '@/constants/featuresList';

var packageJsonFile = require('../package.json');

export default Vue.extend({
  async created() {
    await this.$store.dispatch(
      FEATURES.NS(FEATURES.ACTIONS.SET_FEATURE_STATE),
      FEATURES_LIST.JiraIntegration
    );
  },
  mounted() {
    this.setDrawer = true;
  },
  watch: {
    isAuthenticated: {
      immeditate: true,
      async handler() {
        await this.fetchData();
        this.setActiveTerminalFromRoute();
      }
    }
  },
  data: () => ({
    publicPath: BASE_URL,
    dialog: false,
    jiraRoute: ROUTE_NAMES.CREATE_JIRA_ISSUE
  }),
  methods: {
    resetNavigationValues() {
      this.$store.commit(
        TERMINALS.NS(TERMINALS.MUTATIONS.RESET_DEFAULT_ACTIVE_TERMINAL)
      );
      this.$store.commit(
        UI.NS(UI.MUTATIONS.RESET_DEFAULT_OPERATION_ID)
      );
    },
    async fetchData(){
      await this.$store.dispatch(TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS));
    },
    setActiveTerminalFromRoute(){
      const terminalToSet = this.terminals.terminalsList.find(t => t.id == this.$route.params.terminalId);
      if(this.$route.params.terminalId){
        this.$store.commit(
          TERMINALS.NS(TERMINALS.MUTATIONS.SET_ACTIVE_TERMINAL),
          terminalToSet
        );
      }
    },
    ...mapActions({
      signOut: AUTH.NS(AUTH.ACTIONS.LOGOUT)
    })
  },
  computed: {
    frontendVersion() {
      return packageJsonFile.gitversion;
    },
    environmentText(){
      return ENVIRONMENT == "Prod" ? "" : ENVIRONMENT; 
    },
    statusBarColor(){
      return (ENVIRONMENT == "Dev") || (ENVIRONMENT == "Test") || (ENVIRONMENT == "UAT") ? "red" : '#1e196a';
    },
    operationValue() {
      return this.$store.getters[UI.NS(UI.GETTERS.OPERATION_ID)];
    },
    setDrawer: {
        get() {
            return this.$store.getters[UI.NS(UI.GETTERS.OPEN_DRAWER)]
        },
        set(val) {
            this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_OPEN_DRAWER), val);
        }
    },
    mainLinks() {
      const roles = this.roles;
      return (
        routes
          // filter out routes that do not have meta.main is set
          .filter(route => route.meta && route.meta.main)
          .map(route => ({
            text: route.meta && route.meta.display,
            name: route.name,
            path: route.path,
            roles: route.meta && route.meta.requireRole
          }))
          // filter out routes base on the users role
          .filter(
            route =>
              !route.roles ||
              (roles && route.roles.some(role => roles.indexOf(role) >= 0))
          )
      );
    },
    ...mapState({
      roles: state => state.auth.roles,
      isAuthenticated: state => state.auth.isAuthenticated,
      operationId: state => state.ui.operationId,
      activeTerminal: state => state.terminals && state.terminals.activeTerminal,
      terminals: state => state.terminals,
      jiraIntegrationFeature: state => state.features.featureStateList[FEATURES_LIST.JiraIntegration],
      isLoading: state => state.ui.isLoading
    }),
    ...mapGetters({
      username: AUTH.NS(AUTH.GETTERS.GET_USERNAME)
    })
  }
});
</script>

<style lang="scss" scoped>
.label {
  padding: 10px;
  margin-left: 8px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: rgb(252, 252, 252) !important;
  font-size: 14px;
}

.terminalHeader {
  text-align: center;
  color: #fff !important;
  font-size: 20px;
  margin: 0 8px;
  line-height: 24px;
  vertical-align: text-bottom;
  font-weight: bold;
}

.environmentText {
  font-weight: bold;
  color: #fff;
  font-size: 32px;
  padding-right: 150px;
  text-transform: uppercase;
}

:deep(.v-list) {
  padding-top: 1rem;
}

.link {
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff !important;
  font-size: 18px;
}

.logo {
  width: 50px;
  vertical-align: middle;
  margin-right: 18px;
}
.sub-menu-offset {
  max-width: 100% !important;
}

.toolbar {
  z-index: 6;
}

.tank-sub-menu {
  background-color: $mid-charcoal;
}

.loading-circle{
  display:block;
  min-height:32px;
  min-width:50px;
  width:50px; 
  height:32px;
}
@media print {
  :deep(.v-content) {
    padding-top: 0 !important;
  }
  :deep(.no-print) {
    display: none !important;
  }
}
</style>