import { format, parse, parseISO, isValid, addMinutes, isAfter, isEqual } from 'date-fns';
import { DATE_TIME_FORMAT, TIME_ZONE } from "@/constants/dateFormat";

export function removeTimezone(date) {
  if (date) {
    return String(date).substring(0, 21);
  }
  return date;  
}

export function parseDate(
  val,
  formatString = 'yyyy-MM-dd'
) {
  if (val && typeof val === 'string' && val.length === formatString.length) {
    return parse(val, formatString, new Date());
  }
  return null;
}

export function isValidMaskedDateTime(val, format = DATE_TIME_FORMAT) {
  if (typeof val === "string" || val instanceof String) {
    var date = '';

    if(val.length === format.length) {
      date = parse(val, format, new Date());
    }
    else if (val !== "Invalid Date") {
      date = parse(formatDateTimeToMasked(val), format, new Date());
    }

    return isValid(date); 
  }

  return false;
}

export function validateDate(val) {
  if (val && val !== "Invalid Date") {
    if (typeof val !== 'string') {
      return val;
    } 
    return parseISO(val);
  }
  return val;
}

export function formatDate(val, stringFormat = 'dd/MM/yyyy') {
  return isValid(validateDate(val)) ? format(validateDate(val), stringFormat, { timeZone: TIME_ZONE, awareOfUnicodeTokens: true }) : val;
}

export function formatDateTimePlusHalfHour(val, stringFormat = 'yyyyMMddHHmm') {
  return formatDate(addMinutes(new Date(val), 30), stringFormat);
}

export function formatDateTimeToMasked(val, stringFormat = 'yyyyMMddHHmm') {
  return formatDate(val, stringFormat);
}

function formatAndParseISODateTime(date) {
  return parseISO((new Date(date)).toISOString().split('.')[0] + "Z");
}

export function dateIsAfterOrEqual(date, dateToCompare) {
  const formattedDate = formatAndParseISODateTime(date);
  const formattedDateToCompare = formatAndParseISODateTime(dateToCompare);

  return isAfter(formattedDate, formattedDateToCompare) || isEqual(formattedDate, formattedDateToCompare);
}

export function getTimeMinutes(time) {
  var minutes = 0;
  if(time != null) {
    minutes = time.split(':', 2)[1]
    return minutes;
  }
  return minutes;
}

export function convertNzDateToUs(dateNz) {
  var dateNoChars = dateNz.split('-');
  var dateUs = new Date(dateNoChars[2], dateNoChars[1]-1, dateNoChars[0]);
  return removeTimezone(dateUs);
}