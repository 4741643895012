<template>
  <v-container fluid fill-height>
    <pdf-preview header="Quality Report PDF" fileName="QualityReport.pdf" :showDialog="pdfOptions.showDialog" :url="pdfOptions.url" @close="pdfOptions.showDialog = false" :data="pdfOptions.data"></pdf-preview>
    <tanker-discharge-post-discharge-edit-post-receipt-storage-tank-sample 
    v-on:close="onCancel" :showDialog="showAddEditPostReceiptStorageTankSampleDialog" :product="tankPlan.product" v-bind:testMethods="testMethods">
    </tanker-discharge-post-discharge-edit-post-receipt-storage-tank-sample>

    <tanker-discharge-post-discharge-release-tank :showDialog="showReleaseTankDialog" @close="showReleaseTankDialog = false"></tanker-discharge-post-discharge-release-tank>
    <v-layout row wrap justify-end pa-3>
        <v-flex xs12>
          <v-layout>
            <v-flex xs3 pr-4>
              <v-text-field :value="dischargePlan.vessel" color="orange lighten-1" label="Vessel" readonly filled />
            </v-flex>
            <v-flex xs3 pr-4>
              <v-text-field :value="dischargePlan.voyage" color="orange lighten-1" label="Voyage" readonly filled />
            </v-flex>
          </v-layout>
      </v-flex>

      <v-layout row wrap justify-end >
        <v-flex xs12>
          <v-data-table :headers="qualityReportHeaders"
            :items="qualityReportDetails"
            hide-default-footer
            item-key="value"
            color="orange lighten-1"
            :loading="loading.qualityReport"
            :no-data-text="loading.qualityReport ? 'loading...' : ''"
            loading-text="Loading... Please wait"
            class="no-sort-datatable elevation-3">
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer />
                <v-flex xs2 py-2>
                  <flat-button 
                    @click="addNewSample"
                    :disabled="loading.qualityReport"
                    :loading="loading.qualityReport">
                    {{addEditSampleText}}
                  </flat-button>
                </v-flex>
              </v-toolbar>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ item.row }}</td>
                  <td :class="cellWarningCSSClass(index, item.appearanceClarity)">{{ item.appearanceClarity }}</td>
                  <td :class="cellWarningCSSClass(index, item.productColour)">{{ item.productColour }}</td>
                  <td>{{ item.temperature }}</td>
                  <td v-if="showConductivity(product)" :class="cellWarningCSSClass(index, item.conductivity)">{{ item.conductivity }}</td>
                  <td :class="cellWarningCSSClass(index, item.density)" >{{ item.density }}</td> 
                  <td v-if="showFlashPoint(product)" :class="cellWarningCSSClass(index, item.flashPoint)">{{item.flashPoint}}</td>                   
                  <td v-if="showSulphur(product)" :class="cellWarningCSSClass(index, item.sulphur)">{{ item.sulphur}}</td>
                  <td v-if="showViscosity(product)" :class="cellWarningCSSClass(index, item.viscosity)">{{ item.viscosity }}</td>
                  <td v-if="showColdFilterPluggingPoint(product)" :class="cellWarningCSSClass(index, item.coldFilter)">{{ item.coldFilter }}</td>
                  <td v-if="showCloudPoint(product)" :class="cellWarningCSSClass(index, item.cloudPoint)">{{ item.cloudPoint }}</td>
                  <td v-if="showPourPoint(product)" :class="cellWarningCSSClass(index, item.pourPoint)">{{ item.pourPoint }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex xs12>
          <v-layout>
            <v-flex xs5 mt-2 py-2>
              <v-textarea 
                :hint="internalNotesSavedMessage" 
                v-model="internalNotes" 
                label="Internal Notes" 
                rows="4" 
                outlined 
                color="orange lighten-1" 
                :error-messages="internalNotesErrors"
                @input="setInternalNotes"/>
            </v-flex>
            <v-spacer />
            <v-flex xs2 py-4 mr-2 mt-4>
              <div v-if="!isQualityReportReleasable" class="hint-text">
                Please <em>complete</em> a composite sample before proceeding.
              </div>
            </v-flex>
            <v-flex xs2 py-2 mr-3 mt-4>
              <flat-button :disabled="!isQualityReportReleasable" @click="releaseTank" id="releaseTankButton">
                Release Tank
              </flat-button>
              <div v-if="releasedDate" class="hint-text">
                Last released on: <em>{{ releasedDate | formatDateTime('yyyy-MM-dd HH:mm')}}</em>
              </div>
            </v-flex>
            <v-flex xs2 py-2 mr-3 mt-4 v-if="qualityReportPdfFeature">
              <flat-button @click="printCertificate"
                    :disabled="!isQualityReportReadyToPrint"
                    :loading="loading.pdf"
                    id="printCertificateButton">
                    Print Certificate
              </flat-button>
                <div v-if="isQualityReportReleasable && !isQualityReportReadyToPrint" class="hint-text">
                  Please <em>{{releaseOrReRelease}}</em> the tank before continuing to print the certificate.
                </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import { 
  DISCHARGE_PLAN 
} from '@/store/tankerDischarge/preArrival/dischargePlan';
import { 
  QUALITY_REPORT, 
  GET_DEFAULT_QUALITY_REPORT_LINE_ITEM 
} from '@/store/tankerDischarge/postDischarge/qualityReport';
import { getAppearanceList } from '@/helpers/appearance';
import { mapState } from "vuex";
import { FEATURES } from '@/store/features';
import { FEATURES_LIST } from '@/constants/featuresList';
import debounce from 'lodash/debounce';
import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  async created(){
    await this.$store.dispatch(
      DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
      { id: this.planId }
    );
    this.loading.qualityReport = true;
    await this.$store.dispatch(
        QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.GET_QUALITY_REPORT),
        { tankPlanId: this.tankPlanId,
          planId: this.planId, receiptPlanId: this.receiptPlanId}
    );
    this.internalNotes = this.qualityReport.internalNotes;
    this.loading.qualityReport = false;
    await this.$store.dispatch(
        FEATURES.NS(FEATURES.ACTIONS.SET_FEATURE_STATE),
        FEATURES_LIST.QualityReportPdf
    );
  },
  data() {
    return {
      loading: {
        qualityReport: false,
        pdf: false
      },
      pdfOptions:{
        url: null,
        data: null,
        showDialog: false
      },
      showReleaseTankDialog:false,
      densityPrecision: 4,
      flashpointPrecision: 1,
      maxFlashPointVariation: 3.0,
      showSaveConfirmation: false,
      showAddEditPostReceiptStorageTankSampleDialog: false,
      internalNotesSavedMessage: null,
      internalNotes: null,
      headers: [
        { text: '', value: 'row', width: 100, fixed: true, sortable: false},
        { text: 'Appearance - Clarity', value: 'appearanceClarity', width: 100, sortable: false },
        { text: 'Colour', value: 'colour' , width: 100 ,sortable: false},
        { text: 'Temperature', value: 'temperature', width: 80, sortable: false},
        { text: 'Conductivity pS/m', value: 'conductivity', width: 80, sortable: false},
        { text: 'Density kg/l @15\u2103', value: 'density', width: 80, sortable: false},
        { text: 'Flash Point \u2103', value: 'flashPoint', width: 80, sortable: false},
        { text: 'Sulphur mg/kg', value: 'sulphur', width: 80, sortable: false},
        { text: 'Viscosity', value: 'viscosity', width: 80, sortable: false},
        { text: 'Cold Filter', value: 'coldFilter', width: 80, sortable: false},
        { text: 'Cloud Point', value: 'cloudPoint', width: 80, sortable: false},
        { text: 'Pour Point', value: 'pourPoint', width: 80, sortable: false}
      ],
      rows: [ 
        { row: 'Expected Result', value: 'expectedResult' }, 
        { row: 'Actual Result', value: 'actualResult' }, 
        { row: 'Allowable Tolerance', value: 'allowableTolerance' }, 
        { row: 'Variation', value: 'variation' }, 
        { row: 'Pass / Fail', value: 'passFail' }
      ],
      productColourItems: [
        { text: 'Purple', value: 'Purple' },
        { text: 'Red', value: 'Red' },
        { text: 'Straw', value: 'Straw' },
        { text: 'Water white', value: 'WaterWhite' },
        { text: 'Yellow', value: 'Yellow' }
      ]
    };
  },
computed: {
    product(){
      return this.qualityReport && this.qualityReport.product
    },
    testMethods(){
      return this.qualityReport && this.qualityReport.testMethods
    },
    postReceiptStorageTankSamples(){
      return this.qualityReport && this.qualityReport.postReceiptStorageTankSamples
    },
    addEditSampleText(){
      return this.postReceiptStorageTankSamples && this.postReceiptStorageTankSamples.length > 0 ? "Edit Samples" : "Add Samples"
    },
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    receiptPlanId() {
      return this.$route.params && this.$route.params.receiptPlanId;
    },
    tankPlanId() {
      return this.$route.params && this.$route.params.tankPlanId;
    },
    name() {
     return "Not implemented"
    },
    releaseOrReRelease(){
      return this.releasedDate ? "re-release" : "release";
    },
    tankPlan() {
      return (
        (this.receiptPlan &&
          this.receiptPlan.tankPlans &&
          this.receiptPlan.tankPlans[this.tankPlanId]) ||
        {}
      );
    },
    dischargePlan() {
      return (
        this.$store.state.dischargePlan &&
        this.$store.state.dischargePlan.dischargePlan
      );
    },
    receiptPlan() {
      return (
        (this.dischargePlan &&
          this.dischargePlan.receiptPlans &&
          this.dischargePlan.receiptPlans[this.receiptPlanId]) ||
        {}
      );
    },
    qualityReportHeaders() {
      var headers = this.headers.filter((item) => {
        if (item.value == 'flashPoint' && !this.showFlashPoint(this.product)) return false;
        if (item.value == 'conductivity' && !this.showConductivity(this.product)) return false;
        if (item.value == 'sulphur' && !this.showSulphur(this.product)) return false;
        if (item.value == 'viscosity' && !this.showViscosity(this.product)) return false;
        if (item.value == 'coldFilter' && !this.showColdFilterPluggingPoint(this.product)) return false;
        if (item.value == 'cloudPoint' && !this.showCloudPoint(this.product)) return false;
        if (item.value == 'pourPoint' && !this.showPourPoint(this.product)) return false;
        return true;
      });
      return headers;
    },
    qualityReportDetails() {
      if(!this.qualityReport) return [];
      var details = [];
      this.rows.forEach(row => {
        var rowItems = GET_DEFAULT_QUALITY_REPORT_LINE_ITEM();
        rowItems.value = row.value;
        rowItems.row = row.row;
        switch(row.value) {
          case 'expectedResult':
            rowItems.density = this.qualityReport.expectedResults.density;
            rowItems.flashPoint = this.qualityReport.expectedResults.flashPoint;
            rowItems.sulphur = this.qualityReport.expectedResults.sulphur;
            rowItems.viscosity = this.qualityReport.expectedResults.viscosity;
            rowItems.appearanceClarity = this.qualityReport.expectedResults.appearance;
            rowItems.productColour = this.qualityReport.expectedResults.productColour.join(', ');
            rowItems.coldFilter = this.qualityReport.expectedResults.coldFilterPluggingPoint;
            rowItems.cloudPoint = this.qualityReport.expectedResults.cloudPoint;
            rowItems.pourPoint = this.qualityReport.expectedResults.pourPoint;
            break;
          case 'actualResult':
            rowItems.density = this.qualityReport.testedResults.density;
            rowItems.flashPoint = this.qualityReport.testedResults.flashPoint;
            rowItems.appearanceClarity = this.qualityReport.testedResults.appearance;
            rowItems.conductivity = this.qualityReport.testedResults.conductivity;
            rowItems.temperature = this.qualityReport.testedResults.temperature;
            rowItems.productColour = this.qualityReport.testedResults.productColour;
            break;
          case 'allowableTolerance':
            rowItems.density = this.maxDensityVariation;
            rowItems.flashPoint = this.maxFlashPointVariation;
            break;
          case 'variation':
            rowItems.density = this.qualityReport.variationResults.density;
            rowItems.flashPoint = this.qualityReport.variationResults.flashPoint;
            break;
          case 'passFail':
            rowItems.density = this.qualityReport.passFailResults.density;
            rowItems.flashPoint = this.qualityReport.passFailResults.flashPoint;
            rowItems.appearanceClarity = this.qualityReport.passFailResults.appearance;
            rowItems.productColour = this.qualityReport.passFailResults.productColour;
            rowItems.cloudPoint = this.qualityReport.passFailResults.cloudPoint;
            rowItems.coldFilter = this.qualityReport.passFailResults.coldFilterPluggingPoint;
            rowItems.conductivity = this.qualityReport.passFailResults.conductivity;
            rowItems.pourPoint = this.qualityReport.passFailResults.pourPoint;
            rowItems.viscosity = this.qualityReport.passFailResults.viscosity;
            rowItems.sulphur = this.qualityReport.passFailResults.sulphur;
            break;
        }
        details.push(rowItems);
      });
      return details;
    },
    maxDensityVariation() {
      if (this.receiptPlan && this.receiptPlan.product && this.receiptPlan.product.toLowerCase() === 'lfo') {
        return 0.0050;
      }
      return 0.0030;
    },
    appearanceList() {
      if (this.receiptPlan && this.receiptPlan.product) {
        return getAppearanceList(this.receiptPlan.product);
      }
      return null;
    },
    internalNotesErrors(){
      if(!this.$v.internalNotes.maxLength) return ['The notes must be shorter than 500 characters long']
      return [];
    },
    isQualityReportReadyToPrint(){
      return this.qualityReport && this.qualityReport.isQualityReportReadyToPrint;
    },
    isQualityReportReleasable(){
      return this.qualityReport && this.qualityReport.isQualityReportReleasable;
    },
    releasedDate(){
      return this.qualityReport && this.qualityReport.released;
    },
    ...mapState({
      qualityReport: (state) => state.qualityReport && state.qualityReport.qualityReport,
      qualityReportPdfFeature: state => state.features.featureStateList[FEATURES_LIST.QualityReportPdf]
    })    
  },
  methods: {
    cellWarningCSSClass(index, value){
      if(index == this.rows.findIndex(x => x.value == 'passFail') && value != 'Pass' && value != 'N/A') return 'cell-warning';
      return '';
    },
    addNewSample() {
      this.showAddEditPostReceiptStorageTankSampleDialog = true;
    },
    onCancel(){
      this.showAddEditPostReceiptStorageTankSampleDialog = false
    },
    async printCertificate() {
      this.loading.pdf = true;
      const res = await this.$store.dispatch(
          QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.GET_QUALITY_REPORT_PDF),
          { tankPlanId: this.tankPlanId,
            planId: this.planId, receiptPlanId: this.receiptPlanId}
      );
      const file = new Blob([res.data], {type: 'application/pdf'});
      this.pdfOptions.data = file;
      const fileURL = URL.createObjectURL(file);
      this.pdfOptions.url = fileURL
      this.pdfOptions.showDialog = true;
      this.loading.pdf = false;
    },
    releaseTank(){
      this.showReleaseTankDialog = true;
    },
    setInternalNotes: debounce(async function(notes) {
      if(this.$v.internalNotes.$invalid) return;

      this.internalNotesSavedMessage = "Saving..."
      const success = await this.$store.dispatch(
        QUALITY_REPORT.NS(QUALITY_REPORT.ACTIONS.UPDATE_INTERNAL_NOTES),
        {
          planId: this.planId,
          receiptPlanId: this.receiptPlanId,
          tankPlanId: this.tankPlanId,
          internalNotes: notes
        }
      );
      if(success){
        setTimeout(() => {
          this.internalNotesSavedMessage = null;
        }, 500)
      } else {
         this.internalNotesSavedMessage = "Failed to save"
      }
    }, 1000)
  },
  validations: {
    internalNotes: {
      maxLength: maxLength(500)
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

.hint-text{
  font-size: 14px;
  color: grey
}
</style>

