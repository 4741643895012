<template>
  <v-container fluid fill-height>
    <v-dialog class="no-print" max-width="300" v-model="showDeleteConfirmation">
      <v-card>
        <v-card-text>
          <v-layout>
            <v-flex xs12 pa-1>
              <strong class="save-title">Delete confirmation</strong>
              <div class="save-message">Are you sure you want to delete?</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange lighten-1"
                 @click="onDeleteConfirmation"
                 id="popupYesButton"
                 >Yes</v-btn>

          <v-btn color="secondary lighten-3"
                 @click="onDeleteConfirmationLeave"
                 id="popupNoButton">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <tanker-discharge-pre-arrival-edit-ship-coq 
      :showDialog="showDialog" 
      v-on:close="closeDialog()"
      :shipCoQToEdit="shipCoQToEdit" />

    <v-layout row wrap v-if="hasLoaded" pa-6>
      <v-flex xs12>
        <v-container fluid row wrap>
          <v-layout row wrap my-4>
            <v-flex xs3 pr-3>
              <v-text-field class="input-width"
                            color="orange lighten-1"
                            v-model="vessel"
                            label="Vessel"
                            :rules="[() => $v.vessel.required || 'Vessel is required']"
                            @input="$v.vessel.$touch()"
                            id="vesselInput"/>
            </v-flex>
            <v-flex xs3 pr-3>
              <v-text-field class="input-width"
                            color="orange lighten-1"
                            v-model="voyage"
                            label="Voyage"
                            :rules="[() => $v.voyage.required || 'Voyage is required']"
                            @input="$v.voyage.$touch()" 
                            id="voyageInput"/>
            </v-flex>
            <v-flex xs3>
              <v-text-field label="Pilot on board"
                            color="orange lighten-1"
                            v-model="pilotOnBoard"
                            v-mask="'####-##-## ##:##'"
                            placeholder="yyyy-MM-dd HH:mm"
                            :error-messages="pilotOnBoardErrors"
                            @input="$v.pilotOnBoard.$touch()" 
                            id="pilotOnBoardInput"/>
            </v-flex>
            <v-flex xs3 v-if="isVesselInfoDirty">
              <v-btn @click="onSave"
                     :loading="isLoading"
                     :disabled="isLoading || isDisabled || !isVesselInfoValid"
                     id="saveButton">Save</v-btn>
            </v-flex>
          </v-layout>

          <v-flex xs12 pb-3>
            <h2>Certificate of Qualities</h2>
          </v-flex>
          
          <v-layout row wrap justify-end>
            <v-flex xs12 pt-3>
              <v-data-table
                  :headers="headers"
                  :items="sortedLineSampleShipCoqList"
                  :items-per-page="10"
                  class="vessel-info-table no-sort-datatable elevation-3"
                  :totalItems="shipCoqListTotal"
                  color="orange lighten-1"
                  :loading="isLoading"
                  :no-data-text="isLoading ? 'loading...' : ''"
                  loading-text="Loading... Please wait">
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ item.coqNumber }}</td>
                      <td>{{ item.product | uppercase }}</td>
                      <td :class="!isAppearanceValid(item) ? 'cell-warning' : ''">
                        {{ item.appearance | toAppearance }}
                      </td>
                      <td :class="!isDensityValid(item) ? 'cell-warning' : ''">
                        {{ item.density | toDecimalPlaces(densityPrecision) }}
                      </td>
                      <td :class="!isFlashPointValid(item) ? 'cell-warning' : ''">
                        {{ (showFlashPoint(item.product) ? item.flashPoint : '') | toDecimalPlaces(flashpointPrecision) }}
                      </td>
                      <td :class="!isConductivityValid(item) ? 'cell-warning' : ''">
                        {{ showConductivity(item.product) ? item.conductivity : '' }}
                      </td>
                      <td>{{ getPercentageOfCargo(item) | toPercentage }}</td>
                      <td>{{ item.initials }}</td>
                      <td>{{ item.createdBy === item.updatedBy ? item.createdBy : item.updatedBy }}</td>
                      <td>
                        <v-icon 
                          small
                          class="d-inline mr-2"
                          @click="editDetails(item, index)">
                          edit
                        </v-icon>
                        <v-icon
                          small
                          class="d-inline deleteButton"
                          @click="deleteDetails(item)">
                          delete
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex xs4 py-3>
              <flat-button @click="onAddNewCoq"
                           class="no-print"
                           id="addCoQButton">
                Add CoQ
              </flat-button>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { 
  DISCHARGE_PLAN 
} from '@/store/tankerDischarge/preArrival/dischargePlan';
import { SHIP_COQ } from '@/store/tankerDischarge/preArrival/shipCoq';
import { required} from 'vuelidate/lib/validators';
import { maskedDatetime } from '@/validators';
import { formatDateTimeToMasked, isValidMaskedDateTime } from '@/helpers/date';
import { cloneDeep } from '@/helpers/object';
import {
  DISABLED_STATUSES
} from '@/constants/receiptPlan';
import {
  PRODUCT_ITEMS
} from '@/constants/products';
import { UI } from '@/store/ui';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";
import { percentageOfCargo } from '@/helpers/coqCalculations';
import {
  validateFlashPointValue,
  validateDensityValue,
  validateConductivity,
  validateAppearance
} from '@/helpers/validation/sharedThresholds';

function defaultVesselInfo() {
  return {
    pilotOnBoard: new Date(),
    vessel: '',
    voyage: ''
  };
 }

export default {
  async created() {
    this.isLoading = true;
    await this.$store.dispatch(
      DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
      { id: this.planId }
    );

    this.pilotOnBoardString = formatDateTimeToMasked(this.dischargePlan.pilotOnBoard, DATE_TIME_FORMAT);

    this.loadShipCoqList();

    this.isLoading = false;
  },
  data() {
    return {
      isDirty: false,
      isLoading: false,
      showDialog: false,
      vesselInfo: defaultVesselInfo(),
      shipCoQToEdit: null,
      productItems: PRODUCT_ITEMS,
      pilotOnBoardString: '',
      densityPrecision: 4,
      flashpointPrecision: 1,
      headers: [
        { text: 'COQ Number', value: 'coqNumber' },
        { text: 'Product', value: 'product' },
        { text: 'Appearance', value: 'appearance', width: 240 },
        { text: 'Density', value: 'density' },
        { text: 'Flash Point', value: 'flashPoint' },
        { text: 'Conductivity', value: 'conductivity' },
        { text: 'Percentage of Cargo', value: 'weight' },
        { text: 'Initials', value: 'initials' },
        { text: 'Updated By', value: 'updatedBy' },
        { text: 'Actions', value: 'actions', width: 240 }
      ],
      slots: [
        { type: 'appearance', field: 'appearance' },
        { type: 'density', field: 'density' },
        { type: 'flashpoint', field: 'flashPoint' },
        { type: 'conductivity', field: 'conductivity' },
        { type: 'cargo', field: 'weight' },
        { type: 'author', field: 'updatedBy' }
      ],
      pagination: {
        rowsPerPage: 10
      },
      showDeleteConfirmation: false,
      deleteShipCoQId: -1
    };
  },
  methods: {
    async onSave() {
      this.loading = true;
      const dischargePlan = this.dischargePlan;

      const success = await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.UPDATE_DISCHARGE_PLAN),
        { id: dischargePlan.id, dischargePlan }
      );

      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-discharge-plan',
            text: 'Successfully updated receipt plan'
          },
          { root: true }
        );
      }
      this.loading = false;
    },
    getPercentageOfCargo(item) {
      return percentageOfCargo(this.shipCoqList, item);
    },
    async loadShipCoqList() {
       this.$store.commit(
        SHIP_COQ.NS(SHIP_COQ.MUTATIONS.RESET_SHIP_COQ_LIST)
      );
      this.isLoading = true;

      await this.$store.dispatch(
        SHIP_COQ.NS(SHIP_COQ.ACTIONS.GET_SHIP_COQ_LIST), {
          planId: this.planId
        }
      );
    },
    onAddNewCoq() {
      this.shipCoQToEdit = null;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    editDetails(coq) {
      this.shipCoQToEdit = cloneDeep(coq);
      this.showDialog = true;
    },
    deleteDetails(coq) {
      this.showDeleteConfirmation = true;
      this.deleteShipCoQId = coq.id;
    },
    async onDeleteConfirmation() {
      this.showDeleteConfirmation = false;
      const success = await this.$store.dispatch(
        SHIP_COQ.NS(SHIP_COQ.ACTIONS.DELETE_SHIP_COQ),
        {
          planId: this.planId,
          shipCoQId: this.deleteShipCoQId
        }
      );

      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-delete-ship-coq',
            text: 'Successfully deleted Ship CoQ.'
          },
          { root: true }
        );
      }

      this.deleteShipCoQId = -1;
    },
    onDeleteConfirmationLeave() {
      this.showDeleteConfirmation = false;
      this.deleteShipCoQId = -1;
    },
    resetAppearance() {
      this.shipCoQ.appearance = '';
    },
    isAppearanceValid(item) {
      return validateAppearance(item.product, item.appearance);
    },
    isConductivityValid(item) {
      if (this.showConductivity(item.product)) {
        return validateConductivity(item.product, item.conductivity);
      }
      return true;
    },
    isDensityValid(item){
      return validateDensityValue(item.product, item.density, null, false);
    },
    isFlashPointValid(item){
      if (this.showFlashPoint(item.product)) {
        return validateFlashPointValue(item.product, item.flashPoint, null, false, false);
      }
      return true;     
    }
  },
  computed: {
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    planStatus() {
      return this.dischargePlan && this.dischargePlan.planStatus;
    },
    hasLoaded() {
      return Boolean(this.dischargePlan && this.dischargePlan.id);
    },
    isDisabled() {
      return DISABLED_STATUSES.includes(this.planStatus);
    },
    isVesselInfoValid() {
      return !this.$v.vessel.$invalid && !this.$v.voyage.$invalid &&
        !this.$v.pilotOnBoard.$invalid;
    },
    isVesselInfoDirty() {
      return this.$v.vessel.$anyDirty || this.$v.voyage.$anyDirty ||
        this.$v.pilotOnBoard.$anyDirty;
    },
    shipCoqList() {
      return this.$store.state.shipCoq && this.$store.state.shipCoq.shipCoQList;
    },
    shipCoqListTotal() {
      return this.shipCoqList.length;
    },
    sortedLineSampleShipCoqList() {
      // defaulting to latest first
      return [...this.shipCoqList].sort((a, b) => {
        return a.product < b.product ? -1 : 1
      });
    },
    vessel: {
      get() {
        return this.dischargePlan && this.dischargePlan.vessel;
      },
      set(value) {
        this.$store.commit(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_VESSEL),
          value
        );
        this.isDirty = true;
      }
    },
    voyage: {
      get() {
        return this.dischargePlan && this.dischargePlan.voyage;
      },
      set(value) {
        this.$store.commit(
          DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_VOYAGE),
          value
        );
        this.isDirty = true;
      }
    },
    pilotOnBoard: {
      get() {
        return this.pilotOnBoardString;
      },
      set(value) {
        this.pilotOnBoardString = value;

        if (isValidMaskedDateTime(value)) {
          this.$store.commit(
            DISCHARGE_PLAN.NS(DISCHARGE_PLAN.MUTATIONS.SET_PILOT_ON_BOARD),
            value
          );
        }
        this.isDirty = true;
      }
    },
    pilotOnBoardErrors() {
      let errors = [];

      if (!this.$v.pilotOnBoard.$dirty || this.$v.pilotOnBoard.$pending) {
        return [];
      }

      if (!this.$v.pilotOnBoard.required || !this.$v.pilotOnBoard.maskedDatetime) {
        errors = [...errors, 'A valid date is required (yyyy-MM-dd HH:mm)'];
      }

      return errors;
    },
    ...mapState({
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
      shipCoQList: state => state.shipCoQ && state.shipCoQ.shipCoQList
    })
  },
  validations: {
    vessel: {
      required
    },
    voyage: {
      required
    },
    pilotOnBoard: {
      required,
      maskedDatetime
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/_responsive.scss';
  @import '../../../assets/styles/_variables.scss';

:deep(.vessel-info-table) td,
:deep(.vessel-info-table) th {
  text-align: center !important;
  padding: 0 12px !important;
}

.upper-case {
  text-transform: uppercase;
}

:deep(.v-select) {
  margin-bottom: 6px !important;
}

:deep(.v-select) .v-messages {
  font-size: 12px !important;
}

</style>