<template>
  <v-container fluid fill-height>
    <v-dialog class="no-print" max-width="300" v-model="showDeleteConfirmation">
      <v-card>
        <v-card-text>
          <v-layout>
            <v-flex xs12 mt-3>
              <strong class="save-title">Delete confirmation</strong>
              <div class="save-message">Are you sure you want to delete?</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange lighten-1"
            @click="onDeleteConfirmation"
            id="deletePopupYesButton"
          >Yes</v-btn>

          <v-btn color="secondary lighten-3"
            @click="onDeleteConfirmationLeave"
            id="deletePopupNoButton"
          >No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog max-width="500" v-model="showDialog">
      <v-card>
        <v-card-title class="headline">{{monitoringDetails.id ? 'Edit' : 'Add'}} monitoring details</v-card-title>

        <v-card-text>
          <v-layout>
            <v-flex xs6 pr-4>
              <v-text-field label="Monitored At"
                v-model="monitoringDetails.monitoredAt"
                v-mask="'####-##-## ##:##'"
                placeholder="yyyy-MM-dd HH:mm"
                :error-messages="monitoredAtErrors"
                @input="$v.monitoringDetails.monitoredAt.$touch()" 
                ref="monitorAt" color="orange lighten-1" id="monitoredAtInput"/>
            </v-flex>
            <v-flex xs6>
              <v-currency-field
                v-model="monitoringDetails.tankGaugeReadingMillimetres"
                label="Tank/Gauge reading (mm)"
                :error-messages="tankGaugeReadingMillimetresErrors"
                @input="$v.monitoringDetails.tankGaugeReadingMillimetres.$touch()"
                :allowNegative="allowNegative" color="orange lighten-1"
                ref="tankGaugeReadingMillimetres" id="tankGaugeReadingInput"/>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-textarea outlined color="orange lighten-1"
                v-model="monitoringDetails.comments" ref="comments"
                label="Comments" id="commentsInput"/>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary lighten-3"
            @click="onCancelDialog" tabindex="4"
            :disabled="isLoading"
            :loading="isLoading"
            id="popupCancelButton"
          >Cancel</v-btn>

          <v-btn color="orange lighten-1"
            @click="onSave" tabindex="5"
            :disabled="isLoading || saveIsDisabled"
            :loading="isLoading"
            id="popupSaveButton"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout row wrap justify-end>
      <v-flex xs12 class="stay-put-sticky-position">
        <v-layout>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell 
              :singleValue="dischargePlan.vessel" label="Vessel" id="vesselTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell 
              :singleValue="tankPlan.product | uppercase" 
              label="Product"
              id="productTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              :singleValue="tankPlan.plannedReceipt | commaSeperateNumber "
              label="Planned volume"
              singleValueSuffix="L"
              id="plannedVolumeTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              label="Normal/Safe Fill Level"
              :millimetres="tankPlan.operationalLevelMillimetres | commaSeperateNumber"
              :litres="tankPlan.operationalLevelLitres | commaSeperateNumber"
              id="normalSafeFillLevelTextFeild"
              :safeFillError="isOverSafeFillLevel"
              orangeHighlighting
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              :singleValue="maxFlowRateLitresPerHour | commaSeperateNumber "
              label="Maximum filling rate"
              singleValueSuffix="L/h"
              id="maximumFillingRateTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              :singleValue="tankPlan.pipelineAdjustments | commaSeperateNumber "
              label="Pipeline Adjustments"
              singleValueSuffix="L"
              id="pipelineAdjustmentsTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              :singleValue="tankPlan.tankAdjustments | commaSeperateNumber "
              label="Tank Adjustments"
              singleValueSuffix= "L"
              id="tankAdjustmentsTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              label="Planned Starting Dip"
              :millimetres="tankPlan.openingBalance"
              :litres="openingBalanceLitres"
              id="plannedStartingDipTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              label="Planned Finish Level"
              :millimetres="plannedEstimatedFinalDip"
              :litres="plannedEstimatedFinalVolume"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              label="Actual Starting Dip"
              :millimetres="actualStartingDip.dip"
              :litres="actualStartingDip.liters"
              id="actualStartingDipTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              label="Actual Estimated Finish Level"
              :millimetres="estimatedFinalDip"
              :litres="estimatedFinalVolume"
              id="actualEstimatedFinishLevelTextFeild"
              :safeFillError="isOverSafeFillLevel"
              orangeHighlighting
            />
          </v-flex>
          <v-flex xs2 px-2>
            <tanker-discharge-receipt-plan-cell
              label="Post Discharge Dip"
              :millimetres="postDischargeDip.dip"
              :litres="postDischargeDip.liters"
              id="postDischargeDipTextFeild"
            />
          </v-flex>
          <v-flex xs2 px-1 pt-2>
            <v-currency-field
              :value="tankPlan.productAdjustments"
              label="Product Adjustments"
              suffix="L" color="orange lighten-1"
              @input="updateAdjustments($event, 'productAdjustments')"
              :error-messages="updateAdjustmentsErrors('productAdjustments')"
              id="productAdjustemntsInput"
            />
          </v-flex>
          <v-flex xs2 px-1 pt-2>
            <v-text-field color="orange lighten-1"
              :value="tankPlan.adjustmentComments"
              label="Adjustment Comments"
              id="adjustmentCommentsInput"
              @input="updateAdjustments($event, 'adjustmentComments')"
            />
          </v-flex>
          <v-flex xs2 px-1 pt-2>
            <v-btn color="orange lighten-1"
              :disabled="isLoading || !(this.$v.tankPlan.$dirty && !this.$v.tankPlan.$invalid)"
              :loading="isLoading"
              @click="savePreAndPostDischargeReadings"
              id="saveButton"
            >Save</v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 pa-3>
        <v-card>
          <v-toolbar flat  mb-3 height="60" class="stay-put-table-sticky-position">
            <v-card-title>Monitoring Items</v-card-title>
            <v-divider class="mx-4" inset vertical />
            <v-spacer />
            <v-flex xs3 class="no-print">
                <div style="font-size: 18px;" :class="[ nearingStopLevelWarning ? 'estimated-time-approaching' : '']">
                    Estimated Finish Time: {{estimatedFinishTime | formatDateTime('yyyy-MM-dd HH:mm')}}
                </div>
            </v-flex>
            <v-divider class="mx-4" inset vertical />
            <v-flex xs3 px-3 class="no-print">
              <div style="font-size: 18px;" id="totalVolumeReceived">
                Total Volume Received: <strong>{{ totalVolumeReceived | commaSeperateNumber }} L</strong>
              </div>
            </v-flex>
            <v-flex xs2 mb-2>
              <flat-button @click="onAddNew" id="addNewDetailsButton">Add new details</flat-button>
            </v-flex>
          </v-toolbar>
          <v-data-table
            dense
            height="500"
            :headers="headers"
            :items="expandedMonitorDetails"
            fixed-header 
            hide-default-footer
            disable-pagination
            class="monitoring-details-table no-sort-datatable"
            color="orange lighten-1"
            :loading="isLoading"
            :no-data-text="isLoading ? 'loading...' : ''"
            loading-text="Loading... Please wait">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      {{ item.monitoredAt | formatDateTime('yyyy-MM-dd HH:mm') }}
                    </td>
                    <td :class="item.tankGaugeReadingMillimetres > tankPlan.operationalLevelMillimetres ? 'cell-error' : ''">
                      {{ item.tankGaugeReadingMillimetres | commaSeperateNumber }}
                    </td>
                    <td>
                      {{ item.tankGaugeReadingKiloLitres | commaSeperateNumber }}
                    </td>
                    <td>
                      <div v-if="index !== 0" :key="index" 
                            :class="item.calculatedValues.slowFlowWarning ? 'cell-error' : (item.calculatedValues.negativeFlowRate ? 'cell-warning' : '' )">
                        {{ item.calculatedValues.flowRate | commaSeperateNumber }}
                      </div>
                    </td>
                    <td>
                      <div v-if="index !== 0" :key="index" :class="item.calculatedValues.nearStopLevel ? 'cell-warning' : ''">
                        {{ item.calculatedValues.displayCannotComputeMask ? cannotComputeMask : item.calculatedValues.timeRemaining | numToTime }}
                      </div>
                    </td>
                    <v-menu v-if="index > 1" :key="index"
                        offset-y open-on-hover open-on-click max-height="80" max-width="100">
                      <template v-slot:activator="{ on, attrs }">
                        <td :key="index" v-bind="attrs" v-on="on">
                          {{ item.calculatedValues.volumeDeltaLitres | commaSeperateNumber }}
                          <v-icon small right>info</v-icon>
                        </td>
                      </template>
                      <v-card :class="'comment-box'" elevation="2" hover outlined rounded>
                        <v-card-subtitle>
                          <div class="font-weight-light font-italic">
                            {{ item.calculatedValues.lastTwoVolumeRecievedTotal | commaSeperateNumber }}
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-menu>
                    <td v-else>
                      {{ item.calculatedValues.volumeDeltaLitres | commaSeperateNumber }}
                    </td>
                    <td>
                      <div :key="index" :class="item.calculatedValues.remainingToReceive < 0 ? 'cell-warning' : ''">
                        {{ item.calculatedValues.remainingToReceive | commaSeperateNumber }}
                      </div>
                    </td>
                    <td>
                      <div :key="index" :class="item.calculatedValues.isUllageSufficient ? 'cell-error' : ''">
                      {{ item.calculatedValues.ullage | commaSeperateNumber }}
                      </div>
                    </td>
                    <v-menu v-if="item.comments !== ''" offset-x :nudge-width="100"
                            open-on-hover open-on-click max-height="100" max-width="160">
                      <template v-slot:activator="{ on, attrs }">
                        <td :key="index" v-bind="attrs" v-on="on" :class="'truncate-text'">
                            {{ item.comments }}
                        </td>
                      </template>
                      <v-card :class="'comment-box'" elevation="2" hover outlined rounded>
                        <v-card-subtitle>
                          <div class="font-weight-light font-italic">
                            {{ item.comments }}
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-menu>
                    <td v-else>
                      {{ item.comments }}
                    </td>
                    <td>
                      <v-icon 
                        small
                        class="d-inline mr-2"
                        @click="editDetails(item, index)">
                        edit
                      </v-icon>
                      <v-icon v-if="showDeleteButton(index)"
                        small
                        class="d-inline deleteButton"
                        @click="deleteDetails(item)">
                        delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody> 
              </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  DISCHARGE_PLAN
} from '@/store/tankerDischarge/preArrival/dischargePlan';
import {
  MONITORING_DETAILS,
  GET_DEFAULT_MONITOR_DETAILS
} from '@/store/tankerDischarge/discharge/monitorDetails';
import { cloneDeep } from '@/helpers/object';
import { convertMMToLitres, convertLitresToMM } from '@/helpers/discharge';
import { differenceInMinutes, parseISO, isValid } from 'date-fns';
import { minValue, required, integer } from 'vuelidate/lib/validators';
import { maskedDatetime, number } from '@/validators';
import { formatDateTimeToMasked, formatDateTimePlusHalfHour } from '@/helpers/date';
import { UI } from '@/store/ui';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";

export default {
  async created() {
    //Get tank plan so calibratons are retrieved for monitoring calculations
    if(!this.dischargePlan){
      await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
        { id: this.planId }
      );
    }
    this.loadMonitoringDetails();
  },
  mounted() {
    setTimeout(1000, this.$nextTick(() => this.$v.tankPlan.$reset()));
    this.$nextTick(() => {
      // Triggered for when the page is entered and the data is not reloaded
      this.scrollLastTableElementIntoView();
    });
  },
  data() {
    return {
      isLoading: false,
      editIndex: null,
      showDialog: false,
      estimatedFinishTime: '',
      nearingStopLevelWarning: false,
      totalVolumeReceived: 0,
      showDeleteConfirmation: false,
      allowNegative: false,
      deleteMonitoringDetailsId: 0,
      cannotComputeMask: '###',
      timesRemaining: [],
      showCannotComputeMasks: [],
      dischargeMonitoringDetails: [],
      stopLevelWarningThreashold: 0.7666, // 46 mins
      monitoringDetails: GET_DEFAULT_MONITOR_DETAILS(),
      headers: [
        { text: 'Monitored at', value: 'monitoredAt', width: 160 },
        { text: 'Tank Dip (mm)', value: 'tankGaugeReadingMillimetres' },
        { text: 'Tank Volume (L)', value: 'tankGaugeReadingKiloLitres' },
        { text: 'Flow Rate (L/hr)', value: 'flowRate' },
        { text: 'Remaining Time', value: 'remainingTime' },
        { text: 'Volume Received (L)', value: 'volumeReceived' },
        { text: 'Remaining (L)', value: 'remainingToReceive' },
        { text: 'Ullage (L)', value: 'ullage' },
        { text: 'Comments', value: 'comments', width: 120 },
        { text: 'Actions', value: 'actions', width: 120}
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        descending: true
      },
      calculationsMemonize: new WeakMap()
    };
  },
  watch: {
      expandedMonitorDetails: {
        handler () {
          this.$nextTick(() => {
            this.scrollLastTableElementIntoView();
          });
        }
    }
  },
  computed: {
    saveIsDisabled() {
      return this.$v.monitoringDetails.monitoredAt.$invalid || 
              !this.$v.monitoringDetails.tankGaugeReadingMillimetres.required ||
              !this.$v.monitoringDetails.tankGaugeReadingMillimetres.minValue;
    },
    monitoredAtErrors() {
      if (
        !this.$v.monitoringDetails.monitoredAt.$dirty ||
        this.$v.monitoringDetails.monitoredAt.$pending
      ) {
        return [];
      }
      
      if (
        !this.$v.monitoringDetails.monitoredAt.required ||
        !this.$v.monitoringDetails.monitoredAt.maskedDatetime
      ) {
        return 'A valid date is required (yyyy-MM-dd HH:mm)';
      }
      if (!this.$v.monitoringDetails.monitoredAt.validDate) {
        return 'Date/time is invalid against above or below record';
      }

      return null;
    },
    tankGaugeReadingMillimetresErrors() {
      if (
        !this.$v.monitoringDetails.tankGaugeReadingMillimetres.$dirty ||
        this.$v.monitoringDetails.tankGaugeReadingMillimetres.$pending
      ) {
        return [];
      }

      if (!this.$v.monitoringDetails.tankGaugeReadingMillimetres.required) {
        return 'Value is required';
      }
      if (!this.$v.monitoringDetails.tankGaugeReadingMillimetres.minValue) {
        return 'Invalid value';
      }
      if (!this.$v.monitoringDetails.tankGaugeReadingMillimetres.normalSafeFill) {
        return 'Greater than Normal/Safe Fill Level';
      }

      return null;
    },
    openingBalanceLitres() {
      return this.convertMMToLitres(
        this.tankPlan.openingBalance,
        this.tankPlan
      );
    },
    plannedEstimatedFinalVolume() {
      return this.tankPlan.plannedReceipt + this.openingBalanceLitres +
        this.tankPlan.pipelineAdjustments + this.tankPlan.tankAdjustments;
    },
    plannedEstimatedFinalDip() {
      return this.convertLitresToMM(this.plannedEstimatedFinalVolume, this.tankPlan);
    },
    maxFlowRateLitresPerHour() {
      // convert max flow rate litres per minute to Litres/hour
      return Math.round(this.tankPlan.maxFlowRateLitresPerMinute * 60);
    },
    actualStartingDip() {
      if (this.monitorDetails && this.monitorDetails.length > 0) {
        return {
          dip: this.monitorDetails[0].tankGaugeReadingMillimetres,
          liters: this.monitorDetails[0].tankGaugeReadingKiloLitres
        };
      }
      return {
        dip: 0, liters: 0
      };
    },
    postDischargeDip() {
      if (this.monitorDetails && this.monitorDetails.length > 1) {
        return {
          dip: this.monitorDetails[this.monitorDetails.length - 1].tankGaugeReadingMillimetres,
          liters: this.monitorDetails[this.monitorDetails.length - 1].tankGaugeReadingKiloLitres
        };
      }
      return {
        dip: 0, liters: 0
      };
    },
    estimatedFinalDip() {
      if (this.estimatedFinalVolume) {
        return this.convertLitresToMM(this.estimatedFinalVolume, this.tankPlan);
      }

      return null;
    },
    estimatedFinalVolume() {
      if (this.monitorDetails && this.monitorDetails.length > 0) {
        return this.tankPlan.plannedReceipt + this.actualStartingDip.liters +
          this.tankPlan.pipelineAdjustments + this.tankPlan.tankAdjustments;
      }

      return null;
    },
    isOverSafeFillLevel() {
      return this.estimatedFinalVolume ? (this.estimatedFinalVolume > this.tankPlan.operationalLevelLitres || 
                this.estimatedFinalDip > this.tankPlan.operationalLevelMillimetres) : false;
    },
    dischargePlan() {
      return (
        this.$store.state.dischargePlan &&
        this.$store.state.dischargePlan.dischargePlan
      );
    },
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    receiptPlanId() {
      return this.$route.params && this.$route.params.receiptPlanId;
    },
    tankPlanId() {
      return this.$route.params && this.$route.params.tankPlanId;
    },
    receiptPlan() {
      return (
        this.dischargePlan &&
        this.dischargePlan.receiptPlans &&
        this.dischargePlan.receiptPlans[this.receiptPlanId]
      );
    },
    monitorDetails() {
      return this.dischargeMonitoringDetails;
    },
    expandedMonitorDetails() {
      if (this.monitorDetails) {
        const details = cloneDeep(this.monitorDetails);
        return details.map((item, i) => Object.assign(item, { calculatedValues: this.calculateTableCells({ item: item, index: i }) }));
      }
      
      return this.monitorDetails;
    },
    tankPlan() {
      return (
        (this.receiptPlan && this.receiptPlan.tankPlans[this.tankPlanId]) || {}
      );
    }
  },
  methods: {
    async loadMonitoringDetails(){
      this.dischargeMonitoringDetails = await this.$store.dispatch(
        MONITORING_DETAILS.NS(MONITORING_DETAILS.ACTIONS.LOAD_MONITORING_DETAILS),
        {
          planId: this.planId,
          receiptPlanId: this.receiptPlanId,
          tankPlanId: this.tankPlanId
        }
      );
    },
    showDeleteButton(index) {
      if ((this.receiptPlanId && this.tankPlanId) &&
            !(this.monitorDetails && 
              this.monitorDetails.length - 1 === index)) {
        return false;
      }
      return true;
    },
    scrollLastTableElementIntoView(){
      var lastElement = document.getElementsByClassName("deleteButton")[0];
      if(lastElement){
        lastElement.scrollIntoView()
      }
    },  
    convertMMToLitres(value, tankPlan) {
      return convertMMToLitres(value, tankPlan);
    },
    convertLitresToMM(value, tankPlan) {
      return convertLitresToMM(value, tankPlan);
    },
    formatDecimalPlaces(value) {
      return !isNaN(value) ? value.toFixed(3) : value;
    },
    formatEstimatedDateTime(date, num) {
      var hrs = Math.floor(num);
      var mins = Math.floor((num % 1) * 60);
      return date.setMinutes(date.getMinutes() + ((hrs * 60) + mins));
    },
    calculateEstimatedFinishTime(props, values, displayCannotComputeMask) {
      if (this.monitorDetails && this.monitorDetails.length > 0) {
        if (this.monitorDetails.length === 1) {
          const totalDischargeLitres = this.tankPlan.plannedReceipt + this.tankPlan.pipelineAdjustments +
            this.tankPlan.tankAdjustments;
          const dischargeTime = totalDischargeLitres / (this.tankPlan.maxFlowRateLitresPerMinute * 60);
          this.estimatedFinishTime = this.formatEstimatedDateTime(new Date(this.monitorDetails[0].monitoredAt.valueOf()), dischargeTime);
        }
        else {
          // only process the last item
          if (this.monitorDetails.length - 1 !== props.index) {
            return;
          }
          if (displayCannotComputeMask) {
              for (var i = this.monitorDetails.length - 1;i >= 1;i--) {
                  if (!this.showCannotComputeMasks[i]) {
                      this.estimatedFinishTime = this.formatEstimatedDateTime(new Date(props.item.monitoredAt.valueOf()), this.timesRemaining[i]);
                      return;
                  }
              }
          }
          else {
              this.estimatedFinishTime = this.formatEstimatedDateTime(new Date(props.item.monitoredAt.valueOf()), values.timeRemaining);
          }
        }
      } else {
        this.estimatedFinishTime = '';
      }
    },
    setDisplayCannotComputeMask(previousDip, dip) {
      if ((previousDip >= dip) || (dip - previousDip) <= 3) return true;
      return false;
    },
    calculateMinutesDelta(monitoredAt, previousMonitoredAt) {
      var dateLeft = monitoredAt && typeof monitoredAt !== 'string' && isValid(monitoredAt) ? monitoredAt : parseISO(monitoredAt);
      var dateRight = previousMonitoredAt && typeof previousMonitoredAt !== 'string' && isValid(previousMonitoredAt) ? previousMonitoredAt : parseISO(previousMonitoredAt);

      return differenceInMinutes(dateLeft, (dateLeft && dateRight) || dateLeft);
    },
    previousVolumeDeltaLitres(index, previousVolume) {
      if (index >= 2) {
        return previousVolume - 
          (this.monitorDetails[index - 2] && 
            this.monitorDetails[index - 2].tankGaugeReadingKiloLitres);
      }
      return 0;
    },
    calculateTableCells(props) {
      // to reduce the amount of calculations, caching on the props object
      const cache = this.calculationsMemonize.get(props);
      if (cache) {
        return cache;
      }

      const previousIndex = props.index - 1;
      const monitorDetails = this.monitorDetails;
      const previousMonitorDetails =
        monitorDetails[previousIndex] && monitorDetails[previousIndex];
      // get the previous monitoringDetails or use opening value
      const previousVolume =
        (previousMonitorDetails &&
          previousMonitorDetails.tankGaugeReadingKiloLitres) ||
        this.actualStartingDip.liters;

      this.totalVolumeReceived = props.item.tankGaugeReadingKiloLitres - this.monitorDetails[0].tankGaugeReadingKiloLitres;

      const volumeDeltaLitres =
        props.item.tankGaugeReadingKiloLitres - previousVolume;

      const lastTwoVolumeRecievedTotal = volumeDeltaLitres + this.previousVolumeDeltaLitres(props.index, previousVolume);

      const totalDischargeLitres = this.tankPlan.plannedReceipt + this.tankPlan.pipelineAdjustments +
        this.tankPlan.tankAdjustments;
      const remainingToReceive = totalDischargeLitres -
        (props.item.tankGaugeReadingKiloLitres - this.actualStartingDip.liters);

      const minutesDelta = this.calculateMinutesDelta(props.item.monitoredAt, (previousMonitorDetails && previousMonitorDetails.monitoredAt));
      
      const flowRate = minutesDelta ? Math.ceil((volumeDeltaLitres / minutesDelta) * 60) : null;

      const displayCannotComputeMask = props.index === 0 ? false : this.setDisplayCannotComputeMask(previousMonitorDetails.tankGaugeReadingMillimetres, props.item.tankGaugeReadingMillimetres);
      this.showCannotComputeMasks[props.index] = displayCannotComputeMask;

      const timeRemaining = flowRate ? (remainingToReceive / flowRate).toFixed(2) : 0;
      this.timesRemaining[props.index] = timeRemaining;

      // operational level is now with a title of NORMAL/SAFE FILL LEVEL
      const ullage =
        this.tankPlan.operationalLevelLitres -
        props.item.tankGaugeReadingKiloLitres;

      this.nearingStopLevelWarning = timeRemaining < this.stopLevelWarningThreashold && props.index > 0;
      var nearStopLevel = this.nearingStopLevelWarning

      // check if the mm is lower than the slow pump level mm, if so use slowpumplevel maxflowrate
      // all the calculations here should be done using minutes
      // exclude also the first entry in the checking as it is assumed to use the max flow rate
      const flowRatePerMin = flowRate / 60;
      const slowFlowWarning = props.index === 0 ? false : (props.item.tankGaugeReadingMillimetres <= this.tankPlan.slowPumpLevelMillimetres ?
        flowRatePerMin >= this.tankPlan.maxSlowPumpingFlowRateLitresPerMinute : flowRatePerMin >= this.tankPlan.maxFlowRateLitresPerMinute);

      const negativeFlowRate = flowRate < 0;

      const isOverNormalFillLevel = props.index === 0 ? false : props.item.tankGaugeReadingMillimetres > this.tankPlan.operationalLevelMillimetres;
      const isUllageSufficient = props.index === (this.monitorDetails.length - 1) ? (ullage < 0 || ullage < remainingToReceive) : false;

      const values = {
        volumeDeltaLitres,
        minutesDelta,
        remainingToReceive,
        ullage,
        isUllageSufficient,
        flowRate,
        nearStopLevel,
        timeRemaining: timeRemaining > 0 ? timeRemaining : 0,
        slowFlowWarning,
        negativeFlowRate,
        isOverNormalFillLevel,
        displayCannotComputeMask,
        lastTwoVolumeRecievedTotal
      };

      this.calculationsMemonize.set(props, values);
      this.calculateEstimatedFinishTime(props, values, displayCannotComputeMask);
      return values;
    },
    editDetails(monitorDetails, index) {
      this.monitoringDetails = cloneDeep({
        ...monitorDetails
      });
      this.monitoringDetails.monitoredAt = formatDateTimeToMasked(this.monitoringDetails.monitoredAt, DATE_TIME_FORMAT);
      this.editIndex = index;
      this.showDialog = true;
      this.$v.$reset();
      this.$nextTick(() => this.$refs.monitorAt.focus());
    },
    deleteDetails(monitorDetails) {
      this.showDeleteConfirmation = true;
      this.deleteMonitoringDetailsId = monitorDetails.id;
    },
    async onDeleteConfirmation() {
      const success = await this.$store.dispatch(
        MONITORING_DETAILS.NS(MONITORING_DETAILS.ACTIONS.DELETE_MONITOR_DETAILS),
        {
          planId: this.planId,
          receiptPlanId: this.receiptPlanId,
          tankPlanId: this.tankPlanId,
          monitorDetailsId: this.deleteMonitoringDetailsId
        }
      );

      if (success) {
        await this.loadMonitoringDetails();
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-delete-monitor-detail',
            text: 'Successfully deleted monitor detail.'
          },
          { root: true }
        );
      }

      this.deleteMonitoringDetailsId = 0;
      this.showDeleteConfirmation = false;
    },
    onDeleteConfirmationLeave() {
      this.showDeleteConfirmation = false;
    },
    setTabIndex() {
      this.$refs.monitorAt.tabIndex = 1;
      this.$refs.tankGaugeReadingMillimetres.$refs.textfield.$refs.input.tabIndex = 2;
      this.$refs.comments.tabIndex = 3;
      this.$refs.monitorAt.focus()
    },
    onAddNew() {
      this.resetMonitoringDetails();
      this.showDialog = true;
      this.editIndex = null;
      this.$v.monitoringDetails.monitoredAt.$touch();
      this.$v.monitoringDetails.tankGaugeReadingMillimetres.$reset();
      this.$nextTick(() => this.setTabIndex());
    },
    onCancelDialog() {
      this.resetMonitoringDetails();
      this.editIndex = null;
      this.showDialog = false;
    },
    calculateFields() {
      const monitoringDetails = this.monitoringDetails;
      monitoringDetails.tankGaugeReadingKiloLitres = this.convertMMToLitres(
        monitoringDetails.tankGaugeReadingMillimetres,
        this.tankPlan
      );
    },
    resetMonitoringDetails() {
      this.monitoringDetails = GET_DEFAULT_MONITOR_DETAILS();
      this.monitoringDetails.monitoredAt = this.monitorDetails.length >= 1 ? formatDateTimePlusHalfHour(this.monitorDetails[this.monitorDetails.length - 1].monitoredAt, DATE_TIME_FORMAT) : 
            formatDateTimeToMasked(this.monitoringDetails.monitoredAt, DATE_TIME_FORMAT);
    },
    async onSave() {
      this.isLoading = true;
      this.calculateFields();
      const monitorDetails = cloneDeep(this.monitoringDetails);
      monitorDetails.monitoredAt = new Date(monitorDetails.monitoredAt);
      let success;
      let updateAction = true;
      if (monitorDetails.id) {
        success = await this.$store.dispatch(
          MONITORING_DETAILS.NS(MONITORING_DETAILS.ACTIONS.UPDATE_MONITOR_DETAILS),
          {
            planId: this.planId,
            receiptPlanId: this.receiptPlanId,
            tankPlanId: this.tankPlanId,
            monitorDetailsId: monitorDetails.id,
            monitorDetails
          }
        );
      } else {
        updateAction = false;
        success = await this.$store.dispatch(
          MONITORING_DETAILS.NS(MONITORING_DETAILS.ACTIONS.CREATE_MONITOR_DETAILS),
          {
            planId: this.planId,
            receiptPlanId: this.receiptPlanId,
            tankPlanId: this.tankPlanId,
            monitorDetails
          }
        );
      }

      if (success) {
        this.resetMonitoringDetails();
        this.showDialog = false;
        
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-monitor-detail',
            text: 'Successfully ' + (updateAction ? 'updated' : 'added') + ' monitor detail.'
          },
          { root: true }
        );
        this.isLoading = true;
        await this.loadMonitoringDetails();
      }
      this.isLoading = false;
    },
    async savePreAndPostDischargeReadings() {
      this.isLoading = true;
      const success = await this.$store.dispatch(
        DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.UPDATE_DISCHARGE_PLAN),
        { id: this.planId, dischargePlan: this.dischargePlan }
      );
      this.isLoading = false;
      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-update-discharge-plan',
            text: 'Successfully updated receipt plan'
          },
          { root: true }
        );
      }
    },
    updateAdjustments(value, field) {
      if (!this.tankPlan || Object.keys(this.tankPlan).length === 0) {
        return;
      }
      switch (field) {
        case 'productAdjustments':
          this.$store.commit(
            DISCHARGE_PLAN.NS(
              DISCHARGE_PLAN.MUTATIONS.SET_RECEIPT_PLAN_TANK_PRODUCT_ADJUSTMENTS
            ),
            { value, receiptPlanId: this.receiptPlanId, tankPlanId: this.tankPlanId }
          );
          break;
        case 'adjustmentComments':
          this.$store.commit(
            DISCHARGE_PLAN.NS(
              DISCHARGE_PLAN.MUTATIONS.SET_RECEIPT_PLAN_TANK_ADJUSTMENT_COMMENTS
            ),
            { value, receiptPlanId: this.receiptPlanId, tankPlanId: this.tankPlanId }
          );
          break;
        default:
      }
      this.$v.tankPlan.$touch();
    },
    updateAdjustmentsErrors(field) {
      const model = this.$v.tankPlan[field];
      if (!model) {
        return;
      }
      if (model.$invalid) {
        return 'Invalid value';
      }
      if (field === 'tankAdjustments' && model.$model > 0) {
        return "Invalid value";
      }
      return [];
    },
    checkValidDate(value) {
      const valueDate = new Date(value);
      if (this.monitorDetails && this.monitorDetails.length > 0) {
        if (this.editIndex === null) {
          return valueDate > new Date(formatDateTimeToMasked(this.monitorDetails[this.monitorDetails.length - 1].monitoredAt, DATE_TIME_FORMAT));
        }

        if (this.editIndex === 0) { // first entry
          if (this.monitorDetails.length > 1) {
            return valueDate < new Date(formatDateTimeToMasked(this.monitorDetails[1].monitoredAt, DATE_TIME_FORMAT));
          }
        } else if (this.editIndex === this.monitorDetails.length - 1) { // last entry
          return valueDate > new Date(formatDateTimeToMasked(this.monitorDetails[this.monitorDetails.length - 2].monitoredAt, DATE_TIME_FORMAT));
        } else {
          // middle entry
          return valueDate > new Date(formatDateTimeToMasked(this.monitorDetails[this.editIndex - 1].monitoredAt, DATE_TIME_FORMAT)) &&
            valueDate < new Date(formatDateTimeToMasked(this.monitorDetails[this.editIndex + 1].monitoredAt, DATE_TIME_FORMAT));
        }
      }
      return true;
    }
  },
  validations: {
    tankPlan: {
      preDischargeLitres: {
        number
      },
      postDischargeLitres: {
        number
      },
      preDischargeMillimetres: {
        number
      },
      postDischargeMillimetres: {
        number
      },
      productAdjustments: {
        integer,
        isValid: function(value) {
          return !(value === '-');
        }
      }
    },
    monitoringDetails: {
      monitoredAt: {
        required,
        maskedDatetime,
        validDate: function (value) {
          return this.checkValidDate(value);
        }
      },
      tankGaugeReadingMillimetres: {
        required,
        minValue: minValue(0),
        normalSafeFill: function(value) {
          return !(value > this.tankPlan.operationalLevelMillimetres);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

:deep(.v-tabs__container) {
  background-color: #eee !important;
}
:deep(.tank-plan-tab) .v-tabs__item--active {
  font-weight: bold;
  border-bottom: 2px solid;
  text-decoration: none;
}
:deep(.tank-plan-sub-tabs) .v-tabs__container {
  background-color: #f6f6f6 !important;
}
:deep(.tank-plan-sub-tab) {
  background-color: #f6f6f6;
  font-weight: bold;
}
:deep(.tank-plan-sub-tab) .v-tabs__item--active {
  border-bottom: 2px solid;
  text-decoration: none;
}

// always show input labels in "active" position and display full label
:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}
// remove padding from box class inputs
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details,
:deep(.v-text-field.v-text-field--enclosed)
  > .v-input__control
  > .v-input__slot {
  padding: 0 3px !important;
}

:deep(.monitoring-details-table) td {
  text-align: right;
  padding: 0 12px !important;
}
:deep(.monitoring-details-table) th {
  text-align: right !important;
  padding: 0 12px !important;
}
:deep(.monitoring-details-table) th:nth-child(1),
:deep(.monitoring-details-table) th:nth-last-child(1) {
  text-align: center !important;
}

.estimated-time-approaching {
  color: red;
}

.save-title {
  font-size: 120%;
}
.save-message {
  margin: 10px 0;
  font-size: 140%;
}

.truncate-text {
  max-width: 1px;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-box {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 2px;
}

.stay-put-sticky-position {
  position: sticky;
  padding-top: 6px;
  z-index: 8;
  top: 160px;
  background: white;
}

.stay-put-table-sticky-position {
  position: sticky;
  z-index: 8;
  top: 340px;
  background: white;
}

</style>