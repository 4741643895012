import { cloneDeep } from '@/helpers/object';

export const SHIP_COQ_MUTATIONS = {
  SET_SHIP_COQ_LIST: 'setShipCoQList',
  ADD_SHIP_COQ_LIST: 'addShipCoQList',
  RESET_SHIP_COQ_LIST: 'resetShipCoQList',
  EDIT_SHIP_COQ_LIST: 'editShipCoQList',
  DELETE_SHIP_COQ_LIST: 'deleteShipCoQList'
}

export const mutations = {
  [SHIP_COQ_MUTATIONS.SET_SHIP_COQ_LIST](state, shipCoQList) {
    state.shipCoQList = cloneDeep(shipCoQList);
  },
  [SHIP_COQ_MUTATIONS.RESET_SHIP_COQ_LIST](state) {
    state.shipCoQList = [];
  },
  [SHIP_COQ_MUTATIONS.ADD_SHIP_COQ_LIST](state, shipCoQ) {
    state.shipCoQList.push(shipCoQ);
  },
  [SHIP_COQ_MUTATIONS.EDIT_SHIP_COQ_LIST](state, shipCoQ) {
    const index = state.shipCoQList.findIndex(d => d.id === shipCoQ.id);
    const currentShipCoQ = shipCoQ;
    state.shipCoQList.splice(index, 1, currentShipCoQ);
  },
  [SHIP_COQ_MUTATIONS.DELETE_SHIP_COQ_LIST](state, shipCoQId) {
    const index = state.shipCoQList.findIndex(d => d.id === shipCoQId);
    state.shipCoQList.splice(index, 1);
  }
}