export const lineFlashpointRangeThresholds = {
    ago: 2.0,
    jetA1: 1.0,
    lfo: 2.0
}

export const flashpointRangeThresholds = {
    ago: 3.0,
    jetA1: 2.0,
    lfo: 3.0
}

export const flashpointThresholds = {
    agoLow: 63.0,
    agoHigh: 500.0,
    jetA1Low: 38.0,
    jetA1High: 500.0,
    lfoLow: 63.0,
    lfoHigh: 500.0,
}

export const lineDensityRangeThresholds = {
    ago: 0.002,
    jetA1: 0.002,
    lfo: 0.005,
    rms: 0.002,
    pms: 0.002
}

export const densityRangeThresholds = {
    ago: 0.003,
    jetA1: 0.003,
    lfo: 0.005,
    rms: 0.003,
    pms: 0.003
}

export const densityThresholds = {
    agoLow: 0.8200,
    agoHigh: 0.8500,
    jetA1Low: 0.7750,
    jetA1High: 0.8400,
    lfo: 0.9740,
    pmsLow: 0.7,
    pmsHigh: 0.9,
    rmsLow: 0.7,
    rmsHigh: 0.9
}

export const conductivityThresholds = {
    agoQrLow: 50,
    agoLow: 75,
    agoHigh: 600,
    jetA1Low: 100,
    jetA1High: 600
}

export const sulphurThresholds = {
    ago: 10.00,
    pms: 10.00,
    rms: 10.00,
    lfo: 20000.00,
    jetA1: 3000.00
}

export const viscosityThresholds = {
    agoLow: 2.000,
    agoHigh: 4.500,
    lfoLow: 0.000,
    lfoHigh: 40.000
}

export const cloudPointThresholds = {
    agoLow: -99.99,
    agoHigh: 99.99
}

export const coldFilterPluggingPointThresholds = {
    agoLow: -99.99,
    agoHigh: 99.99
}

export const pourPointThresholds = {
    agoLow: -99.99,
    agoHigh: 99.99
}

export const appearanceThresholds = {
    agoPass: ['AGO_Haze1'],
    jetA1Pass: ['JETA1_ClearAndBright'],
    lfoPass: ['LFO_Black'],
    rmsPass: ['RMS_ClearAndBright'],
    pmsPass: ['PMS_ClearAndBright']
}

export const colourThresholds = {
    agoPass: ['Colourless', 'PaleStraw', 'Straw', 'Yellow'],
    rmsPass: ['Red', 'Orange', 'Pink'],
    pmsPass: ['Colourless', 'PaleStraw', 'Straw', 'Yellow']
};

export default [
    lineFlashpointRangeThresholds,
    flashpointRangeThresholds,
    flashpointThresholds,
    lineDensityRangeThresholds,
    densityRangeThresholds,
    densityThresholds,
    conductivityThresholds,
    appearanceThresholds,
    colourThresholds
];