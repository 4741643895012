import Vue from 'vue';

export const TERMINAL_INFORMATION_MUTATIONS = {
    SET_TERMINAL_INFORMATION_BY_TERMINAL: 'setTerminalInformationByTerminal'
}

export const mutations = {
    [TERMINAL_INFORMATION_MUTATIONS.SET_TERMINAL_INFORMATION_BY_TERMINAL](state, tanks) {
        Vue.set(state, 'tanksList', tanks);
    }
};