<template>
  <v-card flat tile class="d-flex ma-1 mb-3" height="100%">
    <v-card flat class="flex-row pa-1 cell-container" :class="{ 'cell-error': !!error }" width="100%">
        <div class="cell-label">{{ label }}</div>
  
        <div v-if="!isSingleValue" class="d-flex">
          <div :id="idPrefix ? idPrefix + '_Mm_Div' : undefined" class="cell-column">{{ millimetres ? `${millimetres} mm` : '&nbsp;' | commaSeperateNumber }}</div>
          <div :id="idPrefix ? idPrefix + '_L_Div' : undefined" class="cell-column align-right">{{ litres ? `${litres} L` : '&nbsp;' | commaSeperateNumber }}</div>
        </div>
        <template v-else>
          <div :id="idPrefix ? idPrefix + '_Div' : undefined" class="cell-column">{{ singleValue }}</div>
        </template>
      <v-card flat tile class="cell-error-message">{{ error }}</v-card>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    litres: Number | String,
    millimetres: Number | String,
    singleValue: {
      type: Number | String,
      default: null
    },
    label: String,
    error: String,
    idPrefix: {
      type: String,
      default: null
    }
  },
  computed: {
    isSingleValue() {
      return this.singleValue != null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';

.cell-container {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #888;
  min-height: 100%;
}
.cell-error {
  border-bottom: 1px solid $error-color !important;
}
.cell-label {
  width: 100%;
  font-size: 12px !important;
  margin-bottom: 10px;
  font-weight: bold;
}
.cell-error .cell-label {
  color: $error-color !important;
}
.cell-column {
  font-size: 14px;
  width: 50%;
  min-height: 20px;
  white-space: nowrap;
}
.align-right {
  text-align: right;
}
</style>

