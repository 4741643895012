export const LINE_SAMPLE_MUTATIONS = {
  SET_LINE_SAMPLE_LIST: 'setLineSampleList',
  ADD_LINE_SAMPLE_LIST: 'addLineSampleList',
  RESET_LINE_SAMPLE_LIST: 'resetLineSampleList',
  EDIT_LINE_SAMPLE_LIST: 'editLineSampleList',
  DELETE_LINE_SAMPLE_LIST: 'deleteLineSampleList',
};

export const mutations = {
  [LINE_SAMPLE_MUTATIONS.SET_LINE_SAMPLE_LIST](state, lineSampleList) {
    state.lineSampleList = lineSampleList;
  },
  [LINE_SAMPLE_MUTATIONS.RESET_LINE_SAMPLE_LIST](state) {
    state.lineSampleList = [];
  },
  [LINE_SAMPLE_MUTATIONS.ADD_LINE_SAMPLE_LIST](state, lineSample) {
    state.lineSampleList.push(lineSample);
  },
  [LINE_SAMPLE_MUTATIONS.EDIT_LINE_SAMPLE_LIST](state, lineSample) {
    const index = state.lineSampleList.findIndex(d => d.id === lineSample.id);
    const currentLineSample = lineSample;
    state.lineSampleList.splice(index, 1, currentLineSample);
  },
  [LINE_SAMPLE_MUTATIONS.DELETE_LINE_SAMPLE_LIST](state, lineSampleId) {
    const index = state.lineSampleList.findIndex(d => d.id === lineSampleId);
    state.lineSampleList.splice(index, 1);
  }
};
