var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"530"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('div',{staticClass:"dialog-content-container"},[_c('v-card-text',[(_vm.tank)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-flex',{staticClass:"elevation-2",style:({
                  border: ("1px solid " + (_vm.mapProductToColor(
                    _vm.tank.product,
                    true,
                    true
                  ))),
                  borderRadius: '1px'})},[_c('h3',{style:({
                color: _vm.mapProductToColor(_vm.tank.product),
                backgroundColor: _vm.mapProductToColor(_vm.tank.product, true),
                textAlign: 'center',
                textTransform: 'uppercase'
              })},[_vm._v(_vm._s(_vm.tank.product))]),_c('h3',{style:({
                color: _vm.mapProductToColor(_vm.tank.product),
                backgroundColor: _vm.mapProductToColor(_vm.tank.product, true),
                textAlign: 'center'          
              })},[_vm._v(_vm._s(_vm.tank.name))])]),_c('v-flex',[_c('data-table',{staticClass:"tank-calibration-table no-sort-datatable",attrs:{"headers":_vm.headers,"items":_vm.tankCalibrations,"show-search":false,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('td',[_c('v-currency-field',{key:slotProps.index + 'tankCalibrationsMillimetres',attrs:{"error-messages":_vm.getMillimetresErrors(slotProps.index),"readonly":!_vm.hasEditRole},on:{"input":function($event){return _vm.recalculuateLitrePerMM(slotProps.index)}},model:{value:(slotProps.item.millimetres),callback:function ($$v) {_vm.$set(slotProps.item, "millimetres", $$v)},expression:"slotProps.item.millimetres"}})],1),_c('v-currency-field',{attrs:{"error-messages":_vm.getLitresErrors(slotProps.index),"readonly":!_vm.hasEditRole},on:{"input":function($event){return _vm.recalculuateLitrePerMM(slotProps.index)}},model:{value:(slotProps.item.litres),callback:function ($$v) {_vm.$set(slotProps.item, "litres", $$v)},expression:"slotProps.item.litres"}}),_c('td',[_c('v-currency-field',{attrs:{"readonly":"","decimalLength":4},model:{value:(slotProps.item.litersPerMM),callback:function ($$v) {_vm.$set(slotProps.item, "litersPerMM", $$v)},expression:"slotProps.item.litersPerMM"}})],1)]}}],null,false,2248023216)})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"closePopup_Button","color":"secondary lighten-3"},on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.closeButtonText))]),_c('v-btn',{attrs:{"id":"savePopup_Button","color":"orange lighten-1","disabled":_vm.disableSaveButton},on:{"click":_vm.onSave}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }