import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      primary: '#4f4f4f',
      accent: '#1e196a',
      error: '#ff2424',
      secondary: '#424242',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107'
    },
    icons: {
      iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    }
});
