export const ROUTE_NAMES = {
  HOME: 'home',
  OPERATIONSELECT: 'operation-select',
  TERMINAL: "terminal",
  /* Tanker Discharge */
  TANKER_DISCHARGE: 'tanker-discharge',

  PRE_ARRIVAL: 'pre-arrival',
  RECEIPT_PLAN_LIST: 'receipt-plan-list',
  VESSEL_INFO: 'vessel-info',
  RECEIPT_PLAN: 'receipt-plan',
  PRE_ARRIVAL_INFO: 'pre-arrival-info',
  CARGO_PLANNING: 'cargo-planning',
  PRE_ARRIVAL_CHECKLIST: 'pre-arrival-checklist',
  PRE_ARRIVAL_FORMS: 'pre-arrival-forms',
  OTHER_FORMS: 'other-forms',
  FORM_PLACEHOLDER: 'form-placeholder',
  
  ARRIVAL: 'arrival',
  ARRIVAL_LOG_OF_EVENTS: 'arrival-log-of-events',
  SHIP_SAMPLING: 'ship-sampling',
  ARRIVAL_CHECKLIST: 'arrival-checklist',

  DISCHARGE: 'discharge',
  DISCHARGE_LOG_OF_EVENTS: 'discharge-log-of-events',
  PIPELINE_SAMPLING: 'pipeline-sampling',
  PIPELINE_SAMPLING_PRODUCT: 'pipeline-sampling-product',
  DISCHARGE_TANK_MONITORING: 'discharge-tank-monitoring',
  DISCHARGE_CHECKLIST: 'discharge-checklist',
  SHIFT_HANDOVER: 'shift-handover',
  LETTERS_OF_PROTEST: 'letters-of-protest',

  POST_DISCHARGE: 'post-discharge',
  DISCHARGE_QUALITY_REPORT: 'discharge-quality-report',
  DISCHARGE_QUANTITY_REPORT: 'discharge-quantity-report',
  OUT_TURN_REPORT: 'out-turn-report',
  SHIP_PORT_PERFORMANCE: 'ship-port-performance',
  AMAS_LOGIN: 'amas-login',
  DISCHARGE_SUMMARY_REPORT: 'discharge-summary-report',
  POST_DISCHARGE_CHECKLIST: 'post-discharge-checklist',
  POST_DISCHARGE_ATTACHMENTS: 'post-discharge-attachments',

  /* Tank to Tank */
  TANK_TO_TANK: 'tank-to-tank',

  PRE_TRANSFER: 'pre-transfer',
  TANK_TO_TANK_RECEIPT_PLAN_LIST: 'tank-to-tank-receipt-plan-list',
  TRANSFER_PLAN: 'transfer-plan',
  PRE_TRANSFER_CHECKLIST: 'pre-transfer-checklist',

  TRANSFER: 'transfer',
  TRANSFER_LOG_OF_EVENTS: 'transfer-log-of-events',
  TRANSFER_TANK_MONITORING: 'transfer-tank-monitoring',
  TRANSFER_CHECKLIST: 'transfer-checklist',

  POST_TRANSFER: 'post-transfer',
  TRANSFER_QUALITY_REPORT: 'transfer-quality-report',
  TRANSFER_QUANTITY_REPORT: 'transfer-quantity-report',
  TRANSFER_SUMMARY_REPORT: 'transfer-summary-report',
  POST_TRANSFER_CHECKLIST: 'post-transfer-checklist',
  POST_TRANSFER_ATTACHMENTS: 'post-transfer-attachments',

  /* Terminal Data */
  TERMINAL_DATA: 'terminal-data',
  TERMINAL_DATA_TERMINAL_INFORMATION: 'terminal-information',
  TERMINAL_DATA_PIPELINE_INFORMATION: 'pipeline-information',
  TERMINAL_DATA_HOSE_INFORMATION: 'hose-information',
  TERMINAL_DATA_EQUIPMENT_INFORMATION: 'equipment-information',

  /* Levels of Conern */
  LEVELS_OF_CONCERN: 'levels-of-concern',
  LEVELS_OF_CONCERN_TERMINAL: 'levels-of-concern-terminal',

  /* Not found or authorised */
  ACCESS_DENIED: 'access-denied',

  /* Add jira ticket for user feedback */
  CREATE_JIRA_ISSUE: 'create-jira-issue'
};

export const DISPLAY_BREADCRUMB = {
    PREARRIVAL: 'pre-arrival',
    ARRIVAL: 'arrival',
    DISCHARGE: 'discharge',
    POSTDISCHARGE: 'post-discharge',
    ACTIVETANKS: 'active-tanks',
    ACTIVETANKTABS: 'active-tanks-tabs',
    PREARRIVALFORMS: 'pre-arrival-forms',
    LINESAMPLINGMENU: 'line-sampling-menu',
    POSTDISCHARGEFORMS: 'post-discharge-forms',
    ACTIVELINESAMPLINGPRODUCT: 'active-line-sampling-product'
};

export const routes = [
  {
    name: ROUTE_NAMES.HOME,
    path: '/',
    component: () => import(`@/views/Index.vue`),
    meta: {
      main: true,
      title: 'Home'
    }
  },
  {
    name: ROUTE_NAMES.OPERATIONSELECT,
    path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.OPERATIONSELECT}`,
    component: () => import(`./components/Menu/Index.vue`),
    meta: {
        title: 'Select Operation'
    }
  },

  /* Tanker Discharge */
  {
    name: ROUTE_NAMES.TANKER_DISCHARGE,
    path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANKER_DISCHARGE}`,
    component: () => import(`./components/TankerDischarge/Index.vue`),
    meta: {
        title: 'Tanker Receipt'
    },
    children: [
      {
        name: ROUTE_NAMES.RECEIPT_PLAN_LIST,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANKER_DISCHARGE}`,
        component: () => import(`./components/TankerDischarge/ReceiptPlanList.vue`),
        meta: {
          title: 'Tanker Receipt',
          active: true,
          display: false
        }
      },
      {
        name: ROUTE_NAMES.PRE_ARRIVAL,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANKER_DISCHARGE}/${ROUTE_NAMES.PRE_ARRIVAL}/:planId`,
        component: () => import(`./components/TankerDischarge/PreArrival/Index.vue`),
        meta: {
          title: 'Pre-Arrival',
          icon: 'alarm', 
          active: true,
          display: DISPLAY_BREADCRUMB.PREARRIVAL
        },
        children: [
          {
            name: ROUTE_NAMES.VESSEL_INFO,
            path: `${ROUTE_NAMES.VESSEL_INFO}`,
            component: () => import(`./components/TankerDischarge/PreArrival/VesselInfo.vue`),
            meta: {
              title: 'Vessel & CoQ Info',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.PREARRIVAL
            }
          },
          {
            name: ROUTE_NAMES.RECEIPT_PLAN,
            path: `${ROUTE_NAMES.RECEIPT_PLAN}`,
            component: () => import(`./components/TankerDischarge/PreArrival/ReceiptPlan.vue`),
            meta: {
              title: 'Receipt Plan',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.PREARRIVAL
            }
          },
          {
            name: ROUTE_NAMES.PRE_ARRIVAL_INFO,
            path: `${ROUTE_NAMES.PRE_ARRIVAL_INFO}`,
            component: () => import(`./components/TankerDischarge/PreArrival/PreArrivalInfo.vue`),
            meta: {
              title: 'Pre-Arrival Info',
              receiptPlan: true,
              showPreArrivalForms: true,
              active: true,
              display: DISPLAY_BREADCRUMB.PREARRIVAL
            }
          },
          {
            name: ROUTE_NAMES.CARGO_PLANNING,
            path: `${ROUTE_NAMES.CARGO_PLANNING}`,
            component: () => import(`./components/TankerDischarge/PreArrival/CargoPlanning.vue`),
            meta: {
              title: 'Cargo Planning',
              receiptPlan: true,
              showPreArrivalForms: true,
              active: true,
              display: DISPLAY_BREADCRUMB.PREARRIVAL
            }
          },
          {
            name: ROUTE_NAMES.PRE_ARRIVAL_CHECKLIST,
            path: `${ROUTE_NAMES.PRE_ARRIVAL_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.PREARRIVAL
            }
          },
          {
            name: ROUTE_NAMES.PRE_ARRIVAL_FORMS,
            path: `${ROUTE_NAMES.OTHER_FORMS}`,
            component: () => import(`./components/TankerDischarge/PreArrival/OtherForms.vue`),
            meta: {
              title: 'Other Forms',
              receiptPlan: true,
              showPreArrivalForms: true,
              active: true,
              display: DISPLAY_BREADCRUMB.PREARRIVAL
            },
            children: [
              {
                name: ROUTE_NAMES.FORM_PLACEHOLDER,
                path: `${ROUTE_NAMES.FORM_PLACEHOLDER}`,
                component: () => import(`./components/TankerDischarge/PreArrival/PlaceHolder.vue`),
                meta: {
                  title: 'Placeholder',
                  receiptPlan: true,
                  showPreArrivalForms: true,
                  active: true,
                  display: DISPLAY_BREADCRUMB.PREARRIVAL
                }
              }
            ]
          }
        ]
      },
      {
        name: ROUTE_NAMES.ARRIVAL,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANKER_DISCHARGE}/${ROUTE_NAMES.ARRIVAL}/:planId`,
        component: () => import(`./components/TankerDischarge/Arrival/Index.vue`),
        meta: {
          title: 'Arrival',
          icon: 'directions_boat', 
          active: true,
          display: DISPLAY_BREADCRUMB.ARRIVAL
        },
        children: [
          {
            name: ROUTE_NAMES.ARRIVAL_LOG_OF_EVENTS,
            path: `${ROUTE_NAMES.ARRIVAL_LOG_OF_EVENTS}`,
            component: () => import(`./components/TankerDischarge/DischargeLog.vue`),
            meta: {
              title: 'Log of Events',
              receiptPlan: true,
              active: true,
              parentGroup: DISPLAY_BREADCRUMB.ARRIVAL,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.SHIP_SAMPLING,
            path: `${ROUTE_NAMES.SHIP_SAMPLING}`,
            component: () => import(`./components/TankerDischarge/Arrival/ShipSampling.vue`),
            meta: {
              title: 'Ship Sampling',
              receiptPlan: true,
              showPreArrivalForms: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ARRIVAL
            }
          },
          {
            name: ROUTE_NAMES.ARRIVAL_CHECKLIST,
            path: `${ROUTE_NAMES.ARRIVAL_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ARRIVAL
            }
          }
        ]
      },
      {
        name: ROUTE_NAMES.DISCHARGE,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANKER_DISCHARGE}/${ROUTE_NAMES.DISCHARGE}/:planId`,
        component: () => import(`./components/TankerDischarge/Discharge/Index.vue`),
        meta: {
          title: 'Discharge',
          icon: 'outlined_flag', 
          active: true,
          display: DISPLAY_BREADCRUMB.DISCHARGE
        },
        children: [
          {
            name: ROUTE_NAMES.DISCHARGE_LOG_OF_EVENTS,
            path: `${ROUTE_NAMES.DISCHARGE_LOG_OF_EVENTS}`,
            component: () => import(`./components/TankerDischarge/DischargeLog.vue`),
            meta: {
              title: 'Log of Events',
              receiptPlan: true,
              active: true,
              parentGroup: DISPLAY_BREADCRUMB.DISCHARGE,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.PIPELINE_SAMPLING,
            path: `${ROUTE_NAMES.PIPELINE_SAMPLING}/:product`,
            component: () => import(`./components/TankerDischarge/Discharge/LineSampling.vue`),
            meta: {
              title: 'Pipeline Sampling',
              receiptPlan: true,
              showProductTabs: true,
              active: true,
              display: DISPLAY_BREADCRUMB.LINESAMPLINGMENU
            }
          },
          {
            name: ROUTE_NAMES.DISCHARGE_TANK_MONITORING,
            path: `receipt-plan/:receiptPlanId/tank/:tankPlanId/${ROUTE_NAMES.DISCHARGE_TANK_MONITORING}`,
            component: () => import(`./components/TankerDischarge/Discharge/MonitorDetails.vue`),
            meta: {
              title: 'Tanks (Monitoring)',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.DISCHARGE_CHECKLIST,
            path: `${ROUTE_NAMES.DISCHARGE_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.DISCHARGE
            }
          },
          {
            name: ROUTE_NAMES.SHIFT_HANDOVER,
            path: `${ROUTE_NAMES.SHIFT_HANDOVER}`,
            component: () => import(`./components/TankerDischarge/PreArrivalForms.vue`),
            meta: {
              title: 'Shift-Handover',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.DISCHARGE
            }
          },
          {
            name: ROUTE_NAMES.LETTERS_OF_PROTEST,
            path: `${ROUTE_NAMES.LETTERS_OF_PROTEST}`,
            component: () => import(`./components/TankerDischarge/PreArrivalForms.vue`),
            meta: {
              title: 'Letters of Protest',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.DISCHARGE
            }
          }
        ]
      },
      {
        name: ROUTE_NAMES.POST_DISCHARGE,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANKER_DISCHARGE}/${ROUTE_NAMES.POST_DISCHARGE}/:planId`,
        component: () => import(`./components/TankerDischarge/PostDischarge/Index.vue`),
        meta: {
          title: 'Post Discharge',
          icon: 'insert_drive_file', 
          active: true,
          display: DISPLAY_BREADCRUMB.POSTDISCHARGE
        },
        children: [
          {
            name: ROUTE_NAMES.DISCHARGE_QUALITY_REPORT,
            path: `receipt-plan/:receiptPlanId/tank/:tankPlanId/${ROUTE_NAMES.DISCHARGE_QUALITY_REPORT}`,
            component: () => import(`./components/TankerDischarge/PostDischarge/QualityReport.vue`),
            meta: {
              title: 'Quality Report',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.DISCHARGE_QUANTITY_REPORT,
            path: `ROUTE_NAMES.DISCHARGE_QUANTITY_REPORT}`,
            component: () => import(`./components/TankerDischarge/PostDischarge/PostDischargeForms.vue`),
            meta: {
              title: 'Quantity Report',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.OUT_TURN_REPORT,
            path: `${ROUTE_NAMES.OUT_TURN_REPORT}`,
            component: () => import(`./components/TankerDischarge/PostDischarge/PostDischargeForms.vue`),
            meta: {
              title: 'Out Turn Report',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.SHIP_PORT_PERFORMANCE,
            path: `${ROUTE_NAMES.SHIP_PORT_PERFORMANCE}`,
            component: () => import(`./components/TankerDischarge/PostDischarge/ShipPortPerformance.vue`),
            meta: {
              title: 'Ship Port Performance - TO-IOA-FOR-019',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.AMAS_LOGIN,
            path: `${ROUTE_NAMES.AMAS_LOGIN}`,
            component: () => import(`./components/TankerDischarge/PostDischarge/AmasLogin.vue`),
            meta: {
              title: 'AMAS',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.ACTIVETANKS
            }
          },
          {
            name: ROUTE_NAMES.DISCHARGE_SUMMARY_REPORT,
            path: `${ROUTE_NAMES.DISCHARGE_SUMMARY_REPORT}`,
            // component: () => import(`./components/TankerDischarge/PostDischarge/Summary.vue`),
            component: () => import(`./components/TankerDischarge/PostDischarge/SummaryComingSoon.vue`),
            meta: {
              title: 'Summary Report',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.POSTDISCHARGE
            }
          },
          {
            name: ROUTE_NAMES.POST_DISCHARGE_CHECKLIST,
            path: `${ROUTE_NAMES.POST_DISCHARGE_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.POSTDISCHARGE
            }
          },
          {
            name: ROUTE_NAMES.POST_DISCHARGE_ATTACHMENTS,
            path: `${ROUTE_NAMES.POST_DISCHARGE_ATTACHMENTS}`,
            component: () => import(`./components/TankerDischarge/PostDischarge/PostDischargeForms.vue`),
            meta: {
              title: 'Attachments',
              receiptPlan: true,
              active: true,
              display: DISPLAY_BREADCRUMB.POSTDISCHARGE
            }
          }
        ]
      }
    ]
  },

  /* Tank to Tank */
  {
    name: ROUTE_NAMES.TANK_TO_TANK,
    path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANK_TO_TANK}`,
    component: () => import(`./components/TankToTank/Index.vue`),
    meta: {
        title: 'Tank to Tank Transfer'
    },
    children: [
      {
        name: ROUTE_NAMES.TANK_TO_TANK_RECEIPT_PLAN_LIST,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANK_TO_TANK}`,
        component: () => import(`./components/TankToTank/TankToTankTransferPlans.vue`),
        meta: {
          title: 'Tank to Tank Transfer',
          active: true,
          display: false
        }
      },
      {
        name: ROUTE_NAMES.PRE_TRANSFER,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANK_TO_TANK}/:tankToTankPlanId/${ROUTE_NAMES.PRE_TRANSFER}`,
        component: () => import(`./components/TankToTank/PreTransfer/Index.vue`),
        meta: {
          title: 'Pre-Transfer',
          icon: 'alarm', 
          active: true,
          display: true
        },
        children: [
          {
            name: ROUTE_NAMES.TRANSFER_PLAN,
            path: `${ROUTE_NAMES.TRANSFER_PLAN}`,
            component: () => import(`./components/TankToTank/PreTransfer/TransferPlan.vue`),
            meta: {
              title: 'Transfer Plan',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.PRE_TRANSFER_CHECKLIST,
            path: `${ROUTE_NAMES.PRE_TRANSFER_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          }
        ]
      },
      {
        name: ROUTE_NAMES.TRANSFER,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANK_TO_TANK}/${ROUTE_NAMES.TRANSFER}/:tankToTankPlanId`,
        component: () => import(`./components/TankToTank/Transfer/Index.vue`),
        meta: {
          title: 'Transfer',
          icon: 'outlined_flag', 
          active: true,
          display: true
        },
        children: [
          {
            name: ROUTE_NAMES.TRANSFER_LOG_OF_EVENTS,
            path: `${ROUTE_NAMES.TRANSFER_LOG_OF_EVENTS}`,
            component: () => import(`./components/TankToTank/Transfer/LogOfEvents.vue`),
            meta: {
              title: 'Log of Events',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.TRANSFER_TANK_MONITORING,
            path: `${ROUTE_NAMES.TRANSFER_TANK_MONITORING}`,
            component: () => import(`./components/TankToTank/Transfer/TankMonitoring.vue`),
            meta: {
              title: 'Tanks (Monitoring)',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.TRANSFER_CHECKLIST,
            path: `${ROUTE_NAMES.TRANSFER_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          }
        ]
      },
      {
        name: ROUTE_NAMES.POST_TRANSFER,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TANK_TO_TANK}/${ROUTE_NAMES.POST_TRANSFER}/:tankToTankPlanId`,
        component: () => import(`./components/TankToTank/PostTransfer/Index.vue`),
        meta: {
          title: 'Post-Transfer',
          icon: 'insert_drive_file', 
          active: true,
          display: true
        },
        children: [
          {
            name: ROUTE_NAMES.TRANSFER_QUALITY_REPORT,
            path: `${ROUTE_NAMES.TRANSFER_QUALITY_REPORT}`,
            component: () => import(`./components/TankToTank/PostTransfer/QualityReport.vue`),
            meta: {
              title: 'Quality Report',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.TRANSFER_QUANTITY_REPORT,
            path: `${ROUTE_NAMES.TRANSFER_QUANTITY_REPORT}`,
            component: () => import(`./components/TankToTank/PostTransfer/QuantityReport.vue`),
            meta: {
              title: 'Quantity Report',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.TRANSFER_SUMMARY_REPORT,
            path: `${ROUTE_NAMES.TRANSFER_SUMMARY_REPORT}`,
            component: () => import(`./components/TankToTank/PostTransfer/SummaryReport.vue`),
            meta: {
              title: 'Summary Report',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.POST_TRANSFER_CHECKLIST,
            path: `${ROUTE_NAMES.POST_TRANSFER_CHECKLIST}`,
            component: () => import(`./components/CheckList.vue`),
            meta: {
              title: 'Checklist',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          },
          {
            name: ROUTE_NAMES.POST_TRANSFER_ATTACHMENTS,
            path: `${ROUTE_NAMES.POST_TRANSFER_ATTACHMENTS}`,
            component: () => import(`./components/TankToTank/PostTransfer/Attachments.vue`),
            meta: {
              title: 'Attachments',
              active: true,
              display: true,
              tankToTankPlan: true
            }
          }
        ]
      }
    ]
  },

  /* Terminal Data */
  {
    name: ROUTE_NAMES.TERMINAL_DATA,
    path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TERMINAL_DATA}`,
    component: () => import(`./components/TerminalData/Index.vue`),
    meta: {
        title: 'Terminal Data'
    },
    children: [
      {
        name: ROUTE_NAMES.TERMINAL_DATA,
        path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.TERMINAL_DATA}`,
        component: () => import(`./components/TerminalData/Index.vue`),
        meta: { 
          title: 'Terminal Data',
          icon: 'fact_check'
        },
        children: [
          {
            name: ROUTE_NAMES.TERMINAL_DATA_TERMINAL_INFORMATION,
            path: `${ROUTE_NAMES.TERMINAL_DATA_TERMINAL_INFORMATION}`,
            component: () => import(`./components/TerminalData/TerminalInformation/TerminalInformation.vue`),
            meta: { 
              title: 'Terminal Information'
            }
          },
          {
            name: ROUTE_NAMES.TERMINAL_DATA_PIPELINE_INFORMATION,
            path: `${ROUTE_NAMES.TERMINAL_DATA_PIPELINE_INFORMATION}`,
            component: () => import(`./components/TerminalData/PipelineInformation/PipelineInformation.vue`),
            meta: { 
              title: 'Pipeline Information'
            }
          },
          {
            name: ROUTE_NAMES.TERMINAL_DATA_HOSE_INFORMATION,
            path: `${ROUTE_NAMES.TERMINAL_DATA_HOSE_INFORMATION}`,
            component: () => import(`./components/TerminalData/HoseInformation/HoseInformation.vue`),
            meta: { 
              title: 'Cargo Dock Hose Information'
            }
          },
          {
            name: ROUTE_NAMES.TERMINAL_DATA_EQUIPMENT_INFORMATION,
            path: `${ROUTE_NAMES.TERMINAL_DATA_EQUIPMENT_INFORMATION}`,
            component: () => import(`./components/TerminalData/EquipmentInformation/EquipmentInformation.vue`),
            meta: { 
              title: 'Equipment Information'
            }
          }
        ]
      }
    ]
  },

  /* Levels of Concern */
  {
    name: ROUTE_NAMES.LEVELS_OF_CONCERN,
    path: `/${ROUTE_NAMES.TERMINAL}/:terminalId/${ROUTE_NAMES.LEVELS_OF_CONCERN}`,
    component: () => import(`./components/LevelsOfConcern/Index.vue`),
    meta: {
        title: 'Levels of Concern'
    },
    children: [
      {
        name: ROUTE_NAMES.LEVELS_OF_CONCERN_TERMINAL,
        path: `/${ROUTE_NAMES.LEVELS_OF_CONCERN}/terminal/:terminalId`,
        component: () => import(`./components/LevelsOfConcern/Index.vue`),
        meta: { }
      }
    ]
  },

  /* Jira link to add new issue for tdp feedback */
  {
    name: ROUTE_NAMES.CREATE_JIRA_ISSUE,
    path: '/redirect',
    beforeEnter: () => {
      //window.location.href = "https://zenergy.atlassian.net/secure/CreateIssue.jspa?issuetype=11200&pid=15449&customfield_10220-field=#1-terminal-app-user-feedback-2";
      window.location.href = "https://zenergy.atlassian.net/secure/CreateIssue.jspa?issuetype=11200&pid=15449";
    }
  },

  /* Not found or authorised */
  {
    name: ROUTE_NAMES.ACCESS_DENIED,
    path: '/401',
    component: () => import('./views/AccessDenied.vue')
  },
  {
    path: '*',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/NotFound.vue')
  }
];
