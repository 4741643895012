<template>
  <div>
    <v-container top offset-y v-if="activeTerminal">
        <v-select background-color="rgba(255, 255, 255, 0.2)" item-color="orange lighten-1"
                  :items="terminalsSelectItems" :menu-props="styles" style="font-size: 16px;"
                  solo v-model="activeTerminal" color="orange lighten-1" auto />
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { UI } from '@/store/ui';
import { TERMINALS } from '@/store/terminals';
import { ROUTE_NAMES } from '@/routes';

  export default {
    data: () => ({
        styles: {
            bottom: true,
            offsetY: true,
            'open-on-hover': true,
            'open-delay': 1,
            rounded: "rounded-lg"
        }
    }),
    computed: {
        activeTerminal: {
          get() {
            const activeTerminal =
              this.$store.state.terminals &&
              this.$store.state.terminals.activeTerminal &&
              this.$store.state.terminals.activeTerminal.id;
            return activeTerminal;
          },
          set(activeTerminalId) {
            const activeTerminal = this.terminalsByKey[activeTerminalId];
    
            this.$store.dispatch(
              TERMINALS.NS(TERMINALS.ACTIONS.SET_ACTIVE_TERMINAL),
              activeTerminal
            );

            this.$store.commit(
              UI.NS(UI.MUTATIONS.RESET_DEFAULT_OPERATION_ID)
            );
           
            this.$router.replace({name: ROUTE_NAMES.OPERATIONSELECT, params: {terminalId:activeTerminalId} });
          }
        },
        ...mapState({
            terminal: state => state.terminals.activeTerminal,
            terminalsByKey: state =>
              state.terminals.terminalsList &&
              state.terminals.terminalsList.reduce((terminals, terminal) => {
                terminals[terminal.id] = { ...terminal };
                return terminals;
            }, {}),
            terminalsSelectItems: state =>
              state.terminals.terminalsList &&
              state.terminals.terminalsList.map(terminal => ({
              value: terminal.id,
              text: terminal.name
            })),
            operationId: state => state.ui.operationId
        })
    }
  }
</script>