import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';
import { removeTimezone } from '@/helpers/date';
import { parseISO } from 'date-fns';

export const CARGO_PLAN_MUTATIONS = {
  SET_CARGO_PLAN: 'setCargoPlan',
  SET_ORIGINAL_CARGO_PLAN: 'setOriginalCargoPlan',
  SET_AMENDMENT: 'setAmendment',
  SET_DATE: 'setDate',
  SET_DISCHARGE_ID: 'setDischargePlanId',
  CONVERT_DATE_FIELDS_TO_DATES: 'convertDateFieldsToDates',
};

export const mutations = {
  [CARGO_PLAN_MUTATIONS.SET_CARGO_PLAN](state, cargoPlan) {
    Vue.set(state, 'cargoPlan', cloneDeep(cargoPlan));
  },
  [CARGO_PLAN_MUTATIONS.SET_ORIGINAL_CARGO_PLAN](state, cargoPlan) {
    Vue.set(state, 'originalCargoPlan', cloneDeep(cargoPlan));
  },
  [CARGO_PLAN_MUTATIONS.SET_AMENDMENT](state, amendment) {
    state.cargoPlan.amendment = amendment;
  },
  [CARGO_PLAN_MUTATIONS.SET_DISCHARGE_ID](state, dischargePlanId) {
    state.cargoPlan.dischargePlanId = dischargePlanId;
  },
  [CARGO_PLAN_MUTATIONS.SET_DATE](state, date) {
    //Removes timezone information ie changes Wed Mar 25 2015 13:00:00 GMT+1300 (New Zealand Daylight Time) to Wed Mar 25 2015 13:00
    //So that it gets parsed correctly by axios
    state.cargoPlan.date = removeTimezone(new Date(date));
  },
  [CARGO_PLAN_MUTATIONS.CONVERT_DATE_FIELDS_TO_DATES](state) {
    state.cargoPlan.date = parseISO(
      state.cargoPlan.date
    );
  }
};
