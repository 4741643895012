import { actions, DISCHARGE_LOG_ACTIONS } from './actions';
import { getters, DISCHARGE_LOG_GETTERS } from './getters';
import { mutations, DISCHARGE_LOG_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'dischargeLog';

export const state = {
  dischargeLogList: []
};

export const DISCHARGE_LOG = {
  ACTIONS: DISCHARGE_LOG_ACTIONS,
  GETTERS: DISCHARGE_LOG_GETTERS,
  MUTATIONS: DISCHARGE_LOG_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const dischargeLog = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
