import { actions, QUALITY_REPORT_ACTIONS } from './actions';
import { getters, QUALITY_REPORT_GETTERS } from './getters';
import { mutations, QUALITY_REPORT_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'qualityReport';

export function GET_DEFAULT_QUALITY_REPORT() {
  return {
    product: '',
    postReceiptStorageTankSamples: null,
    expectedResults: null,
    testedResults: null,
    variationResults: null,
    passFailResults: null,
    testMethods: null,
    released: null,
    notesForPrintedDocument: '',
    internalNotes: '',
    responsiblePersonsName: '',
    responsiblePersonsPositionTitle: '',
    tankName: '',
    isQualityReportReadyToPrint: false,
    isQualityReportReleasable: false,
    batchNumber: ''
  }
}

export function GET_DEFAULT_QUALITY_REPORT_LINE_ITEM() {
  return {
    colour: '', 
    temperature: '',
    conductivity: '',
    density: '',
    flashPoint: '',
    sulphur: '',
    viscosity: '',
    coldFilter: '',
    cloudPoint: '',
    updatedBy: ''
  };
}

export const state = {
  qualityReport: null
};

export const QUALITY_REPORT = {
  ACTIONS: QUALITY_REPORT_ACTIONS,
  GETTERS: QUALITY_REPORT_GETTERS,
  MUTATIONS: QUALITY_REPORT_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const qualityReport = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};