
export default function numToTime(num) {
  if (num === "###") {
    return num;
  }

  if (!num) {
    return "0m";
  }

  const days = Math.floor(num / 24);
  const hrs = Math.floor(num) % 24;
  const mins = Math.floor((num % 1) * 60);
  const ret = (days > 0 ? (days + "d ") : "") +
    (hrs > 0 ? (hrs + "h ") : "") +
    (mins > 0 ? (mins + "m") : "");
  return ret ? ret : "0m";
}
