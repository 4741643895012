<template>
    <v-container fluid fill-height>
        <v-dialog class="no-print" max-width="300" v-model="showDeleteConfirmation">
            <v-card pa-2>
                <v-card-text>
                    <v-layout>
                        <v-flex xs12 pa-2>
                            <strong class="save-title">Delete confirmation</strong>
                            <div class="save-message">Are you sure you want to delete?</div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="orange lighten-1"
                           @click="onDeleteConfirmation" id="yesButton">Yes</v-btn>

                    <v-btn color="secondary lighten-3"
                           @click="onDeleteConfirmationLeave" id="noButton">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500" v-model="showDialog">
            <v-card>
                <v-card-title class="headline">{{lineSample.id ? 'Edit' : 'Add'}} Pipeline Sample</v-card-title>

                <v-card-text>
                    <v-layout>
                        <v-flex xs12 class="column-padding product-label-container">
                            Product: <span class="product-label" id="productSpan">{{product}}</span>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap class="mx-auto">
                        
                           
                                <v-text-field label="Time"
                                              class="popup-padding"
                                              v-model="lineSample.monitoredAt"
                                              v-mask="'####-##-## ##:##'"
                                              placeholder="yyyy-MM-dd HH:mm"
                                              :error-messages="monitoredAtErrors"
                                              @input="$v.lineSample.monitoredAt.$touch()"
                                              color="orange lighten-1"
                                              ref="monitorAt" 
                                              id="monitoredAtInput"/>

                                <v-select :items="appearanceList" item-color="orange lighten-1"
                                              class="popup-padding"
                                              v-model="lineSample.appearance"
                                              :error-messages="appearanceErrors"
                                              @input="$v.lineSample.appearance.$touch()"
                                              label="Appearance" color="orange lighten-1"
                                              style="font-size: 16px;" 
                                              id="appearanceInput"/>

                                <v-text-field v-model="lineSample.vesselTank"
                                              class="popup-padding"
                                              label="Vessel Tank/s"
                                              :error-messages="vesselTankErrors"
                                              @blur="$v.lineSample.vesselTank.$touch()"
                                              color="orange lighten-1" 
                                              id="vesselTankInput"/>

                                <v-currency-field v-model="lineSample.temperature"
                                                class="popup-padding"
                                                :label="'Temperature \u2103'"
                                                :error-messages="temperatureErrors"
                                                @input="$v.lineSample.temperature.$touch()"
                                                ref="temp" color="orange lighten-1"
                                                :decimalLength="1"
                                                :autoDecimalMode="false" 
                                                id="temperatureInput"/>
                            
                            
                                <v-currency-field v-model="lineSample.density"
                                                  class="popup-padding"
                                                  :label="'Density kg/l @15\u2103'"
                                                  :error-messages="densityErrors"
                                                  @input="$v.lineSample.density.$touch()"
                                                  ref="density" color="orange lighten-1"
                                                  :decimalLength="4"
                                                  :autoDecimalMode="false" 
                                                  id="densityInput"/>   
                          
                                <v-currency-field v-if="showConductivity(product)" v-model="lineSample.conductivity"
                                                  class="popup-padding"
                                                  label="Conductivity pS/m"
                                                  :defaultValue="null"
                                                  :error-messages="conductivityErrors"
                                                  @input="$v.lineSample.conductivity.$touch()"
                                                  ref="conductivity" color="orange lighten-1"
                                                  v-mask="'###'"
                                                  id="conductivityInput"/>
                          
                                <v-select v-if="showFlashPoint()" :items="passFailItems" item-color="orange lighten-1"
                                                  class="popup-padding"
                                                  v-model="lineSample.flashPassFail"
                                                  label="Flash Pass/Fail" style="font-size: 16px;"
                                                  :error-messages="flashPassFailErrors"
                                                  @input="$v.lineSample.flashPassFail.$touch()"
                                                  color="orange lighten-1" 
                                                  id="flashPassFailInput"/>

                                <v-currency-field v-if="showFlashPoint()" v-model="lineSample.flashResult"
                                                  class="popup-padding"
                                                  :label="'Flash Point \u2103'"
                                                  :error-messages="flashResultErrors"
                                                  @input="$v.lineSample.flashResult.$touch()"
                                                  ref="flashResult" color="orange lighten-1"
                                                  :decimalLength="1"
                                                  :autoDecimalMode="false" 
                                                  id="flashResultInput"/>
                        
                            <v-text-field 
                                class="popup-padding"
                                label="Initials"
                                v-model="lineSample.initials"
                                maxlength="10" 
                                color="orange lighten-1"
                                :error-messages="initialsErrors" 
                                ref="initials"
                                @input="$v.lineSample.initials.$touch()"
                                id="initialsInput"/>
                    </v-layout>
                    <v-layout>
                        <v-flex xs12 col-2 mb-3>
                            <v-textarea v-model="lineSample.comments" label="Comments"
                                          color="orange lighten-1" outlined 
                                          id="commentsTextArea"/>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="secondary lighten-3"
                           @click="onCancelDialog"
                           :disabled="isLoading"
                           :loading="isLoading"
                           id="cancelButton">Cancel</v-btn>

                    <v-btn color="orange lighten-1"
                           @click="onSave"
                           :disabled="isLoading || disableSave"
                           :loading="isLoading"
                           id="saveButton">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-layout row wrap justify-end pa-3>
            <v-flex xs12 pt-1>
                <data-table :headers="computedHeaders"
                            :items="sortedLineSampleList"
                            :show-search="false"
                            :pagination.sync="pagination"
                            class="line-samples-table no-sort-datatable"
                            :loading="isLoading">
                              <template v-slot:default="slotProps">
                                <td>
                                  {{ slotProps.item.monitoredAt | formatDateTime('yyyy-MM-dd HH:mm') }}
                                </td>
                                <td :class="!isAppearanceValid(slotProps.item) ? 'cell-warning' : ''">
                                  {{ slotProps.item.appearance | toAppearance }}
                                </td>
                                <td>
                                  {{ slotProps.item.vesselTank }}
                                </td>                                
                                <td :class="!isDensityValid(slotProps.item.density) ? 'cell-warning' : ''">
                                  {{ slotProps.item.density | toDecimalPlaces(densityPrecision) }}
                                </td>  
                                <td>
                                  {{ slotProps.item.temperature | toDecimalPlaces(tempPrecision)  }}
                                </td>     
                                <td v-if="showFlashPoint()">
                                  <div :class="!isFlashPointValid(slotProps.item.flashResult) ? 'cell-warning' : ''">
                                    {{ slotProps.item.flashResult | toDecimalPlaces(flashpointPrecision) }}
                                  </div>
                                </td>   
                                <td v-if="showFlashPoint()">
                                  <div :class="!isFlashPassFailValid(slotProps.item) ? 'cell-warning' : ''">
                                    {{ slotProps.item.flashPassFail  }}
                                  </div>
                                </td>
                                <td v-if="showConductivity(product)">
                                  {{ slotProps.item.conductivity }}
                                </td>  
                                <td>                                 
                                  {{ slotProps.item.initials  }}
                                </td>
                                <td>
                                  {{ slotProps.item.createdBy  }}
                                </td>            
                                <td>
                                  {{ slotProps.item.comments  }}
                                </td>    
                                <td>
                                  <template class="text-xs-right no-print">
                                  <v-icon
                                    small
                                    class="d-inline mr-2"
                                    @click="editDetails(slotProps.item, slotProps.index)"
                                  >
                                    edit
                                  </v-icon>
                                  <v-icon
                                    small
                                    class="d-inline deleteButton"
                                    @click="deleteDetails(slotProps.item, slotProps.index)"
                                  >
                                    delete
                                  </v-icon>
                                </template>
                                </td>                                                                                                                                                                                                                                      
                              </template>
                </data-table>
            </v-flex>
            <v-flex xs4 pt-2>
                <flat-button :disabled="disableAddNewSampleButton" @click="onAddNew" id="addNewSampleButton">Add new sample</flat-button>
                <div v-if="disableAddNewSampleButton" class="error-message" id="disabledMessageDiv">Please fill out the Ship Sampling page for {{product.toUpperCase()}}</div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { 
  LINE_SAMPLE, 
  GET_DEFAULT_LINE_SAMPLE 
} from '@/store/tankerDischarge/discharge/lineSample';
import { SHIP_SAMPLE } from '@/store/tankerDischarge/arrival/shipSample';
import { SHIP_COQ } from '@/store/tankerDischarge/preArrival/shipCoq';
import { cloneDeep } from '@/helpers/object';
import { maskedDatetime, number } from '@/validators';
import {
  required,
  requiredIf,
  minValue,
  maxValue,
  maxLength
} from 'vuelidate/lib/validators';
import { getAppearanceList } from '@/helpers/appearance';
import { formatDateTimeToMasked } from '@/helpers/date';
import {
  validatePipelineFlashPoint,
  validatePipelineDensity,
  validateConductivity,
  validateAppearance
} from '@/helpers/validation/sharedThresholds';
import { DATE_TIME_FORMAT } from "@/constants/dateFormat";
import { PRODUCTS } from "@/constants/products";

export default {
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    this.isLoading = false;
  },
  data() {
    return {
      productFilter: x => x.product.toLowerCase() === this.product.toLowerCase(),
      isLoading: false,
      showDialog: false,
      showDeleteConfirmation: false,
      deleteLineSampleId: -1,
      lineSample: GET_DEFAULT_LINE_SAMPLE(),
      PRODUCTS,
      densityPrecision: 4,
      flashpointPrecision: 1,
      tempPrecision: 1,
      initials: null,
      pagination: {
        rowsPerPage: 10
      },
      passFailItems: [
        { text: 'Pass', value: 'Pass' },
        { text: 'Fail', value: 'Fail' }
      ],
      yesNoItems: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
      ]
    };
  },
  computed: {
    disableAddNewSampleButton(){
      return !this.approvedToReceiveShipSamples.map(t => t.product.toLowerCase()).length;
    },
    approvedToReceiveShipSamples(){
      return this.shipSampleList.filter(t => t.passOrFail).filter(this.productFilter);
    },
    samplesForDensityValidationRanges(){
      return this.product == PRODUCTS.LFO.toLowerCase() ? this.shipCoQList.filter(this.productFilter) : this.approvedToReceiveShipSamples;
    },
    disableSave() {
      if (!this.$v.lineSample.vesselTank.required || 
          !this.$v.lineSample.appearance.required ||
          !this.$v.lineSample.monitoredAt.required) {
          return true;
      }
      return false;
    },
    computedHeaders() {
      const headers = [
        { text: 'Time', value: 'monitoredAt' },
        { text: 'Appearance', value: 'appearance', width: 240 },
        { text: 'Vessel Tank/s', value: 'vesselTank' }
      ];
      headers.push(
        { text: 'Density kg/l @15\u2103', value: 'density' },
        { text: 'Temperature', value: 'temperature' },
      );
      if (this.showFlashPoint()) {
        headers.push({ text: 'Flash Point \u2103', value: 'flashPoint' });
        headers.push({ text: 'Flash Pass/Fail', value: 'flashPassFail' });
      }
      if (this.showConductivity(this.product)) {
        headers.push({ text: 'Conductivity pS/m', value: 'conductivity' });
      }
      headers.push(
        { text: 'Initials', value: 'initials' },
        { text: 'User', value: 'createdBy' },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions', width: 240 }
      );
      return headers;
    },
    lineSampleList() {
      return this.$store.state.lineSample &&
        this.$store.state.lineSample.lineSampleList;
    },
    sortedLineSampleList() {
      // defaulting to latest first
      return [...this.lineSampleList].sort((a, b) => {
        const aDate = new Date(a.monitoredAt);
        const bDate = new Date(b.monitoredAt);
        return aDate === bDate ? b.id - a.id : aDate < bDate ? 1 : -1
      });
    },
    planId() {
      return this.$route.params && this.$route.params.planId;
    },
    product() {
      return this.$route.params && this.$route.params.product;
    },
    appearanceList() {
      if (this.product) {
        return getAppearanceList(this.product);
      }
      return [];
    },
    monitoredAtErrors() {
      if (
        !this.$v.lineSample.monitoredAt.$dirty ||
        this.$v.lineSample.monitoredAt.$pending
      ) {
        return [];
      }
      if (
        !this.$v.lineSample.monitoredAt.required ||
        !this.$v.lineSample.monitoredAt.maskedDatetime
      ) {
        return 'A valid date is required (yyyy-MM-dd HH:mm)';
      }
      return null;
    },
    conductivityErrors() {
      if (!this.$v.lineSample.conductivity.isValid) {
        return 'Conductivity value is outside the range for the given product.';
      }
      if (
        !this.$v.lineSample.conductivity.$dirty ||
        this.$v.lineSample.conductivity.$pending
      ) {
        return [];
      }

      return null;
    },
    densityErrors() {
      if (
        !this.$v.lineSample.density.$dirty ||
        this.$v.lineSample.density.$pending
      ) {
        return [];
      }
      if (!this.$v.lineSample.density.required) {
        return 'Value is required';
      }
      if (!this.$v.lineSample.density.number) {
        return 'Must be a number';
      }
      if (!this.$v.lineSample.density.minValue) {
        return 'Value should be from 0 to 1';
      }
      if (!this.$v.lineSample.density.maxValue) {
        return 'Value should be from 0 to 1';
      }
      if (!this.$v.lineSample.density.isValid) {
        return 'Density value is outside the range for the given product.';
      }
      return null;
    },
    initialsErrors() {
      if (!this.$v.lineSample.initials.required) {
        return 'Value is required';
      }
      if (
        !this.$v.lineSample.initials.$dirty ||
        this.$v.lineSample.initials.$pending
      ) {
        return [];
      }
      return null;
    },
    temperatureErrors() {
      if (
        !this.$v.lineSample.temperature.$dirty ||
        this.$v.lineSample.temperature.$pending
      ) {
        return [];
      }
      if (!this.$v.lineSample.temperature.required) {
        return 'Value is required';
      }
      if (!this.$v.lineSample.temperature.maxValue) {
        return 'Value must be less than 100';
      }
      if (!this.$v.lineSample.temperature.number) {
        return 'Must be a number';
      }
      return null;
    },
    vesselTankErrors() {
      if (!this.$v.lineSample.vesselTank.required) {
        return 'Value is required';
      }
      if (
        !this.$v.lineSample.vesselTank.$dirty ||
        this.$v.lineSample.vesselTank.$pending
      ) {
        return [];
      }
      return null;
    },
    appearanceErrors() {
      if (!this.$v.lineSample.appearance.required) {
        return 'Appearance is required';
      }
      if (!this.$v.lineSample.appearance.isValid) {
        return 'Appearance value is invalid';
      }
      return null;
    },
    flashResultErrors() {
      if (
        !this.$v.lineSample.flashResult.$dirty ||
        this.$v.lineSample.flashResult.$pending
      ) {
        return [];
      }
      if (!this.$v.lineSample.flashResult.minValue) {
          return 'Value should be from 0 to 1000';
      }
      if (!this.$v.lineSample.flashResult.maxValue) {
          return 'Value should be from 0 to 1000';
      }
      if (!this.$v.lineSample.flashResult.isValid) {
        return 'Flashpoint value is outside the range for the given product.';
      }
      return null;
    },
    flashPassFailErrors() {
      if (
        !this.$v.lineSample.flashPassFail.$dirty ||
        this.$v.lineSample.flashPassFail.$pending
      ) {
        return [];
      }
      if (!this.$v.lineSample.flashPassFail.required) {
        return 'Value is required';
      }
      if (!this.$v.lineSample.flashPassFail.isValid) {
        return 'Value is invalid';
      }
      return null;
    },
    ...mapState({
      shipSampleList: state => state.shipSample && state.shipSample.shipSampleList,
      shipCoQList: state => state.shipCoq && state.shipCoq.shipCoQList
    })
  },
  methods: {
    async loadData(){
      await this.loadLineSamples();
      await this.loadShipSamples();
      if(this.product == PRODUCTS.LFO.toLowerCase()) await this.loadShipCoqList();
    },
    async loadLineSamples() {
      this.$store.commit(
        LINE_SAMPLE.NS(LINE_SAMPLE.MUTATIONS.RESET_LINE_SAMPLE_LIST)
      );
      await this.$store.dispatch(
        LINE_SAMPLE.NS(LINE_SAMPLE.ACTIONS.GET_LINE_SAMPLE_LIST), {
        planId: this.planId,
        product: this.product
      }
      );
    },
    async loadShipCoqList() {
       this.$store.commit(
        SHIP_COQ.NS(SHIP_COQ.MUTATIONS.RESET_SHIP_COQ_LIST)
      );
       await this.$store.dispatch(
        SHIP_COQ.NS(SHIP_COQ.ACTIONS.GET_SHIP_COQ_LIST), {
          planId: this.planId
        }
      );

    },
    async loadShipSamples(){
      this.$store.commit(
        SHIP_SAMPLE.NS(SHIP_SAMPLE.MUTATIONS.RESET_SHIP_SAMPLE_LIST)
      );

      await this.$store.dispatch(
        SHIP_SAMPLE.NS(SHIP_SAMPLE.ACTIONS.GET_SHIP_SAMPLE_LIST), {
          planId: this.planId
        }
      );
    },    
    onAddNew() {
      this.$v.$reset();
      this.lineSample = GET_DEFAULT_LINE_SAMPLE();
      this.showDialog = true;
    },
    onCancelDialog() {
      this.lineSample = GET_DEFAULT_LINE_SAMPLE();
      this.showDialog = false;
    },
    async onSave() {
      this.isLoading = true;
      const lineSample = cloneDeep(this.lineSample);
      lineSample.product = this.product;
      lineSample.monitoredAt = new Date(lineSample.monitoredAt)
      let insertId;
      if (lineSample.id) {
        insertId = await this.$store.dispatch(
          LINE_SAMPLE.NS(LINE_SAMPLE.ACTIONS.EDIT_LINE_SAMPLE),
          {
            planId: this.planId,
            lineSample: lineSample
          }
        );
      } else {
        insertId = await this.$store.dispatch(
          LINE_SAMPLE.NS(LINE_SAMPLE.ACTIONS.CREATE_LINE_SAMPLE),
          {
            planId: this.planId,
            lineSample: lineSample
          }
        );
      }

      if (insertId) {
        this.lineSample = GET_DEFAULT_LINE_SAMPLE();
        this.showDialog = false;
      }
      this.isLoading = false;
    },
    editDetails(lineSample) {
      this.$v.$reset();
      this.lineSample = cloneDeep(lineSample);
      this.lineSample.monitoredAt = formatDateTimeToMasked(this.lineSample.monitoredAt, DATE_TIME_FORMAT);
      this.$v.$touch();
      this.showDialog = true;
    },
    onDeleteConfirmationLeave() {
        this.showDeleteConfirmation = false;
    },
    async onDeleteConfirmation() {
      this.isLoading = true;
      const success = await this.$store.dispatch(
          LINE_SAMPLE.NS(LINE_SAMPLE.ACTIONS.DELETE_LINE_SAMPLE),
          {
              planId: this.planId,
              lineSampleId: this.deleteLineSampleId
          }
      );
    
      if (success) {
          this.lineSample = GET_DEFAULT_LINE_SAMPLE();
          this.showDialog = false;
      }
    
      this.deleteLineSampleId = -1;
      this.isLoading = false;
      this.showDeleteConfirmation = false;
    },
    deleteDetails(lineSample) {
        this.showDeleteConfirmation = true;
        this.deleteLineSampleId = lineSample.id;
    },
    getDensityFromSample(sample){
      return this.product == PRODUCTS.LFO.toLowerCase() ? sample.density : sample.testDensity;
    },
    densitiesForValidationRanges() {
      if (this.samplesForDensityValidationRanges) {
        if (this.samplesForDensityValidationRanges !== undefined && this.samplesForDensityValidationRanges.length > 0) {
          const sorted = [...this.samplesForDensityValidationRanges].sort((a, b) => {
            return this.getDensityFromSample(a) < this.getDensityFromSample(b) ? 1 : -1
          });
          return { high: this.getDensityFromSample(sorted[0]), low: this.getDensityFromSample(sorted[sorted.length - 1])};
        }
      }
    },
    flashPointsForValidationRanges() {
      if (this.approvedToReceiveShipSamples) {
        if (this.approvedToReceiveShipSamples !== undefined && this.approvedToReceiveShipSamples.length > 0) {
          const sorted = [...this.approvedToReceiveShipSamples].sort((a, b) => {
            return a.testFlashPoint < b.testFlashPoint ? 1 : -1
          });
          return { high: sorted[0].testFlashPoint, low: sorted[sorted.length - 1].testFlashPoint };
        }
      }
    },
    isDensityValid(value){
      const densityMeasurements = this.densitiesForValidationRanges();
      if (densityMeasurements) {
        return validatePipelineDensity(this.product, value, densityMeasurements.low, densityMeasurements.high);
      }
      return true;
    },
    isFlashPointValid(value){
      if (this.showFlashPoint(this.product)) {
        const flashPointMeasurements = this.flashPointsForValidationRanges();
        if (flashPointMeasurements) {
          return validatePipelineFlashPoint(this.product, value, flashPointMeasurements.low, flashPointMeasurements.high);
        }
      }
      return true;     
    },
    isAppearanceValid(item) {
      return validateAppearance(item.product, item.appearance);
    },
    isFlashPassFailValid(item) {
      if (item && item.flashPassFail) {
        return item.flashPassFail.toLowerCase() === 'pass';
      }
    } 
  },
  validations: {
    lineSample: {
      density: {
        isValid: function (value) {
          return this.isDensityValid(value);
        },
        number,
        required,
        minValue: minValue(0),
        maxValue: maxValue(1)
      },
      temperature: {
        number,
        required,
        maxValue: maxValue(99.9)
      },
      monitoredAt: {
        required,
        maskedDatetime
      },
      vesselTank: {
        required
      },
      appearance: {
        isValid: function (value) {
          return validateAppearance(this.product, value);
        },
        required
      },
      flashResult: {
        isValid: function (value) {
          return this.isFlashPointValid(value);
        },
        number,
        maxLength: (5),
        minValue: minValue(0),
        maxValue: maxValue(999.9)
      },
      flashPassFail: {
        isValid: function (value) {
          if (value) {
            return value.toLowerCase() === 'pass';
          }
          return true;
        },
        required: requiredIf(function () {
          return this.showFlashPoint();
        })
      },
      conductivity: {
        isValid: function (value) {
          return value == null || validateConductivity(this.product, value);
        },
        maxLength: maxLength(3),
        number
      },      
      initials: {
        required
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

// always show input labels in "active" position and display full label
:deep(.v-text-field) .v-label {
  transform: translateY(-18px) scale(0.75);
  max-width: initial !important;
}
// remove padding from box class inputs
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details_,
:deep(.v-text-field.v-text-field--enclosed)
  > .v-input__control
  > .v-input__slot {
  padding: 0 3px !important;
}

:deep(.line-samples-table) td,
:deep(.line-samples-table) th {
  text-align: center !important;
  padding: 0 12px !important;
}


:deep(.v-select) {
  margin-bottom: 0px;
}

.column-padding {
  padding-right: 40px;
}

.popup-padding{
 max-width: 220px;
 min-width: 220px;
 padding-right: 15px;
}

.actions-align {
    text-align: center !important;
}

.product-label {
  text-transform: uppercase;
}

.product-label-container {
  font-size: 140%;
  padding-bottom: 20px;
}
.error-message{
  color: #f00;
  font-size: 16px;
}
</style>
