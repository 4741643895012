import { actions, PRE_ARRIVAL_INFO_ACTIONS } from './actions';
import { getters, PRE_ARRIVAL_INFO_GETTERS } from './getters';
import { mutations, PRE_ARRIVAL_INFO_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'preArrivalInfo';

export const state = {
  preArrivalInfo: null,
  preArrivalInfoInitData: null
};

export const PRE_ARRIVAL_INFO = {
  ACTIONS: PRE_ARRIVAL_INFO_ACTIONS,
  GETTERS: PRE_ARRIVAL_INFO_GETTERS,
  MUTATIONS: PRE_ARRIVAL_INFO_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const preArrivalInfo = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};