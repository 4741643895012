import api from './api';
import { http } from '@/api';
import { cloneDeep } from '@/helpers/object';
import { removeTimezone } from '@/helpers/date';
import { DISCHARGE_PLAN_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
import { 
  DEFAULT_DISCHARGE_PLAN 
} from '@/store/tankerDischarge/preArrival/dischargePlan';

export const ERROR_GET_DISCHARGE_PLAN =
  'There was a problem trying to load the receipt plan';
export const ERROR_CREATE_DISCHARGE_PLAN =
  'There was a problem trying to create the receipt plan';
export const ERROR_UPDATE_DISCHARGE_PLAN =
  'There was a problem trying to update the receipt plan';
export const ERROR_LOAD_DISCHARGE_PLAN_LIST =
  'There was a problem trying to load the receipt plan list';

export const ERROR_ABANDON_DISCHARGE_PLAN =
  'There was a problem trying to abandon the receipt plan';
export const ERROR_REACTIVATE_DISCHARGE_PLAN =
  'There was a problem trying to reactivate the receipt plan';
export const ERROR_COMPLETE_DISCHARGE_PLAN =
  'There was a problem trying to complete the receipt plan';

export const DISCHARGE_PLAN_ACTIONS = {
  RESET_DISCHARGE_PLAN: 'resetDischargePlan',
  GET_DISCHARGE_PLAN: 'getDischargePlan',
  CREATE_DISCHARGE_PLAN: 'createDischargePlan',
  UPDATE_DISCHARGE_PLAN: 'updateDischargePlan',
  ABANDON_DISCHARGE_PLAN: 'abandonDischargePlan',
  REACTIVATE_DISCHARGE_PLAN: 'reactivateDischargePlan',
  COMPLETE_DISCHARGE_PLAN: 'completeDischargePlan',
  LOAD_DISCHARGE_LIST_BY_TERMINAL_ID: 'loadDischargeListByTerminalId'
};

export const actions = {
  async [DISCHARGE_PLAN_ACTIONS.GET_DISCHARGE_PLAN](
    { state, commit, dispatch },
    { id, hateoasGetUrl }
  ) {
    let success = false;
    try {
      let response;

      if (id) {
        response = await api.getDischargePlan(id);
      } else {
        // Temporarily replace http: with https:
        response = await http.get(hateoasGetUrl.replace('http:', 'https:'));
        //response = await http.get(hateoasGetUrl);
      }
      commit(DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN, response.data);
      commit(DISCHARGE_PLAN_MUTATIONS.CONVERT_DATE_FIELDS_TO_DATES);
      commit(DISCHARGE_PLAN_MUTATIONS.SET_ORIGINAL_DISCHARGE_PLAN, state.dischargePlan);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-load-discharge-id',
        text: ERROR_GET_DISCHARGE_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [DISCHARGE_PLAN_ACTIONS.ABANDON_DISCHARGE_PLAN](
    { dispatch },
    { planId }
  ) {
    let success = false;
    try {
      await api.abandonDischargePlan(planId);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-abandon-discharge-plan',
        text: ERROR_ABANDON_DISCHARGE_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [DISCHARGE_PLAN_ACTIONS.REACTIVATE_DISCHARGE_PLAN](
    { dispatch },
    { planId }
  ) {
    let success = false;
    try {
      await api.reactivateDischargePlan(planId);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-reactivate-discharge-plan',
        text: ERROR_REACTIVATE_DISCHARGE_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  async [DISCHARGE_PLAN_ACTIONS.COMPLETE_DISCHARGE_PLAN](
    { dispatch },
    { planId }
  ) {
    let success = false;
    try {
      await api.abandonDischargePlan(planId);
      success = true;
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-complete-discharge-plan',
        text: ERROR_COMPLETE_DISCHARGE_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  },
  [DISCHARGE_PLAN_ACTIONS.SET_TERMINAL]({ commit }, terminalData) {
    commit(DISCHARGE_PLAN_MUTATIONS.SET_TERMINAL, terminalData);
  },
  [DISCHARGE_PLAN_ACTIONS.RESET_DISCHARGE_PLAN]({ commit }) {
    commit(DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN, DEFAULT_DISCHARGE_PLAN());
  },
  async [DISCHARGE_PLAN_ACTIONS.CREATE_DISCHARGE_PLAN](
    { dispatch },
    { terminalId, vessel, voyage, pilotOnBoard }
  ) {
    try {
      const response = await api.createDischargePlan(terminalId, vessel, voyage, pilotOnBoard);

      // the response has the url of the newly created plan
      const hateoasGetUrl =
        response && response.headers && response.headers.location;

      if (hateoasGetUrl) {
        return await dispatch(DISCHARGE_PLAN_ACTIONS.GET_DISCHARGE_PLAN, {
          id: null,
          hateoasGetUrl
        });
      }
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-create-discharge-plan',
        text: ERROR_CREATE_DISCHARGE_PLAN,
        error
      },
        { root: true }
      );
    }
  },
  async [DISCHARGE_PLAN_ACTIONS.LOAD_DISCHARGE_LIST_BY_TERMINAL_ID](
    { commit, dispatch },
    { terminalId, pagination }
  ) {
    try {
      const response = await api.getDischargePlansByTerminalId(
        terminalId,
        pagination
      );
      commit(
        DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN_LIST,
        response.data.data
      );

      commit(
        DISCHARGE_PLAN_MUTATIONS.SET_DISCHARGE_PLAN_LIST_TOTAL,
        response.data.pagination.total
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-load-discharge-plan-list',
        text: ERROR_LOAD_DISCHARGE_PLAN_LIST,
        error
      },
        { root: true }
      );
    }
  },
  async [DISCHARGE_PLAN_ACTIONS.UPDATE_DISCHARGE_PLAN](
    { state, dispatch, commit },
    { id, dischargePlan }
  ) {
    let success = false;
    try {
      // update the PilotOnBoard so axios can parse it properly
      const plan = cloneDeep(dischargePlan);
      plan.pilotOnBoard = removeTimezone(plan.pilotOnBoard);
      await api.updateDischargePlan(id, plan);
      success = true;
      // update 'original' state
      commit(
        DISCHARGE_PLAN_MUTATIONS.SET_ORIGINAL_DISCHARGE_PLAN, cloneDeep(dischargePlan)
      );
    } catch (error) {
      dispatch(
        UI.NS(UI.ACTIONS.ADD_TOAST), {
        id: 'failed-update-discharge-plan',
        text: ERROR_UPDATE_DISCHARGE_PLAN,
        error
      },
        { root: true }
      );
    }
    return success;
  }
};