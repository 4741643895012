import Vue from "vue";

export const PIPELINE_INFORMATION_MUTATIONS = {
    SET_PIPELINE_INFORMATION_BY_TERMINAL: 'setPipelineInformationByTerminal'
};

export const mutations = {
    [PIPELINE_INFORMATION_MUTATIONS.SET_PIPELINE_INFORMATION_BY_TERMINAL](state, pipelines) {
        Vue.set(state, 'pipelinesList', pipelines);
    }
};