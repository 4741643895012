import { actions, DISCHARGE_PLAN_ACTIONS } from './actions';
import { getters, DISCHARGE_PLAN_GETTERS } from './getters';
import { mutations, DISCHARGE_PLAN_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'dischargePlan';

export function DEFAULT_DISCHARGE_PLAN() {
  return {
    vessel: null,
    voyage: null,
    pilotOnBoard: null,
    terminalId: null,
    search: null
  }
}

export const state = {
  dischargePlan: DEFAULT_DISCHARGE_PLAN(),
  originalDischargePlan: null, // used to compare if there are unsaved changes
  dischargePlanList: [],
  terminalDischargeListTotal: 0,
  terminal: null
};

export const DISCHARGE_PLAN = {
  ACTIONS: DISCHARGE_PLAN_ACTIONS,
  GETTERS: DISCHARGE_PLAN_GETTERS,
  MUTATIONS: DISCHARGE_PLAN_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const dischargePlan = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};