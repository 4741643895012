<template>
  <v-dialog max-width="800" v-model="showDialog" ref="dialog" persistent>
    <v-card>
      <v-form v-model="isSaveDisabled" @save.prevent>
        <v-card-title class="headline">Planning Time Scale</v-card-title>
        <v-card-text class="popup-input">

          <v-divider style="margin-top: 5px" class="divider"/>
          <v-layout>
            <v-flex>
              <v-text-field
                v-model="receivingCompanies"
                label="Receiving Companies" 
                style="font-size: 16px; max-width: 250px;"
                :error-messages="receivingCompaniesErrors"
                @input="$v.receivingCompanies.$touch()"
                color="orange lighten-1"
                id="timesScaleReceivingCompaniesId"
                ref="receivingCompanies"
                :rules="[rules.receivingCompaniesRequired, rules.maxLengthReceivingCompanies]"
                />
            </v-flex>
            <v-flex>
              <v-text-field
                v-model="voyage"
                label="Voyage" 
                style="font-size: 16px; max-width: 250px;"
                id="timeScaleVoyage"
                :readonly="true"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-text-field 
                  label="Pilot on Board - Arrival"  
                  style="font-size: 16px; max-width: 300px;" 
                  v-mask="'##/##/#### ##:##'"
                  placeholder="DD/MM/YYYY HH:MM"
                  v-model="startTime"
                  :rules="[rules.timeRequired, rules.validDateTime, rules.incrementsOfThirty]"
                  id="timeScalePoBA"
                  />
            </v-flex>
          </v-layout>
         

          <v-divider style="margin-top: 5px" class="divider"/>        
          
            <v-layout v-for="activity in timeScale.timeScaleActivities" 
                      v-bind:key="timeScale.timeScaleActivities.indexOf(activity)"
                      style="max-width: 800px;"
                      :id="'timescale-activity' + timeScale.timeScaleActivities.indexOf(activity)">
              <v-flex>
                <v-select 
                  label="Activity" style="font-size: 16px; max-width: 300px;"
                  :clearable=true
                  @input="activityName($event, timeScale.timeScaleActivities.indexOf(activity))"
                  :items="activities"
                  :value="activity.activityName"
                  :id="'activities-column-' + timeScale.timeScaleActivities.indexOf(activity)"
                  />
              </v-flex>
              <v-flex v-if="receivingCompaniesContains(activity.activityName)">
                <v-select
                  label="Product" style="font-size: 16px; max-width: 300px;"
                  :clearable=true
                  @input="product($event, timeScale.timeScaleActivities.indexOf(activity))"
                  :items="productsList"
                  :value="activity.product"
                  :id="'products-column-'+ timeScale.timeScaleActivities.indexOf(activity)"
                  :rules="[rules.required]"
                />
              </v-flex>
              <v-flex>
                <v-text-field 
                  label="Start Time (DD/MM/YYYY HH:MM)"  
                  style="font-size: 16px; max-width: 300px;" 
                  v-mask="'##/##/#### ##:##'"
                  placeholder="DD/MM/YYYY HH:MM"
                  @input="activityStartTime($event, timeScale.timeScaleActivities.indexOf(activity))"  
                  v-model="activity.activityStartTime"
                  :rules="[rules.timeRequired, rules.incrementsOfThirty, rules.validDateTime, rules.startTimeAfterPilotOnBoard]"
                  :id="'start-timedate-'+ timeScale.timeScaleActivities.indexOf(activity)"
                  />
              </v-flex>
              <v-flex>
                <v-text-field 
                  label="End Time (DD/MM/YYYY HH:MM)"  
                  style="font-size: 16px; max-width: 300px;" 
                  v-mask="'##/##/#### ##:##'"
                  placeholder="DD/MM/YYYY HH:MM"
                  @input="activityEndTime($event, timeScale.timeScaleActivities.indexOf(activity))"  
                  v-model="activity.activityEndTime"
                  :rules="[rules.endTimeRequired, rules.incrementsOfThirty, rules.validDateTime]"
                  :id="'end-timedate-'+ timeScale.timeScaleActivities.indexOf(activity)"
                  />
              </v-flex>
              
              <v-icon 
              @click="deleteActivity($event, timeScale.timeScaleActivities.indexOf(activity))">delete</v-icon>
              
          </v-layout>
        </v-card-text>     

        <v-card-actions>
          <v-spacer/>
            <v-btn
              color="orange lighten-1"
              @click="onAddActivity"
              id="addActivityPopupButton"
              >Add Activity</v-btn> 
            <v-btn 
              color="secondary lighten-3"
              @click="onCancel"
              :disabled="isLoading"
              :loading="isLoading"
              id="cancelPopupButton"
              ref="cancel"
              tabindex="14"
              >Cancel</v-btn>
            <v-btn 
              color="orange lighten-1"
              @click="onSaveTimeScale"
              :disabled="!isSaveDisabled"
              id="savePopupButton"
              ref="save"
              >Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { cloneDeep } from '@/helpers/object';
import { TIME_SCALE, DEFAULT_TIME_SCALE, DEFAULT_ACTIVITIES, DEFAULT_TIME_SCALE_ACTIVITY } from '@/store/tankerDischarge/preArrival/timeScale';
import { UI } from '@/store/ui';
import { formatDateTimeToMasked, getTimeMinutes, isValidMaskedDateTime, parseDate } from '@/helpers/date';
import { differenceInMonths } from 'date-fns';
import { DATE_TIME_DAY_FIRST } from "@/constants/dateFormat";

export default {
  props: {    
    showDialog: false,
    cargoPlanId: null,
    voyageId: null,
    receivingCompaniesList: [],
    productsList: [],
    cargoPlanDate: ""
  },
  async created() {
    await this.loadData(); 
  },
  data() {
    return {
      isLoading: false,
      isSaveDisabled: true,
      timeScale: DEFAULT_TIME_SCALE(),
      previousStartTime: '',
      rules: {
        timeRequired: value => !!value || 'Start time is required',
        endTimeRequired: value => !!value || 'End time is required',
        incrementsOfThirty: value => (getTimeMinutes(value) / 30 == 1 || getTimeMinutes(value) / 30 == 0) || 'Time must be in 30 minute increments',
        validTime: value => isValidMaskedDateTime(value, 'HH:mm') || 'Must be valid Time',
        receivingCompaniesRequired: value => !!value || 'Receiving Companies is Required',
        validDateTime: value => isValidMaskedDateTime(value, DATE_TIME_DAY_FIRST) || 'must be a valid date and time',
        maxLengthReceivingCompanies: value => value.length <= 25 || 'Receiving Companies cannot be longer than 25 characters',
        required: value => !!value || 'Activity is required',
        startTimeAfterPilotOnBoard: value => !this.isStartTimeAfterPilotOnBoard(value) || 'Start Time must either be after or equal to Pilot on Board Arrival'
      },      
    }
  },
  methods:{
    onAddActivity() {      
      this.timeScale.timeScaleActivities = [...this.timeScale.timeScaleActivities, DEFAULT_TIME_SCALE_ACTIVITY()];
    },
    async loadData() {    
      if(this.cargoPlanId) {        
        await this.$store.dispatch(
          TIME_SCALE.NS(TIME_SCALE.ACTIONS.GET_TIME_SCALE),
          { cargoPlanId: this.cargoPlanId }
        );
        
        this.timeScale = cloneDeep(this.$store.state.timeScale.timeScale);
        if(this.timeScale.id > 0) {
          this.$emit('edit');
        }
      } else {        
        this.timeScale = DEFAULT_TIME_SCALE();
      }
      
      if(this.timeScale.timeScaleActivities == null) {
        this.timeScale.timeScaleActivities = [];
      }
      if(this.timeScale.receivingCompanies == null){
        this.timeScale.receivingCompanies = '';
      }
      this.timeScale.startTime = formatDateTimeToMasked(this.timeScale.startTime, DATE_TIME_DAY_FIRST);
      this.previousStartTime = this.timeScale.startTime;
      for(var activity in this.timeScale.timeScaleActivities) {
        this.timeScale.timeScaleActivities[activity].activityStartTime = formatDateTimeToMasked(this.timeScale.timeScaleActivities[activity].activityStartTime, DATE_TIME_DAY_FIRST);
        this.timeScale.timeScaleActivities[activity].activityEndTime = formatDateTimeToMasked(this.timeScale.timeScaleActivities[activity].activityEndTime, DATE_TIME_DAY_FIRST);
      }
    },
    async onSaveTimeScale() {
      this.timeScale.cargoPlanId = this.cargoPlanId;
      this.isLoading = true;

      const timeScale = cloneDeep(this.timeScale);
      let success = false;      
      if(timeScale.id) {
        success = await this.$store.dispatch(
          TIME_SCALE.NS(TIME_SCALE.ACTIONS.UPDATE_TIME_SCALE),
          {
            timeScale: timeScale
          }
        );
        if (success) {          
          this.$store.dispatch(
            UI.NS(UI.ACTIONS.ADD_TOAST),
            {
              id: 'success-update-discharge-plan', 
              text: 'Successfully Updated Planning Time Scale'
            },
            { root: true }
          );
        }   
      } else {
        success = await this.$store.dispatch(
          TIME_SCALE.NS(TIME_SCALE.ACTIONS.CREATE_TIME_SCALE),
          {
            timeScale: timeScale
          }
        );
        if (success) {          
          this.$store.dispatch(
            UI.NS(UI.ACTIONS.ADD_TOAST),
            {
              id: 'success-create-discharge-plan', 
              text: 'Successfully Created Planning Time Scale'
            },
            { root: true }
          );
        }   
      } 
      if (success) {        
        this.$emit('edit');
        this.loadData();
        this.$emit('closeSave');      
      }
      this.isLoading = false;

    },

    onCancel() {
      this.loadData();
      this.$emit('close');
    },
    activityName(value, activityIndex) {
      this.timeScale.timeScaleActivities[activityIndex].activityName = value;

      if(this.receivingCompaniesContains(value) == false) {
        this.timeScale.timeScaleActivities[activityIndex].product = "";
      }
    },
    product(value, activityIndex) {
      this.timeScale.timeScaleActivities[activityIndex].product = value;
    },
    receivingCompaniesContains(value){
      if(value == null) {
        return false;
      }
      return this.receivingCompaniesList.indexOf(value) > -1;
    },
    activityStartTime(value, activityIndex) {
      this.timeScale.timeScaleActivities[activityIndex].activityStartTime = value;
    },
    activityEndTime(value, activityIndex) {
      this.timeScale.timeScaleActivities[activityIndex].activityEndTime = value;
    },
    deleteActivity(value, activityIndex) {
      this.timeScale.timeScaleActivities.splice(activityIndex,  1);      
    },
    isStartTimeAfterPilotOnBoard(value) {
      var startTime = parseDate(this.startTime, DATE_TIME_DAY_FIRST);
      var pobTime = parseDate(value, DATE_TIME_DAY_FIRST);
 
      if(startTime != null && pobTime != null) {  
        var difference = startTime - pobTime;      
          if (difference > 0) {
            return true;
        }
      }      
      return false;
    }
  },  
  computed: {
    isReceivingCompany(value) {
      if(this.receivingCompaniesList[value]){       
        return true;
      }
      return false;
    },
    activities: {
      get() {
        let activities = DEFAULT_ACTIVITIES();
        for(var company in this.receivingCompaniesList) {
          if(this.receivingCompaniesList[company] != null) {
            activities = [...activities, this.receivingCompaniesList[company]];
          }          
        }        
        return activities;
      }
    },
    voyage: {
      get() {
        return this.voyageId;
      }
    },
    receivingCompanies: {
      get() {
        return this.timeScale && this.timeScale.receivingCompanies;
      },
      set(value) {
        this.timeScale.receivingCompanies = value;
      }
    },
    startTime: {
      get() {                 
        return this.timeScale && this.timeScale.startTime;        
      },
      set(value) {   
        var oldStartTime = parseDate(this.previousStartTime, DATE_TIME_DAY_FIRST); 
        var newStartTime = parseDate(value, DATE_TIME_DAY_FIRST); 

        if(oldStartTime != 'Invalid Date' && newStartTime != 'Invalid Date'){

          if(value.split(':')[1] == '00' || value.split(':')[1] == '30') {
            if(oldStartTime != null && newStartTime != null) {          
              oldStartTime = oldStartTime.getTime();
              newStartTime = newStartTime.getTime();

              var difference = oldStartTime - newStartTime;

              for(var activity in this.timeScale.timeScaleActivities) {
                var activityEnd = parseDate(this.timeScale.timeScaleActivities[activity].activityEndTime, DATE_TIME_DAY_FIRST);
                var activityStart = parseDate(this.timeScale.timeScaleActivities[activity].activityStartTime, DATE_TIME_DAY_FIRST);

                if(activityEnd != null) {
                  activityEnd = activityEnd.getTime();
                  activityEnd -= difference;
                  this.timeScale.timeScaleActivities[activity].activityEndTime = formatDateTimeToMasked(new Date(activityEnd), DATE_TIME_DAY_FIRST);
                } 

                if(activityStart != null) {
                  activityStart = activityStart.getTime();
                  activityStart -= difference;                
                  this.timeScale.timeScaleActivities[activity].activityStartTime = formatDateTimeToMasked(new Date(activityStart), DATE_TIME_DAY_FIRST)
                }
              }
              this.previousStartTime = value;
            }       
            if(oldStartTime == null){
              this.previousStartTime = value;
            }
          }
        }


        this.timeScale.startTime = value;       
      }
    },
    receivingCompaniesErrors() {
      if(!this.$v.receivingCompanies.$dirty || this.$v.receivingCompanies.$pending) {
        return [];
      }
      
      if(!this.$v.receivingCompanies.minLength || !this.$v.receivingCompanies.required) {
        this.isSaveDisabled = false;
        return ['Receiving Companies is required'];
      }

      if(!this.$v.receivingCompanies.maxLength) {
        this.isSaveDisabled = false;
        return ['Receiving Companies exceeds 25 characters']
      }
      this.isSaveDisabled = true;
      return [];
    }
  },
  validations: {
    receivingCompanies: {
      minLength: minLength(1),
      maxLength: maxLength(25),
      required: required()
    }
  }
}
</script>

<style lang="scss" scoped>
 @import '../../../assets/styles/_responsive.scss';
 @import '../../../assets/styles/_variables.scss';
</style>