import formatDate from './dateFormatFilter';
import formatDateTime from './dateTimeFormatFilter';
import toDecimalPlaces from './toDecimalPlaces';
import litresToKL from './litresToKL';
import commaSeperateNumber from './commaSeperateNumber';
import uppercase from './uppercase';
import numToTime from './numToTime';
import toAppearance from './toAppearance';
import toPassFail from './toPassFail';
import toPercentage from './toPercentage';
import toYesNo from './toYesNo';

// TODO - Self registration of filters...
export default {
  install(vue) {
    vue.filter('formatDate', formatDate);
    vue.filter('formatDateTime', formatDateTime);
    vue.filter('toDecimalPlaces', toDecimalPlaces);
    vue.filter('litresToKL', litresToKL);
    vue.filter('commaSeperateNumber', commaSeperateNumber);
    vue.filter('uppercase', uppercase);
    vue.filter('numToTime', numToTime);
    vue.filter('toAppearance', toAppearance);
    vue.filter('toPassFail', toPassFail);
    vue.filter('toPercentage', toPercentage);
    vue.filter('toYesNo', toYesNo);
  }
};
