import { CARGO_DOCK_HOSE_INFORMATION_MUTATIONS } from './mutations';
import { UI } from '@/store/ui';
import api from './api';


export const CARGO_DOCK_HOSE_INFORMATION_ACTIONS = {
    GET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL: 'getCargoDockHoseInformationByTerminal',
    UPDATE_CARGO_DOCK_HOSE_DETAILS: 'updateCargoDockHoseDetails'
}

export const ERROR_GET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL =
    'There was a problem with loading the cargo dock hose information';

export const ERROR_UPDATE_CARGO_DOCK_HOSE_DETAILS =
    'There was a problem with updating the cargo dock hose details';

export const actions = {
    async [CARGO_DOCK_HOSE_INFORMATION_ACTIONS.GET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL]({ commit, dispatch }, terminalId) {
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
            root: true
        });
        try {
            let response = await api.getCargoDockHoseInformationByTerminal(terminalId);
            commit(CARGO_DOCK_HOSE_INFORMATION_MUTATIONS.SET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL, response.data);
        }
        catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'failed-load-cargo-dock-hose-information',
                    text: ERROR_GET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL,
                    error
                },
                { root: true }
            );
        }
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
            root: true
        });
    },
    async [CARGO_DOCK_HOSE_INFORMATION_ACTIONS.UPDATE_CARGO_DOCK_HOSE_DETAILS]({ dispatch }, cargoDockHoseDetails) {
        let success = false;
        try {
            await api.updateCargoDockHoseDetails(cargoDockHoseDetails.id, cargoDockHoseDetails.cargoDockHoseDetails);
            dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'success-update-cargo-dock-hose-details',
                    text: 'Successfully updated cargo dock hose details'
                },
                { root: true }
            );
            success = true;
        } catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'error-update-cargo-dock-hose-details',
                    text: 'Updating cargo dock hose details failed',
                    error: true
                },
                { root: true }
            );
        }
        return success;
    }
}