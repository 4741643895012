<template>
  <v-container fluid fill-height>
     <pdf-preview header="Ship Sampling PDF" :fileName="pdfFileName" :showDialog="printModel.showDialog" :url="printModel.url" @close="closePdfPreview" :data="printModel.data"></pdf-preview>
    <v-dialog class="no-print" max-width="300" v-model="showDeleteConfirmation">
      <v-card>
        <v-card-text>
          <v-layout>
            <v-flex xs12>
              <strong class="save-title">Delete confirmation</strong>
              <div class="save-message">Are you sure you want to delete?</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange lighten-1"
                 @click="onDeleteConfirmation" id="yesButton">Yes</v-btn>
          <v-btn color="secondary lighten-3" 
                @click="onDeleteConfirmationLeave" id="noButton">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="540" v-model="showDialog">
      <v-card>
        <v-card-title class="headline">{{sampleDetails.id ? 'Edit' : 'Add'}} Ship Sample</v-card-title>

        <v-card-text class="popup-input">
          <v-layout>
            <v-flex xs6 pr-2>
              <v-select class="upper-case" item-color="orange lighten-1"
                    :items="productItems" v-model="sampleDetails.product" 
                    label="Product" color="orange lighten-1"
                    @change="onChangeProduct"  style="font-size: 16px;"
                    tabindex="1" ref="product" 
                    id="productSelect"/>
            </v-flex>
            <v-flex xs6 pr-2>
              <v-text-field label="Vessel Tank #"
                    v-model="sampleDetails.vesselTank"
                    :error-messages="vesselTankErrors"
                    @blur="$v.sampleDetails.vesselTank.$touch()"
                    tabindex="2" color="orange lighten-1" 
                    id="vesselTankInput"/>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12>
              <v-layout>
                <v-flex xs12 pr-2>
                  <v-select :items="appearanceList"
                        v-model="sampleDetails.testAppearance"
                        :error-messages="testAppearanceErrors"
                        @blur="$v.sampleDetails.testAppearance.$touch()"
                        label="Tested Appearance" style="font-size: 16px; max-width:250px;"
                        tabindex="3" color="orange lighten-1" item-color="orange lighten-1" 
                        id="testedAppearanceInput"/>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 pr-2>
                  <v-currency-field v-model="sampleDetails.testDensity"
                        :label="'Tested Density kg/l @15\u2103'"
                        :error-messages="testDensityErrors"
                        @blur="$v.sampleDetails.testDensity.$touch()"
                        :decimalLength="4" color="orange lighten-1"
                        :autoDecimalMode="false"
                        ref="testDensity" 
                        id="testDensityInput"/>
                </v-flex>
                <v-flex xs12 pr-2>
                  <template v-if="showFlashPoint(sampleDetails.product)">
                    <v-currency-field v-model="sampleDetails.testFlashPoint"
                        :label="'Tested Flashpoint \u2103'"
                        :error-messages="testFlashPointErrors"
                        @blur="$v.sampleDetails.testFlashPoint.$touch()"
                        ref="testFlashPoint" color="orange lighten-1"
                        :decimalLength="1" 
                        :autoDecimalMode="false" 
                        id="testedFlashpointInput"/>
                  </template>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs6 pr-2>
                  <template v-if="showConductivity(sampleDetails.product)">
                    <v-currency-field label="Tested Conductivity pS/m"
                        v-model="sampleDetails.testConductivity"
                        :error-messages="testConductivityErrors"
                        @blur="$v.sampleDetails.testConductivity.$touch()"
                        v-mask="'###'" color="orange lighten-1"
                        ref="testConductivity"
                        id="testedConductivityInput"
                        />
                  </template>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs6 pr-2>
                  <v-text-field label="Initials"
                        v-model="sampleDetails.initials"
                        maxlength="10" color="orange lighten-1"
                        :error-messages="initialsErrors" ref="initials"
                        @blur="$v.sampleDetails.initials.$touch()"
                        id="initialsInput"/>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary lighten-3"
                 @click="onCancelDialog"
                 :disabled="isLoading"
                 :loading="isLoading"
                 id="popupCancelButton"
                 tabindex="8">Cancel</v-btn>

          <v-btn color="orange lighten-1"
                 @click="onSave" 
                 :disabled="isLoading || !isShipSamplingValid"
                 :loading="isLoading"
                 id="popupSaveButton"
                 tabindex="9">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="d-flex justify-end flex-row fluid wrap fill-height">
      <v-flex xs12 pa-3>
        <v-flex mt-2>
          <v-flex xs12 mb-4>
            <v-layout>
              <v-flex xs3 pr-3>
                <v-text-field :value="dischargePlan.vessel"
                              label="Tanker"
                              readonly 
                              color="orange lighten-1" 
                              id="vesselTextFeild"/>
              </v-flex>
              <v-flex xs3 pr-3>
                <v-text-field :value="dischargePlan.voyage"
                              label="Voyage/Cargo Number"
                              readonly
                              color="orange lighten-1" 
                              id="voyageCargoNumberTextFeild"/>
              </v-flex>
              <v-flex xs3 pr-3>
                <v-text-field :value="dischargePlan.terminalName"
                              label="Receiving Port"
                              readonly
                              color="orange lighten-1" 
                              id="receivingPortTextFeild"/>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-flex>
      </v-flex>
      <v-flex xs12 pa-3>
        <div class="ship-sample-table no-sort-datatable">
          <v-data-table color="orange lighten-1"
                        :headers="mainHeaders"
                        :items="sortedShipSampleList"
                        :show-search="false"
                        :footer-props="footerProps"
                        :items-per-page="footerProps.itemsPerPage"
                        :show-select="printModel.showPrintSelectionDialog"
                        :value="printModel.samplesToPrint"
                        @input="addToPrintSelection($event)"
                        :custom-sort="mockSort"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        show-expand
                        expand-icon="expand_more"
                        item-key="id"
                        class="data-table elevation-1 fixed-header" 
                        @click:row="rowClick"
                        :header-props="{ sortIcon: null }">
                        <template v-slot:header.data-table-select>
                          <v-checkbox v-model="printModel.selectAll" @change="selectAllChanged">
                           <template v-slot:label>
                              <div class="select-all-label">Select All</div>
                            </template>
                            </v-checkbox>
                           </template> 
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-layout>
                  <v-flex xs6 ma-3>
                    <div class="table">
                      <div class="heading">
                        <div class="cell"></div>
                        <div class="cell"><p>Test Method</p></div>
                        <div class="cell"><p>COQ</p></div>
                        <div class="cell"><p>Test Result</p></div>
                      </div>
                      <div class="row">
                        <div class="cell cell-align-center"><p>Appearance</p></div>
                        <div class="cell cell-align-center"><p>(ASTM D4176)</p></div>
                        <div class="cell cell-align-center"><p></p></div>
                        <div :class="[!isAppearanceValid(item) ? 'cell-warning' : '', 'cell cell-align-center']">
                          <p>{{ item.testAppearance | toAppearance }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="cell cell-align-center"><p>{{ 'Density kg/l @15\u2103' }}</p></div>
                        <div class="cell cell-align-center"><p>(ASTM D4052)</p></div>
                        <div class="cell">
                          <template v-if="shipDensity(item.product)"><p class="cell-margin"> {{ shipDensity(item.product) | toDecimalPlaces(densityPrecision) }} </p></template>
                           <template v-else><p class="cell-align-center"> Set in COQ page </p></template>
                        </div>
                        <div :class="[!isDensityValid(item) ? 'cell-warning' : '', 'cell']">
                          <p class="cell-margin" id="testDensityParagraph">{{ item.testDensity | toDecimalPlaces(densityPrecision) }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="cell cell-align-center"><p>{{ 'Flash Point \u2103' }}</p></div>
                        <div class="cell cell-align-center"><p>(ASTM PMCC D93)</p></div>
                        <div class="cell">
                          <template v-if="shipFlashPoint(item.product)">
                              <p class="cell-margin" id="shipFlashPointParagraph"> {{ shipFlashPoint(item.product) | toDecimalPlaces(flashpointPrecision) }} </p>
                          </template>
                         <template v-else-if="showFlashPoint(item.product)">
                            <p class="cell-align-center">Set in COQ page</p>
                         </template>
                        </div>
                        <template v-if="showFlashPoint(item.product)">
                          <div :class="[!isFlashPointValid(item) ? 'cell-warning' : '', 'cell']">
                              <p class="cell-margin" id="testFlashPointParagraph">{{ item.testFlashPoint | toDecimalPlaces(flashpointPrecision) }}</p>
                          </div>
                        </template>
                        <template v-else><div class="cell"></div></template>
                      </div>
                      <div class="row">
                        <div class="cell cell-align-center"><p>Conductivity pS/m</p></div>
                        <div class="cell cell-align-center"><p>(ASTM D2624)</p></div>
                        <div class="cell"><p class="cell-margin"></p></div>
                        <template v-if="showConductivity(item.product)">
                          <div :class="[!isConductivityValid(item) ? 'cell-warning' : '', 'cell']">
                            <p class="cell-margin" id="testConductivityParagraph">{{ item.testConductivity }}</p>
                          </div>
                        </template>
                        <template v-else><div class="cell"></div></template>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs6 mx-2 mt-3>
                    <v-flex xs6 pr-2>
                      <template v-if="item.passOrFail != null">
                        <v-radio-group :value="item.passOrFail" :column="false" label="Product Approved to Receive?" v-model="item.passOrFail">
                        <v-radio  v-for="possibleAnswer in yesNoItems" :value="possibleAnswer.value"
                                    :key="`${possibleAnswer.text}-answers`"
                                    :label="possibleAnswer.text" 
                                    class="radio-item-padding" color="orange lighten-1"
                                    id="productApprovedToReceiveInput"/>
                      </v-radio-group>
                      </template>
                      <template v-else>
                        <v-radio-group :value="item.tempPassOrFail" :column="false" label="Product Approved to Receive?" v-model="item.tempPassOrFail"
                         @change="productAcceptableChange(item)">
                        <v-radio v-for="possibleAnswer in yesNoItems"
                                    :key="`${possibleAnswer.text}-answers`"
                                    :label="possibleAnswer.text" 
                                    :value="possibleAnswer.value"
                                    class="radio-item-padding" color="orange lighten-1"
                                    id="tempProductApprovedToReceiveInput"/>
                      </v-radio-group>
                      </template>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea label="Comments" outlined
                                  color="orange lighten-1"
                                  v-model="item.comments"
                                  no-resize
                                  id="commentsTextArea"></v-textarea>
                    </v-flex>
                    <v-flex xs12 pb-2>
                      <div>
                        <div class="d-inline px-1">
                          <v-btn @click="editDetails(item)"
                                 x-small color="secondary lighten-3"
                                 id="editButton">
                            Edit
                          </v-btn>
                        </div>
                        <div class="d-inline px-1">
                          <v-btn @click="saveDetails(item)"
                                 :disabled="isLoading || !item.enableSave"
                                 x-small color="orange lighten-1"
                                 id="saveButton">
                            Save
                          </v-btn>
                        </div>
                        <div class="d-inline px-1">
                          <v-btn @click="deleteDetails(item)"
                                 x-small color="red lighten-1"
                                 id="deleteButton">
                            Delete
                          </v-btn>
                        </div>
                      </div>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </td>
            </template>
            <template v-slot:item.productName="{ item }" class="maincell-align-center">
                {{ item.product | uppercase }}
            </template>
            <template v-slot:item.vesselTankNo="{ item }" class="maincell-align-center">
                {{ item.vesselTank }}
            </template>
            <template v-slot:item.passOrFail="{ item }" 
                        :class="['maincell-align-center', !item.passOrFail ? 'cell-warning': '' ]">
                {{ item.passOrFail | toYesNo }}
            </template>
            <template v-slot:item.initials="{ item }" class="maincell-align-center">
                {{ item.initials }}
            </template>
          </v-data-table>
        </div>
      </v-flex>

    <v-flex v-if="printModel.showPrintSelectionDialog" xs2 pa-3>
      <div class="print-card">
        <v-card :elevation="10">
             <v-card-title style="padding-bottom:0px">Pick Sample/s</v-card-title>
             <v-card-text style="padding-bottom:0px">
                <div v-if="selectedForPrintSampleCountByProduct.length == 0" style="color:Silver">
                    No samples selected.
                  </div>
                  <div v-else >
                    <div v-for="productAndCount in selectedForPrintSampleCountByProduct"  v-bind:key="productAndCount.product" >
                      <div >
                        {{productAndCount.sampleCount}} {{productAndCount.product}} {{productAndCount.sampleCount == 1 ? "sample" :  "samples"}} selected.
                      </div>
                    </div>
                  </div>
             </v-card-text>
              <v-card-actions >
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary lighten-3"
                  @click="printModel.showPrintSelectionDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="orange lighten-1"
                  :disabled="disablePrintSamples"
                  @click="printSelectedSamples">
                  Print
                </v-btn>
                </v-card-actions>
            </v-card>
      </div>
    </v-flex>
      <v-flex xs2 pa-3 v-else>
        <flat-button :disabled="printModel.showPrintSelectionDialog" @click="onPrint" id="printButton">Print</flat-button>
      </v-flex>
      <v-flex xs4 pa-3>
        <flat-button @click="onAddNew" id="addNewSampleButton">Add new sample</flat-button>
      </v-flex>   
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from '@/helpers/object';
import { 
  DISCHARGE_PLAN
} from '@/store/tankerDischarge/preArrival/dischargePlan';
import { 
  SHIP_SAMPLE,
  GET_DEFAULT_SHIP_SAMPLE
} from '@/store/tankerDischarge/arrival/shipSample';
import { SHIP_COQ } from '@/store/tankerDischarge/preArrival/shipCoq';
import {
  PRODUCT_ITEMS,
  PRODUCTS_WITH_FLASHPOINT
} from '@/constants/products';
import { getAppearanceList } from '@/helpers/appearance';
import { calculateShipDensity, calculateShipFlashPoint } from '@/helpers/coqCalculations';
import { UI } from '@/store/ui';
import {
  validateFlashPointValue,
  validateDensityValue,
  validateConductivity,
  validateAppearance
} from '@/helpers/validation/sharedThresholds';
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';
import { number } from '@/validators';
import { formatDateTimeToMasked } from '@/helpers/date';
import { DATE_FORMAT } from "@/constants/dateFormat";

export default {
  async mounted(){
    this.loading = true;
    await this.$store.dispatch(
      DISCHARGE_PLAN.NS(DISCHARGE_PLAN.ACTIONS.GET_DISCHARGE_PLAN),
      { id: this.dischargePlanId }
    );
    await this.loadList();
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      printModel:{
        showPrintSelectionDialog: false,
        samplesToPrint: [],
        loading: false,
        url: null,
        data: null,
        showDialog: false,
        selectAll: false
      },
      showDeleteConfirmation: false,
      sampleDetails: GET_DEFAULT_SHIP_SAMPLE(),
      productItems: PRODUCT_ITEMS,
      densityPrecision: 4,
      flashpointPrecision: 1,
      deleteShipSampleId: -1,
      mainHeaders: [
        { text: '', value: 'data-table-expand', width: 50 },
        { text: 'Product Name', value: 'productName' },
        { text: 'Vessel Tank #', value: 'vesselTankNo' },
        { text: 'Product Approved to Receive', value: 'passOrFail' },
        { text: 'Initials', value: 'initials' }
      ],
      yesNoItems: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      footerProps: {
        itemsPerPageOptions: [10, 25],
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'chevron_left',
        nextIcon: 'chevron_right',
        itemsPerPage:10
      },
      singleExpand: true,
      expanded: []
    }
  },
  computed: {
    disablePrintSamples(){
      return this.printModel.samplesToPrint.length == 0;
    },
    pdfFileName(){
      return `${formatDateTimeToMasked(new Date(), DATE_FORMAT)}-${this.dischargePlan.vessel}-${this.dischargePlan.voyage }-Ship-Samples.pdf`
    },
    selectedForPrintSampleCountByProduct() {
      const products = [...new Set(this.printModel.samplesToPrint.map(t => t.product))];
      const productWithCounts = products.map(t => {
        return {
          product: t.toUpperCase(),
          sampleCount: this.printModel.samplesToPrint.filter(x => x.product == t).length
        }
      });
      return productWithCounts;
    },
    isLoading() {
      return this.uiIsLoading || this.loading;
    },
    isShipSamplingValid() {
      if (!this.$v.sampleDetails.initials.required || 
          !this.$v.sampleDetails.testAppearance.required || 
          !this.$v.sampleDetails.vesselTank.required) {
        return false
      }
      if (
        this.$v.sampleDetails.$invalid &&
        !(
          !this.$v.sampleDetails.testAppearance.isValid ||
          !this.$v.sampleDetails.testDensity.isValid ||
          !this.$v.sampleDetails.testFlashPoint.isValid ||
          !this.$v.sampleDetails.testConductivity.isValid
        )
      ) {
        return false;
      }
      return true;
    },
    shipSampleList() {
      return this.$store.state.shipSample &&
        this.$store.state.shipSample.shipSampleList;
    },
    sortedShipSampleList() {
      const ret = [...this.shipSampleList].sort((a, b) => {
        return a.product === b.product ? b.id - a.id : a.product < b.product ? -1 : 1
      });
      const cloned = cloneDeep(ret);
      cloned.forEach((e) => {
        e['enableSave'] = e.enableSave != null ? e.enableSave : e.passOrFail != null ? true : false;
        e['tempPassOrFail'] = e.tempPassOrFail != null ? e.tempPassOrFail : null
      });
      return cloned;
    },
    dischargePlanId() {
      return this.$route.params && this.$route.params.planId;
    },
    appearanceList() {
      if (this.sampleDetails && this.sampleDetails.product) {
        return getAppearanceList(this.sampleDetails.product);
      }
      return null;
    },
    vesselTankErrors() {
      if (!this.$v.sampleDetails.vesselTank.required) {
        return 'Value is required';
      }
      if (
        !this.$v.sampleDetails.vesselTank.$dirty ||
        this.$v.sampleDetails.vesselTank.$pending
      ) {
        return [];
      }
      return null;
    },
    passOrFailErrors() {
      if (
        !this.$v.sampleDetails.passOrFail.$dirty ||
        this.$v.sampleDetails.passOrFail.$pending
      ) {
        return [];
      }
      if (!this.$v.sampleDetails.passOrFail.required) {
        return 'Value is required';
      }
      return null;
    },
    initialsErrors() {
      if (!this.$v.sampleDetails.initials.required) {
        return 'Value is required';
      }
      if (
        !this.$v.sampleDetails.initials.$dirty ||
        this.$v.sampleDetails.initials.$pending
      ) {
        return [];
      }
      return null;
    },
    testAppearanceErrors() {
      if (!this.$v.sampleDetails.testAppearance.required) {
        return 'Value is required';
      }
      if (!this.$v.sampleDetails.testAppearance.isValid) {
        return 'Appearance value is invalid.';
      }
      if (
        !this.$v.sampleDetails.testAppearance.$dirty ||
        this.$v.sampleDetails.testAppearance.$pending
      ) {
        return [];
      }
      return null;
    },
    testDensityErrors() {
      if (
        !this.$v.sampleDetails.testDensity.$dirty ||
        this.$v.sampleDetails.testDensity.$pending
      ) {
        return [];
      }
      if (!this.$v.sampleDetails.testDensity.required) {
        return 'Value is required';
      }
      if (!this.$v.sampleDetails.testDensity.number) {
        return 'Must be a number';
      }
      if (!this.$v.sampleDetails.testDensity.minValue) {
        return 'Value should be from 0 to 1';
      }
      if (!this.$v.sampleDetails.testDensity.maxValue) {
        return 'Value should be from 0 to 1';
      }
      if (!this.$v.sampleDetails.testDensity.isValid) {
        return 'Density value is invalid.';
      }
      return null;
    },
    testFlashPointErrors() {
      if (
        !this.$v.sampleDetails.testFlashPoint.$dirty ||
        this.$v.sampleDetails.testFlashPoint.$pending
      ) {
        return [];
      }
      if (!this.$v.sampleDetails.testFlashPoint.required) {
        return 'Value is required';
      }
      if (!this.$v.sampleDetails.testFlashPoint.number) {
        return 'Must be a number';
      }
      if (!this.$v.sampleDetails.testFlashPoint.minValue) {
          return 'Value should be from 0 to 1000';
      }
      if (!this.$v.sampleDetails.testFlashPoint.maxValue) {
          return 'Value should be from 0 to 1000';
      }
      if (!this.$v.sampleDetails.testFlashPoint.isValid) {
        return 'Flashpoint is invalid';
      }
      return null;
    },
    testConductivityErrors() {
      if (
        !this.$v.sampleDetails.testConductivity.$dirty ||
        this.$v.sampleDetails.testConductivity.$pending
      ) {
        return [];
      }
      if (!this.$v.sampleDetails.testConductivity.required) {
        return 'Value is required';
      }
      if (!this.$v.sampleDetails.testConductivity.number) {
        return 'Must be a number';
      }
      if (!this.$v.sampleDetails.testConductivity.isValid) {
        return 'Conductivity is invalid';
      }
      return null;
    },
    ...mapState({
      shipCoQList: state => state.shipCoq && state.shipCoq.shipCoQList,
      dischargePlan: state => state.dischargePlan && state.dischargePlan.dischargePlan,
      uiIsLoading: state => state.ui.isLoading
    })
  },
  methods: {
    selectAllChanged(){
      if(this.printModel.selectAll){
        this.printModel.samplesToPrint = this.sortedShipSampleList;
      }else{
        this.printModel.samplesToPrint = [];
      }
    },
    closePdfPreview(){
      this.printModel.showDialog = false;
      this.printModel.showPrintSelectionDialog = false;
    },
    async printSelectedSamples(){
      this.printModel.loading = true;
      const res = await this.$store.dispatch(
          SHIP_SAMPLE.NS(SHIP_SAMPLE.ACTIONS.GET_SHIP_SAMPLE_PDF),
          { planId: this.dischargePlanId, shipSampleIds: this.printModel.samplesToPrint.map(t => t.id)}
      );
      const file = new Blob([res.data], {type: 'application/pdf'});
      this.printModel.data = file;
      const fileURL = URL.createObjectURL(file);
      this.printModel.url = fileURL
      this.printModel.loading = false;
      this.printModel.showDialog = true;
    },
    rowClick: function (item, row) {   
      if(!this.printModel.showPrintSelectionDialog) return;
      row.isSelected ? row.select(false) :row.select(true); 
    },
    addToPrintSelection(e){
      this.printModel.samplesToPrint = e;
    },
    onPrint(){
      this.printModel.showPrintSelectionDialog = !this.printModel.showPrintSelectionDialog;
      if(this.printModel.showPrintSelectionDialog){
        this.printModel.samplesToPrint = this.sortedShipSampleList;
        this.printModel.selectAll = true;
      }
    },
    mockSort(items) {
      // dont sort anything
      return items;
    },
    productAcceptableChange(item) {
      if (!item.enableSave) {
        this.$store.commit(SHIP_SAMPLE.NS(SHIP_SAMPLE.MUTATIONS.UPDATE_SHIP_SAMPLE_LIST_DISABLE_FLAG), item);
        // reset the fields after mutation
        this.sortedShipSampleList.find(t => t.id == item.id).tempPassOrFail = item.tempPassOrFail;
        this.sortedShipSampleList.find(t => t.id == item.id).comments = item.comments;
      }
    },
    async loadList() {
      this.$store.commit(
        SHIP_SAMPLE.NS(SHIP_SAMPLE.MUTATIONS.RESET_SHIP_SAMPLE_LIST)
      );

      await this.$store.dispatch(
        SHIP_SAMPLE.NS(SHIP_SAMPLE.ACTIONS.GET_SHIP_SAMPLE_LIST), {
          planId: this.dischargePlanId
        }
      );

      await this.$store.dispatch(
        SHIP_COQ.NS(SHIP_COQ.ACTIONS.GET_SHIP_COQ_LIST), {
          planId: this.dischargePlanId
        }
      );
    },
    editDetails(sample) {
      this.sampleDetails = cloneDeep(sample);
      this.showDialog = true;
      this.$nextTick(() => this.setTabIndex());
    },
    saveDetails(sample) {
      this.onSaveDetails(sample);
    },
    deleteDetails(sample) {
      this.showDeleteConfirmation = true;
      this.deleteShipSampleId = sample.id;
    },
    async onDeleteConfirmation() {
      this.showDeleteConfirmation = false;
      const success = await this.$store.dispatch(
        SHIP_SAMPLE.NS(SHIP_SAMPLE.ACTIONS.DELETE_SHIP_SAMPLE),
        {
          planId: this.dischargePlanId,
          shipSampleId: this.deleteShipSampleId
        }
      );

      if (success) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-delete-ship-sample',
            text: 'Successfully deleted ship sample.'
          },
          { root: true }
        );
      }

      this.deleteShipSampleId = -1;
    },
    onDeleteConfirmationLeave() {
      this.showDeleteConfirmation = false;
    },
    setTabIndex() {
      this.$refs.product.focus();
      this.$refs.testDensity.$refs.textfield.$refs.input.tabIndex = 4;
      this.$refs.testFlashPoint.$refs.textfield.$refs.input.tabIndex = 5;
      this.$refs.testConductivity.$refs.textfield.$refs.input.tabIndex = 6;
      this.$refs.initials.$refs.input.tabIndex = 7;
    },
    onAddNew() {
      this.resetShipSampleDetails();
      this.showDialog = true;
      this.$nextTick(() => this.setTabIndex());
    },
    onSave() {
      this.onSaveDetails(this.sampleDetails);
    },
    async onSaveDetails(item) {
      this.loading = true;
      const shipSample = cloneDeep(item);
      shipSample.passOrFail = shipSample.passOrFail != null ? shipSample.passOrFail : shipSample.tempPassOrFail;
      let isCreate = true;
      let result;
      if (shipSample.id) {
        isCreate = false;
        result = await this.$store.dispatch(
          SHIP_SAMPLE.NS(SHIP_SAMPLE.ACTIONS.EDIT_SHIP_SAMPLE),
          {
            planId: this.dischargePlanId,
            shipSample: shipSample
          }
        );
      } else {
        result = await this.$store.dispatch(
          SHIP_SAMPLE.NS(SHIP_SAMPLE.ACTIONS.CREATE_SHIP_SAMPLE),
          {
            planId: this.dischargePlanId,
            shipSample: shipSample
          }
        );
      }
      if (result) {
        this.$store.dispatch(
          UI.NS(UI.ACTIONS.ADD_TOAST),
          {
            id: 'success-'.concat(isCreate ? 'create' : 'update', '-ship-sample'),
            text: 'Successfully '.concat(isCreate ? 'created' : 'updated', ' ship sample.')
          },
          { root: true }
        );
        // only reload when adding new ship sample
        if (isCreate) {
          this.loadList();
        }
        this.resetShipSampleDetails();
        this.showDialog = false;
      
      }
      this.loading = false;
    },
    onCancelDialog() {
      this.resetShipSampleDetails();
      this.showDialog = false;
    },
    resetShipSampleDetails() {
      this.sampleDetails = GET_DEFAULT_SHIP_SAMPLE();
    },
    onChangeProduct() {
      this.sampleDetails.testAppearance = '';
    },
    showFlashPoint(product) {
      return PRODUCTS_WITH_FLASHPOINT.includes(product);
    },
    shipDensity(product) {
      if (this.shipCoQList) { 
        return calculateShipDensity(this.shipCoQList, product);
      } 
    },
    shipFlashPoint(product) {
      if (this.shipCoQList) { 
        return calculateShipFlashPoint(this.shipCoQList, product);
      } 
    },
    isFlashPointValid(item) {
      return validateFlashPointValue(item.product, item.testFlashPoint, this.shipFlashPoint(item.product), true, false);
    },
    isDensityValid(item) {
      return validateDensityValue(item.product, item.testDensity, this.shipDensity(item.product), true);
    },
    isConductivityValid(item) {
      return validateConductivity(item.product, item.testConductivity);
    },
    isAppearanceValid(item) {
      return validateAppearance(item.product, item.testAppearance);
    }
  },
  validations: {
    sampleDetails: {
      product: {
        required
      },
      vesselTank: {
        required
      },
      initials: {
        required
      },
      testAppearance: {
        required,
        isValid: function (value) {
          return validateAppearance(this.sampleDetails.product, value);
        }
      },
      testDensity: {
        required,
        number,
        minValue: minValue(0),
        maxValue: maxValue(1),
        isValid: function (value) {
          return validateDensityValue(this.sampleDetails.product, value, this.shipDensity(this.sampleDetails.product), true);
        }
      },
      testFlashPoint: {
        required: requiredIf(function () {
          return this.showFlashPoint(this.sampleDetails.product);
        }),
        isValid: function (value) {
          return validateFlashPointValue(this.sampleDetails.product, value, this.shipFlashPoint(this.sampleDetails.product), true, false);
        },
        number,
        maxLength: (5),
        minValue: minValue(0),
        maxValue: maxValue(999.9)
      },
      testConductivity: {
        required: requiredIf(function () {
          return this.showConductivity(this.sampleDetails.product);
        }),
        isValid: function (value) {
          return validateConductivity(this.sampleDetails.product, value);
        },
        number
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';

.v-data-table {
  word-break: initial
}

:deep(.ship-sample-table) td {
  text-align: right;
}
:deep(.ship-sample-table) th {
  text-align: center !important;
  padding: 0 12px !important;
  word-wrap: break-word;
}
:deep(.ship-sample-table) th:nth-child(1),
:deep(.ship-sample-table) th:nth-last-child(1) {
  text-align: center !important;
}

.column-padding {
  padding-right: 40px;
}

.actions-align {
  text-align: center !important;
}

.active-row {
  background: #e2f9ff;
}

.cell-warning {
  box-shadow: 0px 0px 0px 2px #fb0 inset;
}
.cell-error {
  box-shadow: 0px 0px 0px 2px #f00 inset;
}

.validation-warning {
  border-style: solid;
  border-color: #f00;
}

:deep(.v-select) {
  margin-bottom: 0px;
}

/*data-table styles*/

.data-table :deep(th),
.data-table :deep(td) {
  font-size: 18px;
}
.data-table :deep(tr) td {
  cursor: pointer;
}

.data-table :deep() {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.data-table :deep(table) {
  table-layout: fixed;
}

.data-table :deep(th) {
  position: sticky;
  top: 0;
  z-index: 5;
  background: white;
}

:deep(.v-table__overflow) {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

.theme--dark.v-table thead th {
  background-color: #424242;
}

.theme--light.v-table thead th {
  background-color: #ffffff;
}

.maincell-align-center {
  text-align: center !important;
}

:deep(.v-select) .v-messages {
  font-size: 12px !important;
}

/*subtable*/
.table {
  display: table;
  width: 100%;

}
.heading {
  display: table-row;
  font-weight: bold;
  text-align: center;
}
.row {
  display: table-row;
}
.cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  padding-left: 5px;
  padding-right: 5px;
}
.cell-align-center {
  text-align: center;
}
.cell-align-left {
  text-align: left;
}
.cell-margin {
  margin-right: 20px;
}
p {
  margin-top: 16px;
}
.radio-item-padding {
  padding-left: 18px;
}
.print-card {
  width: 350px;
  margin-top: -80px;
  margin-left: -175px;
  z-index: 10;
}
.select-all-label {
  margin-top: 4px;
  margin-top: 4px; 
  font-size: 12px;
}
</style>
