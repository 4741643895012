<template>
  <v-col xs3>
    <v-card :color="productColor">
     <v-col xs12>
      <v-row style="display: flex; justify-content: center;">
        <v-col class="pa-2 ma-2">
          <template style="font-size: 20;">Discharge Sequence: {{ dischargeSequence }}</template>
        </v-col>
        <v-col cols="2" class="pa-2 ma-2">
          <v-icon v-if="(activeCargoPlanProductsLength - 1) == columnIndex && activeCargoPlanProductsLength != productsMin"
            small
            @click="passEventToParent()"
            class="d-inline deleteButton">
            delete
          </v-icon>
        </v-col>
      </v-row>
     </v-col>
     <v-col xs12>
      <v-select
        label="Product"
        :clearable=true
        v-model="productGrade"
        :items="products"
        :id="'productGrade-column' + columnIndex"
        :color="highlightColor"/>                       
      </v-col> 
    <v-col xs12>
      <v-select
        label="Receiving Company"
        :clearable=true
        v-model="receivingCompany"
        :items="receivingCompanies"
        :id="'receivingCompany-column' + columnIndex"
        :color="highlightColor"/>
    </v-col>
    <v-col>
      <v-currency-field      
        class="input-width"
        :color="highlightColor"
        label="Discharge Quantity (KiloLitres)"
        :readonly="false"
        suffix="kl"
        v-model="dischargeQuantity"
        :error-messages="dischargeQuantityErrors"
        @input="$v.dischargeQuantity.$touch()"
        :id="'dischargeQuantity-column'+ columnIndex"
      />
    </v-col>
    <v-col xs12>
      <v-currency-field
        :autoDecimalMode="false"
        :decimalLength="1"
        class="input-width"
        :color="highlightColor"
        label="Maximum Pumping Pressure BAR (At Wharf Manifold)"
        v-model="pumpingPressure"
        :error-messages="pumpingPressureErrors"
        @input="$v.pumpingPressure.$touch()"
        suffix="BAR"
        :id="'maxPumpingPressure-column' + columnIndex"/>
    </v-col>
    <v-col>
      <v-currency-field
        class="input-width"
        :color="highlightColor"
        label="Max Receiving Rate (kilolitres/hour)"
        suffix="kl/h"
        v-model="maxReceivingRate"
        @input="$v.maxReceivingRate.$touch()"
        :error-messages="maxReceivingRateErrors"
        :id="'maxReceivingRate-column' + columnIndex"
      />
    </v-col>
    <v-col>
      <v-currency-field
        :autoDecimalMode="false"
        :decimalLength="1"
        class="input-width"
        :color="highlightColor"
        label="Slow Pumping Pressure BAR (At Wharf Manifold)"
        v-model="slowPumpingPressure"
        :error-messages="slowPumpingPressureErrors"
        @input="$v.slowPumpingPressure.$touch()"
        suffix="BAR"
        :id="'slowPumpingPressure-column' + columnIndex"/>
    </v-col>
    <v-col xs12>
      <v-text-field 
        class="input-width"
        :color="highlightColor"
        label="Ship's Tank No.s in Discharge Order"
        v-model="shipTankNumber"
        :error-messages="shipTankNumberErrors"
        @input="$v.shipTankNumber.$touch()"
        :id="'shipTankNumber-column' + columnIndex"/>
    </v-col>    
    <v-col xs12>
      <v-text-field 
        class="input-width"
        :color="highlightColor"
        label="Hose Connection No./Colour"
        v-model="hoseConnectionNumberColour"
        :error-messages="hoseConnectionErrors"
        @input="$v.hoseConnectionNumberColour.$touch()"
        :id="'hoseConnection-column' + columnIndex"/>
    </v-col>
    <v-col xs12>
      <v-text-field
        class="input-width"
        :color="highlightColor"
        label="Shore Hose and Flange Diameter (millimetres)"
        :readonly="true"
        v-model="shoreHoseAndFlange"
        :id="'shoreHoseAndFlange-column' + columnIndex"/>
    </v-col>
    <v-col xs12>
      <v-currency-field
        :decimalLength="0"
        class="input-width"
        :color="highlightColor"
        label="Shore Pipeline Diameter (millimetres)"
        v-model="shorePipelineDiameter"
        :error-messages="shorePipelineDiameterErrors"
        @input="$v.shorePipelineDiameter.$touch()"
        suffix="mm"
        :id="'shorePipelineDiameter-column' + columnIndex"           
      />
    </v-col>
    <v-col xs12>
      <v-currency-field
        :autoDecimalMode="false"
        :decimalLength="1"
        class="input-width"
        :color="highlightColor"
        label="Shore Pipeline Length (metres)"
        v-model="shorePipelineLength"
        :error-messages="shorePipelineLengthErrors"
        @input="$v.shorePipelineLength.$touch()"
        suffix="m"
        :id="'shorePipelineLength-column' + columnIndex" 
      />
    </v-col>    
    <v-col xs12>
      <v-text-field 
        class="input-width"
        :color="highlightColor"
        label="Shore Tank No."
        v-model="shoreTankNumber"
        :error-messages="shoreTankNumberErrors"
        @input="$v.shoreTankNumber.$touch()"
        :id="'shoreTankNumber-column' + columnIndex"/>
    </v-col>
    <v-col xs12>
      <v-select
        label="Ship or Shore to Stop"
        :clearable=true
        v-model="shipOrShore"
        :items="shipOrShoreOptions"
        :id="'shipOrShore-column' + columnIndex"/>
    </v-col>
    <v-col xs12>
      <v-select
        label="Method of Hose/Line Clearance"
        :clearable=true
        v-model="method"
        :items="methodOptions"
        :id="'method-column' + columnIndex"/>
    </v-col>
    <v-col xs12>
      <v-text-field
        label="Interface to Shore Tank No."
        class="input-width"
        :color="highlightColor"
        :error-messages="interfaceShoreTankNumberErrors"
        @input="$v.interfaceShoreTankNumber.$touch()"
        v-model="interfaceShoreTankNumber"
        :id="'interface-column' + columnIndex"/>
    </v-col>
    </v-card>
  </v-col>
</template>

<script>
import { 
  PRODUCTS, 
  RECEIVING_COMPANIES, 
  SHIP_OR_SHORE_OPTIONS,
  METHOD_OPTIONS,
  AGO_COLOR,
  JETA1_COLOR,
  PMS_COLOR,
  RMS_COLOR,
  PRODUCTS_MIN,
  HIGHLIGHT_COLOR
} from '@/constants/cargoPlanDropdown.js';
import { maxLength, between } from 'vuelidate/lib/validators';

export default {
  props: {
    cargoPlanProduct: {type: Object},
    isClearable: {type: Boolean, default: false},
    dischargePlan: {type: Object},
    displayDischargeChangeWarning: {type: Boolean, default: false},
    columnIndex: 0,
    activeCargoPlanProductsLength: {type: Number}
  },
  computed: {   
    receiptPlans(){
      return this.dischargePlan && this.dischargePlan.receiptPlans;
    },
    products (){
      return PRODUCTS;
    },
    receivingCompanies (){
      return RECEIVING_COMPANIES;
    },
    shipOrShoreOptions (){
      return SHIP_OR_SHORE_OPTIONS;
    },
    methodOptions (){
      return METHOD_OPTIONS;
    },
    productsMin() {
      return PRODUCTS_MIN;
    },
    dischargeSequence() {
      return this.columnIndex + 1;
    },
    highlightColor() {
      return HIGHLIGHT_COLOR;
    },
    productGrade: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.productGrade;
      },
      set (value){
        this.cargoPlanProduct.dischargeQuantity = 0;
        this.cargoPlanProduct.productGrade = value;
      }
    },
    receivingCompany: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.receivingCompany;
      },
      set (value){
        this.cargoPlanProduct.receivingCompany = value;
      }
    },
    interfaceShoreTankNumber: {
      get () {
        return this.cargoPlanProduct && this.cargoPlanProduct.interfaceTankNumber;
      },
      set(value) {
        this.cargoPlanProduct.interfaceTankNumber = value;
      }      
    },
    dischargeQuantity: {
      get(){
        if (this.cargoPlanProduct.dischargeQuantity == 0) {
          this.cargoPlanProduct.dischargeQuantity = this.getDischargeQuantityFromReceiptPlanByProduct();
        }
        return this.cargoPlanProduct.dischargeQuantity;
      },
      set(value){
        if (this.productGrade != null && this.cargoPlanProduct.dischargeQuantity != value) {
          this.$emit('changeDischarge');
          this.cargoPlanProduct.dischargeQuantity = value;
          return;
        }
      }      
    },
    pumpingPressure: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.pumpingPressure;
      },
      set (value){
        this.cargoPlanProduct.pumpingPressure = value;
      }
    },
    slowPumpingPressure: {
      get() { 
        return this.cargoPlanProduct && this.cargoPlanProduct.slowPumpingPressure;
      },
      set(value) {
        this.cargoPlanProduct.slowPumpingPressure = value;
      }
    },
    maxReceivingRate: {
      get() {        
        return this.cargoPlanProduct && this.cargoPlanProduct.maxReceivingRate; 
      },
      set(value) {
        this.cargoPlanProduct.maxReceivingRate = value;
      }
    },
    shorePipelineDiameter: {
      get() {
        return this.cargoPlanProduct && this.cargoPlanProduct.shorePipelineDiameter;
      },
      set(value){
        this.cargoPlanProduct.shorePipelineDiameter = value;
      }
    },
    shorePipelineLength: {
      get() {
        return this.cargoPlanProduct && this.cargoPlanProduct.shorePipelineLength;
      },
      set(value){
        this.cargoPlanProduct.shorePipelineLength = value;
      }
    },
    shipTankNumber: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.shipTankNumber;
      },
      set (value){
        this.cargoPlanProduct.shipTankNumber = value;
      }
    },
    hoseConnectionNumberColour: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.hoseConnectionNumberColour;
      },
      set (value){
        this.cargoPlanProduct.hoseConnectionNumberColour = value;
      }
    },
    shoreHoseAndFlange: {
      get(){
        return '200mm (8")'
      }
    },
    shoreTankNumber: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.shoreTankNumber;
      },
      set (value){
        this.cargoPlanProduct.shoreTankNumber = value;
      }
    },
    shipOrShore: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.shipOrShore;
      },
      set (value){
        this.cargoPlanProduct.shipOrShore = value;
      }
    },
    method: {
      get(){
        return this.cargoPlanProduct && this.cargoPlanProduct.method;
      },
      set (value){
        this.cargoPlanProduct.method = value;
      }
    },    
    pumpingPressureErrors() {
      let errors = [];

      if (!this.$v.pumpingPressure.$dirty || this.$v.pumpingPressure.$pending) {
        return [];
      }

      if (!this.$v.pumpingPressure.between) {
        errors = [...errors, 'Pumping Pressure must be between 0 and 10'];
      }
      return errors;
    },
    slowPumpingPressureErrors() {
      let errors = [];

      if (!this.$v.slowPumpingPressure.$dirty || this.$v.slowPumpingPressure.$pending) {
        return [];
      }

      if (!this.$v.slowPumpingPressure.between) {
        errors = [...errors, 'Slow Pumping Pressure must be between 0 and 10'];
      }
      return errors;
    },
    hoseConnectionErrors() {
      let errors = [];

      if (!this.$v.hoseConnectionNumberColour.$dirty || this.$v.hoseConnectionNumberColour.$pending) {
        return [];
      }

      if (!this.$v.hoseConnectionNumberColour.maxLength) {
        errors = [...errors, 'Hose Connection exceeds 20 characters'];
      }
      return errors;
    },
    shipTankNumberErrors() {
      let errors = [];

      if (!this.$v.shipTankNumber.$dirty || this.$v.shipTankNumber.$pending) {
        return [];
      }

      if (!this.$v.shipTankNumber.maxLength) {
        errors = [...errors, 'Ship Tank Number exceeds 20 characters'];
      }
      return errors;
    },
    shoreTankNumberErrors() {
      let errors = [];

      if (!this.$v.shoreTankNumber.$dirty || this.$v.shoreTankNumber.$pending) {
        return [];
      }

      if (!this.$v.shoreTankNumber.maxLength) {
        errors = [...errors, 'Shore Tank Number exceeds 20 characters'];
      }

      return errors;
    },
    shorePipelineDiameterErrors() {
      let errors = [];

      if (!this.$v.shorePipelineDiameter.$dirty || this.$v.shorePipelineDiameter.$pending) {
        return [];
      }

      if (!this.$v.shorePipelineDiameter.between) {
        errors = [...errors, 'Shore Pipeline Diameter must be between 0mm and 350mm'];
      }

      return errors;
    },
    shorePipelineLengthErrors() {
      let errors = [];

      if (!this.$v.shorePipelineLength.$dirty || this.$v.shorePipelineLength.$pending) {
        return [];
      }

      if (!this.$v.shorePipelineLength.between) {
        errors = [...errors, 'Shore Pipeline Length must be between 0m and 3500m'];
      }

      return errors;
    },
    interfaceShoreTankNumberErrors() {
      let errors = [];

      if (!this.$v.interfaceShoreTankNumber.$dirty || this.$v.interfaceShoreTankNumber.$pending) {
        return [];
      }

      if (!this.$v.interfaceShoreTankNumber.maxLength) {
        errors = [...errors, 'Interface Shore Tank Number exceeds 20 characters'];
      }

      return errors;
    },
    dischargeQuantityErrors() {

      if(!this.$v.dischargeQuantity.between) {
        return ['Discharge Quantity must be greater than 0 and less than 2,000,000']
      }
      
      if(this.displayDischargeChangeWarning){
        return ['A discharge quantity has been altered'];
      }        

      return [];
    },
    maxReceivingRateErrors() {
      let errors = [];
      if(!this.$v.maxReceivingRate.$dirty || this.$v.maxReceivingRate.$pending) {
        return errors;
      }

      if(!this.$v.maxReceivingRate.between) {
        return ['Max Receiving Rate must be greater than 0 and less than 2,000']
      }
      return errors;
    },
    productColor: {
      get() {
        return this.getProductColor(this.productGrade);
      }
    }
  },
  methods: {
    getProductColor(product) {
      if (product == 'AGO') {
        return AGO_COLOR;
      }
      else if (product == 'JETA1') {
        return JETA1_COLOR;
      }
      else if (product == 'PMS') {
        return PMS_COLOR;
      }
      else if (product == 'RMS') {
        return RMS_COLOR;
      }
    },
    passEventToParent() {
      if (this.cargoPlanProduct.id) {
        this.$emit('deleteProduct', { productId: this.cargoPlanProduct.id, dischargeSequence: this.dischargeSequence });
      }
      else {
        this.$emit('deleteUnsavedProduct', this.dischargeSequence);
      }
    },
    getDischargeQuantityFromReceiptPlanByProduct() {
      let receiptPlans = this.receiptPlans;
      for (var receiptPlan in receiptPlans) {
        if (this.productGrade != null && receiptPlans[receiptPlan].product.toLowerCase() == this.productGrade.toLowerCase()) {
          return Math.trunc(receiptPlans[receiptPlan].amountInKiloLitres.toFixed() / 1000);
        }
      }
    }
  },
  validations () {
    return {
      pumpingPressure: {
        between: between(0,10)
      },
      slowPumpingPressure: {
        between: between(0, 10)
      },
      hoseConnectionNumberColour: {
        maxLength: maxLength(20)
      },
      shipTankNumber: {
        maxLength: maxLength(20)
      },
      shoreTankNumber: {
        maxLength: maxLength(20)
      },
      shorePipelineDiameter: {
        between: between(0, 350),
      },
      shorePipelineLength: {
        between: between(0, 3500),
      },
      interfaceShoreTankNumber: {
        maxLength: maxLength(20)
      },
      dischargeQuantity: {
        between: between(0, 2000000)
      },
      maxReceivingRate: {
        between: between(0, 2000)
      }
    }
  }
}
</script>
