import { actions, AUTH_ACTIONS } from './actions';
import { getters, AUTH_GETTERS } from './getters';
import { mutations, AUTH_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'auth';

export const state = {
  user: undefined,
  roles: undefined,
  isAuthenticated: false,
  issuedRoles: [],
  isAssumingRole: false
};

export const AUTH = {
  ACTIONS: AUTH_ACTIONS,
  GETTERS: AUTH_GETTERS,
  MUTATIONS: AUTH_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const auth = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
