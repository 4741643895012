import { actions, CHECKLIST_ACTIONS } from './actions';
import { getters, CHECKLIST_GETTERS } from './getters';
import { mutations, CHECKLIST_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'checklist';

export const state = {
    checklist: null
};

export const CHECKLIST = {
  ACTIONS: CHECKLIST_ACTIONS,
  GETTERS: CHECKLIST_GETTERS,
  MUTATIONS: CHECKLIST_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const checklist = {
  namespaced,
  state,
  mutations,
  getters,
  actions
};