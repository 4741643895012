import Vue from 'vue';
import Router from 'vue-router';
import { routes, ROUTE_NAMES } from '@/routes';
import store from '@/store';
import { UI } from '@/store/ui';
import { AUTH } from '@/store/auth';
import { isInSomeRole } from '@/helpers/auth';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true);
  next();
});

router.afterEach(() => {
  store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false);
});

router.beforeResolve((to, from, next) => {
  // if anonymous access is enabled
  if (to.meta.requiresAuthentication === false) {
    next();
    return;
  }

  // authenticate the user
  store
    .dispatch(AUTH.NS(AUTH.ACTIONS.AUTHENTICATE))
    .then(() => {
      // check if the user is authenticated
      if (store.state.auth.isAuthenticated) {
        if (to.meta.requireRole) {
          // if the user does not have any of the roles
          if (!isInSomeRole(to.meta.requireRole)) {
            next({ name: ROUTE_NAMES.ACCESS_DENIED });
            return;
          }
        }

        // no problems, move on
        next();
      }
    })
    .catch(() => {
      // user needs to login - do not call next();
      store.dispatch(AUTH.NS(AUTH.ACTIONS.LOGIN));
    });
});

export default router;