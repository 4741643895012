import Vue from 'vue';
import { cloneDeep } from '@/helpers/object';

export const QUALITY_REPORT_MUTATIONS = {
  SET_QUALITY_REPORT: 'setQualityReport'
}

export const mutations = {
  [QUALITY_REPORT_MUTATIONS.SET_QUALITY_REPORT](state, { qualityReport }) {
    Vue.set(state, 'qualityReport', cloneDeep(qualityReport));
  }
}