<template>
  <v-btn 
    :class="['btn']"
    :color="color"
    :disabled="disabled || loading"
    :outlined="outline"
    :to="to"
    :type="type"
    @click="$emit('click')"
    :name="name"
    :small="small">
      <slot v-if="!loading"/>
      <loading-dots v-if="loading"/>
  </v-btn>
</template>

<script>
import LoadingDots from "@/components/LoadingDots.vue";

export default {
  components: {
    LoadingDots
  },
  props: {
    color: {
      type: String,
      default: "orange lighten-1",
      validator: value => {
        return ["secondary lighten-3", "orange lighten-1", "error"].includes(value);
      }
    },
    type: {
      type: String,
      default: "button"
    },
    outline: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_responsive.scss";
@import "../assets/styles/_variables.scss";

.btn {
  margin: 0;
  min-height: 43px;
  min-width: 100px;
  width: 100%;
  max-width: 100%;
  padding: 0 20px;
  font-size: 16px;
  font-weight: $weight-bold;
  text-transform: none;

  .md{min-height: 46px; min-width: 170px; width: auto; padding: 0 20px; font-size: 18px;};

  &:before {
    opacity: 0;
  }
  &:hover {
    text-decoration: none;
  }
  &.v-btn--outline {
    border-width: 2px;
  }
  &--white {
    background-color: $white !important;
    // Color based on background color of page. Not always orange. Needs to inherit color
    color: $charcoal;
    &:hover {
      background-color: $white !important;
    }
    &:active {
      background-color: $white !important;
    }
    &:focus {
      background-color: $white !important;
      box-shadow: 0 0 4px 0 #4a90e2;
    }
    &.v-btn--outline {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: transparent !important;
        color: rgba(0, 0, 0, 0.6);
        border-color: rgba(0, 0, 0, 0.6);
      }
      &:active {
        color: $charcoal;
      }
    }
  }
  &.v-btn--disabled.v-btn:not(.v-btn--icon) {
    background-color: #e4e4e4 !important;
    color: $white !important;
    &.v-btn--outline {
      background-color: transparent !important;
      border-color: $light-charcoal !important;
      color: $mid-charcoal !important;
    }
  }
}
</style>
