<template>
  <v-navigation-drawer v-if="activeTerminal && !(operationValue === null)" class="navigation-drawer menu-font scoll-bar" v-model="drawerState"
      :mini-variant.sync="miniState" mini-variant-width="70" clipped app fixed dark width="280">
      <v-container top offset-y v-if="!miniState">
        <v-select background-color="rgba(255, 255, 255, 0.2)" item-color="orange lighten-1"
                  open-on-hover :items="operations" style="font-size: 16px;"
                  v-on:change="directToPage" v-model="operationSelect" :menu-props="styles" auto solo color="orange lighten-1" />
      </v-container>

      <v-divider></v-divider>

      <div v-if="operationValue == 0">
        <menu-discharge />
      </div>
      <div v-else-if="operationValue == 1">
        <menu-tank-to-tank />
      </div>
      <div v-else-if="operationValue == 3">
        <menu-terminal-data />
      </div>

      <v-spacer />
      <template v-slot:append>
        <v-layout align-end justify-end class="navigation-drawer">
          <v-spacer />
          <v-btn v-if="miniState" icon fab plain @click.stop="miniState = !miniState">
            <v-icon id='chevron_right'>chevron_right</v-icon>
          </v-btn>
          <v-btn v-if="!miniState" icon fab plain @click.stop="miniState = !miniState">
            <v-icon id='chevron_left'>chevron_left</v-icon>
          </v-btn>
        </v-layout>
      </template>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { ROUTE_NAMES } from '@/routes';
import { UI } from '@/store/ui';
import { BASE_URL } from '@/helpers/config';

export default Vue.extend({
  mounted() {
    this.drawerState = true;
    this.miniState = true;
  },
  data: () => ({
    publicPath: BASE_URL,
    activeOperation: null,
    styles: {
        bottom: true,
        offsetY: true,
        'open-on-hover': true,
        'open-delay': 1,
        rounded: "rounded-lg"
    },
    operations: [
      { value: 0, text: 'Tanker Receipt', name: `${ROUTE_NAMES.RECEIPT_PLAN_LIST}` },
      { value: 1, text: 'Tank to Tank Transfer', name: `${ROUTE_NAMES.TANK_TO_TANK_RECEIPT_PLAN_LIST}` },
      { value: 2, text: 'Levels of Concern', name: `${ROUTE_NAMES.LEVELS_OF_CONCERN}` },
      { value: 3, text: 'Terminal Data', name: `${ROUTE_NAMES.TERMINAL_DATA_TERMINAL_INFORMATION}` }
    ]
  }),
  watch: {
   $route (){
      this.SetOperationValueFromPath();
    }
  },
  methods: {
    directToPage() {
        if (this.operationValue === null) return;
        this.activeOperation = this.operations[this.operationValue];
        this.$router.push({
            name: this.operations[this.operationValue].name
        });
    },
    SetOperationValueFromPath(){
      for(let i = 0;i < this.$router.currentRoute.matched.length;i++){
        const route = this.$router.currentRoute.matched[i];
        for(let j = 0;j < this.operations.length;j++){
          if(route.meta.title == this.operations[j].text){
            this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_OPERATION_ID), j);
            return;
          }
        }
      }
    }
  },
  computed: {
    drawerState: {
        get() {
            return this.$store.getters[UI.NS(UI.GETTERS.OPEN_DRAWER)]
        },
        set(val) {
            this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_OPEN_DRAWER), val);
        }
    },
    miniState: {
        get() {
            return this.$store.getters[UI.NS(UI.GETTERS.EXPAND_MINI)]
        },
        set(val) {
            this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_EXPAND_MINI), val);
        }
    },
    operationSelect: {
      get() {
        return this.operations[this.operationValue];
      },
      set(operationId) {
        this.$store.dispatch(UI.NS(UI.ACTIONS.TOGGLE_OPERATION_ID), operationId);
      }
    },
    routeParentName() {
      return this.$route.meta.parentName;
    },
    ...mapState({
      activeTerminal: state => state.terminals && state.terminals.activeTerminal,
      isLoading: state => state.ui.isLoading,
      drawer: state => state.ui.drawer,
      mini: state => state.ui.mini,
      featureState: state => state.features.featureState
    }),
    ...mapGetters({
      operationValue: UI.NS(UI.GETTERS.OPERATION_ID)
    })
  }
});
</script>

<style lang="scss">
@import '../../assets/styles/_responsive.scss';
@import '../../assets/styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
$error-color: #ff2424;

.v-navigation-drawer__content {
  height: 100%;
  background-color: #242D40;
}

.navigation-drawer {
  background-color: #242D40;
}

.menu-font{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  .menu-header-item{
    font-weight: bolder;
  }
  .menu-item{
    font-weight: lighter;
  }
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px;
}

.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding-left: 52px; 
}

.scoll-bar{
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242D40;
  }
  ::-webkit-scrollbar-thumb {
    background: #acabaa; 
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #929190; 
  }
  *:hover::-webkit-scrollbar {
    width: 14px;
  }
}
</style>