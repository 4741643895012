<template>
  <div v-if="isEnabled">
    <v-chip v-if="isAssumingRole">{{assumedRole}}</v-chip>
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" dark left icon>
          <v-icon>account_circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(issuedRole) in issuedRoles" :key="issuedRole" @click="assumeRole(issuedRole)">
            <v-list-item-title>{{ issuedRole }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="resetRoles">
          <v-list-item-title>Reset Roles</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import Vue from "vue";
import { AUTH } from "@/store/auth";
import { mapGetters, mapState, mapActions } from "vuex";
import { ASSUME_ROLE_ENABLED } from "@/helpers/config";

export default Vue.extend({
  methods: {
    ...mapActions({
      assumeRole: AUTH.NS(AUTH.ACTIONS.ASSUME_ROLE),
      resetRoles: AUTH.NS(AUTH.ACTIONS.RESET_ROLES)
    })
  },
  computed: {
    isEnabled() {
      return ASSUME_ROLE_ENABLED;
    },
    ...mapGetters({
      assumedRole: AUTH.NS(AUTH.GETTERS.GET_ASSUMED_ROLE)
    }),
    ...mapState({
      issuedRoles: (state) => state.auth.issuedRoles,
      isAssumingRole: (state) => state.auth.isAssumingRole
    })
  }
});
</script>
