<template>
  <v-container fluid fill-height class="page">
    <v-layout row wrap pa-4>
      <v-flex xs12>
        <h2 style="color: red;">PLEASE NOTE: Document Link feature is to be further developed :)</h2>
        <v-container fluid>
          <v-card class="elevation-1 url-card">
            <v-row no-gutters>
              <v-col cols="3">
                <div>
                  <v-img class="document-img" src="@/assets/icons/folder.png" />
                  <h3 class="pa-2 ma-2 document-repository-url-header">Document Link</h3>
                </div>
              </v-col>
              <v-col v-if="isEditingURL" cols="7">
                <v-text-field v-model="linkInput.documentRepositoryLink" class="pa-2 ma-2 url-edit-box" solo clearable
                  label="Enter Link" id="url-edit-textfield" :error-messages="documentRepositoryLinkErrors"
                  @input="$v.documentRepositoryLink.$touch()" />
              </v-col>
              <v-col v-if="!isEditingURL" cols="8">
                <a class="pa-2 ma-2 link-btn" id="to-repo-btn" solo :href="linkInput.documentRepositoryLink"
                  target="_blank"> {{ linkInput.documentRepositoryLink ? 'Go to Documents' : defaultLink }}</a>
              </v-col>
              <v-col v-if="!isEditingURL" cols="1">
                <v-btn class="pa-2 ma-2 edit-btn" id="url-edit-btn" @click="editURL()" color="orange">
                  edit
                </v-btn>
              </v-col>
              <v-col v-if="isEditingURL" cols="2">
                <v-btn class="pa-2 ma-2 cancel-btn" id="url-cancel-btn" @click="onCancelEditURL()" color="red">
                  cancel
                </v-btn>
                <v-btn v-if="saveIsHidden" class="disable-url-save-btn pa-2 ma-2" id="url-save-btn" color="#808080">
                  save
                </v-btn>
                <v-btn v-if="!saveIsHidden" class="save-btn pa-2 ma-2" id="url-save-btn" @click="onSaveURL()"
                  color="success">
                  save
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <TerminalInformationTable v-for="tank in sortedTerminalInformationList" v-bind:key="tank.id"
          :id="'tank' + tank.name + 'info-table'" :terminalInformation="tank" />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import TerminalInformationTable from './TerminalInformationTable';
import { TERMINAL_INFORMATION, GET_DEFAULT_DOCUMENT_REPOSITORY_LINK } from '@/store/terminalData/terminalInformation';
import { TERMINALS } from '@/store/terminals'
import { PRODUCT_SORT_ORDER } from '@/constants/products';
import { mapState } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  components: {
    TerminalInformationTable
  },
  async mounted() {
    await this.fetchData();
  },
  data: () => ({
    isEditingURL: false,
    linkInput: GET_DEFAULT_DOCUMENT_REPOSITORY_LINK(),
    defaultLink: 'https://', // if there's no link given
    terminalId: null
  }),
  computed: {
    ...mapState({
      tanksList: state => state.terminalInformation.tanksList,
      terminalsList: state => state.terminals.terminalsList,
      terminalsByKey: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.reduce((terminals, terminal) => {
          terminals[terminal.id] = { ...terminal };
          return terminals;
        }, {}),
      activeTerminal: state => state.terminals.activeTerminal
    }),
    sortedTerminalInformationList() {
      if (this.activeTerminal && this.tanksList) {
        return [...this.tanksList].sort((a, b) => {
          return PRODUCT_SORT_ORDER.indexOf(a.tankTechnicalDetails.product) - PRODUCT_SORT_ORDER.indexOf(b.tankTechnicalDetails.product);
        });
      }
      return null;
    },
    documentRepositoryLink: {
      get() {
        return this.linkInput.documentRepositoryLink;
      },
      set(value) {
        this.linkInput.documentRepositoryLink = value;
      }
    },
    documentRepositoryLinkErrors() {
      if (!this.$v.documentRepositoryLink.$dirty || this.$v.documentRepositoryLink.$pending) {
        return [];
      }
      if (!this.$v.documentRepositoryLink.required) {
        return 'Link is required';
      }
      if (!this.$v.documentRepositoryLink.maxLength) {
        return 'Link exceeds 600 characters.';
      };
      if (!this.$v.documentRepositoryLink.validLink) {
        return 'Invalid Link. Ensure that "https://" is included and no spaces are present.';
      };;
      return null;
    },
    saveIsHidden() {
      return !this.$v.documentRepositoryLink.validLink
        || !this.$v.documentRepositoryLink.required
        || !this.$v.documentRepositoryLink.maxLength;
    }
  },
  methods: {
    async fetchData() {
      const terminalId = this.$route.params && this.$route.params.terminalId;
      this.terminalId = terminalId;
      if (!this.terminalsList) {
        await this.$store.dispatch(
          TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS)
        );
      }
      const terminal = this.terminalsByKey[terminalId];
      this.$store.dispatch(
        TERMINAL_INFORMATION.NS(TERMINAL_INFORMATION.ACTIONS.GET_TERMINAL_INFORMATION_BY_TERMINAL),
        terminalId
      );
      if (this.$route.params.terminalId) {
        this.$store.commit(
          TERMINALS.NS(TERMINALS.MUTATIONS.SET_ACTIVE_TERMINAL),
          terminal
        );
      }
    },
    editURL() {
      this.isEditingURL = true;
    },
    async onSaveURL() {
      this.isLoading = true;
      this.onCancelEditURL();
      this.isLoading = false;
    },
    onCancelEditURL() {
      this.isEditingURL = false;
    },
    checkvalidLinkString(value) {
      if (value === null || value.includes(' ') || !value.includes('https://')) {
        return false;
      }
      else {
        return true;
      }
    }
  },
  validations: {
    documentRepositoryLink: {
      required,
      maxLength: maxLength(600),
      validLink: function (value) {
        return this.checkvalidLinkString(value);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  background-color: rgb(242, 242, 242);
}

.tdp-bar-color {
  color: rgba(30, 25, 106, 255);
}

.link-btn {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  color: orange;
  background-color: rgb(52, 68, 101);
  border-radius: 6px;
  outline: none;
  transition: 0.3s;
  border: 2px solid transparent;
  position: relative;
  top: 17px;
  left: 7px;
  height: 50px;
  width: 97%;
}

.link-btn:hover {
  color: #242D40;
  background-color: orange;
}

.cancel-btn {
  font-size: 13px;
  text-decoration: none;
  position: relative;
  top: 24px;
  left: 9px;
  outline: none;
  transition: 0.3s;
  border: 2px solid transparent;
}

.edit-btn {
  font-size: 13px;
  text-decoration: none;
  position: relative;
  top: 24px;
  left: 9px;
  outline: none;
  transition: 0.3s;
  border: 2px solid transparent;
  color: #242D40;
}

.save-btn {
  font-size: 13px;
  text-decoration: none;
  position: relative;
  top: 24px;
  left: 9px;
  outline: none;
  transition: 0.3s;
  border: 2px solid transparent;
  color: white;
}

.cancel-btn:hover {
  color: white;
}

.edit-btn:hover {
  color: rgb(167, 61, 22);
}

.save-btn:hover {
  color: rgb(13, 90, 13);
}

.document-img {
  float: left;
  position: relative;
  left: 50px;
  top: 25px;
  width: 30px;
  height: 30px;
}

.document-repository-url-header {
  position: relative;
  top: 10px;
  left: 75px;
  line-height: 50px;
  color: white;
}

.url-card {
  background-color: #242D40;
  height: 100px;
}

.url-edit-box {
  size: 80%;
  position: relative;
  top: 10px;
}

.disable-url-save-btn {
  pointer-events: none;
  color: #A9A9A9;
  font-size: 13px;
  text-decoration: none;
  position: relative;
  top: 24px;
  left: 9px;
  outline: none;
  transition: 0.3s;
  border: 2px solid transparent;
}

@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
</style>