export const AUTH_MUTATIONS = {
  SET_USER: 'setUser',
  SET_ROLES: 'setRoles',
  SET_ISSUED_ROLES: 'setIssuedRoles',
  SET_IS_AUTHENTICATED: 'setIsAuthenticated',
  SET_IS_ASSUMING_ROLE: 'setIsAssumingRole'
};

export const mutations = {
  [AUTH_MUTATIONS.SET_USER](state, user) {
    state.user = user;
  },
  [AUTH_MUTATIONS.SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [AUTH_MUTATIONS.SET_ISSUED_ROLES](state, roles) {
    state.issuedRoles = roles;
  },
  [AUTH_MUTATIONS.SET_IS_AUTHENTICATED](state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  [AUTH_MUTATIONS.SET_IS_ASSUMING_ROLE](state, isAssumingRole) {
    state.isAssumingRole = isAssumingRole;
  }
};
