import { TERMINAL_INFORMATION_MUTATIONS } from './mutations';
import api from './api';
import { UI } from '@/store/ui';

export const TERMINAL_INFORMATION_ACTIONS = {
    GET_TERMINAL_INFORMATION_BY_TERMINAL: 'getTerminalInformationByTerminal',
    UPDATE_TERMINAL_INFORMATION_DETAILS: 'updateTerminalInformationDetails'
};

export const ERROR_GET_TERMINAL_INFORMATION_BY_TERMINAL =
    'There was a problem trying to load the terminal information';

export const ERROR_UPDATE_TERMINAL_INFORMATION =
    'There was a problem trying to save the terminal information';

export const actions = {
    async [TERMINAL_INFORMATION_ACTIONS.GET_TERMINAL_INFORMATION_BY_TERMINAL]({ commit, dispatch }, terminalId) {
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), true, {
            root: true
        });
        try {
            let response = await api.getTerminalInformationByTerminal(terminalId);
            commit(TERMINAL_INFORMATION_MUTATIONS.SET_TERMINAL_INFORMATION_BY_TERMINAL, response.data);
        }
        catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'failed-load-terminal-information',
                    text: ERROR_GET_TERMINAL_INFORMATION_BY_TERMINAL,
                    error
                },
                { root: true }
            );
        }
        dispatch(UI.NS(UI.ACTIONS.TOGGLE_IS_LOADING), false, {
            root: true
        });
    },
    async [TERMINAL_INFORMATION_ACTIONS.UPDATE_TERMINAL_INFORMATION_DETAILS]({ dispatch }, terminalInformationDetails) {
        let success = false;
        try {
            await api.updateTerminalInformationDetails(terminalInformationDetails.id, terminalInformationDetails.terminalInformationDetails);
            dispatch(UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'success-update-terminal-information',
                    text: 'Successfully updated terminal information details'
                },
                { root: true }
            );
            success = true;
        } catch (error) {
            dispatch(
                UI.NS(UI.ACTIONS.ADD_TOAST),
                {
                    id: 'error-update-terminal-information',
                    text: 'Updating terminal information details failed',
                    error: true
                },
                { root: true }
            );
        }
        return success;
    }
}