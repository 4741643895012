export function calculateShipDensity(coqList, product) {
  // ship density is from the CoQs in the vessel info page
  // we average the densities (with percentage) if there is more than 1
  if (coqList) {
    let productCoQs = coqList.filter(x => x.product === product);
    if (productCoQs !== undefined && productCoQs.length > 0) {
      const totalWeight = productCoQs.reduce(function (sum, coq) {
        return sum + (coq.weight ? coq.weight : 1);
      }, 0);
      return Number((productCoQs.reduce(function (sum, coq) {
        return sum + (coq.density * (coq.weight ? coq.weight : 1));
      }, 0) / totalWeight).toFixed(4));
    }
  } 
}

export function calculateShipFlashPoint(coqList, product) {
  // ship flash point is from the CoQs in the vessel info page
  // we average the flash points (with percentage) if there is more than 1
  if (coqList) {
    let productCoQs = coqList.filter(x => x.product === product);
    if (productCoQs !== undefined && productCoQs.length > 0) {
      const totalWeight = productCoQs.reduce(function (sum, coq) {
        return sum + (coq.weight ? coq.weight : 1);
      }, 0);
      return Number((productCoQs.reduce(function (sum, coq) {
        return sum + (coq.flashPoint * (coq.weight ? coq.weight : 1));
      }, 0) / totalWeight).toFixed(1));
    }
  } 
}

export function percentageOfCargo(coqList, item) {
  if (coqList) {
    let productCoQs = coqList.filter(x => x.product === item.product);
    if (productCoQs !== undefined && productCoQs.length > 0) {
      return (item.weight ? item.weight : 1) /
        productCoQs.reduce(function (sum, coq) {
          return sum + (coq.weight === 0 ? 1 : coq.weight);
        }, 0);
    }
    return 1;
  }
}

export function calculatePercentageOfCargo(coqList, item) {
  if (coqList) {
    const editId = ('id' in item) ? item.id : 0;
    let productCoQs = coqList.filter(x => x.product === item.product && x.id !== editId);
    if (productCoQs !== undefined && productCoQs.length > 0) {
      var itemWeight = (item.weight ? item.weight : 1);
      return itemWeight /
        (productCoQs.reduce(function (sum, coq) {
          return sum + (coq.weight === 0 ? 1 : coq.weight);
        }, 0) + itemWeight);
    }
    return 1;
  }
}
