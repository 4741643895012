export const PRODUCT_QUALITY_COLOUR_OPTIONS = [
    { text: 'Purple', value: 'Purple' },
    { text: 'Red', value: 'Red' },
    { text: 'Yellow', value: 'Yellow' },
    { text: 'Pale Straw', value: 'PaleStraw' },
    { text: 'Straw', value: 'Straw' },
    { text: 'Water White', value: 'WaterWhite' },
    { text: 'Colourless', value: 'Colourless' },
    { text: 'Pink', value: 'Pink' },
    { text: 'Black', value: 'Black' },
    { text: 'Orange', value: 'Orange' },
    { text: 'Other', value: 'Other' },
];