import { http } from '@/api';

export default {
  createLineSample: (planId, lineSample) => http.post(
    `/v1/plans/${planId}/linesamples`, {
      model: lineSample
    }
  ),
  updateLineSample: (planId, lineSample) => http.put(
    `/v1/plans/${planId}/linesamples`, {
      model: lineSample
    }
  ),
  getLineSampleList: (planId, product) => http.get(
    `/v1/plans/${planId}/linesamples`, {
      params: {
        product: product,
        sortDirection: 'Descending'
      }
    }
  ),
  deleteLineSample: (planId, lineSampleId) => http.delete(
    `/v1/plans/${planId}/linesamples/${lineSampleId}`
  )
}