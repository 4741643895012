<template>
  <v-container fluid fill-height class="page">
    <v-layout row wrap pa-4>
      <v-flex xs12>
        <v-dialog v-model="showDialog" max-width="710" persistent>
          <v-card>
            <v-responsive>
              <v-card-title class="edit-dialog-header text-h6" style="">Edit Cargo Dock Hose Detail</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="9" sm="6" md="9">
                      <v-text-field v-model="equipmentName" label="Equipment Name" id="equipment-name-dialog"
                        color="orange lighten-1" :error-messages="equipmentNameErrors" @input="$v.equipmentName.$touch()" >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="9" sm="6" md="3">
                      <v-text-field v-model="serialNumber" :autoDecimalMode="false" label="Serial Number"
                        id="serialNumber-dialog" color="orange lighten-1" @input="$v.serialNumber.$touch()" :error-messages="serialNumberErrors">
                      </v-text-field>
                    </v-col>
                    <v-col cols="9" sm="6" md="3">
                      <v-currency-field v-model="diameterMm" :autoDecimalMode="false" label="Diameter (mm)"
                        id="diameterMm-dialog" color="orange lighten-1" @input="$v.diameterMm.$touch()" :error-messages="diameterMmErrors">
                      </v-currency-field>
                    </v-col>
                    <v-col cols="9" sm="6" md="3">
                      <v-text-field v-model="lengthMetres" :autoDecimalMode="false" label="Length (m)"
                        id="lengthMetres-dialog" color="orange lighten-1" @input="$v.lengthMetres.$touch()" :error-messages="lengthMetresErrors">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col id="testCertDate-dialog">
                      <h4 class="dialog-label-headers">Test Cert Date</h4>
                      <v-date-picker v-model="testCertDate" :error-messages="testCertDateErrors" @input="$v.testCertDate.$touch()"/>
                    </v-col>
                    <v-col id="testCertExpiry-dialog">
                      <h4 class="dialog-label-headers">Test Cert Expiry</h4>
                      <v-date-picker v-model="testCertExpiry"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="9" sm="6" md="9">
                      <v-textarea v-model="notes" clearable label="Notes" id="notes-dialog" counter outlined
                        color="orange lighten-1" @input="$v.notes.$touch()" :error-messages="notesErrors"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <h4 style="color: red;" v-if="!this.isValidTestCertDate">{{ this.testCertDateErrors }}</h4>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="secondary lighten-3" class="cancel-btn" variant="text" @click="onClose()" id='cancel-btn' :loading="isLoading">Cancel</v-btn>
                <v-btn :disabled="saveIsDisabled || isLoading" color="success" variant="text" @click="onSave()" id='save-btn'
                  class="save-btn" :loading="isLoading">Save</v-btn>
              </v-card-actions>
            </v-responsive>
          </v-card>
        </v-dialog>

        <v-card>
          <v-toolbar flat height="100px" style="background-color: #242D40;">
            <v-flex xs12>
              <v-img class="hose-img" src="@/assets/icons/hose.png" />
              <h3 style="color: white;" class="toolbar-header-text">Cargo Dock Hoses</h3>
            </v-flex>
          </v-toolbar>
          <v-data-table :items="cargoDockHoses" :headers="headers" hide-default-footer class="elevation-1"
            id="cargo-dock-hose-table" fixed-header :loading="isLoading">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-equipmentName'">{{ item.equipmentName }}</td>
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-serialNumber'">{{ item.serialNumber }}</td>
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-testCertDate'">{{ item.testCertDate | formatDateTime(dayFirstDateFormat) }}</td>
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-testCertExpiry'">{{ item.testCertExpiry | formatDateTime(dayFirstDateFormat) }}</td>
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-diameterMm'">{{ item.diameterMm }}</td>
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-lengthMetres'">{{ item.lengthMetres }}</td>
                  <v-menu v-if="item.notes !== null" offset-x :nudge-width="100" open-on-hover open-on-click
                    max-height="100" max-width="160">
                    <template v-slot:activator="{ on, attrs }">
                      <td :key="index" v-bind="attrs" v-on="on" :class="'truncate-text'"
                        :id="removeSpecialCharsFromString(item.equipmentName) + '-notes'">{{ item.notes }}</td>
                    </template>
                    <v-card :class="'comment-box'" elevation="2" hover outlined rounded>
                      <v-card-subtitle>
                        <div class="font-weight-light font-italic">{{ item.notes }}</div>
                      </v-card-subtitle>
                    </v-card>
                  </v-menu>
                  <td v-else :id="removeSpecialCharsFromString(item.equipmentName) + '-notes'">{{ item.notes }}</td>
                  <td :id="removeSpecialCharsFromString(item.equipmentName) + '-documents'">{{ item.documents }}</td>
                  <td>
                    <v-flex>
                      <v-icon v-if="hasEditRole" size="small" :id="removeSpecialCharsFromString(item.equipmentName) + '-edit-btn'" class="me-2 tdp-bar-color"
                        @click="onEditCargoDockHoseDetails(index)">edit</v-icon>
                    </v-flex>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { CARGO_DOCK_HOSE_INFORMATION, GET_DEFAULT_CARGO_DOCK_HOSE_DETAILS } from '@/store/terminalData/cargoDockHoseInformation';
import { TERMINALS } from '@/store/terminals';
import { mapState } from 'vuex';
import { cloneDeep } from '@/helpers/object';
import { DATE_PICKER_DATE_FORMAT, DATE_FORMAT } from '@/constants/dateFormat';
import { maskedDate } from '@/validators';
import { required, integer, maxLength } from 'vuelidate/lib/validators';
import { formatDateTimeToMasked } from '@/helpers/date';
import { isInSomeRole } from '@/helpers/auth'
import { ADMIN, ENGINEER } from '@/constants/roles'
import { removeSpecialCharsFromString } from '@/helpers/removeSpecialChars';

export default {
  async created() {
    await this.fetchData();
  },

  data: () => ({
    isLoading: false,
    showDialog: false,
    showDeleteConfirmationDialog: false,
    rowInput: GET_DEFAULT_CARGO_DOCK_HOSE_DETAILS(),
    dayFirstDateFormat: DATE_FORMAT,
    removeSpecialCharsFromString,
    isValidTestCertDate: false,
    headers: [
      { text: 'Equipment Name', value: 'equipmentName', sortable: false },
      { text: 'Serial Number', value: 'serialNumber', sortable: false },
      { text: 'Test/Cert Date', value: 'testCertDate', sortable: false },
      { text: 'Test/Cert Expiry', value: 'testCertExpiry', sortable: false },
      { text: 'Diameter (mm)', value: 'diameterMm', sortable: false },
      { text: 'Length (m)', value: 'lengthMetres', sortable: false },
      { text: 'Notes', value: 'notes', sortable: false },
      { text: 'Documents', value: 'documents', sortable: false },
      { text: '', value: 'actions', sortable: false }
    ]
  }),

  computed: {
    ...mapState({
      cargoDockHoses: state => state.cargoDockHoseInformation.cargoDockHoses,
      terminalsList: state => state.terminals.terminalsList,
      terminalsByKey: state => state.terminals.terminalsList &&
        state.terminals.terminalsList.reduce((terminals, terminal) => {
          terminals[terminal.id] = { ...terminal };
          return terminals;
        }, {}),
      activeTerminal: state => state.terminals.activeTerminal
    }),
    hasEditRole() {
      return isInSomeRole([ADMIN, ENGINEER]);
    },
    equipmentNameErrors() {
      if ( !this.$v.equipmentName.$dirty || this.$v.equipmentName.$pending ) {
        return [];
      }
      if ( !this.$v.equipmentName.required ) {
        return 'Equipment name is required';
      }
      return null;
    },
    serialNumberErrors() {
      if ( !this.$v.serialNumber.$dirty || this.$v.serialNumber.$pending ) {
        return [];
      }
      if ( !this.$v.serialNumber.required ) {
        return 'Serial number is required';
      }
      return null;
    },
    diameterMmErrors() {
      if ( !this.$v.diameterMm.$dirty || this.$v.diameterMm.$pending ) {
        return [];
      }
      if ( !this.$v.diameterMm.required || !this.$v.diameterMm.integer ) {
        return 'Diameter is required';
      }
      return null;
    },
    lengthMetresErrors() {
      if ( !this.$v.lengthMetres.$dirty || this.$v.lengthMetres.$pending ) {
        return [];
      }
      if ( !this.$v.lengthMetres.required ) {
        return 'Length is required';
      }
      return null;
    },
    testCertDateErrors() {
      if (this.testCertDate >= this.testCertExpiry) {
        this.isValidTestCertDate = false;
        return 'Invalid Cert Date: Test Cert Date must be before Expiry Date.'
      }
      else {
        this.isValidTestCertDate = true;
        return [];
      }
    },
    notesErrors() {
      if ( !this.$v.notes.$dirty || this.$v.notes.$pending ) {
        return [];
      };
      if ( !this.$v.notes.maxLength ) {
        return 'Note exceeds 250 characters.';
      };
      return null;
    },
    equipmentName: {
      get() {
        return this.rowInput.equipmentName;
      },
      set(value) {
        this.rowInput.equipmentName = value;
      }
    },
    serialNumber: {
      get() {
        return this.rowInput.serialNumber;
      },
      set(value) {
        this.rowInput.serialNumber = value;
      }
    },
    diameterMm: {
      get() {
        return this.rowInput.diameterMm;
      },
      set(value) {
        this.rowInput.diameterMm = value;
      }
    },
    lengthMetres: {
      get() {
        return this.rowInput.lengthMetres;
      },
      set(value) {
        this.rowInput.lengthMetres = value;
      }
    },
    testCertDate: {
      get() {
        return this.rowInput.testCertDate = formatDateTimeToMasked(this.rowInput.testCertDate, DATE_PICKER_DATE_FORMAT);
      },
      set(value) {
        this.rowInput.testCertDate = formatDateTimeToMasked(value, DATE_PICKER_DATE_FORMAT);
      }
    },
    testCertExpiry: {
      get() {
        return this.rowInput.testCertExpiry = formatDateTimeToMasked(this.rowInput.testCertExpiry, DATE_PICKER_DATE_FORMAT);
      },
      set(value) {
        this.rowInput.testCertExpiry = formatDateTimeToMasked(value, DATE_PICKER_DATE_FORMAT);
      }
    },
    notes: {
      get() {
        return this.rowInput.notes;
      },
      set(value) {
        this.rowInput.notes = value;
      }
    },
    saveIsDisabled() {
      return !this.$v.equipmentName.required
        || !this.$v.serialNumber.required
        || !this.$v.diameterMm.required || !this.$v.diameterMm.integer
        || !this.$v.lengthMetres.required
        || !this.$v.testCertDate.required || !this.$v.testCertDate.maskedDate  || !this.$v.testCertDate.isValidDate
        || !this.$v.testCertExpiry.required || !this.$v.testCertExpiry.maskedDate
        || !this.$v.notes.maxLength;
    }
  },

  methods: {
    async fetchData() {
      const terminalId = this.$route.params && this.$route.params.terminalId;
      if (!this.terminalsList) {
        await this.$store.dispatch(
          TERMINALS.NS(TERMINALS.ACTIONS.GET_TERMINALS)
        );
      }
      const terminal = this.terminalsByKey[terminalId];
      this.$store.dispatch(
        CARGO_DOCK_HOSE_INFORMATION.NS(CARGO_DOCK_HOSE_INFORMATION.ACTIONS.GET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL),
        terminalId
      );

      if (this.$route.params.terminalId) {
        this.$store.commit(
          TERMINALS.NS(TERMINALS.MUTATIONS.SET_ACTIVE_TERMINAL),
          terminal
        );
      }
    },
    onEditCargoDockHoseDetails(index) {
      this.rowInput = cloneDeep({
        ...this.cargoDockHoses[index]
      });
      this.showDialog = true;
    },
    async onSave() {
      this.isLoading = true;
      const cargoDockHoseDetails = cloneDeep({ ...this.rowInput });
      const terminalId = this.$route.params && this.$route.params.terminalId;
      let success;
      if (cargoDockHoseDetails.id) {
        success = await this.$store.dispatch(
          CARGO_DOCK_HOSE_INFORMATION.NS(CARGO_DOCK_HOSE_INFORMATION.ACTIONS.UPDATE_CARGO_DOCK_HOSE_DETAILS), {
          id: cargoDockHoseDetails.id,
          cargoDockHoseDetails
        });
      }
      else {
        success = await this.$store.dispatch(
          CARGO_DOCK_HOSE_INFORMATION.NS(CARGO_DOCK_HOSE_INFORMATION.ACTIONS.CREATE_NEW_CARGO_DOCK_HOSE_DETAILS), {
          terminalId: terminalId,
          cargoDockHoseDetails
        });
      }
      if (success) {
        this.$store.dispatch(
          CARGO_DOCK_HOSE_INFORMATION.NS(CARGO_DOCK_HOSE_INFORMATION.ACTIONS.GET_CARGO_DOCK_HOSE_INFORMATION_BY_TERMINAL),
          terminalId
        )
        this.onClose();
      }
      this.onClose();
    },
    onClose() {
      this.rowInput = GET_DEFAULT_CARGO_DOCK_HOSE_DETAILS();
      this.isLoading = false;
      this.showDialog = false;
    }
  },

  validations: {
    equipmentName: { required },
    serialNumber: { required },
    diameterMm: { required, integer },
    lengthMetres: { required },
    testCertDate: { required, maskedDate,
    isValidDate: function (value) {
      return value < this.testCertExpiry;
    }},
    testCertExpiry: { required, maskedDate },
    notes: { maxLength: maxLength(250) }
  }
};
</script>

<style lang="scss" scoped>
.save-btn {
  color: white;
  transition: 0.3s;
}

.cancel-btn {
  color: white;
  transition: 0.3s;
}

.save-btn:hover {
  color: rgb(13, 90, 13);
}

.cancel-btn:hover {
  color: orange;
}

.tdp-bar-color {
  color: rgba(30, 25, 106, 255);
}

.page {
  background-color: rgb(242, 242, 242);
}

.comment-box {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 2px;
}

.truncate-text {
  max-width: 1px;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hose-img {
  float: left;
  position: relative;
  left: 20px;
  width: 60px;
  height: 60px;
}

.toolbar-header-text {
  position: relative;
  left: 40px;
  top: 14px;
}


.edit-dialog-header {
  color: orange;
  font-weight: bold;
}

.dialog-label-headers {
  font-weight: bold;
  color:orange
}

@import '../../../assets/styles/_responsive.scss';
@import '../../../assets/styles/_variables.scss';
</style>