import { http } from '@/api';

export default {
  getTerminals: () => http.get('/v1/terminals'),
  getTankTechnicalDetails : (terminalId) => http.get(
    `/v1/tankTechnicalDetails/${terminalId}`
  ),
  updateTechnicalDetails: ({ tankId, tankTechnicalDetails }) => http.put(
    `v1/tanktechnicaldetails/${tankId}`, {
        TankTechnicalDetail: tankTechnicalDetails
    }
  ),
  updateTankCalibrations: (tankTechnicalDetailId, tankCalibrations) => http.put(
    `/v1/tanktechnicaldetails/calibrations/${tankTechnicalDetailId}`, {
        tankCalibrations
    }
  )
}