import { cloneDeep } from '@/helpers/object';

export const PRE_ARRIVAL_INFO_MUTATIONS = {
  SET_PRE_ARRIVAL_INFO: 'setPreArrivalInfo',
  SET_PRE_ARRIVAL_INFO_INIT_DATA: 'setPreArrivalInfoInitData',
  RESET_PRE_ARRIVAL_INFO: 'resetPreArrivalInfo'
}

export const mutations = {
  [PRE_ARRIVAL_INFO_MUTATIONS.SET_PRE_ARRIVAL_INFO](state, { preArrivalInfo }) {
    state.preArrivalInfo = cloneDeep(preArrivalInfo);
  },
  [PRE_ARRIVAL_INFO_MUTATIONS.SET_PRE_ARRIVAL_INFO_INIT_DATA](state, { preArrivalInfoInitData }) {
    state.preArrivalInfoInitData = cloneDeep(preArrivalInfoInitData);
  },
  [PRE_ARRIVAL_INFO_MUTATIONS.RESET_PRE_ARRIVAL_INFO](state) {
    state.preArrivalInfo = null;
    state.preArrivalInfoInitData = null;
  }
}