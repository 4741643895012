import Vue from 'vue';

export const UI_MUTATIONS = {
  TOGGLE_IS_LOADING: 'toggleIsLoading',
  ADD_TOAST: 'addToast',
  REMOVE_TOAST_BY_ID: 'removeToastById',

  // Side menu props
  TOGGLE_OPEN_DRAWER: 'toggleOpenDrawer',
  TOGGLE_EXPAND_MINI: 'toggleExpandMini',

  // Discharge Menu props
  TOGGLE_OPERATION_ID: 'toggleOperationId',
  RESET_DEFAULT_OPERATION_ID: 'resertDefaultOperationId',
  TOGGLE_LAST_TANK_ID: 'toggleLastTankId',
  TOGGLE_LAST_RECEIPT_PLAN_ID: 'toggleLastReceiptPlanId',
  TOGGLE_LAST_PIPELINE_PRODUCT: 'toggleLastPipelineProduct'
};

export const mutations = {
  [UI_MUTATIONS.TOGGLE_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [UI_MUTATIONS.ADD_TOAST](state, { id, text, type }) {
    state.toasts = [...state.toasts, { id, text, type }];
  },
  [UI_MUTATIONS.REMOVE_TOAST_BY_ID](state, idToRemove) {
    state.toasts = state.toasts.filter(toast => {
      return idToRemove !== toast.id;
    });
  },
  [UI_MUTATIONS.TOGGLE_OPEN_DRAWER](state, drawer) {
    state.drawer = drawer;
  },
  [UI_MUTATIONS.TOGGLE_EXPAND_MINI](state, mini) {
    state.mini = mini;
  },
  [UI_MUTATIONS.TOGGLE_LAST_TANK_ID](state, id) {
    state.lastTankId = id;
  },
  [UI_MUTATIONS.TOGGLE_LAST_RECEIPT_PLAN_ID](state, id) {
    state.lastReceiptPlanId = id;
  },
  [UI_MUTATIONS.TOGGLE_LAST_PIPELINE_PRODUCT](state, product) {
    state.lastPipelineProduct = product;
  },
  [UI_MUTATIONS.TOGGLE_OPERATION_ID](state, id) {
    state.operationId = id;
  },
  [UI_MUTATIONS.RESET_DEFAULT_OPERATION_ID](state) {
    Vue.set(state, 'operationId', null);
  }
};
