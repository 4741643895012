import { actions, TANK_TO_TANK_TRANSFER_PLAN_ACTIONS } from './actions';
import { getters, TANK_TO_TANK_GETTERS } from './getters';
import { mutations, TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'tankToTankTransfer';

export const state = {
  transferPlans: [],
  tanksList: [],
  tankToTankTransferPlan: null
};

export function GET_DEFAULT_TRANSFER_PLAN_DETAILS() {
  return {
    date: null,
    sourceTankTechnicalDetailsId: 0,
    targetTankTechnicalDetailsId: 0,
    sourceTankName: null,
    targetTankName: null,
    comment: null,
    planStatus: 1
  }
};

export function GET_DEFAULT_LAST_RELEASE_DATES() {
  return {
    lastSourceReleaseFormDate: null,
    lastTargetReleaseFormDate: null
  }
}

export function GET_DEFAULT_TANK_PLAN() {
  return {
    tankType: null,
    openingDipMm: 0,
    plannedVolumeLitres: 0,
    estimatedPumpableVolumeMm: 0,
    estimatedPumpableVolumeLitres: 0,
    estimatedFinishDipMm: 0,
    estimatedFinishDipLitres: 0,
    plannedPipelineAdjustmentsLitres: 0,
    plannedTankBottomAdjustmentLitres: 0,
    appearance: 0,
    colour: null,
    density: 0.00,
    flashpoint: 0.00,
    conductivity: 0,
    viscosity: 0.00,
    sulphur: 0.00,
    cloudPoint: 0.00,
    coldFilterPluggingPoint: 0.00,
    pourPoint: 0.00
  }
};

export const TANK_TO_TANK_TRANSFER = {
  ACTIONS: TANK_TO_TANK_TRANSFER_PLAN_ACTIONS,
  GETTERS: TANK_TO_TANK_GETTERS,
  MUTATIONS: TANK_TO_TANK_TRANSFER_PLAN_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const tankToTankTransfer = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};