import { actions, FEATURES_ACTIONS } from './actions';
import { getters, FEATURES_GETTERS } from './getters';
import { mutations, FEATURES_MUTATIONS } from './mutations';

const namespaced = true;
const NAME_SPACE = 'features';

export const state = {
  featureStateList: [],
  configSetting: false
};

export const FEATURES = {
  ACTIONS: FEATURES_ACTIONS,
  GETTERS: FEATURES_GETTERS,
  MUTATIONS: FEATURES_MUTATIONS,
  NAME_SPACE,
  NS: x => `${NAME_SPACE}/${x}`
};

export const features = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};